import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';

import '@styles/Common/Common.css'


export default function SubmitButtonCommon(props) {

  let history = useHistory()

  const [goBack,setGoBack] = useState(props.goBack && props.goBack === true ? true : false)
  const [disabled] = useState(!props.disabled || props.disabled === false ? false : true)
  const handleClick = (event) => {
    event.preventDefault()
    if(goBack) {
      history.goBack()
    }
    else props.handleClick()
  }

  return (
    <>
      <Button
        id={props.id}
        className={"actionButton "+props.className}
        variant="contained"
        disabled={disabled}
        fullWidth={props.fullWidth}
        onClick={(event) => handleClick(event)}
      >{props.title}
      </Button>
    </>
  )
}
