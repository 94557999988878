import React, { useEffect, useState, useContext } from "react"
import { Typography } from "@material-ui/core";
import { useParams, useHistory } from 'react-router-dom'

import Axios from "axios"
import { host } from "@utils/config";
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"
import { UserContext } from '@context/userContext'
import OrganizationForm from "@components/Organization/OrganizationForm";
import { useTranslation } from "react-i18next";
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function EditOrganization() {
  let { id } = useParams();
  const [user] = useContext(UserContext)

  const [t] = useTranslation("common")
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  const [organization, setOrganization] = useState(null)
  let history = useHistory()

  useEffect(() => {
    getRequest(host + '/organizations/' + id)
      .then(res => {
        setOrganization(res)
      })
  }, [setOrganization, id])

  const submitRequest = async (org) => {
    let _id = org._id
    delete org._id

    Axios.put(host + "/organizations/" + _id, { ...org })
      .then(res => {
        if (res.data.objectType)
          snackForOrganization("success", t('messages.success.organizations.editRequest'))
        else snackForOrganization("success", t('messages.success.organizations.update'))
        history.goBack()
      })
      .catch(err => {
        snackForOrganization("error", t('messages.error.organizations.update'))
      })
  }

  const snackForOrganization = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
      {(organization) &&
        <>
          <BreadCrumbs crumbs={[
            { href: "/#/", name: "home" },
            { href: "/#/admin", name: "admin" },
            { href: "/#/admin/organizations", name: "organizations" },
            { name: "organizations_update" }
          ]} />
          <Typography component="h2" className="admin__card--title">{t('titles.organizations.update')}</Typography>
          <div >
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.organization.form')}</Typography>
          </div>
          <OrganizationForm
            _id={organization._id}
            name={organization.name}
            url={organization.url}
            email={organization.email}
            address={organization.address}
            fax={organization.fax}
            phones={organization.phones}
            latitude={organization.gps ? organization.gps.lat : null}
            longitude={organization.gps ? organization.gps.lng : null}
            schedule={organization.schedule}
            comments={organization.comments}
            notes={organization.notes}
            country={organization.country[0]}
            terms={organization.terms}
            // liability={organization.liability}
            legalForm={organization.legalForm}
            provider={organization.provider}
            ringgoldID={organization.ringgoldID}
            organizationType={organization.organizationType ? organization.organizationType[0] : null}
            snackForOrganization={snackForOrganization}
            submitLable={t('organizations.form.title.update')}
            submitOrganization={submitRequest}
          />
        </>
      }
    </div>
  )
}
