import React, { useEffect, useState, useContext } from 'react'
import { Card, Grid, Hidden, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import Tab from '@components/Common/Tab/Tab'

import ApexLineChart from '@components/Charts/ApexLineChart'
import ApexMultiChart from '@components/Charts/ApexMultiChart'
import TextLable from '@components/Common/TextLable'
import BadgeCommon from '@components/Common/Badge';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import Terms from '@components/Configuration/Terms.js'

import { Map } from "@components/Common/Map/Map";
import { Layers, TileLayer } from "@components/Common/Map/TileLayer";

import "@styles/backOffice/Repository/Repositories.css"


import ExportMenu from '@components/Repository/ExportMenu'

export default function RepositoryPage(props) {
  const { id } = useParams()
  const [t, i18n] = useTranslation("common")
  const [repository, setRepository] = useState(null)
  const [zoom, setZoom] = useState(16);
  useEffect(() => {
    getRequest(host + "/repositories/" + id)
      .then(res => {
        setRepository(res)
        props.setTitle(res.name)
        props.setDescription(getTranslation(res.description, i18n.language, i18n.languages[i18n.languages.length - 1]))
        props.setAvailable(res.healthCheck.available)
      })
  }, [setRepository, id])
  let history = useHistory()

  const handleOpenEdit = (event, id) => {
    event.preventDefault();
    history.push("/repositories/update/" + id)
  }
  function repoTypeMetadataValues(attr) {
    if (!Array.isArray(attr.value)) {
      if (attr.type === 'text' || attr.type === 'longText')
        if (attr.multilang)
          return getTranslation(attr.value, i18n.language, i18n.languages[i18n.languages.length - 1])
        else
          return attr.value
      else if (attr.type === 'date')
        return attr.value.split('T')[0]
      else if (attr.type === 'url')
        return attr.value
      else if (attr.type === 'bool')
        return attr.value === 'true' ? "&#10003;" : "&#10005;"
      else if (attr.type === 'listOfValues')
        if (attr.multilang)
          return attr.value.i18n && Object.keys(attr.value.i18n).length > 0 && !Object.keys(attr.value.i18n).some(k => attr.value.i18n[k] === '') ? [getTranslation(attr.value.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])] : null
        else
          return [attr.value.value]
    } else if (attr.value && !attr.value.some(at => !at)) {
      if (attr.type === 'text' || attr.type === 'longText')
        if (attr.multilang)
          return attr.value.filter(at => at !== undefined && at !== null && Object.keys(at).length !== 0 && Object.keys(at).every(k => at[k] !== '')).map(at => getTranslation(at, i18n.language, i18n.languages[i18n.languages.length - 1]))
        else
          return attr.value.map(at => at)
      else if (attr.type === 'date')
        return attr.value.map(at => at.split('T')[0])
      else if (attr.type === 'url')
        return attr.value.map(at => at)
      else if (attr.type === 'listOfValues')
        if (attr.multilang)
          return attr.value.map(at => getTranslation(at.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))
        else
          return attr.value.map(at => at.value)
    }
  }

  return (repository &&
    <div className="margin__top--32px">
      <>
        <div
          className="repositories__singleTab--title"
        >
          <div className="repositories__singleTab--title-text">
            {t('titles.repositories.header.identification')}
          </div>
        </div>
        <Card elevation={0} className="repositories__singleTab--content">
          <Grid container>
            <Grid item xs={12} md={6} className="fields__padding--right-grid">
              {repository.logo && <img className="repository__card--cardmedia" src={host + repository.logo.url} alt={repository.name} />}

              <Hidden mdUp>
                <Grid container className="textField__container">
                  {repository.badge && repository.badge.map((badge, index) => (
                    <Grid item xs={2} md={1} key={"badge" + index}>
                      <Tooltip title={getTranslation(badge.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}>
                        <BadgeCommon tag="avatar" name={getTranslation(badge.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])} url={badge.i18n.badge.url} />
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
                {repository.name && repository.name.length > 0 &&
                  <TextLable
                    id="name"
                    title={t('labels.repositories.name')}
                    value={getTranslation(repository.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />}
                {repository.description && repository.description.length > 0 &&
                  <TextLable
                    id="description"
                    title={t('labels.repositories.description')}
                    value={getTranslation(repository.description, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />
                }
                {repository.url && repository.url.length > 0 &&
                  <TextLable
                    id="url"
                    title={t('labels.repositories.url')}
                    value={repository.url}
                    url={true}
                  />
                }
              </Hidden>
              {repository.repositoryType && repository.repositoryType.length > 0 &&
                <TextLable
                  id="repositoryType"
                  title={t('labels.repositories.repositoryType')}
                  value={getTranslation(repository.repositoryType[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                />
              }
              {repository.keyWords && repository.keyWords.length > 0 &&
                <TextLable
                  id="keyWords"
                  title={t('labels.repositories.keyWords')}
                  value={repository.keyWords.map(content => getTranslation(content, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                  isArray={true}
                />
              }
              {repository.software && repository.software.length > 0 &&
                <TextLable
                  id="software"
                  title={t('labels.repositories.software')}
                  value={getTranslation(repository.software[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                />
              }
              {repository.content && repository.content.length > 0 &&
                <TextLable
                  id="content"
                  title={t('labels.repositories.content')}
                  value={repository.content.map(content => getTranslation(content.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                  isArray={true}
                />
              }
              {repository.contentFormat && repository.contentFormat.length > 0 &&
                <TextLable
                  id="contentFormat"
                  title={t('labels.repositories.contentFormat')}
                  value={repository.contentFormat.map(content => getTranslation(content.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                  isArray={true}
                />
              }
              {repository.lang && repository.lang.length > 0 &&
                <TextLable
                  id="language"
                  title={t('labels.repositories.language')}
                  value={repository.lang.map(language => getTranslation(language.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                  isArray={true}
                />
              }
              {repository.subject && repository.subject.length > 0 &&
                <TextLable
                  id="subject"
                  title={t('labels.repositories.subject')}
                  value={repository.subject.map(sub => getTranslation(sub.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                  isArray={true}
                />
              }
              {repository.utility && repository.utility.length > 0 &&
                <TextLable
                  id="utility"
                  title={t('labels.repositories.utility')}
                  value={repository.utility.map(utility => getTranslation(utility.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                  isArray={true}
                />
              }
              {repository.domain && repository.domain.length > 0 &&
                <TextLable
                  id="domain"
                  title={t('labels.repositories.domain')}
                  value={getTranslation(repository.domain[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                />
              }
              {repository.repoTypeMetadata && repository.repoTypeMetadata.length > 0 && repository.repoTypeMetadata.some(dm => dm.value !== '') ?
                <>

                  <Typography className="textField__typography--subtitle hidden__title" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    {t('titles.repositories.header.repoTypeMetadata')}
                  </Typography>

                  {repository.repoTypeMetadata.sort((attr1, attr2) => attr1.order - attr2.order).map(attr =>
                    attr.value !== undefined && attr.value !== null && !attr.multiple ?
                      <TextLable
                        key={"repositoryType-attribute-" + attr.id}
                        id={"repositoryType-attribute-" + attr.id}
                        title={t(`repositoryTypes.${repository.repositoryType[0].value}.${attr.id.split('###')[2]}`)}
                        value={repoTypeMetadataValues(attr)}
                        isArray={attr.type === 'listOfValues'}
                        url={attr.type === 'url'}
                      />
                      :
                      attr.value !== undefined && attr.value !== null && attr.multiple && attr.value.length > 0 && attr.value.some(at => at !== '' && at !== null && at !== undefined) ?
                        <TextLable
                          key={"repositoryType-attribute-" + attr.id}
                          id={"repositoryType-attribute-" + attr.id}
                          title={t(`repositoryTypes.${repository.repositoryType[0].value}.${attr.id.split('###')[2]}`)}
                          value={repoTypeMetadataValues(attr)}
                          isArray={attr.type === 'listOfValues' || attr.type === 'url' && attr.multiple}
                          url={attr.type === 'url'}
                        />
                        : ''

                  )
                  }
                </>
                : ''
              }
            </Grid>
            <Grid item xs={12} md={6}>
              <Hidden smDown>
                <Grid container className="textField__container">
                  {repository.badge && repository.badge.map((badge, index) => (
                    <Grid item xs={2} md={1} key={"badge" + index}>
                      <Tooltip title={getTranslation(badge.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}>
                        <>
                          <BadgeCommon tag="avatar" name={getTranslation(badge.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])} url={badge.i18n.badge.url} />
                        </>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
                {repository.name && repository.name.length > 0 &&
                  <TextLable
                    id="name"
                    title={t('labels.repositories.name')}
                    value={getTranslation(repository.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />
                }
                {repository.description && Object.keys(repository.description).length > 0 &&
                  <TextLable
                    id="description"
                    title={t('labels.repositories.description')}
                    value={getTranslation(repository.description, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />
                }
                {repository.url && repository.url.length > 0 &&
                  <TextLable
                    id="url"
                    title={t('labels.repositories.url')}
                    value={repository.url}
                    url={true}
                  />
                }
              </Hidden>

              <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                {t('titles.repositories.header.additionalInformation')}
              </Typography>

              {repository.link && repository.link.length > 0 &&
                <div>
                  <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    {t('labels.repositories.link.supTitle')}
                  </Typography>
                  {repository.link.map((link, index) => <TextLable
                    key={"link-" + index}
                    id={"link-" + index}
                    title={getTranslation(link.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                    value={link.link}
                    url={true}
                  />
                  )}
                </div>
              }
              {repository.handlePrefix && repository.handlePrefix.length > 0 &&
                <TextLable
                  id="handlePrefix"
                  title={t('labels.repositories.handlePrefix')}
                  value={repository.handlePrefix}
                />
              }
              {repository.targetGroup && repository.targetGroup.length > 0 &&
                <TextLable
                  id="targetGroup"
                  title={t('labels.repositories.targetGroup')}
                  value={repository.targetGroup.map(targetGroup => getTranslation(targetGroup.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                  isArray={true}
                />
              }
              {repository.metadataFormat && repository.metadataFormat.length > 0 &&
                <TextLable
                  id="metadataFormat"
                  title={t('labels.repositories.metadataFormat')}
                  value={repository.metadataFormat.map(metadataFormat => getTranslation(metadataFormat.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                  isArray={true}
                />
              }
              {repository.creationDate && <TextLable
                id="creationDate"
                title={t('labels.repositories.creationDate')}
                value={repository.creationDate}
              />
              }
              {repository.terminationDate && <TextLable
                id="terminationDate"
                title={t('labels.repositories.terminationDate')}
                value={repository.terminationDate || t('common.labels.notDefined')}
              />
              }
              {repository.owner && repository.owner.email && <TextLable
                id="owner"
                title={t('labels.repositories.owner')}
                value={repository.owner.email || t('common.labels.notDefined')}
              />
              }
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className="repositories__container--icons--page repositories__grid--margin-top repositories__container--bottom">
            <ExportMenu
              id={repository.id}
              item={repository}
            />
            <Tooltip placement="top" title={t('tooltip.repositories.edit')}>
              <IconButton id={'repository-edit-button-' + repository.id} aria-label="arrow drop Up"
                size="small" onClick={(event) => handleOpenEdit(event, repository.id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Card>
      </>
      <>
        <div
          className="repositories__singleTab--title"
        >
          <div className="repositories__singleTab--title-text">
            {t('titles.organizations.organization')}
          </div>
        </div>
        <Card elevation={0} className="repositories__singleTab--content">
          <Grid container>
            <Grid item xs={12} md={6} className="fields__padding--right">
              <TextLable
                id="organizations-name"
                title={t('labels.organizations.name')}
                value={getTranslation(repository.organization.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                org={"/#/admin/organizations/" + repository.organization.id}
              />
              <TextLable
                id="organizations-url"
                title={t('labels.organizations.url')}
                value={repository.organization.url}
                url={true}
              />
              {repository.organization.country && repository.organization.country.length > 0 && <TextLable
                id="organizations-country"
                title={t('labels.organizations.country')}
                value={getTranslation(repository.organization.country[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
              />}
            </Grid>
            <Grid item xs={12} md={6} className="fields__padding--left">
              {repository.organization.organizationType && repository.organization.organizationType.length > 0 && <TextLable
                id="organizations-organizationType"
                title={t('labels.organizations.organizationType')}
                value={getTranslation(repository.organization.organizationType[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
              />}
              <TextLable
                id="organizations-email"
                title={t('labels.organizations.email')}
                value={repository.organization.email}
                url={true}
                mailto={true}
              />
              {repository.organization.country && <TextLable
                id="organizations-address"
                title={t('labels.organizations.address')}
                value={repository.organization.address}
              />}
            </Grid>
          </Grid>
        </Card>
      </>
      {repository.policy && repository.policy.length > 0 ?
        <>
          <div
            className="repositories__singleTab--title"
          >
            <div className="repositories__singleTab--title-text">
              {t('labels.repositories.policy.title')}
            </div>
          </div>
          <Card elevation={0} className="repositories__singleTab--content">
            {repository.policy.map((p, index) => (
              <Grid container className="textField__container" key={"policy-" + index}>
                <Grid item xs={12} md={12}>
                  <Typography id={"policy-" + index + "-label"} className="textField__typography" variant="subtitle1" style={{ display: 'flex' }} component="h2" color="textSecondary" gutterBottom>
                    <span dangerouslySetInnerHTML={{ __html: getTranslation(p.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) }} />
                  </Typography>
                </Grid>
                {p.description && <Grid item xs={12} md={12}>
                  <Typography id={"policy-" + index + "-description"} className="request__content--typography" variant="subtitle1" component="h3" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: getTranslation(p.description, i18n.language, i18n.languages[i18n.languages.length - 1]) }} />
                  </Typography>
                </Grid>}
                {p.link && <Grid item xs={12} md={12}>
                  <Typography id={"policy-" + index + "-link"} className="request__content--typography" variant="subtitle1" component="h3" color="textSecondary" gutterBottom>
                    <a target="_blank" href={p.link}>{p.link}</a>
                  </Typography>
                </Grid>}
                {!p.description && !p.link &&
                  <Typography id={"policy-" + index + "-noDescNoLink"} className="request__content--typography" variant="subtitle1" component="h3" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: t('common.labels.notDefined') }} />
                  </Typography>
                }

              </Grid>
            ))}
          </Card>
        </>
        : ''}
      {repository.stats && repository.stats.length > 0 ?
        <>
          <div
            className="repositories__singleTab--title"
          >
            <div className="repositories__singleTab--title-text">
              {t('labels.repositories.statistics.supTitle')}
            </div>
          </div>
          <Card elevation={0} className="repositories__singleTab--content">
            <Grid container>
              {repository.stats.map((stat, index) => (
                <Grid item xs={12} xl={stat.objectType === "VALUE" ? 12 : 6} key={"stats" + index} className="card__padding">
                  {stat.objectType === "VALUE" &&
                    <>
                      <Card elevation={0} className="stats__container stats__green--card">
                        <Typography className="stats__green--card stats__text--card" variant="subtitle1" component="h2" color="textSecondary">
                          {stat.body.value} {getTranslation(stat.body.title, i18n.language, i18n.languages[i18n.languages.length - 1])}
                        </Typography>
                      </Card>
                    </>
                  }
                  {
                    stat.objectType === "TIMESERIES" &&
                    <ApexLineChart
                      data={stat.body.value}
                      value={"value"}
                      argument={"key"}
                      title={stat.body.title}
                    />
                  }
                  {
                    stat.objectType === "MULTITIMESERIES" &&
                    <ApexMultiChart
                      data={stat.body.value}
                      keys={stat.keys}
                      argument={"key"}
                      title={stat.body.title}
                    />
                  }
                </Grid>
              ))}
            </Grid>
          </Card>
        </>
        : ''}
      {repository.terms && repository.terms.length > 0 && repository.terms[repository.terms.length - 1].body ? (
        <>
          <div
            className="repositories__singleTab--title">
            <div className="repositories__singleTab--title-text">
              {t('labels.repositories.terms.supTitle')}
            </div>
          </div>
          <Card elevation={0} className="repositories__singleTab--content">
            <Grid container></Grid>
            <Terms
              showTerms={true}
              terms={repository.terms}
              value={t('labels.repositories.terms.supTitle')}
              acceptedAtLabel={t('labels.repositories.terms.acceptedAt')}
              showUser={true}
            />
          </Card>
        </>
      )
        :
        <></>
      }
      {repository.gps && repository.gps.lat && repository.gps.lng &&
        <>
          <div
            className="repositories__singleTab--title">
            <div className="repositories__singleTab--title-text">
              {t('labels.repositories.gps.title')}
            </div>
          </div>
          <Card elevation={0} className="repositories__singleTab--content">
            <TextLable
              id="repository-gps"
              title={t('labels.repositories.gps.coordinates')}
              value={`${repository.gps.lat}, ${repository.gps.lng}`}
            />
            <Map center={[repository.gps.lng, repository.gps.lat]} zoom={zoom}>
              <Layers>
                <TileLayer
                  center={[repository.gps.lng, repository.gps.lat]}
                  zIndex={0}
                />
              </Layers>
            </Map>
          </Card>
        </>}
      <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
        <SubmitButtonCommon
          id="repository-back-button"
          className="form__button--color-error"
          title={t('table.confirm.backButton')}
          goBack />
      </Grid>
    </div>
  )
}
