import React, { useContext } from 'react'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"

import PostForm from '@components/Post/PostForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import '@styles/backOffice/Post/Posts.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewPost() {
  const [t] = useTranslation("common")
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  let history = useHistory()

  const submitPost = async (post) => {
    try {
      let postRes = await Axios.post(host + '/posts', post)
      snackForPost("success", t('messages.success.post.create'))
      history.goBack()
    }
    catch (err){
      if(err.response.data.message === 'Slug is already in use')
        snackForPost("error", t('messages.error.post.create') + '. ' + t('messages.error.post.create_slug') )
      else 
        snackForPost("error", t('messages.error.post.create'))
    }
  }

  const snackForPost = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
    <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin" , name: "admin"},
        { href: "/#/admin/posts" , name: "posts"},
        {name: "posts_new"}
      ]} />
      <Typography component="h2" className="admin__card--title">{t('titles.post.create')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.post.form')}</Typography>
      </div>
      <PostForm
        snackForPost={snackForPost}
        submitPost={submitPost}
        submitLable={t('post.form.submit.create')}
      />
    </div>
  )
}
