import React from 'react'

import LogPage from '@components/Log/Log'

import '@styles/backOffice/Log/Log.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Log() {
  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/logs", name: "logs"},
        { name: "log" },
      ]} />
      <LogPage />
    </div>
  )
}
