import React, { useState, useEffect } from 'react'
import { CardContent, CardHeader, Grid, Card, Divider, Hidden } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import "@styles/Common/NavBar.css"

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import getTranslation from '@utils/functions/utilities.js'


export default function PostMenu(props) {
  const [t, i18n] = useTranslation('common')
  const [posts, setPosts] = useState(null)
  const [anchorPosts, setAnchorPosts] = useState(null)
  const [isOpenPosts, setIsOpenPosts] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false)
  let history = useHistory()

  const handleClose = () => {
    setPosts(null)
    setAnchorPosts(null)
    setIsOpenPosts(false);
  };

  const handleOpenPosts = async (event) => {
    if (props.tag !== 'small') {
      setAnchorPosts(event.currentTarget);
      await getRequest(host + '/posts?_sort=createdAt:DESC')
        .then(res => {
          setPosts(res.entities)
          setIsOpenPosts(true)
        })
    }
    else {
      if (!isExpanded) {
        await getRequest(host + '/posts?_sort=createdAt:DESC')
          .then(res => {
            setPosts(res.entities)
          })
      }
      setIsExpanded(!isExpanded)
    }
  }

  const handlePosts = (slug) => {
    history.push('/posts/s/' + slug)
    if (props.tag !== 'small') {
      setIsOpenPosts(false)
      setAnchorPosts(null)
    }
    else {
      props.setIsOpenSmallMenu(false)
    }
  }

  return (
    <>
      <Hidden lgUp>
        <ListItem id="nav-about-button" button onClick={() => handleOpenPosts()}>
          <ListItemText primary={t('navBar.about')} />
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List disablePadding>
            <ListItem id="nav-aboutIndexar-button" button onClick={() => history.push("/about")}>
              <ListItemText primary={t('navBar.aboutIndexar')} />
            </ListItem>
            {(posts && posts.length !== 0) &&
              posts.map((post, index) => {
                return (
                  <ListItem id={'post-' + index} key={post._id} button onClick={() => handlePosts(post.slug)}>
                    <ListItemText primary={getTranslation(post.title, i18n.language, i18n.languages[i18n.languages.length - 1])} />
                  </ListItem>
                )
              })
            }
            <ListItem id="nav-contact-button" role="selectMenu" onClick={() => history.push("/contact")}>
              <ListItemText primary={t('navBar.contact')} />
            </ListItem>
          </List>
        </Collapse>
      </Hidden>
      <Hidden mdDown>
        {props.tag === "small" ?
          <ListItem id="nav-about-button" button onClick={() => handleOpenPosts()}>
            <ListItemText primary={t('navBar.about')} />
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          :
          <Button role="navBar" id="nav-about-button" className={"appNavBar__button appNavBar__button--dimentions"} aria-controls="simple-menu" aria-haspopup="true" color="inherit" onClick={handleOpenPosts}>
            <span className={history.location.pathname.startsWith("/about") ? "border__button" : ""}>{t('navBar.about')}</span>
          </Button>
        }
        {(posts && posts.length !== 0) &&
          <Menu
            id="simple-menu"
            anchorEl={anchorPosts}
            getContentAnchorEl={null}
            className="appNavBar__menu--user appNavBar__post--menu--max--width"
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={isOpenPosts}
            onClose={handleClose}
          >
            <MenuItem id="nav-aboutIndexar-button" role="selectMenu" onClick={() => history.push("/about")}>
              {t('navBar.aboutIndexar')}
            </MenuItem>
            {
              posts.map((post, index) => {
                return (
                  <MenuItem id={'post-' + index} role="selectMenu" onClick={() => handlePosts(post.slug)} key={post._id}>
                    {getTranslation(post.title, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  </MenuItem>
                )
              })
            }
            <MenuItem id="nav-contact-button" role="selectMenu" onClick={() => history.push("/contact")}>
              {t('navBar.contact')}
            </MenuItem>

          </Menu>
        }
      </Hidden>
    </>
  )
}