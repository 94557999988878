import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import AdminDrawer from '@components/Common/Drawer/AdminDrawer';
import AppNavBar from '@components/Common/NavBar/AppNavBar'
import Footer from '@components/Common/NavBar/Footer'

import { UserContext } from '@context/userContext'
import adminAccess from '@utils/comparators/AdminAccess'
import ReactGA from 'react-ga4';
import Plausible from 'plausible-tracker'
import { useMatomo } from '@datapunt/matomo-tracker-react'


import Axios from 'axios';
import { host } from '@utils/config'
import Metatags from '../Metatags';
import { useTranslation } from 'react-i18next'


import '@styles/index.css'

function AdminLayout({ component: Component, ...rest }) {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [t, i18n] = useTranslation("common")
  let path = rest.path.replace(/\//, "").replace(/\//g, "_").replace("admin_", "").replace(/ies_:id/, "y").replace(/([rpngc])s_:id/, "$1").replace(/_:id/, "")

  return (
    <>
      {!path.includes("/") && <Metatags title={t('breadcrumbs.' + path)} />}
      <AppNavBar adminMode={true} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}></AppNavBar>
      <AdminDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}></AdminDrawer>
      <div className="App-body-admin">
        <Route {...rest} render={(props) => (
          <Component {...rest} {...props} />
        )} />
      </div>
      <Footer />
    </>
  )
}

export default function AdminPrivateRoute({ component: Component, ...rest }) {
  const [userLogin, setUserLogin] = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const { trackPageView } = useMatomo()
  useEffect(() => {
    fetch('manifest.json')
      .then((r) => r.json())
      .then((data) => {
        if (data['DRD_PLAUSIBLE_API']) {
          const { trackPageview } = Plausible({
            domain: window.location.host,
            apiHost: data['DRD_PLAUSIBLE_API']
          })
          trackPageview({ url: location.pathname + location.search })
        }
      })
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    trackPageView({ href: location.pathname + location.search })
    window.scrollTo(0, 0)
  }, [location.pathname, location.search])


  const isAuthenticated = async () => {
    try {
      Axios.defaults.withCredentials = true;
      let res = await Axios.get(host + '/users/me')
      setUserLogin(res.data)
      setLoading(false)
    }
    catch {
      setLoading(false)
    }
  }

  if (userLogin) {
    if (!rest.method && userLogin.group.permission.find(p => p.controller === rest.controller)) {
      return <AdminLayout component={Component} {...rest} />
    }
    else
      if ((rest.controller === "repository" || rest.controller === "organization") && (rest.method === "create" || rest.method === "createRequest" || rest.method === "editRequest"))
        return <AdminLayout component={Component} {...rest} />
      else if (adminAccess(rest.controller, rest.method, userLogin.group.permission)) {
        return <AdminLayout component={Component} {...rest} />
      }
      else return <Redirect to={{ pathname: '/login', state: location.pathname }} />
  }
  else {
    isAuthenticated()
    if (loading) {
      return <CircularProgress />
    }
    else {
      return !userLogin && <Redirect to={{ pathname: '/login', state: location.pathname }} />
    }
  }
}
