import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next'
import cienciaid from '@utils/assets/logo-cienciaid.svg'
import cienciaid_min from '@utils/assets/logo-cienciaid-min.svg'
import orcid from '@utils/assets/logo_ORCID_min.svg'
import authgov from '@utils/assets/auth_gov_logo.svg'
import iconInstitutional from '@utils/assets/icon-institutional.svg'
import iconEU from '@utils/assets/icon-eu.svg'


import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import '@styles/frontOffice/User/Login.css'
import { Grid, Link } from '@material-ui/core';

export default function SAMLLogin() {
    const [t, i18n] = useTranslation('common')
    const [saml, setSAML] = useState(null)
    useEffect(() => {
        getRequest(host + '/configurations/public?type=SAML')
            .then(res => setSAML(res.idps))
    }, [])
    return (
        <Card className="login__container--SAML">
            <div className='login__card--contents'>
                <img src={cienciaid} alt={cienciaid} className="login__scienceid--title--img" ></img>
                {saml && saml.cienciaid &&
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Button id="cienciaid" href={host + "/saml/login?idp=" + encodeURIComponent(saml.cienciaid)} className="login__cienciaID--minibox button__transparent--text-color">
                                <span style={{ flexDirection: 'column', display: "flex", alignItems: "center" }}>
                                    <Typography gutterBottom>
                                        <img src={cienciaid_min} alt={cienciaid_min} className="login__science--id--button login__scienceid--box--img"></img>
                                        {t('titles.users.scienceID.info')}<b> {t('titles.users.scienceID.scienceID')}</b>
                                    </Typography>
                                </span>
                            </Button>
                        </Grid>
                        <Grid xs={12} item>
                            <Link href="https://www.ciencia-id.pt/CienciaID/Passo1.aspx" variant='caption' className='login__cienciaID--help'>
                                {t('titles.users.scienceID.help')}
                            </Link>
                        </Grid>
                    </Grid>
                }
                {saml && saml.institutional &&
                    <Button id="institutional" href={host + "/saml/login?idp=" + encodeURIComponent(saml.institutional)} className="login__institutional--box button__transparent--text-color">
                        <Grid container direction="row"
                            alignItems="center"
                            className='login__contents'>
                            <Grid item xs={1} md={1}>
                                <img src={iconInstitutional} alt={iconInstitutional} className='login__institutional--icon' />
                            </Grid>
                            <Grid item xs={11} md={11}>
                                <span>
                                    <Typography gutterBottom>
                                        {t('titles.users.scienceID.info')}<b> {t('titles.users.institutional.info')}</b>
                                    </Typography>
                                </span>
                            </Grid>
                        </Grid>
                    </Button>
                }
                {saml && saml.orcid &&
                    <Button id="orcid" href={host + "/saml/login?idp=" + encodeURIComponent(saml.orcid)} className="login__orcID--minibox button__transparent--text-color">
                        <span style={{ flexDirection: 'row', display: "flex", alignItems: "center", width: "100%" }}>
                            <img src={orcid} alt={orcid} className="login__orcid--box--img"></img>
                            <Typography>
                                {t('titles.users.scienceID.info')}<b> {t('titles.users.scienceID.orcID')}</b>
                            </Typography>
                        </span>
                    </Button>
                }
                <Grid container className='login__authgov--grid'>
                    <Grid xs={1} item>
                        <img className='login__authgov--img' src={authgov} alt={authgov}></img>
                    </Grid>
                    <Grid xs={11} item>
                        <Typography className='login__authgov--text'>
                            <b>{t('titles.users.authgov.info')}</b>
                        </Typography>
                    </Grid>
                </Grid>
                {
                    saml && saml.cc &&
                    <Button id="authgov-cc" href={host + "/saml/login?idp=" + encodeURIComponent(saml.cc)} className="login__institutional--box button__transparent--text-color">
                        <Grid container direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={1}
                            className='login__contents'
                        >
                            <Grid item>
                                <RecentActorsIcon className='login__authgov--icon' />
                            </Grid>
                            <Grid item>
                                <Typography gutterBottom>
                                    <b>{t('titles.users.cc.info')}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Button>
                }
                {
                    saml && saml.cmd &&
                    <Button id="authgov-cmd" href={host + "/saml/login?idp=" + encodeURIComponent(saml.cmd)} className="login__institutional--box button__transparent--text-color">
                        <Grid container direction="row"
                            alignItems="center"
                            justify='center'
                            spacing={1}
                            className='login__contents'
                        >
                            <Grid item >
                                <VpnKeyIcon className='login__authgov--icon' />
                            </Grid>
                            <Grid item>
                                <Typography gutterBottom>
                                    <b>{t('titles.users.cmd.info')}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Button>
                }
                {
                    saml && saml.eidas &&
                    <Button id="authgov-eidas" href={host + "/saml/login?idp=" + encodeURIComponent(saml.eidas)} className="login__institutional--box button__transparent--text-color">
                        <Grid container direction="row"
                            alignItems="center"
                            justify='center'
                            spacing={1}
                            className='login__contents'>
                            <Grid item >
                                <img src={iconEU} alt={iconEU} className='login__authgov--icon' />
                            </Grid>
                            <Grid item >
                                <Typography gutterBottom>
                                    <b>{t('titles.users.eidas.info')}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Button>
                }
                <Grid container className='login__cmd--grid'>
                    <Grid xs={12} item>
                        <Link variant='caption' href='https://cmd.autenticacao.gov.pt/Ama.Authentication.Frontend/' className='login__cmd--help'>
                            {t('titles.users.cmd.join')}
                        </Link>
                    </Grid>
                    <Grid xs={12} item>
                        <Link variant='caption' href='https://www.autenticacao.gov.pt/web/guest/perguntas-frequentes' className='login__cmd--help'>
                            {t('titles.users.cmd.help')}
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </Card >
    )
}