import React from 'react'

import StatisticPage from '@components/Statistic/Statistic'

import '@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'
export default function StatisticView() {
  return (
    <div className="admin__container-bottom">

      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/statistics", name: "statistics" },
        { name: "statistic" },
      ]} />
      <StatisticPage />
    </div>
  )
}
