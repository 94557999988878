import React from 'react'

import OrganizationPage from '@components/Organization/Organization'

import '@styles/backOffice/Organization/Organizations.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Organization() {
  return (
    <div className="admin__container-bottom">
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/organizations", name: "organizations" },
        { name: "organization" }
      ]} />
      <OrganizationPage />
    </div>
  )
}
