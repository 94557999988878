import React from 'react'
import { Typography } from '@material-ui/core'

import RegisterPage from '@components/User/Register'

import '@styles/frontOffice/User/Register.css'
import { useTranslation } from 'react-i18next'

export default function Register() {
  const [t] = useTranslation('common')
  return (
    <div className="public__container--margin public__container--padding">
      <Typography component="h2" className="admin__card--title">{t('titles.users.register.title')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.user.register')}</Typography>
      </div>
      <RegisterPage />
    </div>
  )
}
