import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from 'react-i18next'

export const Metatags = props => {
    const [t, i18n] = useTranslation("common")
    let title = t('name') + (props.title && !props.title.startsWith("breadcrumbs") ? " - " + props.title : "")
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        {props.description && <meta name="description" content={props.description} />}
      </Helmet>
    )
}

export default Metatags
