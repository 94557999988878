import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import TableCommon from '@components/Common/Table';

import { UserContext } from '@context/userContext';

import '@styles/backOffice/Organization/Organizations.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Organizations() {
  const [t,i18n] = useTranslation('common')
  let history = useHistory()
  const [headers,setHeaders] = useState(null)
  const [user] = useContext(UserContext)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.organizations.name'),
        value: "name",
        sort: true
      },
      {
        name: t('labels.organizations.email'),
        value: "email",
        sort: true
      },
      {
        name: t('labels.organizations.url'),
        value: "url"
      },
      {
        name: t('labels.organizations.type'),
        value: "organizationType",
        sort: true
      },
      {
        name: t('labels.organizations.provider'),
        value: "provider",
        sort: true
      }
    ])
  },[t, setHeaders,i18n.language])

  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin" , name: "admin"},
          { href: "/#/admin/organizations" , name: "organizations"}
        ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-organizations-title" component="h2" className="admin__card--title">{t('titles.organizations.title')}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {(user && user.group.permission.find(p => p.controller === "organization" && (p.method === "create" || p.method==="createRequest"))) &&
            <Tooltip title={t('tooltip.organizations.create')}>
              <Button id="backoffice-organizations-add-button" className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/organizations/new')}
                startIcon={
                  <AddIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.create")}
              </Button>
            </Tooltip>
          }
        </Grid>
      </Grid>
     {headers && <TableCommon
        headers={headers}
        backendPath="/organizations"
        frontendPath="/organizations"
        sort="name"
        controller="organization"
      />}
    </div>
  );
}
