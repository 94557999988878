import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { Grid, Hidden } from '@material-ui/core';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Toolbar from '@material-ui/core/Toolbar';
import { Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import SearchBar from '@components/Common/SearchBar/SearchBar'
import Facets from '@components/Repository/Facets'
import ListRepositories from '@components/Repository/ListRepositories'

import '@styles/frontOffice/Repository/Repositories.css'
import getTranslation from '@utils/functions/utilities.js'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

const qs = require('qs')

export default function Repositories(props) {
  const [t, i18n] = useTranslation("common")
  const [repositories, setRepositories] = useState(null)
  const [facets, setFacets] = useState(null)
  const [checked, setChecked] = useState([]);
  const [indexes, setIndexes] = useState(null);
  const [numberRepositories, setNumberRepositories] = useState(0)
  let history = useHistory()
  const query = qs.parse(history.location.search.replace(/\?/, ''))
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [advanced, setAdvanced] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [key, setKey] = useState(0)
  const [expanded, setExpanded] = useState(null)

  useEffect(() => {
    let queryParam = {
      _start: query._start || 0,
      _limit: query._limit || 10,
      lang: i18n.language,
      ...query
    }
    let advancedQuery = []
    Object.entries(query).map(obj => {
      if (obj[0].includes("search"))
        advancedQuery.push({
          field: obj[0].split('.')[2].split("_")[0],
          value: obj[1],
          operator: "_" + obj[0].split('.')[2].split("_")[1]
        })
    })
    if (advancedQuery && advancedQuery.length > 0) setAdvanced(advancedQuery)

    let checkedQuery = []
    Object.entries(query).map(obj => {
      if (obj[0].includes("facets"))
        checkedQuery.push(obj[0].split('.')[2] + "##" + obj[1])
    })
    setChecked(checkedQuery)



    let queryString = qs.stringify(queryParam).replace(/facets\.[0-9]+\./g, "facet_").replace(/search\.[0-9]+\./g, "")

    let path = "/repositories?" + queryString
    getRequest(host + path)
      .then(res => {
        if (numberRepositories !== res.count) setPage(0)
        setRepositories(res.entities)
        setNumberRepositories(res.count)

        setFacets(res.facets)

        setPageSize(query._limit ? query._limit : 10)
        setKey(key + 1)
      })
  }, [setRepositories, history.location, i18n.language, setPage])

  useEffect(() => {
    let path = "/repositories/indexes"
    getRequest(host + path)
      .then(res => {
        setIndexes(res[0])
        setExpanded(Object.keys(res[0]).reduce((a, v) => ({ ...a, [v]: (v === "domain" ? true : false) }), {}))
      })
  }, [setIndexes])

  const drawer = (<div key={key}> {facets && indexes && expanded && <Facets facets={facets} checked={checked} indexes={indexes} expanded={expanded} />}</div>)

  return (
    <div className="repositories__no-image">
      <Hidden lgUp>
        <SwipeableDrawer
          variant="temporary"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
        >
          <Toolbar />
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
        >
          <Toolbar />
          {drawer}
        </Drawer>
      </Hidden>
      <div className="repositories__body--container public__container--padding-bottom">
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/repositories", name: "repositories" }
        ]} />
        <Typography id="repositories-title" component="h1" className="index__title content--card admin__card--title admin__form--title-margin">{t('titles.home.repositories')}</Typography>
        <div>
          <SearchBar search={query._q} advanced={advanced} />
        </div>
        <div className="repositories__margin--top-40px">
          {
            (repositories == null) ?
              <div /> :
              (repositories && repositories.length > 0) ?
                <ListRepositories openDrawer={setOpenDrawer} repositories={repositories} setPage={setPage} numberRepositories={numberRepositories} page={page} pageSize={pageSize} checked={checked} setChecked={setChecked} /> :
                <Grid container justify='space-between'>
                  <Grid item>
                    <div><h2>{t('labels.repositories.notFound')}</h2></div>
                  </Grid>
                  <Grid item>
                    <Hidden lgUp>
                      <Tooltip placement="top" title={t('tooltip.repositories.filter')}>
                        <Button
                          className="repositories__filter--button repositories__button--color" id="filter-menu" size="medium" onClick={() => setOpenDrawer(!openDrawer)}>
                          {t('labels.repositories.filter')}
                        </Button>
                      </Tooltip>
                    </Hidden>
                  </Grid>
                </Grid>
          }
        </div>
      </div>
    </div>
  )
}
