import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'

import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

export default function ControlledVocabularyPage() {
    const [t, i18n] = useTranslation('common')
    const [controlledVocabulary, setControlledVocabulary] = useState(null)
    const [langs, setLangs] = useState(null)
    let { id } = useParams()
    let history = useHistory()
    const [user] = useContext(UserContext)

    useEffect(() => {
        getRequest(host + '/controlled-vocabularies/' + id)
            .then(res => {
                setControlledVocabulary(res)
            })
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
            })
    }, [id, setControlledVocabulary])
    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{history.location.pathname.includes('badge') ? t('titles.controlledVocabulary.badge') : t('titles.controlledVocabulary.header')}</Typography>
                </Grid>
                <Grid item xs={6} className="admin__align--left">
                    {user &&
                        <Tooltip title={t('tooltip.repositories.create')}>
                            <Button
                                id="backoffice-repositories-add-button"
                                className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/' + (history.location.pathname.includes('badges') ? "badges" : "controlled-vocabularies") + '/update/' + id)}
                                startIcon={
                                    <EditIcon className="admin__icon--create" />
                                }
                                role="adminCards"
                            >
                                {t("common.buttons.edit")}
                            </Button>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
            <div >
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.controlled-vocabulary.show')}</Typography>
            </div>
            {controlledVocabulary &&
                <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
                    <Grid container>
                        {controlledVocabulary.field === 'badge' &&
                            <Grid item xs={12}>
                                <img className="badges__preview" src={host + controlledVocabulary.i18n.badge.url} alt={getTranslation(controlledVocabulary.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])} />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextLable
                                id="field"
                                title={t('labels.controlledVocabulary.field')}
                                value={controlledVocabulary.field}
                            />
                            <TextLable
                                id="value"
                                title={t('labels.controlledVocabulary.value')}
                                value={controlledVocabulary.value}
                            />
                        </Grid>
                        <Grid container>
                            {langs && langs.filter(lang => controlledVocabulary.i18n[lang]).map((lang, index) =>
                                <Grid key={lang + index} item xs={12}>
                                    <TextLable
                                        id={lang}
                                        title={t('labels.controlledVocabulary.label', { lang: lang })}
                                        value={controlledVocabulary.i18n[lang]}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container>
                            {controlledVocabulary.info && langs && langs.filter(lang => controlledVocabulary.info[lang]).map((lang, index) =>
                                <Grid key={lang + index} item xs={12}>
                                    <TextLable
                                        id={"info-" + lang}
                                        title={t('labels.controlledVocabulary.info') + "<sup> " + lang + "</sup>"}
                                        value={controlledVocabulary.info[lang]}
                                    />
                                </Grid>
                            )
                            }
                        </Grid>
                    </Grid>
                </Card>}
            <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
                <SubmitButtonCommon
                    id="controlled-vocabulary-back-button"
                    className="form__button--color-error"
                    title={t('table.confirm.backButton')}
                    goBack />
            </Grid>
        </>
    )
}
