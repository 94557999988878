import React, { useContext, useState, useEffect } from 'react'
import { Typography } from '@material-ui/core';

import { useParams } from 'react-router-dom'

import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import '@styles/frontOffice/Repository/Repositories.css'
import { SnackBarContext } from "@context/snackBarContext.js"
import { UserContext } from '@context/userContext'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import RepositoryForm from '@components/Repository/RepositoryForm'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import Metatags from '../../../Metatags';
import getTranslation from '@utils/functions/utilities.js'

export default function EditRepository(props) {
  const { id } = useParams()
  const [user] = useContext(UserContext)
  const [errors, setErrors] = useState([])
  const [langs, setLangs] = useState(null)
  const [uploadSize, setUploadSize] = useState(null)

  const [t, i18n] = useTranslation("common")
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [repoTypeMetadataErrors, setRepoTypeMetadataErrors] = useState(null)
  const [repoTypeMetadata, setRepoTypeMetadata] = useState(null)

  const [repository, setRepository] = useState(null)
  let history = useHistory()

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
        getRequest(host + "/repositories/" + id)
          .then(repo => {
            setRepository(repo)
            getRequest(host + '/configurations/public?type=REPOSITORYTYPE&&key=' + encodeURIComponent(repo.repositoryType[0].key))
              .then(res1 => {
                if (res1[0].body.attributes.length > 0) {
                  if (!repo.repoTypeMetadata || repo.repoTypeMetadata.length === 0) {
                    setRepoTypeMetadata(res1[0].body.attributes.map(attr => ({ ...attr, value: repoTypeMetadataValues(attr, res[0].body) })))
                    setRepoTypeMetadataErrors(res1[0].body.attributes.map(attr => false))
                  }
                  else {
                    let attributes = res1[0].body.attributes.filter(at => repo.repoTypeMetadata.findIndex(dm => dm.id === at.id) === -1);
                    if (attributes.length > 0) {
                      attributes = attributes.map(attr => ({ ...attr, value: repoTypeMetadataValues(attr, res[0].body) }))
                      setRepoTypeMetadata([...repo.repoTypeMetadata, ...attributes])
                      setRepoTypeMetadataErrors([...repo.repoTypeMetadata.map(attr => false), ...attributes.map(attr => false)])
                    }
                    else {
                      setRepoTypeMetadata(repo.repoTypeMetadata)
                      setRepoTypeMetadataErrors(repo.repoTypeMetadata.map(attr => false))
                    }
                  }
                }
                else {
                  setRepoTypeMetadata([])
                  setRepoTypeMetadataErrors([])
                }
              })
          })
      })
    getRequest(host + '/configurations/public?type=UPLOADSIZE')
      .then(res => {
        setUploadSize(res[0])
      })

  }, [setRepository, id])


  const repoTypeMetadataValues = (attr, langs) => {
    let value
    if (attr.type === 'text' || attr.type === 'longText' || attr.type === 'url')
      if (attr.multilang)
        value = langs.reduce((l, val) => ({ ...l, [val]: '' }), {})
      else
        value = ''
    else if (attr.type === 'date')
      value = null
    else if (attr.type === 'bool')
      value = false
    else if (attr.type === 'listOfValues')
      if (!attr.multiple)
        value = {}
      else
        value = []
    if (attr.multiple && attr.type !== 'bool' && attr.type !== 'listOfValues')
      value = [value]

    return value
  }

  const hasPermissions = () => {
    if (user.group.permission.some(p => p.type === 'users-permissions' && p.controller === 'user' && p.method === 'find'))
      return repository.owner
    else
      return { _id: repository.owner._id }

  }

  const submitRequest = async (rep) => {
    let newLogo;
    if (rep.newLogo && typeof rep.newLogo !== "undefined") {
      const data = new FormData()
      data.append('files', rep.newLogo)
      try {
        newLogo = await Axios.post(host + "/upload", data)
        newLogo = newLogo.data[0]
        rep.logo = { url: newLogo.url, _id: newLogo._id }
      } catch (err) {
        if (err.response.status === 403)
          snackForRepository("error", t('messages.error.repositories.logo_upload'))
        return
      }
    }

    delete rep.newLogo

    let _id = rep._id
    delete rep._id

    Axios.put(host + "/repositories/" + _id, {
      ...rep
    })
      .then(res => {
        if (res.data.objectType)
          snackForRepository("success", t('messages.success.repositories.editRequest'))
        else snackForRepository("success", t('messages.success.repositories.edit'))
        history.push((history.location.pathname.includes("admin") ? '/admin' : '') + '/repositories/' + _id)
      })
      .catch(err => {
        if (err.response.status === 304) {
          snackForRepository("error", t('messages.error.repositories.notmodified'))
        } else {
          if (err.response.data.message === 'Missing/Invalid repository fields') {
            setErrors(err.response.data)
            snackForRepository("error", t('messages.error.common.validation'))
          }
          else
            snackForRepository("error", t('messages.error.repositories.edit'))
        }
      })
  }

  const snackForRepository = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className={props.tag === 'admin' ? 'admin__container-bottom' : 'public__container--margin public__container--padding-bottom'}>
      {repository && langs && uploadSize && repoTypeMetadata &&
        <>
          {
            props.tag === 'admin' ?
              <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { href: "/#/admin/repositories", name: "repositories" },
                { name: "repositories_update" }
              ]} /> :
              <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/repositories", name: "repositories" },
                { name: "repositories_update" }
              ]} />
          }
          < Metatags title={getTranslation(repository.name, i18n.language, i18n.languages[i18n.languages.length - 1])} description={getTranslation(repository.description, i18n.language, i18n.languages[i18n.languages.length - 1])} />
          <Typography component="h2" className="admin__card--title">{t('titles.repositories.update')}</Typography>
          <div >
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.repository.form')}</Typography>
          </div>
          <RepositoryForm
            _id={repository._id}
            name={repository.name}
            logo={repository.logo}
            repositoryType={repository.repositoryType[0]}
            description={{ ...repository.description }}
            url={repository.url}
            link={repository.link}
            keyWords={repository.keyWords}
            software={repository.software[0]}
            content={repository.content}
            contentFormat={repository.contentFormat}
            language={repository.lang}
            latitude={repository.gps ? repository.gps.lat : null}
            longitude={repository.gps ? repository.gps.lng : null}
            subject={repository.subject}
            utility={repository.utility}
            policy={repository.policy}
            domain={repository.domain[0]}
            terms={repository.terms}
            badge={repository.badge}
            stats={repository.stats}
            organization={repository.organization}
            targetGroup={repository.targetGroup}
            metadataFormat={repository.metadataFormat}
            creationDate={repository.creationDate}
            terminationDate={repository.terminationDate}
            errors={errors}
            owner={hasPermissions}
            handlePrefix={repository.handlePrefix}
            langs={langs}
            uploadSize={uploadSize}
            repoTypeMetadata={repoTypeMetadata}
            repoTypeMetadataErrors={repoTypeMetadataErrors}
            snackForRepository={snackForRepository}
            submitLable={t('repositories.submit.create')}
            submitRepository={submitRequest}
          />
        </>
      }
    </div >
  )
}
