import React, { useState, useEffect, useContext } from 'react'
import { Container } from '@material-ui/core'

import RepositoryPage from '@components/Repository/Repository'
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';

import '@styles/frontOffice/Repository/Repositories.css'
import '@styles/frontOffice/Repository/Facets.css'
import '@styles/frontOffice/Repository/Repository.css'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import Metatags from '../../../Metatags';
import getTranslation from '@utils/functions/utilities.js'


export default function Repository(props) {
  const [t, i18n] = useTranslation("common")

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [available, setAvailable] = useState(true)

  let history = useHistory()
  const [user] = useContext(UserContext)

  return (
    <div className={props.tag === 'admin' ? 'admin__container-bottom' : 'public__container--margin public__container--padding-bottom'}>
      {props.tag === 'admin' ?
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin", name: "admin" },
          { href: "/#/admin/repositories", name: "repositories" },
          { name: getTranslation(title, i18n.language, i18n.languages[i18n.languages.length - 1]), fullTitle: true }
        ]} /> :
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/repositories", name: "repositories" },
          { name: getTranslation(title, i18n.language, i18n.languages[i18n.languages.length - 1]), fullTitle: true }
        ]} />
      }
      <Metatags title={getTranslation(title, i18n.language, i18n.languages[i18n.languages.length - 1])} description={getTranslation(description, i18n.language, i18n.languages[i18n.languages.length - 1])} />
      <Grid container>
        <Grid item lg={9} xs={12}>
          <Typography id="backoffice-repositories-title" component="h1" className="admin__card--title">{getTranslation(title, i18n.language, i18n.languages[i18n.languages.length - 1])}{
            !available &&
            <Tooltip placement="top" title={t('tooltip.repositories.unavailable')}>
              <WarningIcon className='repositories__icon--unavailable' />
            </Tooltip>
          }</Typography>
        </Grid>
        <Grid item xs={3} className="admin__align--left">
          {user && history.location.pathname.includes("admin") &&
            <Tooltip title={t('tooltip.repositories.edit')}>
              <Button
                id="backoffice-repositories-add-button"
                className="actionButton admin__button--create" size="medium" onClick={() => history.push((history.location.pathname.includes("admin") ? '/admin' : '') + '/repositories/update/' + history.location.pathname.split("repositories/")[1])}
                startIcon={
                  <EditIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.edit")}
              </Button>
            </Tooltip>
          }
        </Grid>
      </Grid>
      {user && <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.repository.show')}</Typography>
      </div>}

      <RepositoryPage setTitle={setTitle} setDescription={setDescription} setAvailable={setAvailable} />
    </div>


  )
}
