import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import getTranslation from '@utils/functions/utilities.js'
import { Grid } from '@material-ui/core';
import '@styles/backOffice/News/News.css'


export default function NewsMenu() {
  const [t, i18n] = useTranslation('common')
  let history = useHistory()
  const [news, setNews] = useState(null)

  useEffect(() => {
    getRequest(host + '/news-items')
      .then(res => {
        setNews(res.entities)
      })
  }, [setNews])
  return (

    <Box>
      <Typography gutterBottom variant="h4" align='center' component="h2">
        {t('titles.news.title')}
      </Typography>
      <Grid container alignItems="stretch" justify="center">
        <Grid container item spacing={2} xs={12} sm={10} md={8} justify="flex-start">
          {news && news.length > 0 && news.map(((n, index) =>
            <Grid key={"news-grid-" + index} item xs={12} sm={6} md={4} align="center">
              <Card className="home__news--card">
                <div onClick={() => history.push('/news/' + n._id)} className="news__cardAction">
                  <div className='news__image--container'>
                    {n.image ?
                      <CardMedia
                        className="news__card--media"
                        image={n.image ? host + n.image.url : 'default'}
                        title={n.title[i18n.language]}
                      />
                      :
                      <div className='news__card--media-bgcolor'></div>
                    }
                    <div className='news__overlay'></div>
                  </div>
                  <CardContent >
                    <Typography gutterBottom variant="h5" component="h2" align="left">
                      {getTranslation(n.title, i18n.language, i18n.languages[i18n.languages.length - 1])}
                    </Typography>
                    <Typography className="news_card--typography" variant="body2" color="textSecondary" align="left" component="p">
                      {getTranslation(n.description, i18n.language, i18n.languages[i18n.languages.length - 1])}
                    </Typography>
                    <Typography className="news_card--typography news__date--margin" variant="body2" color="textSecondary" align="left" component="p">
                      {n.newsDate}
                    </Typography>
                  </CardContent>
                </div>
                <CardActions className="news__card--actions">
                  <Tooltip title={t('tooltip.news.view')}>
                    <Button id={"view-news-button-" + index} className="news__button--view" onClick={() => history.push('/news/' + n._id)} size="small">
                      {t('labels.news.view_more')}
                    </Button>
                  </Tooltip>

                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  )

}