import Axios from 'axios'

export default function getRequest(path) {
  return new Promise((resolve,reject) => {
    Axios.defaults.withCredentials = true;
    Axios.get(path)
    .then(res => {
      return resolve(res.data)
    })
    .catch(error => {
      if (error.response && error.response.data) {
        if (error.response.data.statusCode === 403 || error.response.data.statusCode === 401) {
         return reject(window.location.reload())
        }
      }
    })
  })
  

}