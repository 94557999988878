import React, { useState, createContext, useEffect } from 'react'

import SnackBarCommon from '@components/Common/SnackBar';

export const SnackBarContext = createContext()

export const SnackBarContextProvider = props => {
  const [isSnackBar, setIsSnackBar] = useState(false)
  const [tag, setTag] = useState('success')
  const [message,setMessage] = useState('')

  return (
    <SnackBarContext.Provider value={[setIsSnackBar,setTag,setMessage]}>
      {props.children}
      <SnackBarCommon
        isSnackBar={isSnackBar}
        setIsSnackBar={setIsSnackBar}
        tag={tag}
        message={message}
      />
    </SnackBarContext.Provider>
  )
}