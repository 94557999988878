import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { UserContext } from '@context/userContext';

import adminAccess from '@utils/comparators/AdminAccess'

import TableCommon from '@components/Common/Table'

import '@styles/backOffice/User/Users.css'
import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Users() {
  const [t, i18n] = useTranslation('common')
  const [headers, setHeaders] = useState(null)
  const [user] = useContext(UserContext)

  let history = useHistory()

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.users.firstName'),
        value: "firstName",
        sort: true
      },
      {
        name: t('labels.users.lastName'),
        value: "lastName",
        sort: true
      },
      {
        name: t('labels.users.email'),
        value: "email",
        sort: true
      },
      {
        name: t('labels.users.contact'),
        value: "phone"
      },
      {
        name: t('labels.users.group'),
        value: "group",
        sort: true
      },
      {
        name: t('labels.users.provider'),
        value: 'provider',
        sort: true
      },
      {
        name: t('titles.organizations.organization'),
        value: "organization",
        sort: true
      },
    ])
  }, [t, setHeaders, i18n.language])

  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/users", name: "users" }
      ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-users-title" component="h2" className="admin__card--title">{t('titles.users.title')}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {(user && adminAccess('user', 'create', user.group.permission)) &&
            <Tooltip title={t('tooltip.users.create')}>
              <Button id="backoffice-users-add-button" className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/users/new')}
                startIcon={
                  <AddIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.create")}
              </Button>
            </Tooltip>
          }
        </Grid>
      </Grid>
      {headers && <TableCommon
        headers={headers}
        backendPath="/users"
        frontendPath="/users"
        sort="firstName"
        controller="user"
        hasAddButton={false}
      />}
    </div>
  );
}
