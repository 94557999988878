import React, { useState, useContext, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import RadioCommon from '@components/Common/Radio';
import WYSIWYGeditorCommon from '@components/Common/WYSIWYGeditor';

import { host } from '@utils/config';
import getRequest from '@utils/functions/axiosRequest'
import "@styles/Common/Common.css"

const _ = require('lodash');

export default function FAQForm(props) {
    const [t, i18n] = useTranslation("common")

    const [title, setTitle] = useState(props.title || {})
    const [body, setBody] = useState(props.body || {})
    const [langs, setLangs] = useState(null)

    const [isErrorTitle, setIsErrorTitle] = useState(false)
    const [isErrorBody, setIsErrorBody] = useState(false)

    const validateFAQ = () => {
        let res = true
        setIsErrorTitle(false)
        for (let lang of langs) {
            if (!title[lang]) {
                res = false
                setIsErrorTitle(true)
            }
        }

        setIsErrorBody(false)
        for (let lang of langs) {
            if (!body[lang]) {
                res = false
                setIsErrorBody(true)
            }
        }
        return res
    }

    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
            })
    }, [setLangs])


    const handleSubmitFAQs = () => {
        for (let doc of document.getElementsByClassName("se-code-view-enabled")) {
            doc.click()
            doc.click()
        }
        if (validateFAQ()) {
            let faq = {
                title: { ...title },
                body: { ...body },
            }
            props.submitFAQ(faq)
        }
        else {
            props.snackForFAQs("error", t('messages.error.common.validation'))
        }
    }

    const handleChangeTitle = (value, lang) => {
        let newValues = { ...title }
        newValues[lang] = value
        setTitle(newValues)
    }

    const handleSpreadTitle = (event, value) => {
        if (value !== undefined && value !== '') {
            let newValues = { ...title }
            langs.map(l => newValues[l] === '' || newValues[l] === undefined || event.type === 'dblclick' ? newValues[l] = value : '')
            setTitle(newValues)

        }
    }

    const handleChangeContent = (value, lang) => {
        let newValues = body
        newValues[lang] = value
        setBody(newValues)
    }

    return (
        <>
            <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
                <Grid container>
                    <Grid container className="textField__container--right">
                        {langs && langs.map((lang, index) =>
                            <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                <TextFieldCommon
                                    title={t('labels.faq.title') + " <sup>" + lang + "</sup>"}
                                    id={"title" + lang}
                                    required={true}
                                    value={title[lang]}
                                    isOpen={isErrorTitle}
                                    message={t('messages.error.faq.title')}
                                    label={t('common.labels.required')}
                                    type="text"
                                    fullWidth={true}
                                    handleChangeInput={(value) => handleChangeTitle(value, lang)}
                                    handleSpreadInput={(event, value) => handleSpreadTitle(event, value)}
                                    renderIcon={true}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container className="textField__container">
                        {langs && langs.map((lang, index) =>
                            <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                <Grid item xs={12} md={12}>
                                    <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                        <div dangerouslySetInnerHTML={{ __html: t('labels.faq.body') + " <sup>" + lang + "</sup>" }} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <WYSIWYGeditorCommon
                                        id={"content" + lang}
                                        lang={i18n.language}
                                        defaultValue={body[lang]}
                                        handleChange={(value) => handleChangeContent(value, lang)}
                                    />
                                    {isErrorBody && <FormHelperText id="content-error" error>{t('messages.error.faq.body')}</FormHelperText>}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Card>
            <Grid container className="form__button--justify-left">
                <SubmitButtonCommon
                    id="faq-save-button"
                    className="form__button--color-success"
                    title={t('common.buttons.save')}
                    handleClick={handleSubmitFAQs} />
                <SubmitButtonCommon
                    id="faq-cancel-button"
                    className="form__button--color-error"
                    title={t('common.buttons.cancel')}
                    goBack />
            </Grid>
        </>
    )
}
