import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function BreadCrumbs(props) {
  const [t] = useTranslation('common')
  return (
    <Breadcrumbs className="breadcrumbs__margin breadcrumbs__link--color" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      { props.crumbs.map((crumb, index) => (
           (index !== props.crumbs.length -1) ? <Link className="breadcrumbs__link--color" href={crumb.href} key={crumb}>
            {crumb.fullTitle ? crumb.name : t('breadcrumbs.' + crumb.name)}
          </Link> : 
          <Typography className="breadcrumbs__link--color" key={crumb}>{crumb.fullTitle ? crumb.name : t('breadcrumbs.' + crumb.name) }</Typography>
       
      ))}
    </Breadcrumbs>
  )

}