import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';


export default function LogPage() {
  const [t] = useTranslation('common')
  const [log, setLog] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/logs/' + id)
      .then(res => {
        setLog(res)
      })
  }, [setLog])

  return (
    <>
      <Typography id="backoffice-log-title" component="h2" className="admin__card--title">{t('titles.logs.log')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.log.show')}</Typography>
      </div>
      {log &&
        <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
          <Grid container>
            <TextLable
              id="created"
              title={t('labels.logs.created')}
              value={log.created}
            />
            <TextLable
              id="creator"
              title={t('labels.logs.creator')}
              value={log.creator}
            />
            <TextLable
              id="address"
              title={t('labels.logs.address')}
              value={log.address}
            />
            <TextLable
              id="method"
              title={t('labels.logs.method')}
              value={log.method}
            />
            <TextLable
              id="component"
              title={t('labels.logs.component')}
              value={log.component}
            />
            {log.action && <TextLable
              id="action"
              title={t('labels.logs.action')}
              value={log.action}
            />}


            {log.parameters && log.parameters.length > 0 && <TextLable
              id="parameters"
              title={t('labels.logs.parameters')}
              value={Object.keys(log.parameters).map(key => key + ": " + log.parameters[key])}
              isArray={true}
            />}
            <TextLable
              id="sucess"
              title={t('labels.logs.sucess')}
              value={log.success ? t('common.labels.true') : t('common.labels.false')}
            />
            <TextLable
              id="duration"
              title={t('labels.logs.duration')}
              value={log.duration + " ms"}
            />

            <TextLable
              id="url"
              title={t('labels.logs.url')}
              value={log.url}
            />
          </Grid>
        </Card>}
      <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
        <SubmitButtonCommon
          className="form__button--color-error"
          title={t('table.confirm.backButton')}
          goBack />
      </Grid>
    </>
  )
}
