import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import getTranslation from '@utils/functions/utilities.js'
import { Grid } from '@material-ui/core';
import '@styles/backOffice/Highlight/Highlight.css'


export default function HighlightsMenu() {
  const [t, i18n] = useTranslation('common')
  let history = useHistory()
  const [highlights, setHighlights] = useState(null)

  useEffect(() => {
    getRequest(host + '/highlights')
      .then(res => {
        setHighlights(res.entities)
      })

  }, [setHighlights])

  return (

    <Box>
      <Typography gutterBottom variant="h4" align='center' component="h2">
        {t('titles.highlights.title')}
      </Typography>
      <Grid container alignItems="stretch" justify="center">
        <Grid container item spacing={2} xs={12} sm={10} md={8} justify="flex-start">
          {highlights && highlights.length > 0 && highlights.map(((highlight, index) =>
            <Grid key={"highlights-grid-" + index} item xs={12} sm={6} md={4} align="center">
              <Card className={"home__highlights--card" + (index % 2 === 0 ? " highlights__card--blue" : " highlights__card--green")}>
                <div onClick={() => history.push('/highlights/' + highlight._id)} className="highlight__cardAction">
                  <CardContent>
                    <Typography className="highlights__typography--white highlights__title--spacing" gutterBottom variant="h4" component="h4" align="left">
                      {getTranslation(highlight.title, i18n.language, i18n.languages[i18n.languages.length - 1])}
                    </Typography>
                    <Typography className="highlights__repository--spacing highlights__subtitle" gutterBottom variant="h6" component="h6" align="left">
                      {getTranslation(highlight.repository.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                    </Typography>
                    <Typography className="highlights_card--typography highlights__typography--white highlights__typography--spacing" variant="body2" component="p" align="left">
                      {getTranslation(highlight.description, i18n.language, i18n.languages[i18n.languages.length - 1])}
                    </Typography>
                    <Typography className="highlights_card--typography highlights__typography--white highlights__typography--spacing highlights__date--margin" variant="body2" component="p" align="left">
                      {highlight.date}
                    </Typography>
                  </CardContent>
                </div>
                <CardActions className="highlights__card--actions">
                  <Tooltip title={t('tooltip.highlights.view')}>
                    <Button id={"view-highlights-button-" + index} className="highlights__button--view" onClick={() => history.push('/highlights/' + highlight._id)} size="small">
                      {t('labels.highlights.view_more')}
                    </Button>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  )

}