import React, { useState, useContext, useEffect } from 'react';
import {Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import TableCommon from '@components/Common/Table'

import { UserContext } from '@context/userContext';

import adminAccess from '@utils/comparators/AdminAccess'

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import '@styles/backOffice/Post/Posts.css'
import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'


export default function Posts() {
  const [t,i18n] = useTranslation("common")
  let history = useHistory()
  const [headers,setHeaders] = useState(null)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.post.title'),
        value: "title",
        sort: true
      }
    ])
  }, [setHeaders,i18n.language])

  const [user] = useContext(UserContext)

  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin" , name: "admin"},
          { href: "/#/admin/posts" , name: "posts"}
        ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-cms-title" component="h2" className="admin__card--title">{t('titles.post.title')}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {(user && adminAccess('post', 'create', user.group.permission)) && <Tooltip title={t('tooltip.post.create')}>
            <Button id="cms-add-button" className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/posts/new')}
              startIcon={
                <AddIcon className="admin__icon--create" />
              }
              role="adminCards"
            >
              {t("common.buttons.create")}
            </Button>
          </Tooltip>}
        </Grid>
      </Grid>
      {headers && <TableCommon
        headers={headers}
        backendPath="/posts"
        frontendPath="/posts"
        sort="name"
        controller="post"
        filter={'group=admin'}
      />}
    </div>
  );
}
