import React, { useContext, useEffect, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { TableSortLabel, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { Button, Grid } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BadgeCommon from '@components/Common/Badge';
import PublicIcon from '@material-ui/icons/Public';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import AutoCompleteCommon from '@components/Common/AutoComplete';
import getTranslation from '@utils/functions/utilities.js'

import Axios from 'axios'
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"
import { UserContext } from '@context/userContext';
import { DialogContext } from '@context/dialogContext'

import adminAccess from '@utils/comparators/AdminAccess'
import getRequest from '@utils/functions/axiosRequest'

import '@styles/Common/Common.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from "moment-with-locales-es6";

const qs = require('qs')

export default function TableCommon(props) {
  const params = qs.parse(useHistory().location.search.replace(/\?/, ''))

  const [t, i18n] = useTranslation('common')
  const [hasActions, setHasActions] = useState(true)
  const [hasRemoveButton, setHasRemoveButton] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(params._limit) || 10);
  const [page, setPage] = useState(params._start ? params._start / rowsPerPage : 0);
  const [count, setCount] = useState(0)
  const [entities, setEntities] = useState(null)

  const [orderBy, setOrderBy] = useState(params._sort ? params._sort.split(":")[0] : (props.sort || ""))
  const [order, setOrder] = useState(params._sort ? params._sort.split(":")[1].toLowerCase() : (props.order || 'asc'))
  const [search, setSearch] = useState(params._q || '')
  const [query, setQuery] = useState('')
  const [user] = useContext(UserContext)
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [setDialogOpen, setDialogMessage, setDialogCancel, setDialogConfirm, setDialogAction] = useContext(DialogContext)

  const emptyRows = props.data ? rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage) : 0;
  let history = useHistory()

  useEffect(() => {

    let start = params._start || page * rowsPerPage
    let limit = params._limit || rowsPerPage
    let sort = params._sort || orderBy
    let _q = params._q || query

    if (params._start === undefined) {
      setPage(0)
      setRowsPerPage(10)
      limit = 10
    }

    let path = host + props.backendPath + "?lang=" + i18n.language + "&_limit=" + limit + "&_start=" + start

    if (props.filter) path += "&" + props.filter
    if (sort || orderBy) path += "&_sort=" + (orderBy + ":" + order.toUpperCase())
    if (_q) path += "&_q=" + _q

    if (props.filter)
      if (props.filter.includes('type=REPOSITORYTYPE&key=repositoryType'))
        path = host + props.backendPath + "?lang=" + i18n.language + "&" + props.filter
    setHasActions(typeof props.hasActions === "undefined" ? true : props.hasActions)
    setHasRemoveButton(typeof props.hasRemoveButton === "undefined" ? true : props.hasRemoveButton)

    getRequest(path)
      .then(res => {
        if (props.filter && props.filter.includes('type=REPOSITORYTYPE&key=repositoryType')) {
          if (query === '') {
            setCount(res.entities[0].body.attributes.length)
            setEntities([...res.entities[0].body.attributes.sort((attr1, attr2) => attr1.order - attr2.order)].splice(start, limit))
          }
          else {
            let filter = res.entities[0].body.attributes.filter(attr => {
              let ret = false
              for (let e of Object.entries(attr.i18n)) {
                ret = e[1].includes(query) ? true : ret
              }
              ret = attr.type.includes(query) ? true : ret
              ret = attr.id.includes(query) ? true : ret
              return ret
            }).sort((attr1, attr2) => attr1.order - attr2.order);
            setCount(filter.length)
            setEntities(filter.splice(start, limit))
          }
        }
        else {
          setCount(res.count)
          setEntities(res.entities)
        }
      })
  }, [rowsPerPage, page, setEntities, i18n.language, order, orderBy, query, props.headers, props.hasActions])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    history.push("/admin" + props.frontendPath + "?" + qs.stringify({ ...params, _start: newPage * rowsPerPage }))
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    history.push("/admin" + props.frontendPath + "?" + qs.stringify({ ...params, _start: 0, _limit: parseInt(event.target.value, 10) }))

  };


  const handleDeleteData = async (event, data, index) => {
    event.stopPropagation()
    setDialogMessage(t('table.confirm.delete'))
    setDialogCancel(t('table.confirm.cancelButton'))
    setDialogConfirm(t('table.confirm.confirmButton'))
    let obj = {
      function: deleteData(data, index),
    }
    setDialogAction(obj)
    setDialogOpen(true)
  }

  const deleteData = (data, index) => async () => {
    try {
      let path = host + props.backendPath + "/" + data._id
      await Axios.delete(path)
      let newEntities = [...entities]
      newEntities.splice(index, 1)
      setEntities(newEntities)
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.actions'))
      setPage(0)
    }
    catch (error) {
      if (props.backendPath.includes("organizations") && error.response.data.message.includes("Unable to delete organization")) {
        setDialogMessage(<div>{t('messages.error.organizations.user_repository')}
          {error.response.data.data.repositories.length > 0 && <div><h3>{t('titles.repositories.title')}</h3><ol>{error.response.data.data.repositories.map((r, index) => <li key={"repo-" + index}><a href={"#/admin/repositories/" + r.id} target="_blank">{r.name[i18n.language]}</a></li>)}</ol></div>}
          {error.response.data.data.users.length > 0 && <div><h3>{t('titles.users.title')}</h3><ol>{error.response.data.data.users.map((u, index) => <li key={"user-" + index}><a href={"#/admin/users/" + u.id} target="_blank">{u.email}</a></li>)}</ol></div>}</div>)
        setDialogConfirm(null)
        setDialogOpen(true)
      }
      if (props.backendPath.includes("users") && error.response.data.message.includes("Unable to delete user")) {
        setDialogMessage(<div>{t('messages.error.users.repository')}
          {error.response.data.data.repositories.length > 0 && <div><h3>{t('titles.repositories.title')}</h3><ol>{error.response.data.data.repositories.map((r, index) => <li key={"repo-" + index}><a href={"#/admin/repositories/" + r.id} target="_blank">{r.name[i18n.language]}</a></li>)}</ol></div>}
        </div>)
        setDialogConfirm(null)
        setDialogOpen(true)
      }
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.actions'))
    }
  }

  const handleUpdateData = (event, data) => {
    event.stopPropagation()
    let path = '/admin' + props.frontendPath + "/update/" + data._id + (props.filter ? ("?" + props.filter) : "")
    history.push(path)
  }

  const handleChangeVisible = async (event, data, index) => {
    event.stopPropagation()
    try {
      let path = host + props.backendPath + "/" + data._id
      await Axios.put(path, { ...data, visible: !data.visible })
      let newEntities = [...entities]
      newEntities[index].visible = !data.visible
      setEntities(newEntities)
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.actions'))
      setPage(0)
    }
    catch {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.actions'))
    }
  }

  const handleClickData = (event, data) => {
    let path
    event.preventDefault()
    if (props.frontendPath === '/plugins')
      event.stopPropagation()
    else if (props.frontendPath === '/highlights/new' || props.frontendPath.includes('/repositories/import/'))
      props.handleSetRepository(data)
    else if (props.filter && !props.filter.includes("type=REPOSITORYTYPE&key=repositoryType") && (!props.filter.includes("doaj") && !props.filter.includes("opendoar")) || !props.filter && props.frontendPath !== '/highlights/new') {
      path = '/admin' + props.frontendPath + "/" + data._id + (props.filter ? ("?" + props.filter) : "")
      history.push(path)
    }
    else if (props.filter && props.filter.includes("type=REPOSITORYTYPE&key=repositoryType"))
      props.handleViewAttribute(data)


  }

  const handleTableSort = (value) => {
    handleChangePage({}, 0)
    if (orderBy === value)
      setOrder(order === 'asc' ? 'desc' : 'asc')
    else {
      setOrderBy(value)
      setOrder("asc")
    }
    history.push("/admin" + props.frontendPath + "?" + qs.stringify({ ...params, _sort: value + ":" + order.toUpperCase() }))
  }

  const handleChangeSearch = (event) => {
    setSearch(event.target.value)
  }

  const handleSearch = () => {
    setQuery(search)
    setPage(0)
    let path = props.frontendPath
    if (search) {
      history.push("/admin" + path + "?" + qs.stringify({ ...params, _q: search, _start: 0 }))

    }
    else {
      delete params._q
      history.push("/admin" + path + "?" + qs.stringify({ ...params, _start: 0 }))

    }

  }

  const deciferHeader = (header, obj, index, i) => {
    let finalObj = obj
    if (Array.isArray(header.value))
      for (let val of header.value) {
        if (finalObj)
          finalObj = finalObj[val]
      }
    else
      finalObj = obj[header.value]

    if (header.date)
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{moment(finalObj).format(props.controller !== 'news-item' && props.controller !== 'highlight' ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD")}</TableCell>
    else if (header.translation)
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{t(header.translation + finalObj)}</TableCell>
    else if (header.repoTypeMetadataId)
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{finalObj.split('###')[2]}</TableCell>
    else if (header.repoTypeMetadataType)
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{t('labels.configurations.repoTypeMetadata.format.' + finalObj)}</TableCell>
    else if (header.repoTypeMetadataOrder)
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{finalObj + 1}</TableCell>
    else if (header.indexTitle)
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{t('titles.controlledVocabulary.' + finalObj.split('###')[1])}</TableCell>
    else if (header.indexField)
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{t('labels.configurations.index.' + finalObj)}</TableCell>
    else if (Array.isArray(finalObj))
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>
        {finalObj.map(o => <p key={o}>{o.i18n ? getTranslation(o.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : Object.values(o)}</p>)}
      </TableCell>
    else if (typeof finalObj === 'object')
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{finalObj ? getTranslation(finalObj, i18n.language, i18n.languages[i18n.languages.length - 1]) : Object.values(finalObj)[0]}</TableCell>
    else if (header.value.includes("i18n"))
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{getTranslation(obj.i18n, header.value.split(".")[1], i18n.languages[i18n.languages.length - 1])}</TableCell>
    else if (header.value.includes("badge"))
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{<BadgeCommon tag="avatar" name={getTranslation(obj.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])} url={obj.i18n.badge[header.value.split(".")[1]]} />}</TableCell>
    else if (typeof finalObj === 'boolean')
      return <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{finalObj ? t('table.boolean.yes') : t('table.boolean.no')}</TableCell>
    else
      return (
        <TableCell className={(index === 0 ? "firstCell__weigth--width" : "cell__max--width") + " table__cell--padding"} align="justify" key={i + "###" + index}>{
          header.i18n ? t(props.controller + "." + header.value + "." + finalObj)
            : finalObj
        }</TableCell>)
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  const renderActionHeader = () => {
    let cell = <TableCell className="table__cell--color-white" align="right">{t('table.actions')}</TableCell>
    if (user && props.controller === "user" && (adminAccess('user', 'update', user.group.permission) || adminAccess('user', 'destroy', user.group.permission)))
      return cell

    if (user && props.controller === 'request' && (adminAccess('requestManagement', 'repository', user.group.permission) || adminAccess('requestManagement', 'organization', user.group.permission)))
      return cell

    if (user && props.controller === 'post' && adminAccess('post', 'create', user.group.permission))
      return cell

    if (user && props.controller === 'configuration.repoTypeMetadata' && adminAccess('configuration', 'create', user.group.permission))
      return cell
    if (user && props.controller === 'configuration.index' && adminAccess('configuration', 'create', user.group.permission))
      return cell
    if (
      user.group.permission.find(p => p.controller === props.controller && (p.method === "delete" || p.method === "deleteRequest"))
      || user.group.permission.find(p => p.controller === props.controller && (p.method === "create" || p.method === "editRequest"))
    )
      return cell

    return;
  }
  const renderActionButtons = (data, index) => {
    if (data.readOnly) return (
      <TableCell align="right">
      </TableCell>
    )
    if (props.ActionPanel) return (
      <TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding">
        <props.ActionPanel data={data} index={index} />
      </TableCell>
    )

    if (user && props.controller === 'user' && (adminAccess('user', 'create', user.group.permission) || adminAccess('user', 'destroy', user.group.permission)))
      return (
        <TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding">
          {adminAccess('user', 'create', user.group.permission) &&
            <Tooltip title={t('table.action.update')}>
              <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => handleUpdateData(event, data)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
          {hasRemoveButton && adminAccess('user', 'destroy', user.group.permission) &&
            <Tooltip title={t('table.action.delete')}>
              <IconButton id={props.controller + "-delete-icon-" + index} aria-label="delete" onClick={(event) => handleDeleteData(event, data, index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>}
        </TableCell>

      )
    else if (user && props.controller === 'request' && adminAccess('requestManagement', 'requestManagement', user.group.permission))
      return (
        <TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding" >
          <Tooltip title={t('table.action.update')}>
            <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => handleUpdateData(event, data)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {hasRemoveButton && <Tooltip title={t('table.action.delete')}>
            <IconButton id={props.controller + "-delete-icon-" + index} aria-label="delete" onClick={(event) => handleDeleteData(event, data, index)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>}
        </TableCell>
      )
    else if (user && props.controller === 'configuration.index' && adminAccess('configuration', 'create', user.group.permission))
      return (
        <TableCell align="right" className="cell__max--width table__cell--padding">
          <Tooltip title={t('table.action.update')}>
            <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => handleUpdateData(event, data)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
      )
    else if (user && props.controller === 'post' && adminAccess('post', 'create', user.group.permission))
      return (<TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding">
        <Tooltip title={t('table.action.visible')}>
          <IconButton id={props.controller + "-visibility-icon-" + index} aria-label="visible" onClick={(event) => handleChangeVisible(event, data, index)}>
            {data.visible ? <PublicIcon fontSize="small" /> : <VpnLockIcon fontSize="small" />}
          </IconButton>
        </Tooltip>
        <Tooltip title={t('table.action.update')}>
          <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => handleUpdateData(event, data)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {hasRemoveButton && <Tooltip title={t('table.action.delete')}>
          <IconButton id={props.controller + "-delete-icon-" + index} aria-label="delete" onClick={(event) => handleDeleteData(event, data, index)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>}
      </TableCell>
      )
    else if (user && props.controller === 'configuration.repoTypeMetadata' && !props.filter.includes("key=") && adminAccess('configuration', 'create', user.group.permission))
      return (
        <TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding">
          {user.group.permission.find(p => p.controller === 'configuration' && (p.method === "create" || p.method === "editRequest")) &&
            <Tooltip title={t('table.action.update')}>
              <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => handleClickData(event, data)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>}
        </TableCell>
      )
    else if (user && props.controller === 'configuration.repoTypeMetadata' && props.filter.includes("key=") && adminAccess('configuration', 'create', user.group.permission)) {
      return (
        <TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding">
          {hasRemoveButton && user.group.permission.find(p => p.controller === 'configuration' && (p.method === "create" || p.method === "editRequest")) &&
            <>
              {data.order !== count - 1 && query === '' &&
                <Tooltip title={t('table.action.order')}>
                  <IconButton id={props.controller + "-edit-order-down-icon-" + index} onClick={(event) => props.handleSwitchOrder(event, data.order, 1)}>
                    <KeyboardArrowDownIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              {data.order !== 0 && query === '' &&
                <Tooltip title={t('table.action.order')}>
                  <IconButton id={props.controller + "-edit-order-up-icon-" + index} onClick={(event) => props.handleSwitchOrder(event, data.order, -1)}>
                    <KeyboardArrowUpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title={t('table.action.update')}>
                <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => props.handleEditAttribute(event, data)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('table.action.delete')}>
                <IconButton id={props.controller + "-delete-icon-" + index} aria-label="delete" onClick={(event) => props.handleDeleteAttribute(event, data.id)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          }
        </TableCell>
      )
    }
    else if (user && props.controller === 'faq' && adminAccess('faq', 'update', user.group.permission)) {
      return (
        <TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding">
          {hasRemoveButton && user.group.permission.find(p => p.controller === 'faq' && p.method === "update") &&
            <>
              {data.order !== count && query === '' &&
                <Tooltip title={t('table.action.order')}>
                  <IconButton id={props.controller + "-edit-order-down-icon-" + index} onClick={(event) => props.handleSwitchOrder(event, data, 1)}>
                    <KeyboardArrowDownIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              {data.order !== 1 && query === '' &&
                <Tooltip title={t('table.action.order')}>
                  <IconButton id={props.controller + "-edit-order-up-icon-" + index} onClick={(event) => props.handleSwitchOrder(event, data, -1)}>
                    <KeyboardArrowUpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title={t('table.action.update')}>
                <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => handleUpdateData(event, data)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('table.action.delete')}>
                <IconButton id={props.controller + "-delete-icon-" + index} aria-label="delete" onClick={(event) => props.handleDeleteAttribute(event, data)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          }
        </TableCell>
      )
    }
    else if (user && props.controller === 'help' && adminAccess('help', 'update', user.group.permission)) {
      return (
        <TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding">
          {hasRemoveButton && user.group.permission.find(p => p.controller === 'help' && p.method === "update") &&
            <>
              {data.order !== count && query === '' &&
                <Tooltip title={t('table.action.order')}>
                  <IconButton id={props.controller + "-edit-order-down-icon-" + index} onClick={(event) => props.handleSwitchOrder(event, data, 1)}>
                    <KeyboardArrowDownIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              {data.order !== 1 && query === '' &&
                <Tooltip title={t('table.action.order')}>
                  <IconButton id={props.controller + "-edit-order-up-icon-" + index} onClick={(event) => props.handleSwitchOrder(event, data, -1)}>
                    <KeyboardArrowUpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title={t('table.action.update')}>
                <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => handleUpdateData(event, data)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('table.action.delete')}>
                <IconButton id={props.controller + "-delete-icon-" + index} aria-label="delete" onClick={(event) => props.handleDeleteAttribute(event, data)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          }
        </TableCell>
      )
    }
    else if (
      user.group.permission.find(p => p.controller === props.controller && (p.method === "delete" || p.method === "deleteRequest"))
      || user.group.permission.find(p => p.controller === props.controller && (p.method === "create" || p.method === "editRequest"))
    )
      return (
        <TableCell align="right" className="form__flex--multiple cell__max--width table__cell--padding">
          {user.group.permission.find(p => p.controller === props.controller && (p.method === "create" || p.method === "editRequest")) &&
            <Tooltip title={t('table.action.update')}>
              <IconButton id={props.controller + "-edit-icon-" + index} onClick={(event) => handleUpdateData(event, data)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>}
          {hasRemoveButton && user.group.permission.find(p => p.controller === props.controller && (p.method === "delete" || p.method === "deleteRequest")) &&
            <Tooltip title={t('table.action.delete')}>
              <IconButton id={props.controller + "-delete-icon-" + index} aria-label="delete" onClick={(event) => handleDeleteData(event, data, index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>}
        </TableCell>
      )

    else return;
  }

  return (

    <div className="tableComponent__width">
      {props.filter && props.filter.includes('type=REPOSITORYTYPE&key=repositoryType') || props.controller && props.controller === 'import' ?
        ''
        :
        props.controller === 'repository' && props.frontendPath.includes('highlight') ?
          <div>
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.highlight.repository')}</Typography>
          </div>
          :
          <div>
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.' + props.controller + '.table')}</Typography>
          </div>
      }

      {!props.disableSearch && <div className="tableComponent__search--margin form__flex--multiple">
        <OutlinedInput
          id={props.controller + "-search"}
          role={props.controller !== 'controlled-vocabulary' ? "searchInput" : ""}
          className="outline__search"
          fullWidth
          placeholder={t('searchbar.search')}
          value={search}
          onChange={handleChangeSearch}
          onKeyDown={handleEnter}
          type="text"
          endAdornment={
            <>
              <Button className="tableComponent__search--button" id={props.controller + "-search-button"} onClick={() => handleSearch()}>
                <SearchIcon />
              </Button>
            </>
          }
        />
        {props.handleAdd &&
          <Tooltip
            title={t('controlledVocabulary.tooltip.create')}>
            <IconButton id={props.controller + "-add-button"} className="form__button--add" size="medium" onClick={props.handleAdd}>
              <AddIcon className="form__icon--add" />
            </IconButton>
          </Tooltip>
        }
      </div>}
      {props.controller === 'repository' &&
        <div className="tableComponent__repository--filter">
          <Grid container>
            <Grid item xs={12} md={3} className="fields__padding--right">
              <FormControl variant="outlined" className="searchbar__button searchbar__border-radius fixed__heigth">
                <Select
                  className="searchbar__border-radius fixed__heigth"
                  fullWidth
                  value={props.field}
                  IconComponent={ExpandMoreIcon}
                  classes={{
                    icon: "arrowicon__color"
                  }}
                  input={
                    <OutlinedInput />
                  }
                  onChange={props.handleChangeField}
                >
                  <MenuItem role="selectMenu" value="repositoryType">{t('searchbar.fields.repositoryType')}</MenuItem>
                  <MenuItem role="selectMenu" value="software">{t('searchbar.fields.software')}</MenuItem>
                  <MenuItem role="selectMenu" value="subject">{t('searchbar.fields.subject')}</MenuItem>
                  <MenuItem role="selectMenu" value="content">{t('searchbar.fields.content')}</MenuItem>
                  <MenuItem role="selectMenu" value="contentFormat">{t('searchbar.fields.contentFormat')}</MenuItem>
                  <MenuItem role="selectMenu" value="lang">{t('searchbar.fields.language')}</MenuItem>
                  <MenuItem role="selectMenu" value="domain">{t('searchbar.fields.domain')}</MenuItem>
                  <MenuItem role="selectMenu" value="utility">{t('searchbar.fields.utility')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={9} className="fields__padding--left">
              {props.field && <AutoCompleteCommon
                className="fixed__heigth"
                noMargin={true}
                key={props.field}
                id={props.field}
                tag="autocomplete-vc"
                vc={true}
                path={host + "/controlled-vocabularies?field=" + props.field}
                selectedValue={props.selectedValue}
                handleChange={props.setSelectedValue}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
              />}
            </Grid>
          </Grid>
        </div>
      }
      <TableContainer>
        <Table>
          <TableHead
            className="table__head--color"
          >
            <TableRow
              className="table__head--border"
            >
              {props.headers && props.headers.map((header, index) => {
                return (
                  <TableCell
                    id={props.controller + "-header-" + header.value}
                    className={"table__cell--color-white"}
                    key={header.value + index}
                    sortDirection={orderBy === header.value ? order : false}
                  >
                    {header.sort ? (
                      <TableSortLabel
                        IconComponent={ExpandMoreIcon}
                        className="table__cell--color-white"
                        active={orderBy === header.value}
                        direction={order}
                        onClick={() => handleTableSort(header.value)}
                      >
                        {header.name}
                      </TableSortLabel>
                    ) : header.name}
                  </TableCell>
                )
              })}
              {(hasActions) && renderActionHeader()}
            </TableRow>
          </TableHead>
          <TableBody
            id={props.controller + "-tbody"}
            className="table__body--color"
          >
            {entities && entities.length > 0 ? entities.map((obj, i) => (
              <TableRow
                className="table__row--content"
                id={props.controller + "-row-" + i}
                hover={true}
                onClick={(event) => handleClickData(event, obj)} key={i}>
                {props.headers.map((header, index) =>
                  deciferHeader(header, obj, index, i)
                )}
                {(hasActions) && renderActionButtons(obj, i)}
              </TableRow>
            )) : (
              <TableRow hover={true} style={{ height: 53 * emptyRows }}>
                <TableCell className="firstCell__weigth--width" align="center" colSpan={8} >{t('table.empty')}</TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter
            className="table__body--color"
          >
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={count !== 0 ? page : 0}
                labelRowsPerPage={t('table.rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) => from + "-" + to + " " + t('table.of') + " " + count}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                classes={{
                  selectIcon: "arrowicon__color",
                  actions: "arrowicon__color"
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}
