import React, { useState, useEffect, useContext } from 'react';
import { Typography } from '@material-ui/core';


import TableCommon from '@components/Common/Table'

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserContext } from '@context/userContext';

import '@styles/backOffice/Repository/Repositories.css'
import '@styles/Common/Common.css'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'



export default function TablePlugins() {
    const [t, i18n] = useTranslation("common")
    let history = useHistory()
    const [user] = useContext(UserContext)
    const [headers, setHeaders] = useState(null)

    useEffect(() => {
        setHeaders([
            {
                name: t('labels.repositories.plugins.name'),
                value: "name",
            },
            {
                name: t('labels.repositories.plugins.description'),
                value: "description",
            },
            {
                name: t('labels.repositories.software'),
                value: "softwares",
            },
            {
                name: t('labels.repositories.plugins.version'),
                value: "version",
            },

        ])
    }
        , [t, setHeaders, i18n.language])

    return (
        <div className="admin__container-bottom" >
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { name: "plugins" }
            ]} />

            <Typography id="backoffice-plugins-title" component="h2" className="admin__card--title">{t('titles.repositories.plugins')}</Typography>

            {headers && <TableCommon
                headers={headers}
                backendPath="/repositories/plugins"
                frontendPath="/plugins"
                sort="name"
                controller="plugins"
            />}
        </div>
    );
}