import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import AppNavBar from '@components/Common/NavBar/AppNavBar'
import Footer from '@components/Common/NavBar/Footer'

import { UserContext } from '@context/userContext'

import Axios from 'axios';
import { host } from '@utils/config'
import ReactGA from 'react-ga4';
import Plausible from 'plausible-tracker'
import { useMatomo } from '@datapunt/matomo-tracker-react'


import '@styles/index.css'

import Metatags from '../Metatags';
import { useTranslation } from 'react-i18next'


function AuthenticatedLayout({ component: Component, ...rest }) {
  const [t, i18n] = useTranslation("common")
  let path = rest.path.replace(/\//, "").replace(/\//g, "_")
  return (
    <>
      {!path.includes("/") && <Metatags title={t('breadcrumbs.' + path)} />}
      <AppNavBar></AppNavBar>
      <div className="App-body">
        <Route {...rest} render={(props) => (
          <Component {...rest} {...props} />
        )} />
      </div>
      <Footer />
    </>
  )
}

export default function AuthenticatedPrivateRoute({ component: Component, ...rest }) {
  const [userLogin, setUserLogin] = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const { trackPageView } = useMatomo()

  useEffect(() => {
    fetch('manifest.json')
      .then((r) => r.json())
      .then((data) => {
        if (data['DRD_PLAUSIBLE_API']) {
          const { trackPageview } = Plausible({
            domain: window.location.host,
            apiHost: data['DRD_PLAUSIBLE_API']
          })
          trackPageview({ url: location.pathname + location.search })
        }
      })
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    trackPageView({ href: location.pathname + location.search })
    window.scrollTo(0, 0)
  }, [location.pathname, location.search])

  const isAuthenticated = async () => {
    try {
      Axios.defaults.withCredentials = true;
      let res = await Axios.get(host + '/users/me')
      setUserLogin(res.data)
      setLoading(false)
    }
    catch {
      setLoading(false)
    }
  }


  if (userLogin) {
    return <AuthenticatedLayout component={Component} {...rest} />
  }
  else {
    isAuthenticated()
    if (loading) {
      return <CircularProgress />
    }
    else {
      return !userLogin && <Redirect to={{ pathname: '/login', state: location.pathname }} />
    }
  }
}
