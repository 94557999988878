import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import Tab from '@components/Common/Tab/Tab'
import TableCommon from '@components/Common/Table'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router-dom';

export default function GroupPage() {
  const [t, i18n] = useTranslation('common')
  const [group, setGroup] = useState(null)
  let history = useHistory()
  let { id } = useParams()

  const [headers, setHeaders] = useState()

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.users.firstName'),
        value: "firstName",
        sort: true
      },
      {
        name: t('labels.users.lastName'),
        value: "lastName",
        sort: true
      },
      {
        name: t('labels.users.email'),
        value: "email",
        sort: true
      },
      {
        name: t('labels.users.contact'),
        value: "phone"
      },
      {
        name: t('labels.users.group'),
        value: "group",
        sort: true
      },
      {
        name: t('labels.users.provider'),
        value: 'provider',
        sort: true
      },
      {
        name: t('titles.organizations.organization'),
        value: "organization",
        sort: true
      },
    ])
    getRequest(host + '/groups/' + id)
      .then(res => {
        setGroup(res)
      })
  }, [t, id, setGroup, setHeaders, i18n.language])
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.groups.group')}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {group && !group.readOnly &&
            <Tooltip title={t('tooltip.groups.edit')}>
              <Button
                id="backoffice-repositories-add-button"
                className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/groups/update/' + id)}
                startIcon={
                  <EditIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.edit")}
              </Button>
            </Tooltip>
          }
        </Grid>
      </Grid>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.group.show')}</Typography>
      </div>
      {group &&
        <Card elevation={0} className="margin__top--32px">
          <Tab
            isSimpleTab={true}
            headers={[t('titles.groups.group'), t('titles.users.title')]}
            components={
              [<>
                <Grid container>
                  <TextLable
                    id="group-name"
                    title={t('labels.groups.name')}
                    value={getTranslation(group.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />
                  <TextLable
                    id="group-description"
                    title={t('labels.groups.description')}
                    value={getTranslation(group.description, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />

                  <Typography className="textField__typography--subtitle" gutterBottom variant="h6" component="h3">
                    {t('titles.groups.permissions')}
                  </Typography>

                  <Grid container>
                    {group.permission.map((perm, index) => {
                      return (
                        <Grid key={"permission-grid-" + index} item xs={12} sm={6} md={4}>
                          <TextLable
                            id={"permission" + index}
                            key={"permission-" + index}
                            title={t('labels.groups.permissions.controller.' + perm.controller)}
                            value={t('labels.groups.permissions.method.' + perm.method)}
                          />
                        </Grid>
                      )
                    })
                    }
                  </Grid>
                </Grid>
              </>,
              <>
                {headers && <TableCommon
                  headers={headers}
                  backendPath="/users"
                  frontendPath="/users"
                  disableSearch={true}
                  sort="firstName"
                  controller="user"
                  filter={"group=" + getTranslation(group.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  hasAddButton={false}
                />}
              </>
              ]} />
        </Card>
      }
      <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
        <SubmitButtonCommon
          className="form__button--color-error"
          title={t('table.confirm.backButton')}
          goBack />
      </Grid>
    </>
  )
}
