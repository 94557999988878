import { Box, Grid } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';

import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import AutoCompleteCommon from '@components/Common/AutoComplete';
import { UserContext } from '@context/userContext';
import getRequest from '@utils/functions/axiosRequest'

import { Card } from '@material-ui/core';
import { host } from "@utils/config";

import "@styles/backOffice/Organization/OrganizationForm.css"
import "@styles/Common/Common.css"
import Typography from '@material-ui/core/Typography';
import Terms from '@components/Configuration/Terms.js'

const _ = require('lodash');

export default function OrganizationForm(props) {
  const [t, i18n] = useTranslation('common')
  const [user] = useContext(UserContext)
  const [name, setName] = useState(props.name || {})
  const [url, setUrl] = useState(props.url || "")
  const [email, setEmail] = useState(props.email || "")
  const [address, setAddress] = useState(props.address || "")
  const [fax, setFax] = useState(props.fax || "")
  const [phones, setPhones] = useState(props.phones || [""])
  const [latitude, setLatitude] = useState(props.latitude || "")
  const [longitude, setLongitude] = useState(props.longitude || "")
  const [schedule, setSchedule] = useState(props.schedule || "")
  const [comments, setComments] = useState(props.comments || "")
  const [notes, setNotes] = useState(props.notes || "")
  const [country, setCountry] = useState(props.country || null)
  // const [liability, setLiability] = useState(props.liability || null)
  const [organizationType, setOrganizationType] = useState(props.organizationType || null)
  const [legalForm, setLegalForm] = useState(props.legalForm || null)
  const [provider, setProvider] = useState(props.provider || null)
  const [ringgoldID, setRinggoldID] = useState(props.ringgoldID || null)
  const [terms, setTerms] = useState(props.terms || null)
  const [checkedTerm, setCheckedTerm] = useState(false)

  const [isErrorName, setIsErrorName] = useState(false)
  const [isErrorEmail, setIsErrorEmail] = useState(false)
  const [isErrorCountry, setIsErrorCountry] = useState(false)
  const [isErrorAddress, setIsErrorAddress] = useState(false)
  const [isErrorPhones, setIsErrorPhones] = useState(false)
  const [isErrorLatitude, setIsErrorLatitude] = useState(false)
  const [isErrorLongitude, setIsErrorLongitude] = useState(false)
  // const [isErrorLiability, setIsErrorLiability] = useState(false)
  const [isErrorOrganizationType, setIsErrorOrganizationType] = useState(false)
  const [isErrorSchedule, setIsErrorSchedule] = useState(false)
  const [isErrorComments, setIsErrorComments] = useState(false)
  const [isErrorNotes, setIsErrorNotes] = useState(false)
  const [isErrorTerm, setIsErrorTerm] = useState(false)

  const [showTerms, setShowTerms] = useState(props.terms || !props.object ? true : props.object.terms ? false : true)

  const [langs, setLangs] = useState(null)
  useEffect(() => {
    if (user) {
      getRequest(host + '/configurations/public?type=LANGUAGES')
        .then(res => {
          setLangs(res[0].body)
        })
    }
  }, [user])

  useEffect(() => {
    getRequest(host + '/configurations/public?type=TERM&&objectType=ORGANIZATION')
      .then(res => {
        if (window.location.hash.includes('requests')) {
          setCheckedTerm(true)
          setShowTerms(false)
        }
        else if (props.terms) {
          setTerms([...props.terms, res[0]])
        }
        else {
          setTerms(res)
        }
      })
  }, [setTerms])


  const validateOrganization = () => {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let validation = true
    if (!name || Object.keys(name).length === 0 || langs.filter(lang => !name[lang] || name[lang] === '').length > 0) {
      let newNameErrors = langs.map(l => !name[l] || name[l] === "")
      setIsErrorName(newNameErrors)
      validation = false
    } else setIsErrorName(langs.map(l => false))
    if (!email.match(emailRegExp)) {
      setIsErrorEmail(true)
      validation = false
    } else setIsErrorEmail(false)
    if (!country) {
      setIsErrorCountry(true)
      validation = false
    } else setIsErrorCountry(false)
    if (!address) {
      setIsErrorAddress(true)
      validation = false
    } else setIsErrorAddress(false)
    if (phones.length === 1 && phones[0] === "") {
      setIsErrorPhones(true)
      validation = false
    } else setIsErrorPhones(false)
    if (showTerms && !checkedTerm) {
      setIsErrorTerm(true)
      validation = false
    } else setIsErrorTerm(false)
    // if (!liability) {
    //   setIsErrorLiability(true)
    //   validation = false
    // } else setIsErrorLiability(false)
    if ((latitude && (latitude > 90 || latitude < -90) || longitude && !latitude) || isNaN(latitude)) {
      setIsErrorLatitude(true)
      validation = false
    } else setIsErrorLatitude(false)
    if ((longitude && (longitude > 180 || longitude < -180) || latitude && !longitude) || isNaN(longitude)) {
      setIsErrorLongitude(true)
      validation = false
    } else setIsErrorLongitude(false)
    if (!organizationType) {
      setIsErrorOrganizationType(true)
      validation = false
    } else setIsErrorOrganizationType(false)
    if (Object.keys(schedule).length > 0 && (langs.some(lang => !schedule[lang] || schedule[lang] === '') && langs.some(lang => schedule[lang] && schedule[lang] !== ''))) {
      let newScheduleErrors = langs.map((l) => (!schedule[l] || schedule[l] === ""))
      setIsErrorSchedule(newScheduleErrors)
      validation = false
    } else setIsErrorSchedule(langs.map(l => false))
    if (Object.keys(comments).length > 0 && (langs.some(lang => !comments[lang] || comments[lang] === '') && langs.some(lang => comments[lang] && comments[lang] !== ''))) {
      let newCommentsErrors = langs.map((l) => (!comments[l] || comments[l] === ""))
      setIsErrorComments(newCommentsErrors)
      validation = false
    } else setIsErrorComments(langs.map(l => false))
    if (Object.keys(notes).length > 0 && (langs.some(lang => !notes[lang] || notes[lang] === '') && langs.some(lang => notes[lang] && notes[lang] !== ''))) {
      let newNotesErrors = langs.map((l) => (!notes[l] || notes[l] === ""))
      setIsErrorNotes(newNotesErrors)
      validation = false
    } else setIsErrorNotes(langs.map(l => false))

    return validation
  }

  const handleChangeLangInput = (value, lang, input, set) => {
    let newValue = { ...input }
    newValue[lang] = value
    set(newValue)
  }

  const handleSpreadLangInput = (event, value, input, set) => {
    if (value !== undefined && value !== '') {
      let newValue = { ...input }
      langs.map(l => newValue[l] === '' || newValue[l] === undefined || event.type === 'dblclick' ? newValue[l] = value : '')
      set(newValue)
    }
  }


  const handleSubmitOrganization = () => {
    if (validateOrganization()) {
      let date = new Date()
      let newTerms = [...terms]
      if (!newTerms[newTerms.length - 1]['accepted_at'])
        newTerms[newTerms.length - 1]['accepted_at'] = date.toISOString()
      let organization = {
        _id: props._id,
        name: name,
        url: url,
        email: email,
        address: address,
        fax: fax,
        phones: phones,
        gps: {
          lat: latitude,
          lng: longitude
        },
        schedule: schedule,
        notes: notes,
        comments: comments,
        country: [country],
        ...(showTerms ? { terms: [newTerms[newTerms.length - 1]] } : { terms: newTerms }),
        // liability: liability,
        organizationType: [organizationType],
        legalForm: legalForm,
        users: props.users ? props.users : []
      }
      props.submitOrganization(organization)
    }
    else
      props.snackForOrganization("error", t('messages.error.common.validation'))
  }

  return (
    <>
      <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
        <Grid container>
          {
            langs && langs.map((lang, index) =>
              <Grid key={"name-" + lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                <TextFieldCommon
                  title={t('labels.organizations.name') + " <sup>" + lang + "</sup>"}
                  id={"name-" + lang}
                  required={true}
                  isOpen={isErrorName[index]}
                  value={name[lang]}
                  message={t('messages.error.organizations.name')}
                  label={t('common.labels.required')}
                  type="text"
                  fullWidth={true}
                  handleChangeInput={(value) => handleChangeLangInput(value, lang, name, setName)}
                  handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, name, setName)}
                  renderIcon={true}
                />
              </Grid>
            )
          }

          <TextFieldCommon
            title={t('labels.organizations.url')}
            id="url"
            required={false}
            optional={true}
            value={url}
            type="text"
            fullWidth={true}
            handleChangeInput={setUrl}
          />

          <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
            {t('titles.organizations.contact')}
          </Typography>

          <TextFieldCommon
            className="width__50"
            title={t('labels.organizations.email')}
            id="email"
            required={true}
            value={email}
            isOpen={isErrorEmail}
            message={t('messages.error.organizations.email')}
            label={t('common.labels.required')}
            type="text"
            fullWidth={true}
            handleChangeInput={setEmail}
          />
          <TextFieldCommon
            className="width__33"
            title={t('labels.organizations.phones')}
            id="phones"
            required={true}
            isOpen={isErrorPhones}
            message={t('messages.error.organizations.phones')}
            addButton={true}
            value={phones}
            label={t('common.labels.required')}
            type="text"
            fullWidth={true}
            handleChangeInput={setPhones}
          />
          <TextFieldCommon
            className="width__33"
            title={t('labels.organizations.fax')}
            id="fax"
            optional={true}
            value={fax}
            type="text"
            fullWidth={true}
            handleChangeInput={setFax}
          />
          <TextFieldCommon
            title={t('labels.organizations.address')}
            id="address"
            required={true}
            isOpen={isErrorAddress}
            value={address}
            message={t('messages.error.organizations.address')}
            label={t('common.labels.required')}
            type="text"
            fullWidth={true}
            handleChangeInput={setAddress}
          />
          <AutoCompleteCommon
            className="width__33"
            title={t('labels.organizations.country')}
            label={t('common.labels.search') + ' ' + t('labels.organizations.country')}
            id="country"
            tag="autocomplete-vc"
            vc={true}
            path={host + "/controlled-vocabularies?field=country"}
            required={true}
            isOpen={isErrorCountry}
            message={t('messages.error.organizations.country')}
            selectedValue={country}
            handleChange={setCountry}
            language={i18n.language}
            fallbackLng={i18n.languages[i18n.languages.length - 1]}
          />
          <TextFieldCommon
            className="width__33"
            title={t('labels.organizations.gps.lat')}
            id="gps-lat"
            type="text"
            isOpen={isErrorLatitude}
            required={false}
            optional={true}
            value={latitude}
            message={t('messages.error.repositories.gps.lat')}
            fullWidth={true}
            handleChangeInput={setLatitude}
          />
          <TextFieldCommon
            className="width__33"
            title={t('labels.organizations.gps.lng')}
            id="gps-lng"
            isOpen={isErrorLongitude}
            required={false}
            optional={true}
            value={longitude}
            message={t('messages.error.repositories.gps.lng')}
            type="text"
            fullWidth={true}
            handleChangeInput={setLongitude}
          />
          {
            langs && langs.map((lang, index) =>
              <Grid key={"schedule-" + lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                <TextFieldCommon
                  title={t('labels.organizations.schedule') + " <sup>" + lang + "</sup>"}
                  id={"schedule-" + lang}
                  multiline={true}
                  fullWidth={true}
                  value={schedule[lang]}
                  required={false}
                  optional={true}
                  type="text"
                  isOpen={isErrorSchedule[index]}
                  message={t('messages.error.organizations.schedule')}
                  handleChangeInput={(value) => handleChangeLangInput(value, lang, schedule, setSchedule)}
                  handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, schedule, setSchedule)}
                  renderIcon={true}
                />
              </Grid>
            )
          }
          <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
            {t('titles.organizations.aditional')}
          </Typography>

          {provider && <TextFieldCommon
            className="width__33"
            title={t('labels.organizations.provider')}
            id="provider"
            disabled={true}
            value={provider}
            fullWidth={true}
            type="text"
            handleChangeInput={setProvider}
          />}
          {ringgoldID && <TextFieldCommon
            className="width__33"
            title={t('labels.organizations.ringgoldID')}
            id="ringgoldID"
            disabled={true}
            value={ringgoldID}
            fullWidth={true}
            type="text"
            handleChangeInput={setRinggoldID}
          />}
          <AutoCompleteCommon
            className="width__50"
            title={t('labels.organizations.organizationType')}
            label={t('common.labels.search') + ' ' + t('labels.organizations.organizationType')}
            id="organizationType"
            tag="autocomplete-vc"
            required={true}
            isOpen={isErrorOrganizationType}
            vc={true}
            path={host + "/controlled-vocabularies?field=organizationType"}
            message={t('messages.error.organizations.organizationType')}
            selectedValue={organizationType}
            handleChange={setOrganizationType}
            language={i18n.language}
            fallbackLng={i18n.languages[i18n.languages.length - 1]}
          />
          {/* <AutoCompleteCommon
          className="width__50"
          title={t('labels.organizations.liability')}
          id="liability"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=liability"}
          required={true}
          isOpen={isErrorLiability}
          message={t('messages.error.organizations.liability')}
          selectedValue={liability}
          handleChange={setLiability}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
        /> */}
          <AutoCompleteCommon
            className="width__50"
            title={t('labels.organizations.legalForm')}
            id="legalForm"
            tag="autocomplete-vc"
            vc={true}
            required={false}
            optional={true}
            path={host + "/controlled-vocabularies?field=legalForm"}
            selectedValue={legalForm}
            handleChange={setLegalForm}
            language={i18n.language}
            fallbackLng={i18n.languages[i18n.languages.length - 1]}
          />
          {
            langs && langs.map((lang, index) =>
              <Grid key={"comments" + lang + index} item xs={12} md={6} className={(index % 2 === 0 ? "fields__padding--right" : "fields__padding--left")}>
                <TextFieldCommon
                  title={t('labels.organizations.comments') + " <sup>" + lang + "</sup>"}
                  id={"comments-" + lang}
                  multiline={true}
                  value={comments[lang]}
                  fullWidth={true}
                  type="text"
                  required={false}
                  optional={true}
                  isOpen={isErrorComments[index]}
                  message={t('messages.error.organizations.comments')}
                  handleChangeInput={(value) => handleChangeLangInput(value, lang, comments, setComments)}
                  handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, comments, setComments)}
                  renderIcon={true}
                />
              </Grid>
            )}
            {
            langs && langs.map((lang, index) =>
              <Grid key={"notes" + lang + index} item xs={12} md={6} className={(index % 2 === 0 ? "fields__padding--right" : "fields__padding--left")}>
                <TextFieldCommon
                  title={t('labels.organizations.notes') + " <sup>" + lang + "</sup>"}
                  id={"notes-" + lang}
                  multiline={true}
                  value={notes[lang]}
                  fullWidth={true}
                  type="text"
                  required={false}
                  optional={true}
                  isOpen={isErrorNotes[index]}
                  message={t('messages.error.organizations.notes')}
                  handleChangeInput={(value) => handleChangeLangInput(value, lang, notes, setNotes)}
                  handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, notes, setNotes)}
                  renderIcon={true}
                />
              </Grid>
          )}
          {!props.history && props.condition === undefined ?
            <Terms
              label={t('labels.organizations.terms')}
              termForm={showTerms}
              terms={terms}
              checkedTerm={checkedTerm}
              setCheckedTerm={setCheckedTerm}
              isErrorTerm={isErrorTerm}
              errorMessage={t('messages.error.organizations.term')}
            />
            : null}
        </Grid>
      </Card>
      {props.submitLable &&
        <Grid container className="form__button--justify-left repositoryform__buttons--margins">
          <SubmitButtonCommon
            id="organization-save-button"
            className="form__button--color-success"
            title={t('common.buttons.save')}
            handleClick={handleSubmitOrganization} />
          <SubmitButtonCommon
            id="organization-cancel-button"
            className="form__button--color-error"
            title={t('common.buttons.cancel')}
            goBack />
        </Grid>
      }
    </>
  )
}