import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackBarCommon(props) {

  const handleCloseSnackBar = (event) => {
    props.setIsSnackBar(false)
  }

  return (
    <Snackbar className='snackbar__message' open={props.isSnackBar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={5000} onClose={handleCloseSnackBar}>
      <Alert id="snackbar-message" onClose={handleCloseSnackBar} severity={props.tag}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}
