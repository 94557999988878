import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

import '@styles/Common/Common.css'

export default function RadioCommon(props) {

    const handleChange = (event) => {
        event.preventDefault()
        props.handleChange(event.target.value)
    }

    return (
        <Grid container className="radio__container">
            <Grid item xs={12} md={12}>
                {props.title !== '' ?
                    <Typography id={props.id + '-lable'} className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                        <div dangerouslySetInnerHTML={{ __html: props.title }} />
                    </Typography>
                    : ''
                }
            </Grid>
            <Grid item xs={10} md={12}>
                <Grid container className="radio__container">
                    <div className="radio__div--margin radio__div--typography--margin--top">
                        <Typography variant="subtitle2" component="h5" color="textSecondary" >{props.firstLabel}</Typography>
                    </div>
                    <div className="radio__div--margin">
                        <Radio
                            id={props.id + "-0-input"}
                            checked={props.value ? (props.value === props.firstCondition) : (props.defaultValue === props.firstCondition)}
                            onChange={(event) => handleChange(event)}
                            value={props.firstCondition}
                            disabled={props.disabled}
                        >
                        </Radio>
                    </div>
                    <div className="radio__div--margin radio__div--typography--margin--top">
                        <Typography variant="subtitle2" component="h5" color="textSecondary" >{props.secondLabel}</Typography>
                    </div>
                    <div className="radio__div--margin">
                        <Radio
                            id={props.id + "-1-input"}
                            checked={(props.value) ? (props.value === props.secondCondition) : (props.defaultValue === props.secondCondition)}
                            onChange={(event) => handleChange(event)}
                            value={props.secondCondition}
                            disabled={props.disabled}
                        >
                        </Radio>
                    </div>
                    {props.thirdCondition &&
                        <>
                            <div className="radio__div--margin radio__div--typography--margin--top">
                                <Typography variant="subtitle2" component="h5" color="textSecondary" >{props.thirdLabel}</Typography>
                            </div>
                            <div className="radio__div--margin">
                                <Radio
                                    id={props.id + "-2-input"}
                                    checked={(props.value) ? (props.value === props.thirdCondition) : (props.defaultValue === props.thirdCondition)}
                                    onChange={(event) => handleChange(event)}
                                    value={props.thirdCondition}
                                    disabled={props.disabled}
                                >
                                </Radio>
                            </div>
                        </>
                    }
                    {props.fourthCondition &&
                        <>
                            <div className="radio__div--margin radio__div--typography--margin--top">
                                <Typography variant="subtitle2" component="h5" color="textSecondary" >{props.fourthLabel}</Typography>
                            </div>
                            <div className="radio__div--margin">
                                <Radio
                                    id={props.id + "-3-input"}
                                    checked={(props.value) ? (props.value === props.fourthCondition) : (props.defaultValue === props.fourthCondition)}
                                    onChange={(event) => handleChange(event)}
                                    value={props.fourthCondition}
                                    disabled={props.disabled}
                                >
                                </Radio>
                            </div>
                        </>
                    }
                    {props.fifthCondition &&
                        <>
                            <div className="radio__div--margin radio__div--typography--margin--top">
                                <Typography variant="subtitle2" component="h5" color="textSecondary" >{props.fifthLabel}</Typography>
                            </div>
                            <div className="radio__div--margin">
                                <Radio
                                    id={props.id + "-4-input"}
                                    checked={(props.value) ? (props.value === props.fifthCondition) : (props.defaultValue === props.fifthCondition)}
                                    onChange={(event) => handleChange(event)}
                                    value={props.fifthCondition}
                                    disabled={props.disabled}
                                >
                                </Radio>
                            </div>
                        </>
                    }
                    {props.sixthCondition &&
                        <>
                            <div className="radio__div--margin radio__div--typography--margin--top">
                                <Typography variant="subtitle2" component="h5" color="textSecondary" >{props.sixthLabel}</Typography>
                            </div>
                            <div className="radio__div--margin">
                                <Radio
                                    id={props.id + "-5-input"}
                                    checked={(props.value) ? (props.value === props.sixthCondition) : (props.defaultValue === props.sixthCondition)}
                                    onChange={(event) => handleChange(event)}
                                    value={props.sixthCondition}
                                    disabled={props.disabled}
                                >
                                </Radio>
                            </div>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}