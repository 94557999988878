import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core';

import SearchBar from '@components/Common/SearchBar/SearchBar'
import NewsMenu from '@components/NewsItem/NewsMenu'
import HighlightsMenu from '@components/Highlight/HighlightsMenu'


import '@styles/frontOffice/Home.css'
import INDEXAR from '@utils/assets/indexar-logo-white.svg'


export default function Home() {
  const [t, i18n] = useTranslation("common")
  return (
    <>
      <div className="image__container">
        <div className="searchbar__container">
          <div className="searchbar__box--size searchbar__box--width">
            <img src={INDEXAR} alt={"INDEXAR"} className="home__mission--title logo__mission" />
            <Typography className="home__mission--text" component="h2">{t('messages.home.mission')}</Typography>
          </div>
        </div>
      </div>
      <div className="homeFrontOffice__div--padding-top">
        <SearchBar title={t('titles.home.title', { lng: i18n.language })} />
        <NewsMenu />
        <HighlightsMenu />
      </div>

    </>
  )
}
