import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'

import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';


export default function ControlledVocabularyPage() {
    const [t, i18n] = useTranslation('common')
    const [statistic, setStatistic] = useState(null)
    const [langs, setLangs] = useState(null)
    let { id } = useParams()
    let history = useHistory()
    const [user] = useContext(UserContext)

    useEffect(() => {
        getRequest(host + '/statistics/' + id)
            .then(res => {
                setStatistic(res)
            })
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
            })
    }, [id, setStatistic, setLangs])

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.statistics.statistic')}</Typography>
                </Grid>
                <Grid item xs={6} className="admin__align--left">
                    {user &&
                        <Tooltip title={t('tooltip.repositories.create')}>
                            <Button
                                id="backoffice-repositories-add-button"
                                className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/statistics/update/' + id)}
                                startIcon={
                                    <EditIcon className="admin__icon--create" />
                                }
                                role="adminCards"
                            >
                                {t("common.buttons.edit")}
                            </Button>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
            <div >
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.statistic.show')}</Typography>
            </div>
            {statistic &&
                <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
                    <Grid container>
                        <TextLable
                            id="statistic-value"
                            title={t('labels.statistics.value')}
                            value={statistic.value}
                        />
                        <TextLable
                            id="statistic-title"
                            title={t('labels.statistics.type.title')}
                            value={t("titles.statistics." + statistic.type)}
                        />
                        <Grid container>
                            {langs && langs.filter(lang => statistic.i18n[lang]).map((lang, index) =>
                                <Grid key={lang + index} item xs={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                    <TextLable
                                        id={"statistic-" + lang}
                                        title={t('labels.controlledVocabulary.label', { lang: lang })}
                                        value={statistic.i18n[lang]}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {
                            statistic.configs && statistic.configs.length > 0 &&
                            <TextLable
                                id="statistic-configs"
                                title={t('labels.statistics.configs')}
                                value={statistic.configs.map(config => getTranslation(config.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                                isArray={true}
                            />
                        }
                    </Grid>
                </Card>}
            <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
                <SubmitButtonCommon
                    id="statistic-back-button"
                    className="form__button--color-error"
                    title={t('table.confirm.backButton')}
                    goBack />
            </Grid>
        </>
    )
}
