import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import TextLable from '@components/Common/TextLable'
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextFieldCommon from '@components/Common/TextField';
import AutoCompleteCommon from '@components/Common/AutoComplete';

import getTranslation from '@utils/functions/utilities.js'
export default function RepositoryPage(props) {
  const [t, i18n] = useTranslation('common')
  const [history, setHistory] = useState(props.history || [])
  const [organization, setOrganization] = useState("")

  const [langs, setLangs] = useState(null)

  useEffect(() => {
    if(props.history && props.history.organization){
      getRequest(host + "/organizations/" + props.history.organization.original._id)
        .then(res => {
          setOrganization(res)
      })
    }

    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setOrganization, props.object])

  const handleChangeObject = (index, value, lang) => {
    let newObject = props.object
    let newValue = value
    if (index === "repositoryType" || index === "software" || index === "domain")
      newValue = [value]

    else if (index === "description") {
      newValue = props.object.description
      newValue[lang] = value
    }

    newObject[index] = newValue
    props.setObject(newObject)
  }

  return (
    <>
      {!props.history || !props.history.name ?
        <TextLable
          id="name"
          title={t('labels.repositories.name')}
          value={props.object.name}
        />
        :
        <TextFieldCommon
          className={props.history.name.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('labels.repositories.name')}
          id="name"
          value={props.object.name}
          type="text"
          fullWidth={true}
          handleChangeInput={(value) => handleChangeObject('name', value)}
          previous={props.history.name.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.repositoryType ?
        <TextLable
          id="repositoryType"
          title={t('labels.repositories.repositoryType')}
          value={getTranslation(props.object.repositoryType[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
        />
        :
        <AutoCompleteCommon
          className={props.history.repositoryType.action === "UPDATE" ? "request__color--update width__50" : "request__color--create width__50"}
          title={t('labels.repositories.repositoryType')}
          id="repositoryType"
          tag="autocomplete-vc"
          vc={true}
          path={host + "/controlled-vocabularies?field=repositoryType"}
          required={true}
          selectedValue={props.object.repositoryType[0]}
          handleChange={(value) => handleChangeObject('repositoryType', value)}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          previous={props.history.repositoryType.original}
          disabled={true}
        />
      }
      {langs && langs.map((lang, index) =>
        <div key={"request-description" + index}>
          {!props.history || !props.history.description ?
            <TextLable
              id={"description-" + lang}
              title={t('labels.repositories.description', { lang: lang })}
              value={props.object.description[lang]}
            />
            :
            <TextFieldCommon
              className={props.history.description.action === "UPDATE" ? "request__color--update" : "request__color--create"}
              title={t('labels.repositories.description', { lang: lang })}
              id={"description-" + lang}
              value={props.object.description[lang]}
              type="text"
              multiline={true}
              fullWidth={true}
              handleChangeInput={(value) => handleChangeObject('description', value, lang)}
              previous={props.history.description.original[lang]}
              disabled={true}
            />
          }
        </div>
      )}

      {!props.history || !props.history.url ?
        <TextLable
          id="url"
          title={t('labels.repositories.url')}
          value={props.object.url}
        />
        :
        <TextFieldCommon
          className={props.history.url.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('labels.repositories.url')}
          id="url"
          value={props.object.url}
          type="text"
          fullWidth={true}
          handleChangeInput={(value) => handleChangeObject('url', value)}
          previous={props.history.url.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.keyWords ?
        <TextLable
          id="keyWords"
          title={t('labels.repositories.keyWords')}
          value={props.object.keyWords}
          isArray={true}
        />
        : <TextFieldCommon
          className={props.history.keyWords.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('labels.repositories.keyWords')}
          id="keyWords"
          required={true}
          value={props.object.keyWords}
          addButton={true}
          type="text"
          fullWidth={true}
          previous={props.history.keyWords.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.software ?
        <TextLable
          id="software"
          title={t('labels.repositories.software')}
          value={getTranslation(props.object.software[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
        /> :
        <AutoCompleteCommon
          className={props.history.software.action === "UPDATE" ? "request__color--update width__50" : "request__color--create width__50"}
          title={t('labels.repositories.software')}
          id="software"
          tag="autocomplete-vc"
          vc={true}
          path={host + "/controlled-vocabularies?field=software"}
          selectedValue={props.object.software[0]}
          handleChange={(value) => handleChangeObject('software', value)}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          previous={props.history.software.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.content ?
        <TextLable
          id="content"
          title={t('labels.repositories.content')}
          value={props.object.content && props.object.content.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          isArray={true}
        /> :
        <AutoCompleteCommon
          className={props.history.content.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('labels.repositories.content')}
          id="content"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=content"}
          selectedValue={props.object.content}
          handleChange={(value) => handleChangeObject('content', value)}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          previous={props.history.content.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.contentFormat ?
        <TextLable
          id="contentFormat"
          title={t('labels.repositories.contentFormat')}
          value={props.object.contentFormat && props.object.contentFormat.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          history={props.history && props.history.contentFormat}
          isArray={true}
        /> :
        <AutoCompleteCommon
          className={props.history.contentFormat.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('labels.repositories.contentFormat')}
          id="contentFormat"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=contentFormat"}
          selectedValue={props.object.contentFormat}
          handleChange={(value) => handleChangeObject('contentFormat', value)}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          previous={props.history.contentFormat.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.lang ?
        <TextLable
          id="language"
          title={t('labels.repositories.language')}
          value={props.object.lang && props.object.lang.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          history={props.history && props.history.lang}
          isArray={true}
        /> :
        <AutoCompleteCommon
          className={props.history.lang.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('labels.repositories.language')}
          id="language"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=lang"}
          selectedValue={props.object.lang}
          handleChange={(value) => handleChangeObject('lang', value)}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          preValues={["por", "eng"]}
          previous={props.history.lang.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.subject ?
        <TextLable
          id="subject"
          title={t('labels.repositories.subject')}
          value={props.object.subject && props.object.subject.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          history={props.history && props.history.subject}
          isArray={true}
        /> :
        <AutoCompleteCommon
          className={props.history.subject.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('labels.repositories.subject')}
          id="subject"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=subject"}
          selectedValue={props.object.subject}
          handleChange={(value) => handleChangeObject('subject', value)}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          previous={props.history.subject.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.utility ?
        <TextLable
          id="utility"
          title={t('labels.repositories.utility')}
          value={props.object.utility.length > 0 && props.object.utility.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          history={props.history && props.history.utility}
        /> :
        <AutoCompleteCommon
          className={props.history.utility.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('labels.repositories.utility')}
          id="utility"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=utility"}
          selectedValue={props.object.utility}
          handleChange={(value) => handleChangeObject('utility', value)}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          previous={props.history.utility.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.domain ?
        <TextLable
          id="domain"
          title={t('labels.repositories.domain')}
          value={props.object.domain && props.object.domain.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          history={props.history && props.history.domain}
          isArray={true}
        /> :
        <AutoCompleteCommon
          className={props.history.domain.action === "UPDATE" ? "width__50 request__color--update" : "width__50 request__color--create"}
          title={t('labels.repositories.domain')}
          id="domain"
          tag="autocomplete-vc"
          vc={true}
          path={host + "/controlled-vocabularies?field=domain"}
          selectedValue={props.object.domain[0]}
          handleChange={(value) => handleChangeObject('domain', value)}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          previous={props.history.domain.original}
          disabled={true}
        />
      }
      {!props.history || !props.history.organization ?
        <TextLable
          id="organization"
          title={t('titles.organizations.organization')}
          value={[props.object.organization.name]}
          history={props.history && props.history.organization}
          isArray={true}
        /> : 
        <AutoCompleteCommon
          className={props.history.organization.action === "UPDATE" ? "request__color--update" : "request__color--create"}
          title={t('titles.organizations.organization')}
          id="organization"
          tag="autocomplete-organization"
          vc={true}
          path={host + "/organizations?_sort=name:ASC"}
          selectedValue={props.object.organization}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
          previous={organization.name}
          disabled={true}
       />
      }
      {(props.object.logo || props.history && props.history.logo) && props.condition !== "REJECTED" &&
        <TextLable
          id="logo"
          title={t('labels.repositories.logo')}
          value={props.object.logo.url}
          history={props.history && props.history.logo}
          image={true}
        />
      }
    </>
  )
}
