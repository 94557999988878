import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"

import IndexForm from '@components/Repository/IndexForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useParams } from 'react-router-dom'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function EditIndex() {
  const [t] = useTranslation("common")

  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [index, setIndex] = useState(null)

  let history = useHistory()
  const { id } = useParams()

  const submitIndex = async (newIndex) => {
    try {
      await Axios.put(host + '/configurations/' + id, newIndex)
      snackForIndex("success", t('messages.success.configurations.update'))
      history.goBack()
    }
    catch {
        snackForIndex("error", t('messages.error.configurations.update'))
    }
  }

  useEffect(() => {
    getRequest(host + "/configurations/" + id)
      .then(res => {
        setIndex(res)
      })
  }, [setIndex, id])

  const snackForIndex = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
      {index && <>
      <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin" , name: "admin"},
          { href: "/#/admin/indexes" , name: "indexes"},
          { name: "indexes_update"}
        ]} />
        <Typography component="h2" className="admin__card--title">{t('titles.configurations.index.update')}</Typography>
        <div >
          <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.configuration.index.edit')}</Typography>
        </div>
        <IndexForm
          id={index.key}
          body={index.body}
          snackForIndex={snackForIndex}
          submitIndex={submitIndex}
          submitLable={t('index.form.submit.update')}
        />
      </>}
    </div>
  )
}