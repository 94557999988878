import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useTranslation } from 'react-i18next';

import { host } from '@utils/config'

import TextFieldCommon from '@components/Common/TextField';
import AutoCompleteCommon from '@components/Common/AutoComplete';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import SelectorCommon from '@components/Common/Selector';
import Terms from '@components/Configuration/Terms.js'



export default function CreateUserForm(props) {
  const [t, i18n] = useTranslation('common')
  return (
    <>
      <Grid container>

        <Typography className="textField__typography--subtitle-margin" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
          {t('titles.users.register.authentication')}
        </Typography>


        <TextFieldCommon
          className="width__33"
          title={t('labels.users.email')}
          id="email"
          required={true}
          isOpen={props.isEmail}
          message={t('messages.error.users.email')}
          label={t('common.labels.required')}
          type="text"
          fullWidth={true}
          handleChangeInput={props.setEmail}
        />
        <TextFieldCommon
          className="width__33"
          title={t('labels.users.password')}
          id="password"
          required={true}
          isOpen={props.isPassword}
          message={t('messages.error.users.password.error')}
          label={t('common.labels.required')}
          type="password"
          value={props.password}
          renderIcon={true}
          showPassword={props.showPassword}
          handleClickShowPassword={props.handleClickShowPassword}
          handleMouseDownPassword={props.handleMouseDownPassword}
          fullWidth={true}
          handleChangeInput={props.setPassword}
        />
        <TextFieldCommon
          className="width__33"
          title={t('labels.users.confirmPassword')}
          id="confirmPassword"
          required={true}
          isOpen={props.isConfirmPassword}
          message={t('messages.error.users.confirmPassword')}
          label={t('common.labels.required')}
          type="password"
          renderIcon={true}
          showPassword={props.showConfirmPassword}
          handleClickShowPassword={props.handleClickShowConfirmPassword}
          handleMouseDownPassword={props.handleMouseDownPassword}
          fullWidth={true}
          value={props.confirmPassword}
          password={props.password}
          handleChangeInput={props.setConfirmPassword}
        />

        <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
          {t('titles.users.register.personal')}
        </Typography>

        <TextFieldCommon
          className="width__33"
          title={t('labels.users.firstName')}
          id="firstName"
          required={true}
          isOpen={props.isFirstName}
          message={t('messages.error.users.firstName')}
          label={t('common.labels.required')}
          type="text"
          fullWidth={true}
          handleChangeInput={props.setFirstName}
        />
        <TextFieldCommon
          className="width__33"
          title={t('labels.users.lastName')}
          id="lastName"
          required={true}
          isOpen={props.isLastName}
          message={t('messages.error.users.lastName')}
          label={t('common.labels.required')}
          type="text"
          fullWidth={true}
          handleChangeInput={props.setLastName}
        />
        <AutoCompleteCommon
          title={t('labels.users.job')}
          label={t('common.labels.search') + ' ' + t('labels.users.job')}
          id="job"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=job"}
          required={false}
          optional={true}
          isOpen={props.isJob}
          message={t('messages.error.users.job')}
          selectedValue={props.selectedJobs}
          handleChange={props.handleChangeJobs}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
        />
        <AutoCompleteCommon
          title={t('labels.users.subject')}
          label={t('common.labels.search') + ' ' + t('labels.users.subject')}
          id="subject"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=subject"}
          required={false}
          optional={true}
          isOpen={props.isSubject}
          selectedValue={props.selectedSubject}
          handleChange={props.handleChangeSubject}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
        />
        <Grid container className="textField__container">
          <Grid item xs={12} md={12}>
            <Typography id="subjectNotifications-lable" className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
              <div dangerouslySetInnerHTML={{ __html: t('labels.users.emailNotifications') }} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              className="checkbox__text"
              label={t('labels.users.subjectNotifications')}
              control={
                <Checkbox
                  id="subjectNotifications-input"
                  checked={props.subjectNotifications}
                  onChange={(event) => props.setSubjectNotifications(event.target.checked)}
                  name="subjectNotifications"
                />
              }
            />
          </Grid>
        </Grid>
        <TextFieldCommon
          className="width__33"
          optional={true}
          title={t('labels.users.phone')}
          id="phone"
          type="text"
          fullWidth={true}
          handleChangeInput={props.setPhone}
        />
        <SelectorCommon
          className="width__33"
          title={t('labels.users.language')}
          id="lang"
          tag="autocomplete-simple"
          required={true}
          isOpen={props.isLanguage}
          message={t('messages.error.users.language')}
          label={t('common.labels.required')}
          selectedValue={props.selectedLanguage}
          handleChangeAutocomplete={props.setSelectedLanguage}
          dataValues={props.languages}
        />
        <AutoCompleteCommon
          title={t('titles.organizations.organization')}
          label={t('common.labels.search') + ' ' + t('titles.organizations.organization')}
          id="organization"
          tag="autocomplete-organization"
          vc={true}
          path={host + "/organizations?_sort=name:ASC"}
          required={true}
          isOpen={props.isOrganization}
          message={t('messages.error.users.organization')}
          selectedValue={props.selectedOrganization}
          handleChange={props.handleChangeOrganization}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
        />
        {props.terms && <Terms
          label={t('labels.users.terms')}
          termForm={true}
          terms={props.terms}
          checkedTerm={props.checkedTerms}
          setCheckedTerm={props.setCheckedTerms}
          isErrorTerm={props.isTerm}
          errorMessage={t('messages.error.users.terms')}
        />}
      </Grid>
      <Grid container className="form__button--justify-left">
        <SubmitButtonCommon
          id="user-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={props.handleCreateUser} />
        <SubmitButtonCommon
          id="user-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          goBack />
      </Grid>
    </>
  )

}
