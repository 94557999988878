import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextFieldCommon from '@components/Common/TextField';
import RadioCommon from '@components/Common/Radio';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Axios from "axios"
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import SubmitButtonCommon from '@components/Common/SubmitButton';

import { SnackBarContext } from "@context/snackBarContext.js"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { set } from 'date-fns';

export default function RepoTypeMetadataEdit(props) {
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  let history = useHistory()

  const [t, i18n] = useTranslation('common')
  const [langs, setLangs] = useState(null)
  const [attributes, setAttributes] = useState([])
  const [errors, setErrors] = useState(null)
  const [optionErrors, setOptionErrors] = useState(null)
  const [title, setTitle] = useState({})
  const [repoType, setRepoType] = useState('')

  let { id } = useParams()

  function verifyAttributes(attrs) {
    let ret = true
    let newErrors = [...errors]
    let newOptionErrors = [...optionErrors]
    for (let [index, attr] of attrs.entries()) {
      if (attr.type === 'listOfValues') {
        for (let [indexOpt, opt] of attr.options.entries()) {
          if (opt.value === '') {
            newOptionErrors[index][indexOpt]['value'] = true
            ret = false
          }
          else
            newOptionErrors[index][indexOpt]['value'] = false

          if (attr.multilang)
            for (let optLang of langs) {
              if (opt.i18n[optLang] === '') {
                newOptionErrors[index][indexOpt]['i18n'][optLang] = true
                ret = false
              }
              else
                newOptionErrors[index][indexOpt]['i18n'][optLang] = false
            }
        }
      }
      for (let lang of langs) {
        if (attr.i18n[lang] === '') {
          newErrors[index][lang] = true
          ret = false
        }
        else
          newErrors[index][lang] = false
      }
      if (attr.id === '' || attr.id === undefined || attr.id === null) {
        newErrors[index]['id'] = true
        ret = false
      }
      else
        newErrors[index]['id'] = false
    }
    setErrors(newErrors)
    setOptionErrors(newOptionErrors)

    return ret
  }
  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        let newLangs = res[0].body
        setLangs(newLangs)
        getRequest(host + '/configurations/' + id)
          .then(res => {
            if (props.id === undefined)
              setAttributes([...res.body.attributes, { i18n: newLangs.reduce((l, val) => ({ ...l, [val]: '' }), {}), type: "text", required: false, multiple: false, multilang: false, id: "" }])
            else
              setAttributes(res.body.attributes)

            if (res.body.title)
              setTitle(res.body.title)

            setRepoType(res.key.split('###')[1]);
          })
      })



  }, [setLangs])

  useEffect(() => {
    if (errors === null || errors.length === 0) {
      let newErrors = []
      let newOptionErrors = []
      attributes.map(attr => {
        let i18n = langs.reduce((l, val) => ({ ...l, [val]: false }), {})
        newErrors.push({ id: false, ...i18n })
        if (attr.type === 'listOfValues')
          newOptionErrors.push(attr.options.map(opt => ({ value: false, ...(attr.multilang && { i18n: { ...i18n } }) })))
        else
          newOptionErrors.push({})
      })
      setOptionErrors(newOptionErrors)
      setErrors(newErrors)
    }
  }, [attributes])

  const handleUpdateConfig = async () => {
    if (verifyAttributes(attributes)) {
      let newAttributes = [...attributes]
      for (let attr of newAttributes) {
        attr.id = !attr.id.includes('repoTypeMetadata###') ? `repoTypeMetadata###${repoType}###${attr.id.replace(/\s/g, '_')}` : attr.id
        for (let [key, value] of Object.entries(attr.i18n)) {
          i18n.addResource(key, 'common', `repositoryTypes.${repoType + '.' + attr.id.split('###')[2]}`, value);
        }
        if (attr.type === 'listOfValues')
          for (let [index, opt] of attr.options.entries()) {
            opt.key = opt.key === '' ? `attroption###${attr.id}-${index}` : opt.key
          }
        attr.order = attr.order === undefined ? newAttributes.length - 1 : attr.order
      }
      await Axios.put(host + '/configurations/' + id, {
        body: {
          title,
          attributes
        }
      }).then(() => {
        setIsSnackBar(true)
        setTag("success")
        setMessage(t('messages.success.configurations.update'))
        history.goBack();
      })
        .catch(() => {
          setIsSnackBar(true)
          setTag("error")
          setMessage(t('messages.error.configurations.update'))
        })
    } else {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.configurations.update'))
    }
  }

  const handleChangeOption = (event, index, indexOpt, lang) => {
    let newAttributes = [...attributes]
    if (lang === undefined)
      newAttributes[index]['options'][indexOpt]['value'] = event.target.value
    else
      newAttributes[index]['options'][indexOpt]['i18n'][lang] = event
    setAttributes(newAttributes)

  }

  const handleSpreadOption = (event, value, index, indexOpt) => {
    let newAttributes = [...attributes]
    langs.map(l => newAttributes[index]['options'][indexOpt]['i18n'][l] === '' || event.type === 'dblclick' ? newAttributes[index]['options'][indexOpt]['i18n'][l] = value : '')
    setAttributes(newAttributes)


  }

  const handleAddOption = (index, indexOpt) => {
    let newAttributes = [...attributes]
    let newOptionErrors = [...optionErrors]
    let i18n = langs.reduce((l, val) => ({ ...l, [val]: '' }), {})
    let i18nError = langs.reduce((l, val) => ({ ...l, [val]: false }), {})
    if (newAttributes[index]['options'].every(obj => Object.entries(obj).every(o => o[0] !== 'key' ? o[1] !== '' : true) ? !newAttributes[index]['multilang'] || (newAttributes[index]['multilang'] && Object.entries(obj.i18n).every(o => o[1] !== '')) : false)) {
      newAttributes[index]['options'].push({ key: '', value: '', ...(newAttributes[index]['multilang'] && { i18n: { ...i18n } }) })
      newOptionErrors[index].push({ value: false, ...(newAttributes[index].multilang && { i18n: { ...i18nError } }) })
      setOptionErrors(newOptionErrors)
      setAttributes(newAttributes)
    }
  }

  const handleRemoveOption = (event, index, indexOpt) => {
    let newAttributes = [...attributes]
    let newOptionErrors = [...optionErrors]
    newAttributes[index]['options'].splice(indexOpt, 1)
    newOptionErrors[index].splice(indexOpt, 1)
    setOptionErrors(newOptionErrors)
    setAttributes(newAttributes)

  }

  const handleChangeId = (value, index) => {
    let newAttributes = [...attributes]
    newAttributes[index]['id'] = value
    setAttributes(newAttributes)
  }

  const handleChangeLabel = (value, lang, index) => {
    let newAttributes = [...attributes]
    newAttributes[index].i18n[lang] = value
    setAttributes(newAttributes)
  }

  const handleSpreadLabel = (event, value, index) => {
    if (value !== undefined && value !== '') {
      let newAttributes = [...attributes]
      langs.map(l => newAttributes[index].i18n[l] === '' || event.type === 'dblclick' ? newAttributes[index].i18n[l] = value : '')
      setAttributes(newAttributes)
    }
  }

  const handleChangeSwitch = (index, input) => {
    let newAttributes = [...attributes]
    let newOptionErrors = [...optionErrors]
    newAttributes[index][input] = !newAttributes[index][input]
    if (input === 'multilang' && newAttributes[index]['type'] === 'listOfValues' && newAttributes[index][input]) {
      newOptionErrors[index] = newOptionErrors[index].map(opt => ({ ...opt, ...(!opt.i18n && { i18n: langs.reduce((l, val) => ({ ...l, [val]: false }), {}) }) }))
      newAttributes[index]['options'] = newAttributes[index]['options'].map(opt => ({ ...opt, i18n: langs.reduce((l, val) => ({ ...l, [val]: '' }), {}) }))
      setOptionErrors(newOptionErrors)
    }
    else if (input === 'multilang' && newAttributes[index]['type'] === 'listOfValues' && !newAttributes[index][input]) {
      newOptionErrors[index].map(opt => opt.i18n ? delete opt['i18n'] : '')
      newAttributes[index]['options'] = newAttributes[index]['options'].map(opt => ({ key: opt.key, value: opt.value }))
      setOptionErrors(newOptionErrors)
    }
    setAttributes(newAttributes)
  }

  const handleTypeChange = (value, index) => {
    let newAttributes = [...attributes]
    let newOptionErrors = [...optionErrors]
    if ((value === 'text' || value === 'longText' || value === 'listOfValues') && newAttributes[index]['multilang'] === undefined)
      newAttributes[index]['multilang'] = false
    if (value !== 'bool' && newAttributes[index]['multiple'] === undefined)
      newAttributes[index]['multiple'] = false
    if (value === 'listOfValues' && newAttributes[index]['options'] === undefined) {
      newAttributes[index]['options'] = [{ key: '', value: '', ...(newAttributes[index]['multilang'] && { i18n: langs.reduce((l, val) => ({ ...l, [val]: '' }), {}) }) }]
      newOptionErrors[index] = ([{ value: false, ...(newAttributes[index].multilang && { i18n: langs.reduce((l, val) => ({ ...l, [val]: false }), {}) }) }])
      setOptionErrors(newOptionErrors)
    }
    if ((value === 'bool' || value === 'date' || value === 'url') && newAttributes[index]['multilang'] !== undefined)
      delete newAttributes[index]['multilang']
    if (value === 'bool' && newAttributes[index]['multiple'] !== undefined)
      delete newAttributes[index]['multiple']
    if (value !== 'listOfValues' && newAttributes[index]['options'] !== undefined)
      delete newAttributes[index]['options']


    newAttributes[index].type = value
    setAttributes(newAttributes)
  }
  return (
    <>
      <Typography component="h2" className="admin__card--title">{
        t('titles.configurations.repoTypesMetadata.' + (props.id === undefined ? 'repoTypeMetadata_new' : 'repoTypeMetadata_update'))
      }</Typography>
      <div>
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.configuration.repoTypeMetadata.' + (props.id === undefined ? 'create' : 'edit'))}</Typography>
      </div>
      {id && attributes && attributes.length > 0 && errors && errors.length > 0 && (
        <>
          {attributes.map((attr, index) =>
            ((props.id !== undefined && attr.id === props.id) || (props.id === undefined && index === attributes.length - 1)) &&
            <Card key={'card-' + index} elevation={0} className="MuiBox-root-43 margin__top--32px">
              <Grid key={'container-grid-' + index} container>
                <Grid key={'item-grid-' + index} item xs={11}>
                  <RadioCommon
                    id={"configurations-attribute-type-" + index}
                    title={t('labels.configurations.repoTypeMetadata.format.title')}
                    value={attr.type}
                    firstLabel={t('labels.configurations.repoTypeMetadata.format.text')}
                    firstCondition="text"
                    secondLabel={t('labels.configurations.repoTypeMetadata.format.longText')}
                    secondCondition="longText"
                    thirdLabel={t('labels.configurations.repoTypeMetadata.format.date')}
                    thirdCondition="date"
                    fourthLabel={t('labels.configurations.repoTypeMetadata.format.bool')}
                    fourthCondition="bool"
                    fifthLabel={t('labels.configurations.repoTypeMetadata.format.listOfValues')}
                    fifthCondition="listOfValues"
                    sixthLabel={t('labels.configurations.repoTypeMetadata.format.url')}
                    sixthCondition="url"
                    defaultValue="text"
                    handleChange={(value) => handleTypeChange(value, index)}
                  />
                </Grid>
              </Grid>
              <Grid key={'container-grid-id-' + index} container className="textField__container">
                <Grid key={'id-grid-item-' + index} item xs={12}>
                  <TextFieldCommon
                    id={"configurations-attribute-id-" + index}
                    title={t('labels.configurations.repoTypeMetadata.key')}
                    value={props.id !== undefined ? attr.id.split('###')[2] : attr.id}
                    isOpen={errors[index]['id']}
                    message={t('messages.error.configurations.repoTypeMetadata.id')}
                    required={true}
                    type="text"
                    handleChangeInput={(value) => handleChangeId(value, index)}
                    disabled={props.id !== undefined}
                  />
                </Grid>
              </Grid>
              <Grid key={'labels-grid-' + index} container className="textField__container">
                {langs && langs.map((lang, indexLang) =>
                  <Grid key={'label-item-grid-' + indexLang + '-' + index} item xs={12} md={6} className={indexLang % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                    <TextFieldCommon
                      id={"configurations-attribute-label-" + lang + '-' + index}
                      title={t('labels.configurations.repoTypeMetadata.title') + ` <sup>${lang}</sup>`}
                      value={attr.i18n[lang]}
                      isOpen={errors[index][lang]}
                      message={t('messages.error.configurations.repoTypeMetadata.label')}
                      required={true}
                      type="text"
                      fullWidth={true}
                      handleChangeInput={(value) => handleChangeLabel(value, lang, index)}
                      handleSpreadInput={(event, value) => handleSpreadLabel(event, value, index)}
                      renderIcon={true}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid key={'container-grid-required-' + index} container className="textField__container">
                <Typography component="div" className="textField__typography--form" variant="subtitle1" id={"configurations-repoTypeAttributes-required-" + index} gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: t('labels.configurations.repoTypeMetadata.required') }} />
                </Typography>
                <Grid key={'required-grid-item-' + index} item xs={12}>
                  <Switch
                    id={"configurations-attribute-required-" + index}
                    checked={attr.required}
                    onChange={() => handleChangeSwitch(index, "required")}
                    name="required"
                  />
                </Grid>
              </Grid>
              {attr.multiple !== undefined && <Grid key={'container-grid-multiple-' + index} container className="textField__container">
                <Typography component="div" className="textField__typography--form" variant="subtitle1" id={"configurations-repoTypeAttributes-multiple-" + index} gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: t('labels.configurations.repoTypeMetadata.multiple') }} />
                </Typography>
                <Grid key={'multiple-grid-item-' + index} item xs={12}>
                  <Switch
                    id={"configurations-attribute-multiple-" + index}
                    checked={attr.multiple}
                    onChange={() => handleChangeSwitch(index, 'multiple')}
                    name="multiple"
                  />
                </Grid>
              </Grid>}
              {attr.multilang !== undefined && <Grid key={'container-grid-multilang-' + index} container className="textField__container">
                <Typography component="div" className="textField__typography--form" variant="subtitle1" id={"configurations-repoTypeAttributes-multilang-" + index} gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: t('labels.configurations.repoTypeMetadata.multilang') }} />
                </Typography>
                <Grid key={'multilang-grid-item-' + index} item xs={12}>
                  <Switch
                    id={"configurations-attribute-multilang-" + index}
                    checked={attr.multilang}
                    onChange={() => handleChangeSwitch(index, 'multilang')}
                    name="multilang"
                  />
                </Grid>
              </Grid>}
              {attr.options && optionErrors &&
                <Typography className="textField__typography--subtitle" id="configurations-general-gravatar-title" variant="h5" component="h5" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.repoTypeMetadata.optionTitle') }} /></Typography>
              }
              {attr.options && optionErrors && attr.options.map((opt, indexOpt) =>
                <React.Fragment key={'container-grid-options' + index + '-' + indexOpt}>
                  <Grid key={'container-grid-options' + index + '-' + indexOpt} container className="textField__container">
                    <Grid item xs={12} md={12} >
                      <Typography component="div" className="textField__typography--form" variant="subtitle1" id={"configurations-repoTypeAttributes-multilang-" + index} gutterBottom>
                        <div key={'container-grid-options' + index + '-' + indexOpt} dangerouslySetInnerHTML={{ __html: t('labels.configurations.repoTypeMetadata.optionValue') + ` ${indexOpt + 1}` }} />
                      </Typography>
                    </Grid>
                    <Grid key={'option-grid-' + index + '-' + indexOpt} item xs={11} md={11}>
                      <TextField
                        id={'configurations-attribute-options-value-' + indexOpt + '-input'}
                        size="small"
                        variant="outlined"
                        value={opt.value}
                        onChange={(event) => handleChangeOption(event, index, indexOpt)}
                        fullWidth={true}
                      />
                      {
                        optionErrors[index] && optionErrors[index][indexOpt] && optionErrors[index][indexOpt]['value'] &&
                        <FormHelperText id={index + '-option-' + indexOpt + '-input-error'}>{t('messages.error.configurations.repoTypeMetadata.label')}</FormHelperText>
                      }
                    </Grid>
                    <Grid key={'option-button-grid-' + index + '-' + indexOpt} item xs={1} md={1}>
                      {indexOpt !== attr.options.length - 1 ?
                        <Button id={'remove-option-' + index + '-' + indexOpt + '-button'} className="form__button--remove" size="medium" onClick={(event) => handleRemoveOption(event, index, indexOpt)}>
                          <RemoveIcon className="form__icon--remove" />
                        </Button>
                        :
                        <Button id={'add-option-' + index + '-' + indexOpt + '-button'} className="form__button--add" size="medium" onClick={(event) => handleAddOption(index, attr.options.length)}>
                          <AddIcon className="form__icon--add" />
                        </Button>
                      }
                    </Grid>


                    {attr.multilang && langs.map((lang, indexLang) =>
                      <Grid key={'label-item-grid-' + indexLang + '-' + index + '-' + indexOpt} item xs={12} md={6} className={indexLang % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                        <TextFieldCommon
                          id={"configurations-attribute-options-" + lang + '-' + index + '-' + indexOpt}
                          title={t('labels.configurations.repoTypeMetadata.optionLabel') + ` ${indexOpt + 1} <sup>${lang}</sup>`}
                          value={opt.i18n[lang]}
                          isOpen={optionErrors[index][indexOpt] ? optionErrors[index][indexOpt]['i18n'][lang] : ''}
                          message={t('messages.error.configurations.repoTypeMetadata.label')}
                          required={true}
                          type="text"
                          fullWidth={true}
                          handleChangeInput={(value) => handleChangeOption(value, index, indexOpt, lang)}
                          handleSpreadInput={(event, value) => handleSpreadOption(event, value, index, indexOpt)}
                          renderIcon={true}
                        />
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>
              )
              }
            </Card>
          )}
        </>)}
      <Grid container className="margin__top--10px form__button--justify-center">
        <SubmitButtonCommon
          id="configurations-attribute-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={handleUpdateConfig} />
        <SubmitButtonCommon
          id="configurations-attribute-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          goBack />
      </Grid>
    </>
  )
}