import React, { useState, useEffect } from 'react'
import { Card, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'

import ApexPieChart from '@components/Charts/ApexPieChart'
import ApexBarChart from '@components/Charts/ApexBarChart'
import ApexLineChart from '@components/Charts/ApexLineChart'
import ApexMultiChart from '@components/Charts/ApexMultiChart'
import getTranslation from '@utils/functions/utilities.js'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import '@styles/frontOffice/Home.css'
import '@styles/Common/Common.css'

export default function Home() {
  const [t, i18n] = useTranslation("common")
  const [data, setData] = useState(null)

  useEffect(() => {
    getRequest(host + '/configurations/public?type=STATISTICS')
      .then(res => {
        setData(res)
      })
  }, [setData])
  return (
    <div className="public__container--margin public__container--padding-bottom">
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { name: "statistics" }
      ]} />
      <Typography id="statistics-title" component="h1" className="index__title content--card admin__card--title admin__form--title-margin">{t('titles.home.statistics')}</Typography>
      <div className="stats__display--flex">
        {data && data.map((stat, index) => (
          <Grid item xs={12} key={stat.key + index}>
            {
              stat.objectType === "VALUE" ?
                <Card elevation={0} className="card__margin stats__container stats__green--card">
                  <Typography className="stats__green--card stats__text--card" variant="subtitle1" component="h2" color="textSecondary">
                    {stat.body.value} {getTranslation(stat.body.title, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  </Typography>
                </Card> :
                stat.objectType === "PieChart" ?
                  stat.body.value.length > 0 &&
                  <Card elevation={0} className="card__margin stats__container">
                    <ApexPieChart
                      data={stat.body.value}
                      value={"value"}
                      argument={"key"}
                      title={stat.body.title}
                    // width={"100%"}
                    // height={920}
                    />
                  </Card> :
                  stat.objectType === "TIMESERIES" ?
                    stat.body.value.length > 0 &&
                    <Card elevation={0} className="card__margin stats__line--heigth stats__container">
                      <ApexLineChart
                        data={stat.body.value}
                        value={"value"}
                        argument={"key"}
                        title={stat.body.title}
                      />
                    </Card> :
                    stat.objectType === "MULTITIMESERIES" ?
                      stat.body.value.length > 0 &&
                      <Card elevation={0} className="card__margin stats__line--heigth stats__container">
                        <ApexMultiChart
                          data={stat.body.value}
                          keys={stat.body.keys}
                          argument={"key"}
                          title={stat.body.title}
                        />
                      </Card> :
                      stat.body.value.length > 0 &&
                      <Card elevation={0} className="card__margin stats__line--heigth stats__container">
                        <ApexBarChart
                          data={stat.body.value}
                          value={"value"}
                          argument={"key"}
                          title={stat.body.title}
                        />
                      </Card>
            }
          </Grid>
        ))
        }
      </div>
    </div>
  )
}


