import React, { useEffect, useState } from 'react'
import { Typography, Card } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'

import '@styles/frontOffice/Home.css'
import getTranslation from '@utils/functions/utilities.js'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Home() {
  const [t, i18n] = useTranslation("common")
  const [configuration, setConfiguration] = useState(null)

  useEffect(() => {
    getRequest(host + '/configurations/public?type=CONTACT')
      .then(res => {
        setConfiguration(res[0])
      })
  }, [setConfiguration])


  return (
    <div className="about__container public__container--padding-bottom">
      <div className="content--card">
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { name: "contact" }
        ]} />
      </div>
      {(configuration) &&
        <>
          <Typography id="contact-title" component="h1" className="index__title content--card admin__card--title admin__form--title-margin">{t('titles.home.contact')}</Typography>
          <Card elevation={0} id="contact-card" className="MuiBox-root-43 border__radius--8px content--card">
            <div id="contact-content" className="indexes__info--text" dangerouslySetInnerHTML={{ __html: getTranslation(configuration.body, i18n.language, i18n.languages[i18n.languages.length - 1]) }} >
            </div>
          </Card>
        </>
      }
    </div>
  )
}
