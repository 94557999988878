import React from 'react'

import GroupPage from '@components/Group/Group'

import '@styles/backOffice/Group/Groups.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Role() {
  return (
    <div className="admin__container-bottom" >
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin", name: "admin" },
      { href: "/#/admin/groups", name: "groups"},
      { name: "group" },
    ]} />
      <GroupPage />
    </div>
  )
}
