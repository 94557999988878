import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"

import HelpForm from '@components/Help/HelpForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useParams } from 'react-router-dom'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function EditHelp() {
    const [t] = useTranslation("common")

    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
    const [help, setHelp] = useState(null)

    let history = useHistory()
    const { id } = useParams()

    const submitHelp = async (newHelp) => {
        try {
            await Axios.put(host + '/helps/' + id, newHelp)
            snackForHelp("success", t('messages.success.help.update'))
            history.goBack()
        }
        catch (err) {
            snackForHelp("error", t('messages.error.help.update'))
        }
    }

    useEffect(() => {
        getRequest(host + "/helps/" + id)
            .then(res => {
                setHelp(res)
            })
    }, [setHelp, id])

    const snackForHelp = (tag, message) => {
        setTag(tag)
        setMessage(message)
        setIsSnackBar(true)
    }

    return (
        <div className="admin__container-bottom">
            {help && <>
                <BreadCrumbs crumbs={[
                    { href: "/#/", name: "home" },
                    { href: "/#/admin", name: "admin" },
                    { href: "/#/admin/helps", name: "helps" },
                    { name: "helps_update" }
                ]} />
                <Typography component="h2" className="admin__card--title">{t('titles.help.update')}</Typography>
                <div >
                    <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.help.form')}</Typography>
                </div>
                <HelpForm
                    title={help.title}
                    body={help.body}
                    snackForHelp={snackForHelp}
                    submitHelp={submitHelp}
                    submitLable={t('help.form.submit.update')}
                />
            </>}
        </div>
    )
}