import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next'

import '@styles/Common/Common.css'
import { Tooltip } from '@material-ui/core';
import { height } from '@material-ui/system';

export default function TextFieldCommon(props) {
  const [t] = useTranslation('common')
  const [render, setRender] = useState(true)

  useEffect(() => {
    if (typeof props.render === "boolean")
      setRender(props.render)
  }, [props.render])

  const handleChangeArrayInput = (event, index) => {
    let values = [...props.value]
    values[index] = event.target.value
    props.handleChangeInput(values)
  }

  const handleSpreadLangArrayInput = (event, index, lang) => {
    let values = [...props.value]
    if (values[index][lang] !== undefined && values[index][lang] !== '') {
      props.langs.map(l => values[index][l] === '' || values[index][l] === undefined || event.type === 'dblclick' ? values[index][l] = values[index][lang] : '')
    }
    props.handleChangeInput(values)
  }

  const handleChangeLangArrayInput = (event, index, lang) => {
    let values = [...props.value]
    values[index][lang] = event.target.value
    props.handleChangeInput(values)
  }

  const handleChangeInput = (event) => {
    event.preventDefault()
    props.handleChangeInput(event.target.value)
  }

  const handleClickShowPassword = (event) => {
    event.preventDefault()

    props.handleClickShowPassword()
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAddField = (event) => {
    let values = [...props.value]
    values.push("")
    props.handleChangeInput(values)
  }

  const handleAddLangField = (langs) => {
    let values = [...props.value]
    let newValue = langs.reduce((l, val) => ({ ...l, [val]: '' }), {})
    if (values.every(val => (Object.keys(val).length !== 0 && Object.entries(val).length === langs.length && Object.entries(val).every(v => v[1] !== ''))))
      values.push(newValue)
    props.handleChangeInput(values)
  }

  const handleRemoveField = (event, index) => {
    let values = [...props.value]
    values.splice(index, 1)
    props.handleChangeInput(values)
  }

  const passwordIcon = (expr) => {
    return expr ? <CheckIcon fontSize='small' className='users--correctPW--icon--color' /> : <CloseIcon fontSize='small' className='users--wrongPW--icon--color' />

  }

  const validPasswordConfirmation = (password, confirm) => {
    const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9])+$/
    return <Box className={props.className}>
      <Grid container>
        <Grid item xs={2} md={2} lg={1}>
          {passwordIcon(password.length >= 1)}
        </Grid>
        <Grid item xs={10} md={10} lg={11}>
          {t('messages.error.users.password.empty')}
        </Grid>
        <Grid item xs={2} md={2} lg={1}>
          {passwordIcon(password.length >= 13)}
        </Grid>
        <Grid item xs={10} md={10} lg={11}>
          {t('messages.error.users.password.length')}
        </Grid>
        <Grid item xs={2} md={2} lg={1}>
          {passwordIcon(password.match(passRegExp))}
        </Grid>
        <Grid item xs={10} md={10} lg={11}>
          {t('messages.error.users.password.format')}
        </Grid>
        <Grid item xs={2} md={2} lg={1}>
          {passwordIcon(password === confirm && confirm !== '' && password !== '')}
        </Grid>
        <Grid item xs={10} md={10} lg={11}>
          {t('messages.error.users.password.confirm')}
        </Grid>
      </Grid>
    </Box>
  }


  if (!render) return null

  return (
    <Grid container className="textField__container">
      {!Array.isArray(props.value) ?
        <>
          <Grid item xs={12} md={12}>
            {props.title !== '' ?
              <Typography id={props.id + '-lable'} className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                <div dangerouslySetInnerHTML={{ __html: props.title + (props.optional ? " (" + t('labels.common.optional') + ")" : props.required ? " *" : "") }} />
                {
                  props.tooltip &&
                  <Tooltip title={props.tooltip}>
                    <HelpIcon style={{ height: "auto", paddingLeft: "0.1rem" }} fontSize='small'></HelpIcon>
                  </Tooltip>}
              </Typography>
              : ''
            }
          </Grid>
          <Grid item xs={12} md={12}>
            {
              props.type === 'password'
                ? (
                  <>
                    <TextField
                      className={props.className + " margin__top--9px"}
                      id={props.id + '-input'}
                      size="small"
                      variant="outlined"
                      required={props.required}
                      multiline={props.multiline}
                      value={props.value}
                      disabled={props.disabled}
                      rows={props.rows || 3}
                      fullWidth={props.fullWidth}
                      name={props.id}
                      onChange={(event) => handleChangeInput(event)}
                      type={props.showPassword ? 'text' : 'password'}
                      InputProps={props.renderIcon && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {props.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>

                          </InputAdornment>
                        )
                      }}
                    />
                    {
                      props.id === 'confirmPassword' && validPasswordConfirmation(props.password, props.value)
                    }
                  </>
                )
                : <TextField
                  className={props.className + " margin__top--9px"}
                  id={props.id + '-input'}
                  size="small"
                  variant="outlined"
                  required={props.required}
                  value={props.value}
                  disabled={props.disabled}
                  defaultValue={props.defaultValue}
                  multiline={props.multiline}
                  rows={props.rows || 3}
                  fullWidth={props.fullWidth}
                  name={props.id}
                  type={props.type}
                  onChange={(event) => handleChangeInput(event)}
                  InputProps={props.renderIcon && props.handleSpreadInput && !props.disabled ? {
                    endAdornment: (
                      <Tooltip title={t('tooltip.autoComplete')}>
                        <InputAdornment position="end">
                          <IconButton
                            id={props.id + '-input-spread'}
                            onClick={(event) => props.handleSpreadInput(event, props.value)}
                            onDoubleClick={(event) => props.handleSpreadInput(event, props.value)}
                          >
                            <LowPriorityIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      </Tooltip>
                    )
                  } : {}}
                />
            }
            {
              (props.message && props.isOpen) &&
              <FormHelperText className={props.className} id={props.id + '-error'} error>{props.message && props.message}</FormHelperText>
            }
          </Grid>
          {props.previous && <Grid item xs={12}>
            <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" id={'previous-' + props.id} gutterBottom>
              {t('labels.workflows.previous') + (props.lang ? props.previous[props.lang] : props.previous)}
            </Typography>
          </Grid>}
        </>
        : props.value.map((val, index) => (
          <Grid container key={index}>
            {index === 0 ?
              <Grid item xs={12} md={12}>
                <Typography id={props.id + '-lable-' + index} className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: props.title + (props.optional ? " (" + t('labels.common.optional') + ")" : props.required ? " *" : "") }} />
                  {
                    props.tooltip &&
                    <Tooltip title={props.tooltip}>
                      <HelpIcon style={{ height: "auto", paddingLeft: "0.1rem" }} fontSize='small'></HelpIcon>
                    </Tooltip>}
                </Typography>
              </Grid>
              : <Grid item xs={12} md={12} />
            }
            <Grid item xs={12} md={12}>
              <div className="textField__multi--margin--top">
                <div className="form__flex--multiple">
                  {
                    !props.lang ?
                      <TextField
                        className={props.className + " margin__top--9px"}
                        id={props.id + '-' + index + '-input'}
                        size="small"
                        variant="outlined"
                        required={props.required}
                        value={val}
                        multiline={props.multiline}
                        disabled={props.disabled}
                        rows={props.rows || 3}
                        fullWidth={props.fullWidth}
                        name={props.id}
                        type={props.type}
                        onChange={(event) => handleChangeArrayInput(event, index)}
                      />
                      :
                      <TextField
                        className={props.className + " margin__top--9px"}
                        id={props.id + '-' + index + '-input'}
                        size="small"
                        variant="outlined"
                        required={props.required}
                        value={val[props.lang]}
                        multiline={props.multiline}
                        disabled={props.disabled}
                        rows={props.rows || 3}
                        fullWidth={props.fullWidth}
                        name={props.id}
                        type={props.type}
                        onChange={(event) => handleChangeLangArrayInput(event, index, props.lang)}
                        InputProps={props.renderIcon && !props.disabled ? {
                          endAdornment: (
                            <Tooltip title={t('tooltip.autoComplete')}>
                              <InputAdornment position="end">
                                <IconButton
                                  id={props.id + '-' + index + '-input-spread'}
                                  onClick={(event) => handleSpreadLangArrayInput(event, index, props.lang)}
                                  onDoubleClick={(event) => handleSpreadLangArrayInput(event, index, props.lang)}
                                >
                                  <LowPriorityIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            </Tooltip>
                          )
                        } : {}
                        }
                      />
                  }
                  {!props.disabled && props.addButton && <>{
                    index === props.value.length - 1 ?

                      <Button id={props.id + '-' + index + '-add-button'} className="form__button--add" size="medium" onClick={(event) => !!val ? !props.langs ? handleAddField(event) : handleAddLangField(props.langs) : null}>
                        <AddIcon className="form__icon--add" />
                      </Button>

                      :
                      <>
                        <Button id={props.id + '-' + index + '-remove-button'} className="form__button--remove" size="medium" onClick={(event) => handleRemoveField(event, index)}>
                          <RemoveIcon className="form__icon--remove" />
                        </Button>

                      </>
                  }</>}
                </div>
                {
                  (props.required && props.isOpen && index === props.value.length - 1) &&
                  <FormHelperText id={props.id + '-error'} error>{props.message && props.message}</FormHelperText>
                }
              </div>
            </Grid>
            {props.previous && props.previous[index] && <Grid item xs={12}>
              <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                {t('labels.workflows.previous') + (props.lang ? props.previous[index][props.lang] : props.previous[index])}
              </Typography>
            </Grid>}
          </Grid>
        )
        )
      }


    </Grid >
  )
}
