import React, { useState, useEffect, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core';

import { UserContext } from '@context/userContext';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import TableCommon from '@components/Common/Table';

import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"
import '@styles/Common/Common.css'


export default function RepoTypesMetadata() {
  const [t, i18n] = useTranslation('common')

  const [user] = useContext(UserContext)
  const [headers, setHeaders] = useState(null)


  useEffect(() => {
    setHeaders([
      {
        name: t('labels.configurations.repoTypeMetadata.value'),
        value: ["body", "title"],
        sort: true
      },
      {
        name: t('labels.configurations.repoTypeMetadata.attributes'),
        value: ["body", "attributes", "length"],
        sort: false
      }
    ])
  }, [t, setHeaders, i18n.language])
  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { name: "repoTypesMetadata" }
      ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-repoTypeMetadata-title" component="h2" className="admin__card--title">{t('titles.repositories.header.repoTypeMetadata')}</Typography>
        </Grid>
      </Grid>
      {headers && <TableCommon
        key='all'
        headers={headers}
        backendPath="/configurations"
        frontendPath="/repoTypeMetadata"
        sort="createdAt"
        controller="configuration.repoTypeMetadata"
        filter={"type=REPOSITORYTYPE"}
        hasRemoveButton={false}
        hasAddButton={false}
      />}
    </div>
  );
}
