import React, { useState, createContext } from 'react'

import DialogCommon from '@components/Common/Dialog';

export const DialogContext = createContext()

export const DialogContextProvider = props => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const [action, setDialogAction] = useState(null)
    const [dialogMessage,setDialogMessage] = useState('')
    const [dialogCancel,setDialogCancel] = useState('')
    const [dialogConfirm,setDialogConfirm] = useState('')

    const [note, setNote] = useState('')

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleConfirm = () => {
        if (action) {
            if(action.message)
                action.function(note)
            else
                action.function()
        }
        setDialogOpen(false)
    }

    return (
        <DialogContext.Provider value={[setDialogOpen,setDialogMessage,setDialogCancel,setDialogConfirm,setDialogAction]}> 
            {props.children}
            <DialogCommon
                open={dialogOpen}
                message={dialogMessage}
                hasNote={action ? (action.message || false) : false}
                note={note}
                setNote={setNote}
                noteTitle={action ? (action.text || "") : ""}
                cancel={dialogCancel}
                confirm={dialogConfirm}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
            />
        </DialogContext.Provider>
    )
}