import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import adminAccess from '@utils/comparators/AdminAccess'
import Metatags from '../../Metatags';
import '@styles/Common/Common.css'
import '@styles/frontOffice/Repository/Repository.css'
import '@styles/backOffice/Highlight/Highlight.css'


export default function HighlightPage(props) {
  const [t, i18n] = useTranslation('common')
  const [highlight, setHighlight] = useState(props.highlight)
  const [langs, setLangs] = useState(null)
  let history = useHistory()
  const [user] = useContext(UserContext)

  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setHighlight])

  if (highlight) return (
    <>
      <Metatags title={getTranslation(highlight.title, i18n.language, i18n.languages[i18n.languages.length - 1])} description={getTranslation(highlight.body, i18n.language, i18n.languages[i18n.languages.length - 1])} />
      {props.tag !== 'public' ?
        <>
          <Grid container>
            <Grid item xs={6}>
              <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{props.tag !== 'public' ? t('titles.highlights.highlight') : getTranslation(highlight.title, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
            </Grid>
            {props.tag !== "public" && <Grid item xs={6} className="admin__align--left">
              {user && adminAccess('highlight', 'update', user.group.permission) &&
                <Tooltip title={t('tooltip.highlights.edit')}>
                  <Button
                    id="backoffice-repositories-add-button"
                    className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/highlights/update/' + id)}
                    startIcon={
                      <EditIcon className="admin__icon--create" />
                    }
                    role="adminCards"
                  >
                    {t("common.buttons.edit")}
                  </Button>
                </Tooltip>
              }
            </Grid>}
          </Grid>
          {props.tag !== "public" && <div >
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.highlight.show')}</Typography>
          </div>}
          <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
            <Grid container>
              <Grid key="highlight-repository" item xs={12} md={12}>
                <TextLable
                  id="highlight-repository"
                  title={t('labels.highlights.repository')}
                  value={getTranslation(highlight.repository.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                />
              </Grid>
              {langs && langs.filter(lang => highlight.title[lang]).map((lang, index) =>
                <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"title" + lang}
                    title={t('labels.highlights.title') + " <sup>" + lang + "</sup>"}
                    value={highlight.title[lang]}
                  />
                </Grid>
              )}
              {langs && langs.filter(lang => highlight.title[lang]).map((lang, index) =>
                <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"description" + lang}
                    title={t('labels.highlights.description') + " <sup>" + lang + "</sup>"}
                    value={highlight.description[lang]}
                  />
                </Grid>
              )}
              <Grid key="highlightDate" item xs={12} md={12}>
                <TextLable
                  id="highlightDate"
                  title={t('labels.highlights.date')}
                  value={highlight.date}
                />
              </Grid>
              <Grid key="startDate" item xs={12} md={6}>
                <TextLable
                  id="startDate"
                  title={t('labels.highlights.start_date')}
                  value={highlight.startDate}
                />
              </Grid>
              <Grid key="endDate" item xs={12} md={6}>
                <TextLable
                  id="endDate"
                  title={t('labels.highlights.end_date')}
                  value={highlight.endDate}
                />
              </Grid>
              {langs && langs.filter(lang => highlight.body[lang]).map((lang, index) =>
                <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"content" + lang}
                    title={t('labels.highlights.body') + " <sup>" + lang + "</sup>"}
                    value={highlight.body[lang]}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
          <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
            <SubmitButtonCommon
              id="highlight-back-button"
              className="form__button--color-error"
              title={t('table.confirm.backButton')}
              goBack />
          </Grid>
        </>
        :
        <>
          <Typography id="highlight-title" component="h1" className="index__title content--card admin__card--title">{getTranslation(highlight.title, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
          <Typography variant='h6' color="textSecondary" className='content--card highlights__subtitle--margin' component="h6">
            {getTranslation(highlight.description, i18n.language, i18n.languages[i18n.languages.length - 1])}
          </Typography>
          <Typography variant="subtitle2" component="h2" color="textSecondary" className='content--card highlights__subtitle--margin' gutterBottom>
            <time className='highlight__date' datetime={highlight.date}>{highlight.date}</time>
          </Typography>

          <Card elevation={0} className="MuiBox-root-43 border__radius--8px content--card">
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12} md={9} className="fields__padding--right">
                <Typography id="highlight-repository" variant="h5" gutterBottom component="h5">
                  <Link href={"#/repositories/" + highlight.repository._id} id="highlight-repository" variant="h5" >
                    {getTranslation(highlight.repository.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  </Link>
                </Typography>

              </Grid>
              {highlight.repository.logo &&
                <Grid item container xs={12} md={3} align="center" justify="flex-end">
                  <img className="highlights__card--logo" src={host + highlight.repository.logo.url} alt={highlight.repository.name[i18n.language]} />
                </Grid>
              }

            </Grid>
            <Grid container>
              <Typography variant="body1" color="textSecondary" component="div">
                <div id="highlight-content" className="indexes__info--text" dangerouslySetInnerHTML={{ __html: getTranslation(highlight.body, i18n.language, i18n.languages[i18n.languages.length - 1]) }} />
              </Typography>
            </Grid>
          </Card>
        </>
      }
    </>
  )

  return null
}
