import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AppNavBar from '@components/Common/NavBar/AppNavBar'
import Footer from '@components/Common/NavBar/Footer'
import ReactGA from 'react-ga4';
import Plausible from 'plausible-tracker'
import { useMatomo } from '@datapunt/matomo-tracker-react'


import Metatags from '../Metatags';

import '@styles/index.css'

export default function PublicRoute({ component: Component, ...rest }) {
  const location = useLocation()
  const [t, i18n] = useTranslation("common")
  const { trackPageView } = useMatomo()

  useEffect(() => {
    fetch('manifest.json')
      .then((r) => r.json())
      .then((data) => {
        if (data['DRD_PLAUSIBLE_API']) {
          const { trackPageview } = Plausible({
            domain: window.location.host,
            apiHost: data['DRD_PLAUSIBLE_API'],
          })
          trackPageview({ url: location.pathname + location.search })
        }
      })
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    trackPageView({ href: location.pathname + location.search })
    window.scrollTo(0, 0)
    // ReactGA.event({
    //   category: "test",
    //   action: "page____view",
    //   label: "Isto é um teste", // optional
    //   value: 68, // optional, must be a number
    //   nonInteraction: true, // optional, true/false
    //   transport: "xhr", // optional, beacon/xhr/image
    // });
  }, [location.pathname, location.search])

  let path = rest.path.replace(/\//, "")
  return (
    <>
      {!path.includes("/") && <Metatags title={path.includes('home') ? null : t('breadcrumbs.' + path)} description={path.includes('home') ? t('messages.home.mission') : null} />}
      <AppNavBar></AppNavBar>
      <div id="main-content" className="App-body backgroundImage">
        <Route {...rest} render={(props) => (
          <Component {...rest} {...props} />
        )} />
      </div>
      <Footer />
    </>
  )
}
