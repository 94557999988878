import React, { useEffect, useState, useContext } from 'react';
import { CardContent, CardHeader, Grid, Card, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography';

import { useParams, useLocation } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import Axios from 'axios';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"
import { DialogContext } from '@context/dialogContext'
import '@styles/backOffice/Workflow/Request.css'
import RepositoryShow from '@components/Workflow/Repository/RepositoryShow.js'
import RepositoryValidate from '@components/Workflow/Repository/RepositoryValidate.js'
import OrganizationShow from '@components/Workflow/Organization/OrganizationShow.js'
import ActionPanel from './ActionPanel';
import RepositoryForm from '@components/Repository/RepositoryForm';
import OrganizationForm from '@components/Organization/OrganizationForm';
import TextLable from '@components/Common/TextLable';
import getTranslation from '@utils/functions/utilities.js'
import Metatags from '../../Metatags';
import moment from "moment-with-locales-es6";

const qs = require('qs')

export default function RequestPage(props) {
  let { id } = useParams()
  const [t, i18n] = useTranslation('common')

  const [isEdit, setIsEdit] = useState(false)
  const [request, setRequest] = useState(null)
  const [progress, setProgress] = useState(0)
  const [errors, setErrors] = useState([])
  const [langs, setLangs] = useState(null)
  const [uploadSize, setUploadSize] = useState(null)
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [repoTypeMetadataErrors, setRepoTypeMetadataErrors] = useState(null)
  const [repoTypeMetadata, setRepoTypeMetadata] = useState(null)


  const location = useLocation()
  const query = qs.parse(location.search.replace(/\?/, ''))

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
        getRequest(host + '/requests/' + id)
          .then(req => {
            setRequest({ ...req })
            req.condition === "CREATED" ? setProgress(33) :
              req.condition === "ASSIGNED" ? setProgress(66) : setProgress(100)
            if (req.objectType === "REPOSITORY") {
              getRequest(host + '/configurations/public?type=REPOSITORYTYPE&&key=' + encodeURIComponent(req.object.repositoryType[0].key))
                .then(res1 => {
                  if (res1[0].body.attributes.length > 0) {
                    if (!req.object.repoTypeMetadata || req.object.repoTypeMetadata.length === 0) {
                      req.object.repoTypeMetadata = res1[0].body.attributes.map(attr => ({ ...attr, value: repoTypeMetadataValues(attr, res[0].body) }))
                      setRepoTypeMetadata(req.object.repoTypeMetadata)
                      setRepoTypeMetadataErrors(res1[0].body.attributes.map(attr => false))
                    }
                    else {
                      let attributes = res1[0].body.attributes.filter(at => req.object.repoTypeMetadata.findIndex(dm => dm.id === at.id) === -1);
                      if (attributes.length > 0) {
                        attributes = attributes.map(attr => ({ ...attr, value: repoTypeMetadataValues(attr, res[0].body) }))
                        setRepoTypeMetadata([...req.object.repoTypeMetadata, ...attributes])
                        setRepoTypeMetadataErrors([...req.object.repoTypeMetadata.map(attr => false), ...attributes.map(attr => false)])
                      }
                      else {
                        setRepoTypeMetadata(req.object.repoTypeMetadata)
                        setRepoTypeMetadataErrors(req.object.repoTypeMetadata.map(attr => false))
                      }
                    }
                    setRepoTypeMetadataErrors(req.object.repoTypeMetadata.map(attr => false))
                  } else {
                    setRepoTypeMetadata([])
                    setRepoTypeMetadataErrors([])
                  }

                })
            } else {
              setRepoTypeMetadata([])
              setRepoTypeMetadataErrors([])
            }
          })
      })

    getRequest(host + '/configurations/public?type=UPLOADSIZE')
      .then(res => {
        setUploadSize(res[0])
      })

  }, [setRequest, query.condition])

  const repoTypeMetadataValues = (attr, langs) => {
    let value
    if (attr.type === 'text' || attr.type === 'longText' || attr.type === 'url')
      if (attr.multilang)
        value = langs.reduce((l, val) => ({ ...l, [val]: '' }), {})
      else
        value = ''
    else if (attr.type === 'date')
      value = null
    else if (attr.type === 'bool')
      value = false
    else if (attr.type === 'listOfValues')
      if (!attr.multiple)
        value = {}
      else
        value = []
    if (attr.multiple && attr.type !== 'bool' && attr.type !== 'listOfValues')
      value = [value]

    return value
  }

  const handleEditButton = (event) => {
    event.preventDefault()
    setErrors([])
    setIsEdit(!isEdit)
  }

  const saveChanges = async (data) => {
    let newRequest = { ...request }

    let newLogo;
    if (data.newLogo) {
      const content = new FormData()
      content.append('files', data.newLogo)

      newLogo = await Axios.post(host + "/upload", content)
      if (newLogo && data.logo) {
        await Axios.delete(host + "/upload/files/" + data.logo._id)
      }
      newLogo = newLogo.data[0]
      data.logo = { _id: newLogo._id, url: newLogo.url }
    }
    delete data.newLogo

    newRequest.object = { ...data }

    Axios.put(host + "/requests/" + request._id, {
      object: { ...data },
      objectType: request.objectType
    }).then((res) => {
      newRequest.object.stats = res.data.object.stats
      setRequest(newRequest)
      setIsEdit(false)
    }).catch(error => {
      if (error.response.data.message === 'Missing/Invalid repository fields') {
        setErrors(error.response.data)
        snackForRepository("error", t('messages.error.common.validation'))
      }
      else
        snackForRepository("error", t('messages.error.repositories.create'))
    })

  }

  const snackForRepository = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  const snackForOrganization = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <>
      {request && langs && uploadSize && repoTypeMetadata &&
        <>
          <Metatags title={t('titles.workflows.workflow')} />
          <Typography component="h2" className="admin__card--title">{t('titles.workflows.workflow')}</Typography>
          <div >
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.request.form')}</Typography>
          </div>
          <LinearProgress variant="determinate" className="request__linearProgress margin__top--32px" value={progress} />
          <Card elevation={0} className="MuiBox-root-43">
            <Grid container>
              <TextLable
                id="condition"
                title={t('labels.workflows.condition')}
                value={t('titles.workflows.condition.' + request.condition)} />
              <TextLable
                id="objectType"
                title={t('labels.workflows.objectType')}
                value={t('titles.workflows.objectType.' + request.objectType)} />
              <TextLable
                id="type"
                title={t('labels.workflows.type')}
                value={t('titles.workflows.type.' + request.type)} />
              {
                request.assigned &&
                <TextLable
                  id="assignedAt"
                  title={t('labels.workflows.assignedAt')}
                  value={request.assigned.email} />
              }
              <TextLable
                id="createdBy"
                title={t('labels.workflows.createdBy')}
                value={request.createdBy} />
              <TextLable
                id="createdAt"
                title={t('labels.workflows.createdAt')}
                value={moment(request.createdAt).format("YYYY-MM-DD HH:mm")} />
              {
                request.createdAt !== request.updatedAt &&
                <TextLable
                  id="updatedAt"
                  title={t('labels.workflows.updatedAt')}
                  value={moment(request.updatedAt).format("YYYY-MM-DD HH:mm")} />
              }
              {
                request.note &&
                <TextLable
                  id="note"
                  title={t('labels.workflows.note')}
                  value={request.note} />
              }

              <div className="form__align--space-between form__width--100">
                <Typography component="h2" className="admin__card--subtitle admin__form--subtitle-margin">{t('labels.workflows.objectTitle')}</Typography>
                {
                  request.condition === "ASSIGNED" &&
                  <Tooltip title={t('tooltip.workflows.edit')}>
                    <Button id="request-edit-button" className="form__button--add admin__form--subtitle-margin" onClick={handleEditButton}>
                      <EditIcon className="form__icon--add " />
                    </Button>
                  </Tooltip>
                }
                {request.type === "EDIT" && <div style={{ display: "flex" }}>
                  <div className="request__flex--text">
                    <span className="workflow__circle workflow__circle--not-changed"></span>
                    <Typography className="request__align--text textField__typography--form" style={{ display: 'flex' }} variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.workflows.notChanged')}
                    </Typography>
                  </div>

                  <div className="request__flex--text">
                    <span className="workflow__circle workflow__circle--changed"></span>
                    <Typography className="request__align--text textField__typography--form" style={{ display: 'flex' }} variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.workflows.changed')}
                    </Typography>
                  </div>

                  <div className="request__flex--text">
                    <span className="workflow__circle workflow__circle--added"></span>
                    <Typography className="request__align--text textField__typography--form" style={{ display: 'flex' }} variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.workflows.added')}
                    </Typography>
                  </div>
                </div>
                }
              </div>
              <Grid className="RequestPage" item xs={12}>
                {/* {JSON.stringify(request.object)} */}
                {
                  request.objectType === "REPOSITORY" ?
                    request.type === "VALIDATE" ?
                      <RepositoryValidate id={request.object._id} />
                      : isEdit ?
                        repoTypeMetadata &&
                        <RepositoryForm key="1"
                          _id={request.object._id}
                          name={request.object.name}
                          logo={request.object.logo}
                          repositoryType={request.object.repositoryType[0]}
                          description={request.object.description}
                          url={request.object.url}
                          link={request.object.link}
                          keyWords={request.object.keyWords}
                          software={request.object.software[0]}
                          content={request.object.content}
                          contentFormat={request.object.contentFormat}
                          language={request.object.lang}
                          latitude={request.object.gps ? request.object.gps.lat : ''}
                          longitude={request.object.gps ? request.object.gps.lng : ''}
                          subject={request.object.subject}
                          utility={request.object.utility}
                          policy={request.object.policy}
                          domain={request.object.domain[0]}
                          terms={request.object.terms}
                          badge={request.object.badge}
                          stats={request.object.stats}
                          organization={request.object.organization}
                          targetGroup={request.object.targetGroup}
                          metadataFormat={request.object.metadataFormat}
                          creationDate={request.object.creationDate}
                          terminationDate={request.object.terminationDate}
                          owner={request.object.owner}
                          repoTypeMetadata={repoTypeMetadata}
                          repoTypeMetadataErrors={repoTypeMetadataErrors}
                          langs={langs}
                          uploadSize={uploadSize}
                          errors={errors}
                          submitLable={t('workflows.action.save')}
                          snackForRepository={snackForRepository}
                          submitRepository={saveChanges}
                          goBack={() => setIsEdit(false)}
                        /> :
                        // <RepositoryShow object={request.object} history={request.history} condition={request.condition} /> :
                        repoTypeMetadata &&
                        <RepositoryForm key="0" object={request.object} history={request.history} condition={request.condition} langs={langs} uploadSize={uploadSize} repoTypeMetadata={repoTypeMetadata} repoTypeMetadataErrors={repoTypeMetadataErrors} /> :
                    isEdit
                      ?
                      <OrganizationForm
                        _id={request.object._id}
                        name={request.object.name}
                        url={request.object.url}
                        email={request.object.email}
                        address={request.object.address}
                        fax={request.object.fax}
                        phones={request.object.phones}
                        latitude={request.object.gps.lat}
                        longitude={request.object.gps.lng}
                        schedule={request.object.schedule}
                        comments={request.object.comments}
                        notes={request.object.notes}
                        country={request.object.country[0]}
                        users={request.object.users || null}
                        // liability={request.object.liability}
                        organizationType={request.object.organizationType[0]}
                        terms={request.object.terms}
                        snackForOrganization={snackForOrganization}
                        submitLable={t('workflows.action.save')}
                        submitOrganization={saveChanges}
                      /> :
                      <OrganizationShow object={request.object} history={request.history} />
                }
              </Grid>
            </Grid>
          </Card>
          <Grid item xs={12} className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
            {!isEdit && <ActionPanel
              data={request}
              buttons={true}
            />}
          </Grid>
        </>}

    </>
  );
}
