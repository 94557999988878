import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import TableCommon from '@components/Common/Table'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import { SnackBarContext } from "@context/snackBarContext.js"
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import Axios from 'axios'


export default function ImportAPI(props) {
    const { api } = useParams()
    let history = useHistory()
    const [t, i18n] = useTranslation("common")
    const [headers, setHeaders] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [needAPIKey, setNeedAPIKey] = useState(false)
    const [reload, setReload] = useState(0)
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
    const snackForImportation = (tag, message) => {
        setTag(tag)
        setMessage(message)
        setIsSnackBar(true)
    }

    const handleSetRepository = (data) => {
        let path = '/admin/repositories/import/' + api + "/" + data.api_id
        history.push(path)
    }

    const checkLoaded = () => {
        getRequest(host + '/controls')
            .then(res => {
                let control = res.find(({ key }) => key === "import###" + api)
                if (control && control.value.start === '') {
                    setDisabled(false)
                    handleLoadData()
                } else if (control && control.value.start < control.value.end) {
                    setDisabled(false)
                    setReload(reload + 1)
                }
                else if (control && (control.value.start !== '' && control.value.end === '' || control.value.start > control.value.end)) {
                    setDisabled(true)
                    setTimeout(checkLoaded, 5000)
                }
            })
    }

    const handleLoadData = async () => {
        if (needAPIKey)
            snackForImportation("error", t('messages.error.repositories.import_apikey'))
        else if (disabled)
            snackForImportation("error", t('messages.error.repositories.import_apiload'))
        else {
            Axios.get(host + '/imports/load/' + api)
                .catch(err => {
                    snackForImportation("error", t('messages.error.repositories.import_apiload'))
                }
                )
            setDisabled(true)
        }

    }

    const handleImport = (data, event) => {
        event.stopPropagation()
        let path = '/admin/repositories/import/' + api + "/" + data.api_id
        history.push(path)
    }


    useEffect(() => {
        setHeaders([
            {
                name: t('labels.repositories.nameTable'),
                value: "name",
                sort: true
            },
            {
                name: t('labels.repositories.url'),
                value: "url"
            }
        ])

    }, [setHeaders, t, i18n.language, api])


    useEffect(() => {
        if (api === "opendoar") {
            getRequest(host + '/configurations?type=APIKEYS')
                .then(res => {
                    if (res.entities[0].body[0].opendoar === "") {
                        setNeedAPIKey(true)
                        snackForImportation("error", t('messages.error.repositories.import_apikey'))
                    }
                    else
                        checkLoaded()
                })
        }
        else
            checkLoaded()
    }, [disabled])
    return (
        <div className="admin__container-bottom" >
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { href: "/#/admin/repositories", name: "repositories" },
                { name: "import" }
            ]} />
            <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.repositories.import')}</Typography>
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">
                {t('help.repository.import_api', { api: t('common.buttons.import_' + api) })}
            </Typography>
            {headers && < TableCommon
                key={reload}
                headers={headers}
                backendPath="/imports/"
                frontendPath={"/repositories/import/" + api}
                sort="name"
                controller="import"
                filter={"key=" + api}
                handleSetRepository={(data, event) => handleSetRepository(data, event)}
                ActionPanel={(data, index) =>
                    <Tooltip placement="top" title={t('tooltip.repositories.import_api')}>
                        <IconButton id={"repository-import-button-" + data.index} onClick={(event) => handleImport(data.data, event)}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                }
            />}
            <Grid container className="form__button--justify-left repositoryform__buttons--margins">
                <SubmitButtonCommon
                    id="repository-import-back-button"
                    className="form__button--color-error"
                    title={t('table.confirm.backButton')}
                    handleClick={() => history.push('/admin/repositories/import/')} />
                <Button
                    id="repository-import-refresh-button"
                    className="form__button--color-success"
                    onClick={() => handleLoadData()} >{t('common.buttons.refresh')}
                </Button>

            </Grid>
        </div>
    )
}
