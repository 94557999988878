import React, { useState, useEffect } from 'react'

import HighlightPage from '@components/Highlight/Highlight'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import getTranslation from '@utils/functions/utilities.js'
import { host } from '@utils/config'
import { useTranslation } from 'react-i18next';

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Highlight() {
  const [t, i18n] = useTranslation('common')
  const [highlight, setHighlight] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/highlights/' + id)
      .then(res => {
        setHighlight(res)
      })
  }, [id])
  return (
    <>
    {highlight && 
    <div className="about__container public__container--padding-bottom" >
        <div className="content--card">
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { name: "highlight" },
          { name: `${getTranslation(highlight.title, i18n.language, i18n.languages[i18n.languages.length - 1])}`, fullTitle: true}
          
        ]} />
      </div>
      <HighlightPage tag="public" highlight={highlight}/>
    </div>
    }
    </>
  )
}
