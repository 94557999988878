import React, { useState, useEffect, useContext } from 'react'
import { Container } from '@material-ui/core'

import UpdateUserForm from '@components/User/UpdateUserForm'

import { useHistory } from 'react-router-dom'

import Axios from 'axios'
import { host } from '@utils/config'

import { UserContext } from '@context/userContext';
import { SnackBarContext } from "@context/snackBarContext.js"

import '@styles/frontOffice/User/User.css'
import { useTranslation } from 'react-i18next'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function ChangePasswordUser() {
    const [t] = useTranslation('common')
    const [user, setUser] = useContext(UserContext)
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [isPassword, setIsPassword] = useState(false)
    const [isConfirmPassword, setIsConfirmPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    let history = useHistory()

    const handleReset = () => {
        setOldPassword('')
        setPassword('')
        setConfirmPassword('')
    }

    const handleChangeUserPassword = async () => {
        let validate = true
        const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/
        if (!password.match(passRegExp)) {
            setIsPassword(true)
            validate = false
        }
        if (confirmPassword !== password) {
            setIsConfirmPassword(true)
            validate = false
        }
        if (validate) {
            try {
                let newObj = {
                    password: password,
                    passwordConfirmation: confirmPassword,
                    oldPassword: oldPassword
                }

                await Axios.put(host + '/auth/edit-password', newObj)
                let newUser = { ...user }
                newUser.password = password
                setUser(newUser)
                setIsSnackBar(true)
                setTag("success")
                setMessage(t('messages.success.users.changePassword'))
                history.goBack()
            }
            catch (error) {
                setIsSnackBar(true)
                setTag("error")
                if (error.response.data.message[0].messages[0].id === "User.form.error.oldPassword.format")
                    setMessage(t('messages.error.users.oldPassword'))
                else
                    setMessage(t('messages.error.users.changePassword'))
            }
        } else {
            setIsSnackBar(true)
            setTag("error")
            setMessage(t('messages.error.users.changePassword'))
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/
        if (password.match(passRegExp) && password.length >= 13)
            setIsPassword(false)
        if (password === confirmPassword)
            setIsConfirmPassword(false)
    }, [password, confirmPassword])

    return (
        <div className="public__container--margin public__container--padding-bottom">
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/profile", name: "profile" },
                { href: "/#/profile/changePassword", name: "profile_changePassword" }
            ]} />
            <UpdateUserForm
                tag="CHANGE_PASSWORD"
                setOldPassword={setOldPassword}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                password={password}
                confirmPassword={confirmPassword}
                isPassword={isPassword}
                isConfirmPassword={isConfirmPassword}
                handleReset={handleReset}
                handleClick={handleChangeUserPassword}
                showPassword={showPassword}
                showOldPassword={showOldPassword}
                showConfirmPassword={showConfirmPassword}
                handleClickShowPassword={handleClickShowPassword}
                handleClickShowOldPassword={handleClickShowOldPassword}
                handleClickShowConfirmPassword={handleClickShowConfirmPassword}
                handleMouseDownPassword={handleMouseDownPassword}
            />
        </div>
    )
}
