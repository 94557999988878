import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container } from '@material-ui/core';
import TextFieldCommon from '@components/Common/TextField';

import "@styles/Common/Common.css";

export default function DialogCommon(props) {

  const handleConfirm = (event) => {
    event.preventDefault()
    props.handleConfirm()
    if(props.hasNote) props.setNote("")
  }

  return (
    <Container>
      <Dialog open={props.open} onClose={props.handleClose} fullWidth={true}>
        <DialogTitle />
        <DialogContent>
          {props.message}
          {props.hasNote &&
            <TextFieldCommon
              title={props.noteTitle}
              id="note"
              required={true}
              value={props.note}
              multiline={true}
              type="text"
              fullWidth={true}
              handleChangeInput={props.setNote}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button id="cancel-button" onClick={props.handleClose}>
            {props.cancel}
          </Button>
          {props.confirm && <Button id="confirm-button" onClick={(event) => handleConfirm(event)}>
            {props.confirm}
          </Button>}
        </DialogActions>
      </Dialog>
    </Container>
  );
}