import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Axios from 'axios'
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"

import GroupForm from '@components/Group/GroupForm'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import '@styles/backOffice/Group/Groups.css'

export default function NewGroup() {
  const [t] = useTranslation('common')
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  let history = useHistory()

  const handleCreateRole = async (role) => {
    try {
      await Axios.post(host + '/groups', role)
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.groups.create'))
      history.push('/admin/groups')
    }
    catch {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.groups.create'))
    }
  }

  const snackForGroup = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }
  
  return (
    <div className="admin__container-bottom">
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/groups", name: "groups" },
        { name: "groups_new" },
      ]} />
      <Typography component="h2" className="admin__card--title">{t('titles.groups.create')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.group.form')}</Typography>
      </div>
      <GroupForm
        handleCreateRole={handleCreateRole}
        snackForGroup={snackForGroup}
      />
    </div>
  );
}
