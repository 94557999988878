import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '@context/userContext';
import { SnackBarContext } from "@context/snackBarContext.js"
import { useHistory } from 'react-router-dom';

import Axios from 'axios';
import { host } from '@utils/config'
export default function Login() {
  const [t, i18n] = useTranslation('common')
  let history = useHistory()

  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [user, setUser] = useContext(UserContext)

  useEffect(() => {
    Axios.post(host + '/logout')
      .then(res => {
        setUser(null)
        setIsSnackBar(true)
        setTag("success")
        setMessage(t('messages.success.users.logout'))
        history.push('/home')
      })
      .catch(() => {
        setIsSnackBar(true)
        setTag("error")
        setMessage(t('messages.error.users.logout'))
      })
  }, [user, setUser])

  return null
}