import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import TableCommon from '@components/Common/Table'

import '@styles/backOffice/Repository/Repositories.css'
import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'


export default function TableLogs() {
  const [t,i18n] = useTranslation('common')
  const [headers,setHeaders] = useState(null)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.logs.method'),
        value: "method",
        sort: true
      },
      {
        name: t('labels.logs.component'),
        value: "component",
        sort: true
      },
      {
        name: t('labels.logs.address'),
        value: "address",
        sort: true
      },
      {
        name: t('labels.logs.creator'),
        value: "creator",
        sort: true
      },
      {
        name: t('labels.logs.created'),
        value: "created",
        sort: true,
        date: true
      }
    ])
  },[t, setHeaders,i18n.language])

  return (
    <div className="admin__container-bottom" >
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin", name: "admin" },
      { name: "logs" },
    ]} />
      <Typography id="backoffice-log-title" component="h2" className="admin__card--title">{t('titles.logs.title')}</Typography>
      {headers && <TableCommon
        headers={headers}
        backendPath="/logs"
        frontendPath="/logs"
        sort="created"
        order="desc"
        controller="log"
        hasActions={false}
        hasAddButton={false}
      />}
    </div>
  );
}
