import React, {useContext, useState, useEffect} from 'react'
import { useParams, useLocation } from 'react-router-dom';
import { host } from '@utils/config'
import Axios from "axios"
import { SnackBarContext } from "@context/snackBarContext.js"



import DomainPage from '@components/Configuration/RepoTypeMetadata/RepoTypeMetadataView.js'
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import getRequest from '@utils/functions/axiosRequest'
import TableCommon from '@components/Common/Table';
import SubmitButtonCommon from '@components/Common/SubmitButton';


export default function RepoTypeMetadata() {
  const location = useLocation();
  const [t, i18n] = useTranslation("common")
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  let history = useHistory()
  const [user] = useContext(UserContext)
  const [headers, setHeaders] = useState(null)
  const [config, setConfig] = useState(null)
  const [isViewAttribute, setIsViewAttribute] = useState(false)
  const [viewAttribute, setViewAttribute] = useState(null)

  const [langs, setLangs] = useState(null)
  let { id } = useParams()
  useEffect(() => {
    getRequest(host + '/configurations/' + id)
      .then(res => {
        if(location.search.includes('?type=REPOSITORYTYPE&key=repoTypeMetadata') && !viewAttribute){
          setViewAttribute(res.body.attributes.find(attr => attr.id === decodeURIComponent(location.search.split('key=')[1])))
          setIsViewAttribute(true)
        }
        setConfig(res)})

    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setConfig, setLangs])

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.configurations.repoTypeMetadata.key'),
        value: ["id"],
        sort: false,
        repoTypeMetadataId: true
      },
      {
        name: t('labels.configurations.repoTypeMetadata.title'),
        value: ["i18n"],
        sort: false
      },
      {
        name: t('labels.configurations.repoTypeMetadata.format.title'),
        value: ["type"],
        sort: false,
        repoTypeMetadataType: true
      },
      {
        name: t('labels.configurations.repoTypeMetadata.required'),
        value: ["required"],
        sort: false
      },
      {
        name: t('labels.configurations.repoTypeMetadata.order'),
        value: ["order"],
        sort: false,
        repoTypeMetadataOrder: true
      }
    ])
  }, [t, setHeaders, i18n.language])

  const handleDeleteAttribute = async (event,value) => {
    event.stopPropagation()
    let newAttributes = config.body.attributes.sort((attr1, attr2) => attr1.order - attr2.order)
    let index = newAttributes.findIndex(attr => attr.id === value)
    if(index > -1){
      if(index < newAttributes.length - 1)
        newAttributes.slice(index + 1, newAttributes.length).map(attr => {attr.order = attr.order - 1; return attr})
      newAttributes.splice(index,1)
      await Axios.put(host + '/configurations/' + id, {
        body: {
          title: config.body.title,
          attributes: newAttributes
        }
      }).then(() => {
        setIsSnackBar(true)
        setTag("success")
        setMessage(t('messages.success.configurations.update'))
        window.location.reload(false);
      })
      .catch(() => {
        setIsSnackBar(true)
        setTag("error")
        setMessage(t('messages.error.configurations.update'))
      })
    }
  }

  const handleEditAttribute = async (event, value) => {
    event.stopPropagation()
    history.push('/admin/repoTypeMetadata/update/' + history.location.pathname.split("repoTypeMetadata/")[1] + "?key=" + encodeURIComponent(value.id))
  }

  const handleViewAttribute = async (value) => {
    history.push('/admin/repoTypeMetadata/' + id + '?type=REPOSITORYTYPE&key=' + encodeURIComponent(value.id))
    setViewAttribute(value)
    setIsViewAttribute(true)
  }
  const handleGoBack = async () => {
    history.replace(location.pathname + '?type=REPOSITORYTYPE')
    setViewAttribute(null)
    setIsViewAttribute(false)
  }

  const back = async () => {
    history.push('/admin/repoTypeMetadata/')
  }


  const handleSwitchOrder = async (event, order, inc) => {
    event.stopPropagation()
    let newAttributes = config.body.attributes.sort((attr1, attr2) => attr1.order - attr2.order)
    newAttributes[order].order = newAttributes[order + inc].order
    newAttributes[order + inc].order = order
    await Axios.put(host + '/configurations/' + id, {
      body: {
        title: config.body.title,
        attributes: newAttributes
      }
    }).then(() => {
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.configurations.update'))
      window.location.reload(false);
    })
    .catch(() => {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.configurations.update'))
    })
    
  }
  

  
  useEffect(() => {
    if(!location.search.includes('?type=REPOSITORYTYPE')){
      history.push(location.pathname + '?type=REPOSITORYTYPE')
      setViewAttribute(null)
      setIsViewAttribute(false)
    }
    else if(!location.search.includes('?type=REPOSITORYTYPE&key=repoTypeMetadata')){
      setViewAttribute(null)
      setIsViewAttribute(false)
    }

  }, [location])

  return (
    <div className="admin__container-bottom" >
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin", name: "admin" },
      { href: "/#/admin/repoTypeMetadata", name: "repoTypesMetadata" },
      ...(!isViewAttribute ? [{ name: "repoTypeMetadata" }] : [{href:'/#/admin/repoTypeMetadata/' + encodeURIComponent(id),  name: "repoTypeMetadata" }, { name: "repoTypeMetadata_attribute"}])
    ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-repoTypeMetadata-title" component="h2" className="admin__card--title">{t('titles.configurations.repoTypesMetadata.' + (!isViewAttribute ? 'title' : 'repoTypeMetadata') )}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {user && !isViewAttribute &&
            <Tooltip title={t('tooltip.configurations.repoTypeMetadata.create')}>
              <Button
                id="backoffice-repoTypeMetadata-create-button"
                className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/repoTypeMetadata/update/' + history.location.pathname.split("repoTypeMetadata/")[1])}
                startIcon={
                  <AddIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.create")}
              </Button>
            </Tooltip>
          }
          {
            user && isViewAttribute && viewAttribute &&
            <Tooltip title={t('tooltip.configurations.repoTypeMetadata.edit')}>
              <Button
                id="backoffice-repoTypeMetadata-edit-button"
                className="actionButton admin__button--edit" size="medium" onClick={(event) => handleEditAttribute(event,viewAttribute)}
                startIcon={
                  <EditIcon className="admin__icon--edit" />
                }
                role="adminCards"
              >
                {t("common.buttons.edit")}
              </Button>
            </Tooltip>
          }
        </Grid>
      </Grid>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.configuration.repoTypeMetadata.show')}</Typography>
      </div>
      {headers && config && !isViewAttribute ? 
      <>
      <TableCommon
        headers={headers}
        backendPath={"/configurations"}
        frontendPath={"/repoTypeMetadata/" + id}
        controller="configuration.repoTypeMetadata"
        filter={"type=REPOSITORYTYPE&key=" + encodeURIComponent(config.key)}
        hasRemoveButton={true}
        hasAddButton={false}
        handleDeleteAttribute={(event,value) => handleDeleteAttribute(event,value)}
        handleEditAttribute={(event, value) => handleEditAttribute(event, value)}
        handleViewAttribute={(value) => handleViewAttribute(value)}
        handleSwitchOrder={(event, value, order) => handleSwitchOrder(event, value, order)}
        />
      <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
        <SubmitButtonCommon
          id="repoTypeMetadata-back-button"
          className="form__button--color-error"
          title={t('table.confirm.backButton')}
          handleClick={() => back() }
          />
      </Grid>
      </>
      : isViewAttribute && viewAttribute && <DomainPage id={viewAttribute.id} handleGoBack={() => handleGoBack()}/>
    }




    </div>
  )
}