import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts"

import '@styles/Common/Common.css';


import '@styles/Common/Common.css'
import getTranslation from '@utils/functions/utilities.js'


export default function LineChart(props) {
  const [t, i18n] = useTranslation("common")
  const [data, setData] = useState([])
  const [title, setTitle] = useState("")

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    tooltip: {
      x: {
        show: true
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      },
      marker: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    title: {
      text: '',
      align: 'left'
    },
    dataLabels: {
      enabled: true
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    responsive: [
      // {
      //   breakpoint: 1882,
      //   options: {
      //     xaxis: {
      //       labels: {
      //         rotate: -25,
      //         style: {
      //           fontSize: '15px',
      //         }
      //       },
      //     },
      //   },
      // },
      {
        breakpoint: 2500,
        options: {
          xaxis: {
            // tickAmount: 6,
            tickPlacement: 'on',
            labels: {
              style: {
                fontSize: '15px',
              }
            },
          },
          yaxis: {
            labels: {
              offsetX: 8,
              align: 'left',
              minWidth: 0,
              maxWidth: 320,
              style: {
                fontSize: '18px',
              }
            },
          },
        },
      },
      {
        breakpoint: 968,
        options: {
          xaxis: {
            tickAmount: 3,
            tickPlacement: 'on',
            labels: {
              style: {
                fontSize: '12px',
              }
            },
          },
          yaxis: {
            labels: {
              align: 'left',
              minWidth: 0,
              maxWidth: 120,
              style: {
                fontSize: '14px',
              }
            },
          },
        },
      },
    ]
  })

  useEffect(() => {
    setTitle(props.title[i18n.language])

    let newData = props.data.slice(0, 12).sort((a, b) => {
      if (a.key < b.key)
        return -1
      else
        return 1
    })
    newData = newData.map(d => {
      return { key: typeof d.key === "object" ? getTranslation(d.key, i18n.language, i18n.languages[i18n.languages.length - 1]) : d.key, value: parseInt(d.value) }
    })
    setData(newData)
    let newOptions = { ...options, xaxis: { categories: newData.map(d => d.key) }, title: { text: getTranslation(props.title, i18n.language, i18n.languages[i18n.languages.length - 1]) } }
    setOptions(newOptions)
  }, [setData, props.data, i18n.language])

  return (
    <>
      {(data && options && options.xaxis && options.xaxis.categories) && <ReactApexChart
        options={options}
        series={[{ name: title, data: data.map(d => d.value) }]}
        type="bar"
        height={480}
      />}
    </>
  )
}
