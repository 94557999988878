import React, { useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Advanced from '@components/Common/SearchBar/Advanced';

import '@styles/Common/SearchBar.css';

const qs = require('qs');

export default function SearchBar(props) {
  const [t] = useTranslation('common')
  const [isAdvanced, setIsAdvanced] = useState(false)
  const [searchArray, setSearchArray] = useState([])
  const [search, setSearch] = useState("")
  const [nFilters, setNFilters] = useState(3)
  const [key, setKey] = useState(0)
  const [advanced, setAdvanced] = useState([{
    field: "name",
    value: "",
    operator: "_contains"
  }, {
    field: "repositoryType",
    value: "",
    operator: "_contains"
  }, {
    field: "description",
    value: "",
    operator: "_contains"
  }])
  useEffect(() => {
    if (props.search) {
      let searchList = []
      if (Array.isArray(props.search))
        for (let s of props.search)
          searchList.push(s)
      else searchList.push(props.search)
      setSearchArray(searchList)
    }
    if (props.advanced) {
      for (let i = 0; i < props.advanced.length || i < 3; i++)
        if (i >= props.advanced.length)
          props.advanced[i] = {
            field: i === 0 ? "name" : i === 1 ? "repositoryType" : "description",
            value: "",
            operator: "_contains"
          }

      setNFilters(props.advanced.length)
      setAdvanced(props.advanced)
    }
  }, [props.advanced, props.search])

  let history = useHistory()

  const handleChangeSearch = (event) => {
    setSearch(event.target.value)
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      searchRepositories(advanced, search, searchArray)
    }
  }

  const addAdvanced = async (event) => {
    event.preventDefault();
    let newAdvanced = advanced
    newAdvanced.push({
      field: "name",
      value: "",
      operator: "_contains"
    })
    setAdvanced(newAdvanced)
    setNFilters(nFilters + 1)
  }

  const changeAdvanced = async (index, field, value, operator) => {
    let newAdvanced = advanced
    newAdvanced[index] = {
      field: field,
      value: value,
      operator: operator
    }
    setAdvanced(newAdvanced)
  }

  const queryAdvancedSearch = (advanced, search, searchArray) => {
    let query = {}
    for (let i = 0; i < advanced.length; i++) {
      if (advanced[i].value)
        query["search." + i + "." + advanced[i].field + advanced[i].operator] = advanced[i].value
    }
    if (search) {
      let searchList = searchArray
      searchList.push(search)
      setSearch("")
      setSearchArray(searchList)
    }
    if (searchArray.length > 0)
      query["_q"] = searchArray

    return query
  }

  const searchRepositories = (advanced, search, searchArray) => {
    let queryParams = qs.parse(history.location.search.replace(/\?/, ''))
    let finalQuery = {}
    Object.entries(queryParams).map(obj => {
      if (!obj[0].includes("search") && !obj[0].includes("_q"))
        finalQuery[obj[0]] = obj[1]
    })

    let query = queryAdvancedSearch(advanced, search, searchArray)
    query = { ...query, ...finalQuery, _start: 0 }
    let path = "/repositories?" + qs.stringify(query)
    if (Object.values(query).length > 1) {
      setIsAdvanced(false)
    }
    history.push(path)
  }

  const handleDeleteSearch = (index) => {
    const newSearchArray = [...searchArray];
    newSearchArray.splice(index, 1);
    setSearchArray(newSearchArray);
    searchRepositories(advanced, search, newSearchArray)
  }

  const handleDeleteAdvanced = (index) => {
    const newAdvanced = [...advanced];

    newAdvanced[index].value = ""

    setAdvanced(newAdvanced)
    setNFilters(newAdvanced.length)
    searchRepositories(newAdvanced, search, searchArray)
    setKey(key + 1)
  }

  const viewAllRepositories = () => {
    history.push('/repositories')
  }

  const handleClearSearch = () => {
    let newAdvanced = [...advanced]
    newAdvanced = newAdvanced.map(a => {
      a.value = ""
      return a
    })
    setSearchArray([])
    setNFilters(newAdvanced.length)
    setAdvanced(newAdvanced)
    searchRepositories(newAdvanced, "", [])
    setKey(key + newAdvanced.length)
  }
  return (
    <>
      <div className="searchbar__container">
        <div className={"about__container searchbar__box--size " + (props.title ? "searchbar__box--width" : "")}>
          {props.title && <div>
            <Typography variant="h4" component="h1" className="index__title searchbar__grid--margin searchbar__title-size searchbar__margin--bottom-52px">
              {props.title}
            </Typography>
          </div>}
          <div className={"searchbar__box--size " + (props.title ? "searchbar__box--width" : "")}>
            <OutlinedInput
              id="search-input"
              className="searchbar__border-radius textField__container--height"
              fullWidth
              placeholder={t('searchbar.search')}
              value={search}
              onChange={handleChangeSearch}
              onKeyDown={handleEnter}
              type="text"
              startAdornment={
                <InputAdornment position="start">
                  {searchArray.length > 0 && searchArray.map((s, i) =>
                  (<Chip
                    className='searchbar__button--color'
                    key={s + i}
                    label={s}
                    onDelete={() => handleDeleteSearch(i)}
                  />))}
                  {advanced && advanced.map((a, i) => {
                    return (a.value && history.location.search.includes('search') &&
                      <Chip
                        className='searchbar__advanced--color'
                        key={a.value + i}
                        label={t('searchbar.fields.' + a.field) + ": " + a.value}
                        onDelete={() => handleDeleteAdvanced(i)}
                      />)
                  })}
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {(searchArray.length > 0 || advanced.some(a => a.value) && history.location.search.includes('search')) &&
                    <Button id="search-clear-button" className="searchbar__clear--button" onClick={() => handleClearSearch()}><ClearIcon /></Button>
                  }
                  <Button id="search-advanced-button" className="searchbar__button--advanced" size="small" onClick={() => setIsAdvanced(!isAdvanced)}>{t('searchbar.advanced')}</Button>
                  <Button id="search-button" className="search__button" onClick={() => searchRepositories(advanced, search, searchArray)}><SearchIcon /></Button>
                </InputAdornment>
              }
            />
          </div>
        </div>
        {isAdvanced && <div key={key} className={"searchbar__box--size searchbar__advanced " + (props.title ? "searchbar__box--width" : "")}>
          {isAdvanced && advanced && advanced.map((adv, index) =>
            <Advanced key={index} handleEnter={handleEnter} changeAdvanced={changeAdvanced} index={index} advanced={adv} />
          )}
          <Grid container justify="space-between">
            <Grid item xs={11}>
              <Button className="searchbar__button--add" onClick={addAdvanced}>
                {t('searchbar.addTerm')}
                <AddIcon fontSize="small" />
              </Button>
            </Grid>
            <Grid item className='searchbar__advanced--grid' xs={1}>
              <Button id="search-button-advanced" className="searchbar__advanced--button" onClick={() => searchRepositories(advanced, search, searchArray)}>
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>

        </div>
        }
      </div>
      {props.title && <div className="about__container searchbar__margin--top-24px searchbar__margin--bottom-82px">
        <Button id="search-button" className="actionButton form__button--color-success" onClick={() => viewAllRepositories()}>
          {t('searchbar.view')}
        </Button>
      </div>}
    </>
  )
}
