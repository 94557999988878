import React, { useState } from 'react';
import {
  Grid, Typography, Link, FormControlLabel, Checkbox, FormHelperText,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import getTranslation from '@utils/functions/utilities.js'
import TextLable from '@components/Common/TextLable'
import moment from "moment-with-locales-es6";

export default function Terms(props) {
  const [t, i18n] = useTranslation("common")
  const [showDialog, setShowDialog] = useState(false)
  const [showDialogIndex, setShowDialogIndex] = useState(0)
  const [expandedLength, setExpandedLength] = useState(2)
  const createTermsLink = (data, index) => {
    let regex = /\[(.*)\]/
    let match = regex.exec(data)
    if (match)
      return data.split(regex).map(txt => txt === match[1] ? <Link key='terms-dialog' onClick={(event) => handleTermsClick(event, index)}>{txt}</Link> : txt);
    else return <Link href="#" key='terms-dialog' onClick={(event) => handleTermsClick(event, index)}>{data}</Link>
  }
  const handleTermsClick = (event, index) => {
    event.preventDefault()
    setShowDialogIndex(index)
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialogIndex(0)
    setShowDialog(false);
  };

  const handleAccept = () => {
    props.setCheckedTerm(true)
    setShowDialog(false);
  };

  const handleDisagree = () => {
    props.setCheckedTerm(false)
    setShowDialog(false);
  };

  const handleExpand = (value) => {
    if (value === 'expand')
      setExpandedLength(props.terms.length)
    else
      setExpandedLength(2)
  }

  return (
    <>
      <Grid container className="textField__container">
        {(props.termForm && props.terms && props.terms.length > 0) ?
          <>
            <Grid item xs={12} md={12}>
              <Typography id={props.terms[props.terms.length - 1].objectType.toLowerCase() + "-terms-lable"} className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                {props.label}
              </Typography>
            </Grid>
            <Grid item className="margin__left--8px" xs={12} md={12}>
              <FormControlLabel
                key='terms-form'
                label={createTermsLink(props.terms[props.terms.length - 1].body.description[i18n.language], props.terms.length - 1)}
                control={
                  <Checkbox
                    id={props.terms[props.terms.length - 1].objectType.toLowerCase() + "-terms-input"}
                    checked={props.checkedTerm}
                    onChange={() => props.setCheckedTerm(!props.checkedTerm)}
                    name="terms"
                  />
                }
              />
              {
                (props.isErrorTerm) &&
                <FormHelperText id="terms-error" error>{props.errorMessage}</FormHelperText>
              }
            </Grid>
          </>
          :
          props.terms && props.terms.length > 0 && !props.termForm && props.showTerms &&
          <Grid container className="textField__container ">
            {props.terms.sort((a, b) => {
              let aDate = new Date(a.accepted_at)
              let bDate = new Date(b.accepted_at)
              return bDate - aDate
            }).map((term, index) =>
              index < expandedLength &&
              <Grid key={"term-" + index} style={{ paddingBottom: "25px" }} item xs={12} md={6}>
                <Grid item xs={12} md={12}>
                  <Typography id={"terms-description-lable-" + index} className="textField__typography" style={{ display: 'flex' }} variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    {t('labels.repositories.terms.title')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography id={"terms-description-" + index} className="request__content--typography" variant="subtitle1" component="h3" color="textSecondary" gutterBottom>
                    {createTermsLink(props.value, index)}
                  </Typography>
                </Grid>
                <TextLable
                  id={"terms-acceptedAt-" + index}
                  title={props.acceptedAtLabel}
                  value={moment(term.accepted_at).format("YYYY-MM-DD HH:mm")}
                />
                {
                  props.showUser &&
                  <TextLable
                    id={"terms-acceptedBy-" + index}
                    title={t('labels.configurations.termObj.accepted_by')}
                    value={term.accepted_by !== "Public" ? term.accepted_by.email : "Public"}
                  />
                }

              </Grid>

            )}
          </Grid>
        }
        {props.terms && props.terms.length > 2 && !props.termForm && props.showTerms &&
          <Grid container justify='flex-end'>
            {
              expandedLength === 2 ?
                <Tooltip key="expand" placement="top" title={t('tooltip.repositories.terms_expand')} >
                  <IconButton id="expand" size="small" onClick={() => handleExpand('expand')}><ExpandMoreIcon /></IconButton>
                </Tooltip>
                :
                <Tooltip key="shrink" placement="top" title={t('tooltip.repositories.terms_shrink')} >
                  <IconButton id="shrink" size="small" onClick={() => handleExpand('shrink')}><ExpandLessIcon /></IconButton>
                </Tooltip>
            }
          </Grid>
        }
      </Grid>
      {
        (props.terms && props.terms.length > 0) &&
        <Dialog fullWidth maxWidth='md' open={showDialog} onClose={handleClose} >
          <DialogTitle id="terms-dialog-title">{t('titles.terms.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="terms-dialog-description">
              <Typography component="div">
                <div className="configuration__typography--textField--margin" dangerouslySetInnerHTML={{ __html: getTranslation(props.terms[showDialogIndex].body.content, i18n.language, i18n.fallbackLng) }} />
              </Typography>
            </DialogContentText>
          </DialogContent>
          {
            props.termForm &&
            <DialogActions>
              <Button onClick={handleAccept} className="form__button--color-success">
                {t('labels.configurations.termObj.agree')}
              </Button>
              <Button onClick={handleDisagree} className="form__button--color-error">
                {t('labels.configurations.termObj.disagree')}
              </Button>
            </DialogActions>
          }
        </Dialog>
      }
    </>
  )
}