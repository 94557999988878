import React, { useState, useEffect, useContext } from 'react'
import { Typography } from '@material-ui/core'

import { Card } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom'
import { UserContext } from '@context/userContext';
import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"

import '@styles/frontOffice/User/User.css'
import '@styles/frontOffice/Repository/RepositoryForm.css'

import { useTranslation } from 'react-i18next'
import Tab from '@components/Common/Tab/Tab'
import AdminUserForm from '@components/User/AdminUserForm'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function EditUser() {
    const [t, i18n] = useTranslation('common')
    const [loggedUser] = useContext(UserContext)
    const [user, setUser] = useState(null)
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [organization, setOrganization] = useState(null)
    const [phone, setPhone] = useState('')
    const [selectedLanguage, setSelectedLanguage] = useState(null)
    const [job, setJob] = useState(null)
    const [subject, setSubject] = useState(null)
    const [subjectNotifications, setSubjectNotifications] = useState(null)
    const [selectedJob, setSelectedJob] = useState(null)
    const [selectedSubject, setSelectedSubject] = useState(null)
    const [confirmed, setConfirmed] = useState(false)
    const [confirmedByOrganization, setConfirmedByOrganization] = useState(false)
    const [blocked, setBlocked] = useState(false)
    const [cienciaid, setCienciaId] = useState('')
    const [provider, setProvider] = useState('')

    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedDomain, setSelectedDomain] = useState(null)

    const [isFirstName, setIsFirstName] = useState(false)
    const [isLastName, setIsLastName] = useState(false)
    const [isOrganization, setIsOrganization] = useState(false)
    const [isJob, setIsJob] = useState(false)
    const [isSubject, setIsSubject] = useState(false)
    const [isDomain, setIsDomain] = useState(false)
    const [isLanguage, setIsLanguage] = useState(false)
    const [isGroup, setIsGroup] = useState(false)
    const [isErrorTab, setIsErrorTab] = useState(new Array(false, false))


    let history = useHistory()
    let { id } = useParams()

    const [langs, setLangs] = useState(null)

    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
            })
    }, [setLangs])

    const handleResetUser = () => {
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setOrganization(user.organization)
        setPhone(user.phone)
        setSelectedJob([...user.job])
        setSelectedLanguage({
            name: t('common.labels.language.' + user.lang),
            value: user.lang
        })
        setJob([...user.job])
        setSubject([...user.subject])
        setSubjectNotifications(user.subjectNotifications)
        setConfirmed(user.confirmed)
        setConfirmedByOrganization(user.confirmedByOrganization)
        setBlocked(user.blocked)
    }

    const handleResetGroup = () => {
        setSelectedGroup({ ...user.group })
    }

    const handleUpdateUser = async () => {
        let newIsErrorTab = [...isErrorTab]
        let validate = true
        if (firstName === '') {
            newIsErrorTab[0] = true
            setIsErrorTab(newIsErrorTab)
            setIsFirstName(true)
            validate = false
        }
        if (lastName === '') {
            newIsErrorTab[0] = true
            setIsErrorTab(newIsErrorTab)
            setIsLastName(true)
            validate = false
        }
        if (!organization || organization === '') {
            newIsErrorTab[0] = true
            setIsErrorTab(newIsErrorTab)
            setIsOrganization(true)
            validate = false
        }
        // if (job.length === 0) {
        //     setIsJob(true)
        //     validate = false
        // }
        if (!selectedGroup) {
            newIsErrorTab[1] = true
            setIsErrorTab(newIsErrorTab)
            setIsGroup(true)
            validate = false
        }
        if (!selectedLanguage) {
            newIsErrorTab[0] = true
            setIsErrorTab(newIsErrorTab)
            setIsLanguage(true)
            validate = false
        }
        if (validate) {
            try {
                let newUser = { ...user }
                newUser.firstName = firstName
                newUser.lastName = lastName
                newUser.organization = organization
                newUser.phone = phone
                newUser.job = job
                newUser.subject = subject
                newUser.subjectNotifications = subjectNotifications
                newUser.lang = selectedLanguage.value
                newUser.confirmed = confirmed
                newUser.confirmedByOrganization = confirmedByOrganization
                newUser.blocked = blocked
                if (provider === 'local' && cienciaid !== '') {
                    newUser.cienciaid = cienciaid
                }
                await Axios.put(host + '/users/' + newUser._id, newUser)
                setIsSnackBar(true)
                setTag("success")
                if (newUser._id === loggedUser._id && selectedLanguage.value !== i18n.language)
                    i18n.changeLanguage(selectedLanguage.value)
                setMessage(t('messages.success.users.update.admin.user'))
                history.goBack()
            }
            catch {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.users.update.admin.user'))
            }
        } else {
            setIsSnackBar(true)
            setTag("error")
            setMessage(t('messages.error.users.register.validation'))

        }
    }

    const handleUpdateUserGroup = async () => {
        let newIsErrorTab = [...isErrorTab]
        let validate = true
        if (!selectedGroup) {
            newIsErrorTab[1] = true
            setIsErrorTab(newIsErrorTab)
            setIsGroup(true)
            validate = false

        } else if (selectedGroup.name.en === 'Domain' && !selectedDomain) {
            newIsErrorTab[1] = true
            setIsErrorTab(newIsErrorTab)
            validate = false
            setIsDomain(true)
        }
        if (validate) {
            try {
                let newUser = { ...user, group: selectedGroup._id }
                if (selectedGroup.name.en === 'Domain') {
                    newUser = { ...newUser, domain: [{ ...selectedDomain }] }
                }
                await Axios.put(host + '/users/' + newUser._id, newUser)
                setIsSnackBar(true)
                setTag("success")
                setMessage(t('messages.success.users.update.admin.group'))
                history.goBack()
            }
            catch {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.users.update.admin.group'))
            }
        }
    }

    const handleChangeJobs = (value) => {
        if (value) {
            let newJobs = [...value]
            let refactor = []
            for (let job of newJobs) {
                refactor.push({
                    key: job.key,
                    field: job.field,
                    value: job.value,
                    i18n: {
                        pt: job.i18n.pt,
                        en: job.i18n.en
                    }
                })
            }
            setJob(refactor)
            setSelectedJob(value)
        }
    };

    const handleChangeSubject = (value) => {
        if (value) {
            let newSubject = [...value]
            let refactor = []
            for (let subject of newSubject) {
                refactor.push({
                    key: subject.key,
                    field: subject.field,
                    value: subject.value,
                    i18n: {
                        pt: subject.i18n.pt,
                        en: subject.i18n.en
                    }
                })
            }
            setSubject(refactor)
            setSelectedSubject(value)
        }
    };

    useEffect(() => {
        let newLanguage;
        getRequest(host + '/users/' + id)
            .then(res => {
                newLanguage = {
                    name: t('common.labels.language.' + res.lang),
                    value: res.lang
                }
                setCienciaId(res.cienciaid)
                setFirstName(res.firstName)
                setLastName(res.lastName)
                setOrganization(res.organization)
                setPhone(res.phone)
                setSelectedLanguage(newLanguage)
                setJob(res.job && res.job.length !== 0 ? [...res.job] : null)
                setSelectedJob(res.job ? [...res.job] : null)
                setSelectedSubject(res.subject ? [...res.subject] : null)
                setSubjectNotifications(res.subjectNotifications)
                setSelectedGroup(res.group)
                setConfirmed(res.confirmed)
                setConfirmedByOrganization(res.confirmedByOrganization)
                setBlocked(res.blocked)
                setProvider(res.provider)
                if (res.group.name.en === 'Domain') {
                    setSelectedDomain(res.domain[0])
                }
                setUser(res)
            })
    }, [setFirstName, setLastName, setPhone, setJob, setSelectedJob, setSelectedSubject])

    return (
        <div className="admin__container-bottom">

            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { href: "/#/admin/users", name: "users" },
                { name: "users_update" },
            ]} />
            <Typography component="h2" className="admin__card--title">{t('titles.users.update.header')}</Typography>
            <div >
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.user.form')}</Typography>
            </div>
            <Card className="margin__top--32px">
                {
                    (user) &&
                    <Tab
                        error={isErrorTab}
                        isSimpleTab={true}
                        headers={[t('titles.users.update.user'), t('titles.users.update.group')]}
                        components={
                            [
                                <AdminUserForm
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    isFirstName={isFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    isLastName={isLastName}
                                    organization={organization}
                                    setOrganization={setOrganization}
                                    isOrganization={isOrganization}
                                    cienciaid={cienciaid}
                                    setCienciaId={setCienciaId}
                                    provider={provider}
                                    phone={phone}
                                    confirmed={confirmed}
                                    confirmedByOrganization={confirmedByOrganization}
                                    blocked={blocked}
                                    selectedLanguage={selectedLanguage}
                                    setSelectedLanguage={setSelectedLanguage}
                                    setConfirmed={setConfirmed}
                                    setConfirmedByOrganization={setConfirmedByOrganization}
                                    setBlocked={setBlocked}
                                    languages={langs ? langs.map(lang => {
                                        return {
                                            name: t('common.labels.language.' + lang), value: lang
                                        }
                                    }) : []}
                                    setPhone={setPhone}
                                    selectedJob={selectedJob}
                                    isJob={isJob}
                                    subjectNotifications={subjectNotifications}
                                    setSubjectNotifications={setSubjectNotifications}
                                    selectedSubject={selectedSubject}
                                    isSubject={isSubject}
                                    isLanguage={isLanguage}
                                    handleChangeJobs={handleChangeJobs}
                                    handleChangeSubject={handleChangeSubject}
                                    handleReset={handleResetUser}
                                    handleClick={handleUpdateUser}
                                />,
                                <AdminUserForm
                                    tag='UPDATE_ROLE'
                                    selectedGroup={selectedGroup}
                                    selectedDomain={selectedDomain}
                                    setSelectedDomain={setSelectedDomain}
                                    setSelectedGroup={setSelectedGroup}
                                    isDomain={isDomain}
                                    isGroup={isGroup}
                                    handleReset={handleResetGroup}
                                    handleClick={handleUpdateUserGroup}
                                />
                            ]
                        }
                    />
                }
            </Card>
        </div>
    )
}