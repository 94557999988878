
import React, { useState, useContext, useEffect } from 'react';
import { Button, Grid, Typography, Tooltip, Card, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DropzoneArea } from 'material-ui-dropzone';




import { UserContext } from '@context/userContext';
import { host } from '@utils/config'

import SubmitButtonCommon from '@components/Common/SubmitButton';
import "@styles/frontOffice/Repository/RepositoryForm.css"
import "@styles/Common/Common.css"
import getRequest from '@utils/functions/axiosRequest'
import Terms from '@components/Configuration/Terms.js'

const _ = require('lodash');


export default function ImportRepository(props) {
  const [t, i18n] = useTranslation("common")
  const [user] = useContext(UserContext)

  const [uploadFile, setUploadFile] = useState([])
  const [uploadSize, setUploadSize] = useState(null)
  const [showText, setShowText] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [checkedTerm, setCheckedTerm] = useState(false)
  const [terms, setTerms] = useState('')

  const handleSubmitFile = () => {
    if (!checkedTerm)
      props.snackForImportation("error", t('messages.error.repositories.term'))
    else if (uploadFile.length === 0)
      props.snackForImportation("error", t('messages.error.repositories.import_upload'))
    else
      props.submitFile(uploadFile[0])
  }

  const handleChangeFile = (file) => {
    if (file.length !== 0) {
      setShowText(false)
      checkedTerm ? setDisabled(false) : setDisabled(true)
    }
    else {
      setShowText(true)
      setDisabled(true)
    }
    setUploadFile(file)
  }

  useEffect(() => {
    getRequest(host + '/configurations/public?type=TERM&&objectType=REPOSITORY')
      .then(res => setTerms(res))

    getRequest(host + '/configurations/public?type=UPLOADSIZE')
      .then(res => {
        setUploadSize(res[0])
      })
  }, [setTerms, setUploadSize])

  useEffect(() => {
    checkedTerm && uploadFile.length > 0 ? setDisabled(false) : setDisabled(true)
  }, [checkedTerm, setCheckedTerm])

  return (

    <>
      {props.disabled &&
        <>
          <Card elevation={0} className="tableComponent__repository--filter">

            <Grid container spacing={2}>
              <Grid item xs={12} md={12} >
                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  {t('labels.repositories.import_file')}
                </Typography>
                {uploadSize && <DropzoneArea
                  showPreviews={false}
                  useChipsForPreview
                  className="dropzone__style"
                  initialFiles={uploadFile}
                  dropzoneText={showText ? t('common.labels.dropzone.title') : null}
                  classes={{
                    icon: showText ? "dropZone__color--grey" : "dropZone__color--white"
                  }}
                  maxFileSize={uploadSize.body}
                  filesLimit={1}
                  acceptedFiles={['.csv', '.json', '.xml']}
                  onChange={handleChangeFile}
                  getFileAddedMessage={(filename) => (t('common.labels.dropzone.add', { file: filename }))}
                  getFileRemovedMessage={(filename) => (t('common.labels.dropzone.delete', { file: filename }))}
                  alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                />
                }
              </Grid>
              {terms &&
                <Grid container className="textField__container repositoryimport__terms--margins">
                  <Terms
                    label={t('labels.repositories.terms.supTitle')}
                    termForm={true}
                    terms={terms}
                    checkedTerm={checkedTerm}
                    setCheckedTerm={setCheckedTerm}
                    errorMessage={t('messages.error.repositories.term')}
                  />
                </Grid>
              }
            </Grid>
          </Card>

          <Grid container className="form__button--justify-left repositoryform__buttons--margins">

            <Tooltip title={t('tooltip.repositories.import')}>
              <span>
                <Button
                  id="repository-import-button"
                  className="form__button--color-success"
                  onClick={handleSubmitFile}
                >
                  {t('common.buttons.import')}
                </Button>
              </span>
            </Tooltip>
            <SubmitButtonCommon
              id="repository-import-back-button"
              className="form__button--color-error"
              title={t('table.confirm.backButton')}
              goBack />
          </Grid>
        </>
      }

    </>

  )
}
