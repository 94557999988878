import React, { useState, useEffect } from 'react'
import PostPage from '@components/Post/Post'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import { useTranslation } from 'react-i18next';
import getTranslation from '@utils/functions/utilities.js'

import '@styles/backOffice/Post/Posts.css'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'
export default function Post() {
  const [t, i18n] = useTranslation('common')
  const [post, setPost] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    if (!window.location.hash.includes('posts/s/'))
      getRequest(host + '/posts/' + id)
        .then(res => {
          setPost(res)
        })
    else
      getRequest(host + '/posts/s/' + id)
        .then(res => {
          setPost(res)
        })
  }, [setPost, id])
  return (
    <>
      {post ?
        <div className="about__container public__container--padding-bottom">
          <div className="content--card">
            <BreadCrumbs crumbs={[
              { href: "/#/", name: "home" },
              { name: "staticContent" },
              { name: `${getTranslation(post.title, i18n.language, i18n.languages[i18n.languages.length - 1])}`, fullTitle: true }
            ]} />
          </div>
          <PostPage key={post.slug} tag="public" post={post} />
        </div>
        :
        <div className="about__container public__container--padding-bottom">
          <div className="content--card">
            <BreadCrumbs crumbs={[
              { href: "/#/", name: "home" },
              { name: "staticContent" },
              { name: `${t('labels.post.notFound')}`, fullTitle: true }
            ]} />
          </div>
          <div><h2>{t('labels.post.notFound_slug')}</h2></div>
        </div>
      }
    </>
  )
}
