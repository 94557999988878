import React, { useState, useContext, useEffect } from 'react';
import { Card, Grid, Typography, TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';

import Axios from 'axios';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"
import TextFieldCommon from '@components/Common/TextField';
import { useTranslation } from 'react-i18next';

import 'suneditor/dist/css/suneditor.min.css';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import WYSIWYGeditorCommon from '@components/Common/WYSIWYGeditor';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import { MenuItem, Select } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function GeneralForm(props) {
  const [t, i18n] = useTranslation('common')
  const [validation, setValidation] = useState(null)
  const [gravatar, setGravatar] = useState(null)
  const [checked, setChecked] = useState(false)
  const [contact, setContact] = useState(null)
  const [about, setAbout] = useState(null)
  const [cookiesPolicy, setCookiesPolicy] = useState(null)
  const [languagesId, setLanguagesId] = useState(null)
  const [languages, setLanguages] = useState(null)
  const [defaultLanguageId, setDefaultLanguageId] = useState(null)
  const [defaultLanguage, setDefaultLanguage] = useState(null)
  const [uploadSize, setUploadSize] = useState(null)
  const [healthCheck, setHealthCheck] = useState(null)
  const [apiKeys, setApiKeys] = useState(null)

  const [isEdit, setIsEdit] = useState(true)


  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  const handleChangeisEdit = () => {
    setIsEdit(!isEdit)
    props.setDisabled(!props.disabled)
  }

  const handleChangeValidation = (value) => {
    validation.body = value < 0 ? 0 : value
    setValidation({ ...validation })
  }

  const handleChangeHealthCheckLimit = (value) => {
    let newConfig = { ...healthCheck }
    newConfig.body.limit = value < 0 ? 0 : parseInt(value)
    setHealthCheck(newConfig)
  }

  const handleChangeHealthCheckShow = (event) => {
    let newConfig = { ...healthCheck }
    newConfig.body.show_unavailable = event.target.checked
    setHealthCheck(newConfig)
  }
  const handleChangeHealthCheckNotification = (event) => {
    let newConfig = { ...healthCheck }
    newConfig.body.send_email = event.target.checked
    setHealthCheck(newConfig)
  }

  const handleChangeDefaultLanguage = (event) => {
    event.preventDefault()
    setDefaultLanguage(event.target.value)
  }

  const handleChangeLanguages = (event, index) => {
    let newLanguages = [...languages]
    newLanguages[index] = event.target.value
    setLanguages(newLanguages)
  }

  const handleAddLanguage = (event) => {
    let newLanguages = [...languages, '']
    setLanguages(newLanguages)
  }

  const handleRemoveLanguage = (event, index) => {
    let newLanguages = [...languages]
    newLanguages.splice(index, 1)
    setLanguages(newLanguages)
  }

  const handleChangeGravatar = (event) => {
    let newGravatar = gravatar
    newGravatar.body = event.target.checked
    setChecked(event.target.checked)
    setGravatar(newGravatar)
  }

  const handleChangeContact = (value, lang) => {
    let newContact = contact
    newContact.body[lang] = value
    setContact(newContact)
  }

  const handleChangeAbout = (value, lang) => {
    let newAbout = about
    newAbout.body[lang] = value
    setAbout(newAbout)
  }

  const handleChangeCookiesPolicy = (value, lang) => {
    let newCookiesPolicy = cookiesPolicy
    newCookiesPolicy.body[lang] = value
    setCookiesPolicy(newCookiesPolicy)
  }

  const handleChangeUploadSize = (event) => {
    let newUploadSize = { ...uploadSize }
    newUploadSize.body = parseFloat(event.target.value) * 1024 * 1024
    setUploadSize(newUploadSize)
  }

  const handleChangeApiKeys = (event, index, api) => {
    let newApiKeys = { ...apiKeys }
    newApiKeys.body[index][api] = event.target.value
    setApiKeys(newApiKeys)
  }


  const handleUpdate = async () => {
    for (let doc of document.getElementsByClassName("se-code-view-enabled")) {
      doc.click()
      doc.click()
    }
    try {
      await Axios.put(host + '/configurations/' + validation._id, validation)
      await Axios.put(host + '/configurations/' + gravatar._id, gravatar)
      await Axios.put(host + '/configurations/' + contact._id, contact)
      await Axios.put(host + '/configurations/' + about._id, about)
      await Axios.put(host + '/configurations/' + cookiesPolicy._id, cookiesPolicy)
      await Axios.put(host + '/configurations/' + languagesId, { body: languages })
      await Axios.put(host + '/configurations/' + defaultLanguageId, { body: defaultLanguage })
      await Axios.put(host + '/configurations/' + uploadSize._id, uploadSize)
      await Axios.put(host + '/configurations/' + healthCheck._id, healthCheck)
      await Axios.put(host + '/configurations/' + apiKeys._id, apiKeys)
      props.setDisabled(!props.disabled)
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.configurations.update'))
    }
    catch {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.configurations.update'))
    }
  }

  useEffect(() => {
    getRequest(host + '/configurations?type=VALIDATION')
      .then(res => {
        setValidation(res.entities[0])
      })
    getRequest(host + '/configurations?type=GRAVATAR')
      .then(res => {
        setChecked(res.entities[0].body)
        setGravatar(res.entities[0])
      })
    getRequest(host + '/configurations?type=CONTACT')
      .then(res => {
        setContact(res.entities[0])
      })
    getRequest(host + '/configurations?type=ABOUT')
      .then(res => {
        setAbout(res.entities[0])
      })
    getRequest(host + '/configurations?type=COOKIESPOLICY')
      .then(res => {
        setCookiesPolicy(res.entities[0])
      })
    getRequest(host + '/configurations?type=LANGUAGES')
      .then(res => {
        setLanguagesId(res.entities[0]._id)
        setLanguages(res.entities[0].body)
      })
    getRequest(host + '/configurations?type=LANGUAGEDEFAULT')
      .then(res => {
        setDefaultLanguageId(res.entities[0]._id)
        setDefaultLanguage(res.entities[0].body)
      })
    getRequest(host + '/configurations?type=UPLOADSIZE')
      .then(res => {
        setUploadSize(res.entities[0])
      })
    getRequest(host + '/configurations?type=HEALTHCHECK')
      .then(res => {
        setHealthCheck(res.entities[0])
      })
    getRequest(host + '/configurations?type=APIKEYS')
      .then(res => {
        setApiKeys(res.entities[0])
      })
  }, [setValidation, setGravatar, setContact, setAbout, setCookiesPolicy, setUploadSize, setHealthCheck, setApiKeys, isEdit])
  return (

    <>
      {validation && gravatar && contact && about && cookiesPolicy && uploadSize && healthCheck && apiKeys &&
        <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
          <>
            <Typography className="textField__typography--subtitle-margin" id="configurations-general-validationDate-title" variant="h5" component="h5" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.validation') }} /></Typography>
            {validation && <TextFieldCommon
              className="width__10"
              id="configurations-general-validationDate"
              title={t('labels.configurations.validationDate')}
              required={false}
              value={validation.body}
              type="number"
              disabled={props.disabled}
              fullWidth={true}
              handleChangeInput={(value) => handleChangeValidation(value)}
            />}
            <Typography className="textField__typography--subtitle-margin" id="configurations-general-health_check-title" variant="h5" component="h5" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.health_check.title') }} /></Typography>
            {(healthCheck) &&
              <Grid container className="textField__container">
                <Grid item xs={12}>
                  <TextFieldCommon
                    className="width__10"
                    id="configurations-general-healthcheck-limit"
                    title={t('labels.configurations.health_check.limit')}
                    required={false}
                    value={healthCheck.body.limit}
                    type="number"
                    disabled={props.disabled}
                    fullWidth={true}
                    handleChangeInput={(value) => handleChangeHealthCheckLimit(value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: t('labels.configurations.health_check.show_unavailable') }} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Switch
                    id="configurations-general-healthcheck-show_unavailable"
                    checked={healthCheck.body.show_unavailable}
                    disabled={props.disabled}
                    onChange={handleChangeHealthCheckShow}
                    name="show_unavailable"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: t('labels.configurations.health_check.send_email') }} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Switch
                    id="configurations-general-healthcheck-send_email"
                    checked={healthCheck.body.send_email}
                    disabled={props.disabled}
                    onChange={handleChangeHealthCheckNotification}
                    name="send_email"
                  />
                </Grid>
              </Grid>
            }
            <Typography className="textField__typography--subtitle-margin" id="configurations-general-uploadSize-title" variant="h5" component="h5" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.upload_size.title') }} /></Typography>
            {(uploadSize) &&
              <Grid container className="textField__container">
                <Grid item xs={12}>
                  <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: t('labels.configurations.upload_size.label') }} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="width__10"
                    id="configurations-general-uploadSize-input"
                    variant="outlined"
                    title={t('labels.configurations.upload_size.label')}
                    required={true}
                    value={(uploadSize.body / 1024 / 1024).toString()}
                    type="number"
                    disabled={props.disabled}
                    onChange={(event) => handleChangeUploadSize(event)}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">Mb</InputAdornment>,
                      inputProps: { min: 0 }
                    }}
                  />
                </Grid>
              </Grid>
            }
            <Typography className="textField__typography--subtitle-margin" id="configurations-general-apikeys-title" variant="h5" component="h5" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.api_keys.title') }} /></Typography>
            {(apiKeys.body) && apiKeys.body.map((apikey, index) =>
              <Grid container className="textField__container">
                <Grid item xs={12}>
                  <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: t('labels.configurations.api_keys.' + Object.keys(apikey)[0]) }} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  < TextField
                    className="width__33"
                    id={"configurations-general-apikeys-input-" + index}
                    variant="outlined"
                    title={t('labels.configurations.api_keys.' + Object.keys(apikey)[0])}
                    required={true}
                    value={Object.values(apikey)[0]}
                    type="text"
                    fullWidth={true}
                    disabled={props.disabled}
                    onChange={(event) => handleChangeApiKeys(event, index, Object.keys(apikey)[0])}
                  />
                </Grid>
              </Grid>
            )
            }

            {(gravatar) &&
              <Grid container className="textField__container">
                <Typography className="textField__typography--subtitle" id="configurations-general-gravatar-title" variant="h5" component="h5" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.gravatar.title') }} /></Typography>
                <Grid item xs={12}>
                  <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: t('labels.configurations.gravatar.label') }} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Switch
                    id="configurations-general-gravatar"
                    checked={checked}
                    disabled={props.disabled}
                    onChange={handleChangeGravatar}
                    name="gravatar"
                  />
                </Grid>
              </Grid>
            }

            {languages && defaultLanguage &&
              <>
                <Typography className="textField__typography--subtitle" variant="h5" component="h5" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.language') }} /></Typography>

                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  {t('labels.configurations.defaultLanguage')}
                </Typography>

                <Select
                  className="width__10 textField__typography"
                  IconComponent={ExpandMoreIcon}
                  classes={{
                    icon: "arrowicon__color"
                  }}
                  id="configurations-general-defaultLanguage"
                  name="configurations-general-defaultLanguage"
                  disabled={props.disabled}
                  value={defaultLanguage}
                  onChange={handleChangeDefaultLanguage}
                  input={<OutlinedInput margin="dense" />}
                >
                  {languages.map((data, index) => {
                    return (
                      <MenuItem id={"languages-" + index} key={index} value={data}>
                        {data}
                      </MenuItem>
                    )
                  })}
                </Select>

                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  {t('labels.configurations.languages')}
                </Typography>
                {languages.map((data, index) =>
                  <Grid item xs={12} md={12}>
                    <TextField
                      className="width__10 margin__top--9px"
                      id={"configurations-general-defaultLanguage-" + index + '-input'}
                      size="small"
                      variant="outlined"
                      required={true}
                      value={data}
                      disabled={props.disabled}
                      rows={3}
                      fullWidth={true}
                      name={"configurations-general-defaultLanguage-" + index}
                      type="text"
                      onChange={(event) => handleChangeLanguages(event, index)}
                    />
                    {!props.disabled &&
                      <>
                        {languages.length > 1 &&
                          <Button id={"configurations-general-defaultLanguage-" + index + '-remove-button'} className="form__button--remove margin__top--9px" size="medium" onClick={(event) => handleRemoveLanguage(event, index)}>
                            <RemoveIcon className="form__icon--remove" />
                          </Button>

                        }
                        {index === languages.length - 1 &&
                          <Button id={"configurations-general-defaultLanguage-" + index + '-add-button'} className="form__button--add margin__top--9px" size="medium" onClick={(event) => handleAddLanguage(event)}>
                            <AddIcon className="form__icon--add" />
                          </Button>
                        }
                      </>}
                  </Grid>
                )}
              </>
            }

            {contact && <>
              <Typography className="textField__typography--subtitle" variant="h5" component="h5" gutterBottom id="configurations-general-contact-title"><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.contact') }} /></Typography>
              <Grid container className="textField__container">
                {languages && languages.map((lang, index) =>
                  <Grid key={"contact" + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                    <Grid item xs={12}>
                      <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('common.labels.language.' + lang, lang) }} /></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {props.disabled ?
                        <Typography component="div" className="configuration__typography--textField"><div className="configuration__typography--textField--margin" dangerouslySetInnerHTML={{ __html: contact.body[lang] }} /></Typography>
                        :
                        <WYSIWYGeditorCommon
                          id="configurations-general-contact-pt"
                          lang={i18n.language}
                          defaultValue={contact.body[lang]}
                          handleChange={(value) => handleChangeContact(value, lang)}
                        />
                      }
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
            }

            {about && <>
              <Typography className="textField__typography--subtitle" variant="h5" component="h5" gutterBottom id="configurations-general-about-title"><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.about') }} /></Typography>
              <Grid container className="textField__container">
                {languages && languages.map((lang, index) =>
                  <Grid key={"about" + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                    <Grid item xs={12}>
                      <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('common.labels.language.' + lang, lang) }} /></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {props.disabled ?
                        <Typography component="div" className="configuration__typography--textField"><div className="configuration__typography--textField--margin" dangerouslySetInnerHTML={{ __html: about.body[lang] }} /></Typography>
                        :
                        <WYSIWYGeditorCommon
                          id={"configurations-general-about-" + lang}
                          lang={i18n.language}
                          defaultValue={about.body[lang]}
                          handleChange={(value) => handleChangeAbout(value, lang)}
                        />
                      }
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
            }

            {cookiesPolicy && <>
              <Typography className="textField__typography--subtitle" variant="h5" component="h5" gutterBottom id="configurations-general-cookiesPolicy-title"><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.cookiesPolicy') }} /></Typography>
              <Grid container className="textField__container">
                {languages && languages.map((lang, index) =>
                  <Grid key={"cookiesPolicy" + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                    <Grid item xs={12}>
                      <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('common.labels.language.' + lang, lang) }} /></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {props.disabled ?
                        <Typography component="div" className="configuration__typography--textField"><div className="configuration__typography--textField--margin" dangerouslySetInnerHTML={{ __html: cookiesPolicy.body[lang] }} /></Typography>
                        :
                        <WYSIWYGeditorCommon
                          id={"configurations-general-cookiesPolicy-" + lang}
                          lang={i18n.language}
                          defaultValue={cookiesPolicy.body[lang]}
                          handleChange={(value) => handleChangeCookiesPolicy(value, lang)}
                        />
                      }
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
            }
          </>
        </Card>
      }
      {!props.disabled &&
        <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
          <SubmitButtonCommon
            id="configurations-general-save-button"
            className="form__button--color-success"
            title={t('common.buttons.save')}
            disabled={props.disabled}
            handleClick={handleUpdate} />
          <SubmitButtonCommon
            id="configurations-general-cancel-button"
            className="form__button--color-error"
            title={t('common.buttons.cancel')}
            handleClick={handleChangeisEdit} />
        </Grid>
      }
    </>
  )
}
