import React, { useState, useEffect, useContext } from 'react'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'

import '@styles/backOffice/Home.css'
import { useHistory } from 'react-router-dom';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import adminAccess from '@utils/comparators/AdminAccess'
import { UserContext } from '@context/userContext';

function grantAccess(component, permission) {
  return adminAccess(component, "find", permission)
}


export default function Home() {
  const [t] = useTranslation("common")
  const history = useHistory()
  const [user] = useContext(UserContext)

  const [repositoriesCount, setRepositoriesCount] = useState(0)
  const [organizationsCount, setOrganizationsCount] = useState(0)
  const [usersCount, setUsersCount] = useState(0)
  const [requestsCount, setRequestsCount] = useState(0)

  useEffect(() => {
    getRequest(host + '/repositories/count')
      .then(res => {
        setRepositoriesCount(res)
      })
    getRequest(host + '/organizations/count')
      .then(res => {
        setOrganizationsCount(res)
      })
    getRequest(host + '/users/count')
      .then(res => {
        setUsersCount(res)
      })
    getRequest(host + '/requests/count?condition=CREATED')
      .then(res => {
        setRequestsCount(res)
      })

  }, [setRepositoriesCount, setOrganizationsCount, setUsersCount, setRequestsCount])

  return (
    <div className="admin__container-bottom">
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" }
      ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography component="h2" className="admin__card--title admin__form--title-margin">{t('titles.home.admin')}</Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Card className="home__card1--backgroundColor" onClick={() => history.push('/admin/repositories')}>
              <CardContent className="home__cardContent--display">
                <div>
                  <Typography className="home__card--typography" component="h6" variant="h6">
                    {t('titles.repositories.title')}
                  </Typography>
                  <Typography className="home__card--typography" variant="subtitle1" color="textSecondary">
                    {repositoriesCount}
                  </Typography>
                </div>
                <div className="home--icon--flex--end">
                  <FolderOpenOutlinedIcon className="home__card--icon--size" />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className="home__card2--backgroundColor" onClick={() => history.push('/admin/organizations')}>
              <CardContent className="home__cardContent--display">
                <div>
                  <Typography className="home__card--typography" component="h6" variant="h6">
                    {t('titles.organizations.title')}
                  </Typography>
                  <Typography className="home__card--typography" variant="subtitle1" color="textSecondary">
                    {organizationsCount}
                  </Typography>
                </div>
                <div className="home--icon--flex--end">
                  <StoreMallDirectoryOutlinedIcon className="home__card--icon--size" />
                </div>
              </CardContent>
            </Card>
          </Grid>

          {
            grantAccess('user', user.group.permission) &&
            <Grid item xs={12} sm={6} md={3}>
              <Card className="home__card3--backgroundColor" onClick={() => history.push('/admin/users')}>
                <CardContent className="home__cardContent--display">
                  <div>
                    <Typography className="home__card--typography" component="h6" variant="h6">
                      {t('titles.users.title')}
                    </Typography>
                    <Typography className="home__card--typography" variant="subtitle1" color="textSecondary">
                      {usersCount}
                    </Typography>
                  </div>
                  <div className="home--icon--flex--end">
                    <PersonOutlineOutlinedIcon className="home__card--icon--size" />
                  </div>
                </CardContent>
              </Card>
            </Grid>}
          <Grid item xs={12} sm={6} md={3}>
            <Card className="home__card4--backgroundColor" onClick={() => history.push('/admin/requests?field=created')}>
              <CardContent className="home__cardContent--display">
                <div>
                  <Typography className="home__card--typography" component="h6" variant="h6">
                    {t('titles.workflows.home')}
                  </Typography>
                  <Typography className="home__card--typography" variant="subtitle1" color="textSecondary">
                    {requestsCount}
                  </Typography>
                </div>
                <div className="home--icon--flex--end">
                  <AssignmentOutlinedIcon className="home__card--icon--size" />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
