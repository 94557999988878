import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts"

import '@styles/Common/Common.css'
import Typography from '@material-ui/core/Typography';


import '@styles/Common/Common.css'
import getTranslation from '@utils/functions/utilities.js'


export default function LineChart(props) {
  const [t, i18n] = useTranslation("common")
  const [data, setData] = useState([])
  const [title, setTitle] = useState("")

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: '',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: null,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 260,
          height: 400
        },
      }
    }]
  }

    /* {
    chart: {
      zoom: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: null
    }
  } */)

  useEffect(() => {
    setTitle(getTranslation(props.title, i18n.language, i18n.languages[i18n.languages.length - 1]))

    let newData = props.data.slice(0, 12).sort((a, b) => {
      if (a.key < b.key)
        return -1
      else
        return 1
    })
    newData = newData.map(d => {
      return { key: typeof d.key === "object" ? getTranslation(d.key, i18n.language, i18n.languages[i18n.languages.length - 1]) : d.key, value: parseInt(d.value) }
    })
    setData(newData)
    let newOptions = { ...options, xaxis: { categories: newData.map(d => d.key) }, title: { text: getTranslation(props.title, i18n.language, i18n.languages[i18n.languages.length - 1]) } }
    setOptions(newOptions)
  }, [setData, props.data, i18n.language])

  return (
    <>
      {
          <>
            {(data && options && options.xaxis && options.xaxis.categories) && <ReactApexChart
              options={options}
              series={[{ name: title, data: data.map(d => d.value) }]}
              type="line"
            />}
          </>
      }
    </>
  )
}