import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@material-ui/core';
import TextLable from '@components/Common/TextLable'

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import SubmitButtonCommon from '@components/Common/SubmitButton';

export default function ControlledVocabularyPage() {
  const [t, i18n] = useTranslation('common')
  const [temp, setTemp] = useState(null)
  const [langs, setLangs] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/configurations/' + id)
      .then(res => setTemp(res))

    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setTemp, setLangs])

  return (
    <>
      {temp &&
        <Card elevation={0} className="MuiBox-root-43">
          <Grid container>
            <TextLable
              id="configurations-template-format"
              title={t('labels.configurations.templateObj.format.title')}
              value={t('labels.configurations.templateObj.format.' + temp.body.format)}
            />

            <Grid container className="textField__container">
              {langs && langs.filter(lang => temp.body.subject[lang]).map((lang, index) =>
                <Grid item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"configurations-template-subject-" + lang}
                    title={t('labels.configurations.templateObj.subject', { lang: lang })}
                    value={temp.body.subject[lang]}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container className="textField__container">
              {langs && langs.filter(lang => (temp.body.text && temp.body.text[lang]) || (temp.body.html && temp.body.html[lang])).map((lang, index) =>
                <Grid item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  {temp.body.format === 'text' && <TextLable
                    id={"configurations-template-text-"+lang}
                    title={t('labels.configurations.templateObj.text',{lang: lang})}
                    value={temp.body.text[lang]}
                  />}
                  {temp.body.format === 'html' && <TextLable
                    id={"configurations-template-html-"+lang}
                    title={t('labels.configurations.templateObj.html',{lang: lang})}
                    value={temp.body.html[lang]}
                  />}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>}
      <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
        <SubmitButtonCommon
          id="controlled-vocabulary-back-button"
          className="form__button--color-error"
          title={t('table.confirm.backButton')}
          goBack />
      </Grid>
    </>
  )
}
