import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"

import PostForm from '@components/Post/PostForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useParams } from 'react-router-dom'

import '@styles/backOffice/Post/Posts.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewPost() {
  const [t] = useTranslation("common")

  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [post, setPost] = useState(null)

  let history = useHistory()
  const { id } = useParams()

  const submitPost = async (newPost) => {
    try {
      await Axios.put(host + '/posts/' + id, newPost)
      snackForPost("success", t('messages.success.post.update'))
      history.goBack()
    }
    catch (err) {
      if(err.response.data.message === 'Slug is already in use')
        snackForPost("error", t('messages.error.post.update') + '. ' + t('messages.error.post.create_slug'))
      else
        snackForPost("error", t('messages.error.post.update'))
    }
  }

  useEffect(() => {
    getRequest(host + "/posts/" + id)
      .then(res => {
        setPost(res)
      })
  }, [setPost, id])

  const snackForPost = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
      {post && <>
      <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin" , name: "admin"},
          { href: "/#/admin/posts" , name: "posts"},
          { name: "posts_update"}
        ]} />
        <Typography component="h2" className="admin__card--title">{t('titles.post.update')}</Typography>
        <div >
          <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.post.form')}</Typography>
        </div>
        <PostForm
          title={post.title}
          body={post.body}
          slug={post.slug}
          visible={post.visible}
          snackForPost={snackForPost}
          submitPost={submitPost}
          submitLable={t('post.form.submit.update')}
        />
      </>}
    </div>
  )
}
