import React, { useState, useContext } from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { Card, Divider, Grid, Hidden, IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TablePagination from '@material-ui/core/TablePagination';
import Link from '@material-ui/core/Link';
import BadgeCommon from '@components/Common/Badge';
import TextLable from '@components/Common/TextLable'
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import WarningIcon from '@material-ui/icons/Warning';

import { UserContext } from '@context/userContext';

import ExportMenu from '@components/Repository/ExportMenu'

import { host } from '@utils/config'
import getTranslation from '@utils/functions/utilities.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const qs = require('qs')

export default function ListRepositories(props) {
  const query = qs.parse(useHistory().location.search.replace(/\?/, ''))
  const [t, i18n] = useTranslation("common")
  const pageSize = props.pageSize
  const page = props.page
  const [orderBy, setOrderBy] = useState("best")
  const [user] = useContext(UserContext)

  const [anchorEl, setAnchorEl] = useState(null);

  let history = useHistory()

  const handleButtonRepo = (id) => {
    history.push("/repositories/" + id)
  }

  const handleChangePage = (event, newPage) => {
    delete query._start
    props.setPage(newPage)
    history.push("/repositories?" + qs.stringify(query) + "&_start=" + ((newPage) * pageSize))
  }

  const handleChangePageSize = (event) => {
    delete query._start
    delete query._limit
    props.setPage(0)
    history.push("/repositories?" + qs.stringify(query) + "&_start=0&_limit=" + event.target.value)
  }

  const handleChangeOrder = (event) => {
    setOrderBy(event.target.value)
    delete query._start
    delete query._sort
    let sort = (event.target.value !== 'best') ? "&_sort=" + event.target.value : ""
    history.push("/repositories?" + qs.stringify(query) + "&_start=0" + sort)
  }

  const handleOpenEdit = (event, id) => {
    event.preventDefault();
    history.push("/repositories/update/" + id)
  }

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };
  const createExportLink = (type) => {
    let queryParam = {
      action: "export",
      _start: 0,
      _limit: -1,
      lang: i18n.language,
      format: type,
      ...query
    }

    let queryString = qs.stringify(queryParam).replace(/facets\.[0-9]+\./g, "facet_").replace(/search\.[0-9]+\./g, "")
    return host + "/repositories?" + queryString
  }

  const handleClick = (badge) => {
    let filters = {}
    const newChecked = [...props.checked]
    const currentIndex = newChecked.indexOf("badge##" + badge.value);
    if (currentIndex === -1) {
      newChecked.push("badge##" + badge.value);
      filters["facets." + newChecked.length + ".badge"] = badge.value
    }
    else {
      newChecked.splice(currentIndex, 1);
      for (const obj of Object.entries(query)) {
        if (obj[0].includes("badge") && obj[1] === badge.value)
          delete query[obj[0]];
      }
    }
    props.setChecked(newChecked)
    history.push("/repositories?" + qs.stringify({ ...query, _start: 0, ...filters }))
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8} className="repositories__selector--align-center">
          <Typography id="found-lable" className="repositories__results--text" component="h2" color="textSecondary" variant="body1">
            {t('labels.repositories.nrResults', { number: props.numberRepositories })}
          </Typography>
        </Grid>
        <Grid item xs={4} className="repositories__selector--justify-left">
          {(
            <>
              <Hidden lgUp>
                <Tooltip placement="top" title={t('tooltip.repositories.filter')}>
                  <Button
                    className="repositories__filter--button repositories__button--color" id="filter-menu" size="medium" onClick={() => props.openDrawer(true)}>
                    {t('labels.repositories.filter')}</Button>
                </Tooltip>
              </Hidden>
              <Tooltip placement="top" title={t('tooltip.repositories.exportRepositories')}>
                <Button className="repositories__download--button" id="export-menu-button-all" size="medium" onClick={handleClickExport}>
                  <SaveAltIcon />
                </Button>
              </Tooltip>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                className="appNavBar__menu--user"
                keepMounted
                open={Boolean(anchorEl)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                onClose={handleCloseExportMenu}
              >

                <MenuItem role="selectMenu" key="json">
                  <Link id="export-json-button-all" className="repositories__a--style" href={createExportLink('json')} onClick={handleCloseExportMenu}>JSON</Link>
                </MenuItem>
                <MenuItem role="selectMenu" key="xml">
                  <Link id="export-xml-button-all" className="repositories__a--style" href={createExportLink('xml')} onClick={handleCloseExportMenu}>XML</Link>
                </MenuItem>
                <MenuItem role="selectMenu" key="csv">
                  <Link id="export-csv-button-all" className="repositories__a--style" href={createExportLink('csv')} onClick={handleCloseExportMenu}>CSV</Link>
                </MenuItem>
                <MenuItem role="selectMenu" key="jsonld">
                  <Link id="export-jsonld-button-all" className="repositories__a--style" href={createExportLink('jsonld')} onClick={handleCloseExportMenu}>JSON-LD</Link>
                </MenuItem>
              </Menu>
            </>
          )}
          <FormControl role="RepositoriesSort" className="repositories__sort--menu repositories__margin--left" variant="outlined">
            <Select
              role="RepositoriesSort"
              className="repositories__sort--menu"
              native
              IconComponent={ExpandMoreIcon}
              classes={{
                icon: "arrowicon__color"
              }}
              value={orderBy}
              onChange={handleChangeOrder}
            >
              <option value={"best"}>{t('labels.repositories.orderBy.best')}</option>
              <option value={"name:ASC"}>{t('labels.repositories.orderBy.nameAsc')}</option>
              <option value={"name:DESC"}>{t('labels.repositories.orderBy.nameDesc')}</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {
        props.repositories.map((repo, index) => {
          return (
            <div key={repo._id}>
              <Card elevation={0} className="repositories__card">
                <Grid container>
                  <Grid item xs={12} md={2}>
                    <CardMedia
                      style={repo.logo ? { backgroundSize: "contain", borderRadius: 0 } : null}
                      className="repositories__card--cardmedia"
                      image={repo.logo ? host + repo.logo.url : '/defaultRepository.jpg'}
                      title={getTranslation(repo.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} className="repositories__padding--inline">
                    <CardContent>

                      <Typography gutterBottom variant="h6" component="h2">
                        {getTranslation(repo.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                        {
                          !repo.healthCheck.available &&
                          <Tooltip placement="top" title={t('tooltip.repositories.unavailable')}>
                            <WarningIcon className='repositories__icon--unavailable' />
                          </Tooltip>
                        }
                      </Typography>

                      <Typography variant="body1" color="textSecondary" component="p" className="">
                        {getTranslation(repo.description, i18n.language, i18n.languages[i18n.languages.length - 1])}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p" className="">
                        <a target="_blank" href={repo.organization.url ? (repo.organization.url.startsWith("http") ? repo.organization.url : "https://" + repo.organization.url) : (undefined)}>{getTranslation(repo.organization.name, i18n.language, i18n.languages[i18n.languages.length - 1])}</a>
                      </Typography>
                      <TextLable
                        id="content"
                        value={repo.content.map(content => getTranslation(content.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
                        isArray={true}
                      />
                    </CardContent>
                  </Grid>
                  <Grid item xs={12} md={2} className="repositories__container--icons">
                    <Button id={"repositories-see-repository-button-" + index} variant="contained" className="actionButton repositories__button--color repositories__see--button" onClick={() => handleButtonRepo(repo._id)}>{t('titles.repositories.seeRepo')}</Button>
                  </Grid>
                  <Divider varient="inset" color="primary" />
                  <Hidden smDown>
                    <Grid item md={2} className="repositories__container--bottom repositories__padding--bottom-30px">
                      <Grid container className="textField__container repositories__container--badges">
                        {repo.badge && repo.badge.map((badge, index) => (
                          <Grid item xs={2} md={2} key={"badge" + index} className="repositories__badges--margin-right">
                            <IconButton size='small' className='facets__badge--button' onClick={() => handleClick(badge)}>
                              <BadgeCommon tag="avatar" name={getTranslation(badge.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])} url={badge.i18n.badge.url} />
                            </IconButton>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} className="repositories__container--bottom repositories__padding--left-45px">
                      <CardContent>
                        <Typography gutterBottom variant="subtitle1" component="h3">
                          {t('labels.repositories.repositoryType')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {getTranslation(repo.repositoryType[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                        </Typography>
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} md={3} className="repositories__container--bottom repositories__padding--left-45px">
                      <CardContent>
                        <Typography gutterBottom variant="subtitle1" component="h3">
                          {t('labels.repositories.software')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {getTranslation(repo.software[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                        </Typography>
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} md={3} className="repositories__container--bottom repositories__padding--left-45px">
                      <CardContent>
                        <Typography gutterBottom variant="subtitle1" component="h3">
                          {t('labels.repositories.language')}
                        </Typography>
                        <Typography key={index} variant="body2" color="textSecondary" component="p">
                          {repo.lang.map((lang, index) => {
                            let dot = ""
                            if (index !== repo.lang.length - 1) dot = " • "
                            return getTranslation(lang.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) + dot
                          })}
                        </Typography>
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} md={1} className="repositories__container--icons repositories__grid--margin-top repositories__container--bottom repositories__padding--bottom-24px">
                      <ExportMenu
                        id={index}
                        item={repo}
                      />
                      <Tooltip placement="top" title={t('tooltip.repositories.edit')}>
                        <IconButton id={'repository-edit-button-' + index} aria-label="arrow drop Up"
                          size="small" onClick={(event) => handleOpenEdit(event, repo._id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Hidden>
                </Grid>
              </Card>
            </div>
          )
        })
      }
      <Grid container role="repositoriesPage" className="repositories__pagination--margin--justify">
        <TablePagination
          role="repositoriesPage"
          component="div"
          rowsPerPageOptions={[10, 25, 100]}
          count={props.numberRepositories}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={pageSize}
          labelRowsPerPage={t('labels.repositories.itemsPerPage')}
          onChangeRowsPerPage={handleChangePageSize}
          classes={{
            selectIcon: "arrowicon__color",
            actions: "arrowicon__color"
          }}
        />
      </Grid>
    </>
  )
}
