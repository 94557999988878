import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"

import NewsForm from '@components/NewsItem/NewsForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useParams } from 'react-router-dom'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function EditNewsItem() {
  const [t] = useTranslation("common")

  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [news, setNews] = useState(null)

  let history = useHistory()
  const { id } = useParams()

  const submitNews = async (news) => {
    let newImage;
    if (news.newImage && typeof news.newImage !== "undefined") {
      const data = new FormData()
      data.append('files', news.newImage)

      newImage = await Axios.post(host + "/upload", data)
      newImage = newImage.data[0]
      news.image = { url: newImage.url, _id: newImage._id }
    }

    delete news.newImage
    try {
      await Axios.put(host + '/news-items/' + id, news)
      snackForNews("success", t('messages.success.news.update'))
      history.goBack()
    }
    catch {
      snackForNews("error", t('messages.error.news.update'))
    }
  }

  useEffect(() => {
    getRequest(host + "/news-items/" + id)
      .then(res => {
        setNews(res)
      })
  }, [setNews, id])

  const snackForNews = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
      {news && <>
      <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin" , name: "admin"},
          { href: "/#/admin/news" , name: "news"},
          { name: "news_update"}
        ]} />
        <Typography component="h2" className="admin__card--title">{t('titles.news.update')}</Typography>
        <div >
          <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.news-item.form')}</Typography>
        </div>
        <NewsForm
          title={news.title}
          body={news.body}
          description={news.description}
          image={news.image}
          newsDate={news.newsDate}
          startDate={news.startDate}
          endDate={news.endDate}
          snackForNews={snackForNews}
          submitNews={submitNews}
          submitLable={t('news.form.submit.update')}
        />
      </>}
    </div>
  )
}
