import React from 'react'

import TemplateEdit from '@components/Configuration/Template/TemplateEdit.js'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Notification() {
  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/emailTemplates?field=emailTemplate", name: "emailTemplates" },
        { name: "updateEmailTemplate" }
      ]} />
      <TemplateEdit />
    </div>
  )
}