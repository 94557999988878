import React from "react";
import { useTranslation } from 'react-i18next'
import CookieConsent from "react-cookie-consent";

export default function CookiePopup() {
    const [t, i18n] = useTranslation("common")
    return (
        <CookieConsent
            location="bottom"
            buttonText={t('cookie.cookieButton')}
            cookieName="cookieConsent"
            contentStyle={{ flex: "none !important" }}
            style={{
                background: "#62625d",
                justifyContent: "center",
                zIndex: 9999,
                fontSize: "16px",
                position: "sticky"
            }}
            buttonStyle={{
                backgroundColor: "var(--color-darken-green)",
                fontSize: "16px",
                color: "#fff",
                fontWeight: "bold",
                borderRadius: "5px",
                padding: "8px 15px"
            }}
            expires={150}
        >
            {t('cookie.cookieMessage')}{" "}
            <a href="/#/policy-cookies" className="cookie-link" target="_blank" style={{ fontWeight: "bold" }}>
                {t('cookie.cookiePolicy')}
            </a>
        </CookieConsent>
    );
}