import React, { useState, useContext, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import WYSIWYGeditorCommon from '@components/Common/WYSIWYGeditor';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { host } from '@utils/config';
import getRequest from '@utils/functions/axiosRequest'
import "@styles/Common/Common.css"

export default function HighlightForm(props) {
  const [t, i18n] = useTranslation("common")
  const [langs, setLangs] = useState(null)
  const [title, setTitle] = useState(props.title || {})
  const [description, setDescription] = useState(props.description || {})
  const [body, setBody] = useState(props.body || {})
  const [date, setDate] = useState(props.date || null)
  const [startDate, setStartDate] = useState(props.startDate|| null)
  const [endDate, setEndDate] = useState(props.endDate || null)

  const [isErrorTitle, setIsErrorTitle] = useState(false)
  const [isErrorDescription, setIsErrorDescription] = useState(false)
  const [isErrorBody, setIsErrorBody] = useState(false)
  const [isErrorDate, setIsErrorDate] = useState(false)
  const [isErrorStartDate, setIsErrorStartDate] = useState(false)
  const [isErrorEndDate, setIsErrorEndDate] = useState(false)

  let history = useHistory()


  const validateHighlight = () => {
    let res = true
    setIsErrorTitle(false)
    setIsErrorDescription(false)
    setIsErrorBody(false)
    setIsErrorDate(false)
    setIsErrorStartDate(false)
    setIsErrorEndDate(false)
    for (let lang of langs){
      if (!title[lang]) {
        res = false
        setIsErrorTitle(true)
      }
      if (!body[lang]) {
        res = false
        setIsErrorBody(true)
      }
      if (!description[lang]) {
        res = false
        setIsErrorDescription(true)
      }
    }
    if(!date){
        res = false
        setIsErrorDate(true)
    }
    if(!startDate){
      res = false
      setIsErrorStartDate(true)
    }
    if(!endDate){
      res = false
      setIsErrorEndDate(true)
    }
    return res
  }

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setLangs])

  const handleSubmitHighlight = () => {
    for (let doc of document.getElementsByClassName("se-code-view-enabled")) {
      doc.click()
      doc.click()
    }
    if (validateHighlight()) {
      let highlight = {
        title: { ...title },
        body: { ...body },
        description: {...description},
        date: date,
        startDate: startDate,
        endDate: endDate,
        repository: props.repository._id
      }
      props.submitHighlight(highlight)
    }
    else {
      props.snackForHighlight("error", t('messages.error.common.validation'))
    }
  }

  const handleChange = (value, lang, tag, set) => {
    let newValues = { ...tag }
    newValues[lang] = value
    set(newValues)
  }

  const handleSpread = (event, value, tag, set) => {
    if(value !== undefined && value !== ''){
      let newValues = { ...tag }
      langs.map(l => newValues[l] === '' || newValues[l] === undefined || event.type === 'dblclick' ? newValues[l] = value : '' )
      set(newValues)

    }
  }

  const handleChangeContent = (value, lang) => {
    let newValues = body
    newValues[lang] = value
    setBody(newValues)
  }

  return (
    <>
    <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.highlight.form')}</Typography>
    </div>
    <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
      <Grid container>
        <TextFieldCommon
            title={t('labels.highlights.repository')}
            id={"repository"}
            value={props.repository.name[i18n.language]}
            label={t('common.labels.required')}
            type="text"
            fullWidth={true}
            disabled
        />
        <Grid item xs={12} md={12}>
                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    {t('labels.highlights.date')}
                </Typography>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="datePicker__container"
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format="yyyy/MM/dd"
              openTo="date"
              id="HighlightDate-picker-inline"
              label={t('labels.repositories.datePicker')}
              error={isErrorDate}
              helperText={isErrorDate ? t('messages.error.highlights.date') : ''}
              value={date}
              onChange={(value) => setDate(value)}
              />
          </MuiPickersUtilsProvider>
          <Grid container item xs={12} md={12}>  
            <Grid item xs={12} md ={6} className="fields__padding--right">
                <Grid item xs={12} md={12}>
                    <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                        {t('labels.highlights.start_date')}
                    </Typography>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="datePicker__container"
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="yyyy/MM/dd"
                    openTo="date"
                    id="startDate-picker-inline"
                    label={t('labels.repositories.datePicker')}
                    error={isErrorStartDate}
                    helperText={isErrorStartDate ? t('messages.error.highlights.start_date') : ''}
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                    />
                </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md ={6} className="fields__padding--left" >
                <Grid item xs={12} md={12}>
                    <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                        {t('labels.highlights.end_date')}
                    </Typography>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="datePicker__container"
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="yyyy/MM/dd"
                    openTo="date"
                    id="endDate-picker-inline"
                    label={t('labels.repositories.datePicker')}
                    error={isErrorEndDate}
                    helperText={isErrorEndDate ? t('messages.error.highlights.end_date') : ''}
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        <Grid container className="textField__container--right">
          {langs && langs.map((lang, index) =>
            <Grid key={"highlights-title-" + lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
              <TextFieldCommon
                title={t('labels.highlights.title') + " <sup>" + lang + "</sup>"}
                id={"title" + lang}
                required={true}
                value={title[lang]}
                isOpen={isErrorTitle}
                message={t('messages.error.highlights.title')}
                label={t('common.labels.required')}
                type="text"
                fullWidth={true}
                handleChangeInput={(value) => handleChange(value, lang, title, setTitle)}
                handleSpreadInput={(event, value) => handleSpread(event, value, title, setTitle)}
                renderIcon={true}
              />
            </Grid>
          )}
          {langs && langs.map((lang, index) =>
           <Grid key={"highlights-description-" + lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
            <TextFieldCommon
              title={t('labels.highlights.description') + " <sup>" + lang + "</sup>"}
              id={"description" + lang}
              required={true}
              value={description[lang]}
              isOpen={isErrorDescription}
              message={t('messages.error.highlights.description')}
              label={t('common.labels.required')}
              type="text"
              fullWidth={true}
              multiline
              handleChangeInput={(value) => handleChange(value, lang, description, setDescription)}
              handleSpreadInput={(event, value) => handleSpread(event, value, description, setDescription)}
              renderIcon={true}
            />
         </Grid>
           )}
           <Grid container className="textField__container">
          {langs && langs.map((lang, index) =>
            <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
              <Grid item xs={12} md={12}>
                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: t('labels.highlights.body') + " <sup>" + lang + "</sup>" }} />
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <WYSIWYGeditorCommon
                  id={"content" + lang}
                  lang={i18n.language}
                  defaultValue={body[lang]}
                  handleChange={(value) => handleChangeContent(value, lang)}
                />
                {isErrorBody && <FormHelperText id="content-error" error>{t('messages.error.highlights.body')}</FormHelperText>}
              </Grid>
            </Grid>
          )}
        </Grid>
        </Grid>
      </Grid>
    </Card>
    <Grid container className="form__button--justify-left">
        <SubmitButtonCommon
          id="highlight-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={handleSubmitHighlight} />
        <SubmitButtonCommon
          id="highlight-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          handleClick={() => history.push("/admin/highlights")}/>
      </Grid>
    </>
    )
}