import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwipeableDrawer, Drawer, Toolbar, Hidden, List, ListItem, ListItemText, Card, Typography, Grid, Button } from '@material-ui/core';
import getTranslation from '@utils/functions/utilities.js'
import Metatags from '../../Metatags';

const ListItemLink = (props) => {
    return <ListItem button component="div" {...props} />;
}

export default function FAQsMenu(props) {
    const [t, i18n] = useTranslation("common")
    const [faqs, setFAQs] = useState(props.faqs)
    const [openDrawer, setOpenDrawer] = useState(false)

    const scroll = (id) => {
        const section = document.querySelector("#" + id);
        section.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    };

    const drawer = (
        <List style={{ marginTop: "-8px" }}>
            <ListItem className='adminDrawer__accordion--selected'> <Typography variant="h6" component="div">{t('titles.faq.title')}</Typography></ListItem>
            {faqs && faqs.map((faq, index) =>
                <ListItemLink id={"faq-" + index} key={"faq-" + index} onClick={() => scroll("faq-grid-item-" + index)}>
                    <ListItemText primaryTypographyProps={{ style: { width: "100%", whiteSpace: "normal" } }} primary={getTranslation(faq.title, i18n.language, i18n.languages[i18n.languages.length - 1])} />
                </ListItemLink>
            )}
        </List>
    );
    return (

        <>
            {faqs && faqs.length > 0 ?
                <div className="repositories__no-image">
                    <Metatags title={t('titles.faq.faq')} />
                    <Hidden lgUp>
                        <Drawer
                            variant="temporary"
                            disableRestoreFocus
                            disableScrollLock
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                            PaperProps={{
                                style:
                                    { width: "307px" }

                            }}
                        >
                            <Toolbar />
                            {drawer}
                        </Drawer>
                        <Grid container item justify='flex-end' xs={12} style={{ marginBottom: "10px", borderRadius: "8px" }} className="content--card">
                            <Button className="repositories__button--color" size='medium' onClick={() => setOpenDrawer(!openDrawer)}>{t('common.buttons.toc')}</Button>
                        </Grid>
                    </Hidden>
                    <Hidden mdDown implementation="css">
                        <Drawer
                            variant="permanent"
                        >
                            <Toolbar />
                            {drawer}
                        </Drawer>
                    </Hidden>

                    <Card elevation={0} style={{ overflow: "visible", overflowWrap: "anywhere" }} className="MuiBox-root-43 border__radius--8px content--card">
                        <Grid container>
                            {faqs && faqs.map((faq, index) =>
                                <React.Fragment key={"faq-list-" + index}>
                                    <Grid style={{ scrollMarginTop: 5 + "rem" }} id={"faq-grid-item-" + index} key={"faq-grid-item" + index} item xs={12}>
                                        <Typography key={"faq-title" + index} variant="h4" component="h1">
                                            {getTranslation(faq.title, i18n.language, i18n.languages[i18n.languages.length - 1])}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography key={"faq-content" + index} variant="body1" color="textSecondary" component="div">
                                            <div id="faq-content" className="indexes__info--text" dangerouslySetInnerHTML={{ __html: getTranslation(faq.body, i18n.language, i18n.languages[i18n.languages.length - 1]) }} />
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            )
                            }
                        </Grid>
                    </Card>
                </div>
                : window.location.replace('#/error?msg=faq.empty')}
        </>
    )
}