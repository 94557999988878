import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '@styles/Common/SearchBar.css'

export default function Advanced(props) {
  const [t] = useTranslation('common')
  const [field, setField] = useState(props.advanced.field || "name")
  const [operator, setOperator] = useState(props.advanced.operator || "_contains")
  const [term, setTerm] = useState(props.advanced.value || "")

  const handleChangeField = (event) => {
    setField(event.target.value)
    props.changeAdvanced(props.index, event.target.value, term, operator)
  }

  const handleChangeTerm = (event) => {
    setTerm(event.target.value)
    props.changeAdvanced(props.index, field, event.target.value, operator)
  }

  const handleChangeOperator = (event) => {
    setOperator(event.target.value)
    props.changeAdvanced(props.index, field, term, event.target.value)
  }

  return (
    <Grid container color="primary" className="textField__container--height searchbar__grid--margin">
      <Grid item md={2} xs={12}>
        <FormControl variant="outlined" className="textField__container--height" style={{ width: "100%" }}>
          <Select
            className="textField__container--height searchbar__border-radius searchbar__button searchbar__input--font-size"
            fullWidth
            IconComponent={ExpandMoreIcon}
            classes={{
              icon: "arrowicon__color"
            }}
            value={field}
            id={"advanced-select-field-" + props.index}
            input={
              <OutlinedInput />
            }
            onChange={handleChangeField}
          >
            <MenuItem id="advanced-name" value="name">{t('searchbar.fields.name')}</MenuItem>
            <MenuItem id="advanced-repositoryType" value="repositoryType">{t('searchbar.fields.repositoryType')}</MenuItem>
            <MenuItem id="advanced-description" value="description">{t('searchbar.fields.description')}</MenuItem>
            <MenuItem id="advanced-software" value="software">{t('searchbar.fields.software')}</MenuItem>
            <MenuItem id="advanced-subject" value="subject">{t('searchbar.fields.subject')}</MenuItem>
            <MenuItem id="advanced-keyWord" value="keyWords">{t('searchbar.fields.keyWords')}</MenuItem>
            <MenuItem id="advanced-content" value="content">{t('searchbar.fields.content')}</MenuItem>
            <MenuItem id="advanced-contentFormat" value="contentFormat">{t('searchbar.fields.contentFormat')}</MenuItem>
            <MenuItem id="advanced-language" value="language">{t('searchbar.fields.language')}</MenuItem>
            <MenuItem id="advanced-domain" value="domain">{t('searchbar.fields.domain')}</MenuItem>
            <MenuItem id="advanced-utility" value="utility">{t('searchbar.fields.utility')}</MenuItem>
            <MenuItem id="advanced-repoTypeMetadata" value="repoTypeMetadata">{t('searchbar.fields.repoTypeMetadata')}</MenuItem>
            <MenuItem id="advanced-handlePrefix" value="handlePrefix">{t('searchbar.fields.handlePrefix')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={8} xs={12} className="searchbar__input--padding-left searchbar__input--padding-right">
        <OutlinedInput
          id={"advanced-term-" + props.index}
          className="textField__container--height searchbar__border-radius searchbar__border-radius"
          fullWidth
          placeholder={t('searchbar.term')}
          value={term}
          onChange={handleChangeTerm}
          onKeyDown={props.handleEnter}
          type="text"
        />
      </Grid>
      <Grid item md={2} xs={12} className="">
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <Select
            id={"advanced-select-operator-" + props.index}
            IconComponent={ExpandMoreIcon}
            classes={{
              icon: "arrowicon__color"
            }}
            className="textField__container--height searchbar__button searchbar__input--border-radius searchbar__border-radius searchbar__input--font-size"
            fullWidth
            value={operator}
            input={<OutlinedInput />}
            onChange={handleChangeOperator}
          >
            <MenuItem id="advanced-contains" className="fontSize" value="_contains">{t('searchbar.filters.contains')}</MenuItem>
            <MenuItem id="advanced-exact" className="fontSize" value="_eq">{t('searchbar.filters.exact')}</MenuItem>
            <MenuItem id="advanced-nExact" className="fontSize" value="_ne">{t('searchbar.filters.nExact')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}