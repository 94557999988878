import React, { useState, useEffect } from 'react'
import { Card, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'

import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';

import Permissions from '@components/Group/Permissions';

const _ = require('lodash');

export default function GroupForm(props) {
  const [t] = useTranslation('common')
  const [oldRole, setOldRole] = useState(null)

  const [langs, setLangs] = useState(null)
  const [name, setName] = useState({})
  const [description, setDescription] = useState({})

  const [permissions, setPermissions] = useState([])

  const [applicationPermissions, setApplicationPermissions] = useState(null)
  const [usersPermissions, setUsersPermissions] = useState(null)
  const [readOnly, setReadOnly] = useState(true)
  const [isErrorName, setIsErrorName] = useState(false)
  const [isErrorDescription, setIsErrorDescription] = useState(false)


  const validateGroup = () => {
    let validation = true
    if (!name || Object.keys(name).length === 0 || langs.filter(lang => !name[lang] || name[lang] === '').length > 0) {
      setIsErrorName(true)
      validation = false
    } else setIsErrorName(false)

    if (!description || Object.keys(description).length === 0 || langs.filter(lang => !description[lang] || description[lang] === '').length > 0) {
      setIsErrorDescription(true)
      validation = false
    } else setIsErrorDescription(false)

    return validation
  }


  const handleClick = () => {
    if (validateGroup()) {
      let newPermissions = [...permissions]
      let newRole = {
        name: name,
        description: description,
        permission: newPermissions
      }
      if (props.tag === 'UPDATE') {
        props.handleUpdateRole(newRole)
      }
      else {
        props.handleCreateRole(newRole)
      }
    } else
      props.snackForGroup("error", t('messages.error.common.validation'))
  }

  const handleChangeLangInput = (value, lang, input, set) => {
    let newInput = { ...input }
    newInput[lang] = value
    set(newInput)
  }

  const handleSpreadLangInput = (event, value, input, set) => {
    if (value !== undefined && value !== '') {
      let newInput = { ...input }
      langs.map(l => (newInput[l] === '' || newInput[l] === undefined || event.type === 'dblclick') ? newInput[l] = value : '')
      set(newInput)
    }
  }

  const handleChangePermission = (event, permission) => {
    let newPermissions = [...permissions]
    let index = -1

    for (let i in newPermissions) {
      if (_.isEqual(newPermissions[i], permission)) {
        index = i
      }
    }
    if (index !== -1) {
      newPermissions.splice(index, 1)
    }
    else {
      if (permission.method === 'administration') {
        let repositoryFind = {
          type: 'application',
          controller: 'repository',
          method: 'find'
        }
        let repositoriesFind = newPermissions.find(perm => _.isEqual(perm, repositoryFind))
        if (!repositoriesFind) {
          newPermissions.push(repositoryFind)
        }
        let organizationFind = {
          type: 'application',
          controller: 'organization',
          method: 'find'
        }
        let organizationsFind = newPermissions.find(perm => _.isEqual(perm, organizationFind))
        if (!organizationsFind) {
          newPermissions.push(organizationFind)
        }
      }
      if (permission.method === 'requestManagement') {
        let requestRepositoryFind = {
          type: 'application',
          controller: 'repository',
          method: 'find'
        }
        let requestRepositoriesFind = newPermissions.find(perm => _.isEqual(perm, requestRepositoryFind))
        if (!requestRepositoriesFind) {
          newPermissions.push(requestRepositoryFind)
        }
        let requestRepositoryCreate = {
          type: 'application',
          controller: 'repository',
          method: 'create'
        }
        let requestRepositoriesCreate = newPermissions.find(perm => _.isEqual(perm, requestRepositoryCreate))
        if (!requestRepositoriesCreate) {
          newPermissions.push(requestRepositoryCreate)
        }
        let requestOrganizationFind = {
          type: 'application',
          controller: 'organization',
          method: 'find'
        }
        let requestOrganizationsFind = newPermissions.find(perm => _.isEqual(perm, requestOrganizationFind))
        if (!requestOrganizationsFind) {
          newPermissions.push(requestOrganizationFind)
        }
        let requestOrganizationCreate = {
          type: 'application',
          controller: 'organization',
          method: 'create'
        }
        let requestOrganizationsCreate = newPermissions.find(perm => _.isEqual(perm, requestOrganizationCreate))
        if (!requestOrganizationsCreate) {
          newPermissions.push(requestOrganizationCreate)
        }
      }
      newPermissions.push(permission)
    }
    setPermissions(newPermissions)
  }

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setLangs])

  useEffect(() => {
    if (props.tag === 'UPDATE') {
      getRequest(host + '/groups/' + props.id)
        .then(res => {
          if (res.readOnly)
            window.location.replace('/#/error?msg=groups.readOnly')
          else {
            setReadOnly(false)
            setName(res.name)
            setDescription(res.description)
            setPermissions([...res.permission])
            setOldRole({ ...res })
          }
        })
      getRequest(host + '/groups/permissions/')
        .then(res => {
          let newApplicationPermissions = res.filter(permission => permission.type === 'application')
          let newUsersPermissions = res.filter(permission => permission.type === 'users-permissions')

          setApplicationPermissions(newApplicationPermissions)
          setUsersPermissions(newUsersPermissions)
        })
    }
    else {
      setReadOnly(false)
      getRequest(host + '/groups/permissions/')
        .then(res => {
          let newApplicationPermissions = res.filter(permission => permission.type === 'application')
          let newUsersPermissions = res.filter(permission => permission.type === 'users-permissions')

          setApplicationPermissions(newApplicationPermissions)
          setUsersPermissions(newUsersPermissions)
        })
    }
  }, [setPermissions, setApplicationPermissions, setUsersPermissions])

  return (
    !readOnly &&
    <>
      <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
        {props.tag === 'UPDATE' ? (applicationPermissions && usersPermissions) &&
          <Grid container>
            {langs && langs.map((lang, index) =>
              <TextFieldCommon
                title={t('labels.groups.name') + " <sup> " + lang + "</sup>"}
                id={"name-" + lang}
                key={"name-" + lang}
                value={name[lang]}
                required={true}
                isOpen={isErrorName}
                message={t('messages.error.groups.name')}
                label={t('common.labels.required')}
                type="text"
                fullWidth={true}
                handleChangeInput={(value) => handleChangeLangInput(value, lang, name, setName)}
                handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, name, setName)}
                renderIcon={true}
              />
            )}
            {langs && langs.map((lang, index) =>
              <TextFieldCommon
                title={t('labels.groups.description') + " <sup>" + lang + "</sup>"}
                id={"description-" + lang}
                key={"description-" + lang}
                required={true}
                value={description[lang]}
                isOpen={isErrorDescription}
                message={t('messages.error.groups.description')}
                label={t('common.labels.required')}
                type="text"
                fullWidth={true}
                handleChangeInput={(value) => handleChangeLangInput(value, lang, description, setDescription)}
                handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, description, setDescription)}
                renderIcon={true}
              />
            )}

            <Permissions
              tag="application"
              permissions={applicationPermissions}
              selectedPermissions={permissions}
              handleChangeCheckbox={handleChangePermission}
            />
            <Permissions
              tag="users-permissions"
              permissions={usersPermissions}
              selectedPermissions={permissions}
              handleChangeCheckbox={handleChangePermission}
            />
          </Grid>
          : (applicationPermissions && usersPermissions) &&
          <Grid container spacing={3} >
            {langs && langs.map((lang, index) =>
              <TextFieldCommon
                title={t('labels.groups.name') + " <sup>" + lang + "</sup>"}
                id={"name-" + lang}
                value={name[lang]}
                required={true}
                isOpen={isErrorName}
                message={t('messages.error.groups.name')}
                label={t('common.labels.required')}
                type="text"
                fullWidth={true}
                handleChangeInput={(value) => handleChangeLangInput(value, lang, name, setName)}
                handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, name, setName)}
                renderIcon={true}
              />
            )}
            {langs && langs.map((lang, index) =>
              <TextFieldCommon
                title={t('labels.groups.description') + " <sup>" + lang + "</sup>"}
                id={"description-" + lang}
                required={true}
                value={description[lang]}
                isOpen={isErrorDescription}
                message={t('messages.error.groups.description')}
                label={t('common.labels.required')}
                type="text"
                fullWidth={true}
                handleChangeInput={(value) => handleChangeLangInput(value, lang, description, setDescription)}
                handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, description, setDescription)}
                renderIcon={true}
              />
            )}
            <Permissions
              tag="application"
              permissions={applicationPermissions}
              selectedPermissions={permissions}
              handleChangeCheckbox={handleChangePermission}
            />
            <Permissions
              tag="users-permissions"
              permissions={usersPermissions}
              selectedPermissions={permissions}
              handleChangeCheckbox={handleChangePermission}
            />
          </Grid>
        }
      </Card>
      <Grid container className="form__button--justify-left">
        <SubmitButtonCommon
          id="group-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={handleClick} />
        <SubmitButtonCommon
          id="group-save-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          goBack />
      </Grid>
    </>
  )

}
