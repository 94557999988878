import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import WarningIcon from '@material-ui/icons/Warning';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'


import '@styles/frontOffice/Home.css'


export default function Home() {
  const [t] = useTranslation("common")
  const [msg, setMsg] = useState(null)

  useEffect(() => {
    if (window.location.href.includes('?msg=')) {
      setMsg('messages.error.' + window.location.href.replace(/.*?msg=/, ''))
    } else {
      setMsg('titles.error.message')
    }
  }, [msg])

  return (
    <div className="about__container public__container--padding--bottom">
      <div className="content--card">
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { name: "error" }
        ]} />
        <Grid container alignItems="center" justify="center">
          <Grid className='error__justify--center' item xs={12} sm={2} md={2}>
            <WarningIcon className="home__warning--icon-size" />
          </Grid>
          <Grid item xs={12} sm={8} md={8} >
            <Typography variant="h5" component="p" className="">{t("titles.error.title")}</Typography>
            <Typography variant="body1" color="textSecondary" component="p" className="">{t(msg)}</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
