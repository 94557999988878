import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { CardHeader, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

import { UserContext } from '@context/userContext';

import adminAccess from '@utils/comparators/AdminAccess'

import TableCommon from '@components/Common/Table';

import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"
import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

const qs = require('qs')

export default function Statistics() {
  const location = useLocation()
  const [t] = useTranslation('common')
  let history = useHistory()
  const [user] = useContext(UserContext)

  const [headers] = useState([
    {
      name: t('labels.controlledVocabulary.value'),
      value: "value",
      sort: true
    },
    {
      name: t('labels.statistics.type.title'),
      value: "type",
      translation: "titles.statistics."
    },
    {
      name: t('labels.controlledVocabulary.labelWOutLang'),
      value: "i18n",
      sort: true
    }
  ])

  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { name: "statistics" },
      ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-statistics-title" component="h2" className="admin__card--title">{t('titles.statistics.title')}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {(user && adminAccess('controlled-vocabulary', 'create', user.group.permission)) && <Tooltip title={t('tooltip.configurations.statistics')}>
            <Button id="backoffice-statistics-add-button" className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/statistics/new')}
              startIcon={
                <AddIcon className="admin__icon--create" />
              }
              role="adminCards"
            >
              {t("common.buttons.create")}
            </Button>
          </Tooltip>
          }
        </Grid>
      </Grid>

      <TableCommon
        key='all'
        headers={headers}
        backendPath="/statistics"
        frontendPath="/statistics"
        sort="value"
        controller="statistic"
        hasActions={true}
      />

    </div>
  );
}
