import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useHistory, useLocation } from "react-router-dom";
import ErrorIcon from '@material-ui/icons/Error';
import '@styles/Common/Tab.css'


const qs = require('qs')

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box component="div" p={5}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function TabCommon(props) {
  const history = useHistory()
  const location = useLocation()
  const query = qs.parse(location.search.replace(/\?/, ''))
  const [value, setValue] = useState(query.index ? Number(query.index) : 0);
  const [isSimpleTab] = useState(props.isSimpleTab || false)

  const handleChange = (event, newValue) => {
    let newPath = "";
    setValue(newValue);
    if (!isSimpleTab) {
      if (location.search && !query.index) {
        newPath = location.pathname + location.search + '&index=' + newValue
      }
      else {
        if (location.search && query.index) {
          let path = location.pathname + location.search
          newPath = path.replace(/index=.*/, 'index=' + newValue)
        }
        else {
          newPath = location.pathname + '?index=' + newValue
        }
      }

      if (props.editor) {
        newPath = newPath.replace(/disable=false/, 'disable=true')
      }

      history.push(newPath)
    }
  };

  useEffect(() => {
    if (query.index) {
      if (Number(query.index) !== value) {
        setValue(Number(query.index))
      }
    }
    else {
      setValue(0)
    }
  }, [query.index])

  return (
    <>
      <AppBar elevation={0} position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered={!props.scroll ? true : false}
          variant={props.scroll ? "scrollable" : "fullWidth"}
        >
          {props.headers.map((header, index) => {
            return <Tab id={"tab-" + index} className={props.error && props.error[index] ? "tab__error" : ""} label={header} key={index} icon={props.error && props.error[index] && <ErrorIcon id={"tab-error-" + index} className='tab__error--icon' />} />
          })}
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={value}
        onChangeIndex={(index, event) => handleChange(event, index)}
      >
        {props.components.map((component, index) => {
          return (
            <TabPanel value={value} key={index} index={index} >
              {component}
            </TabPanel>
          )
        })
        }
      </SwipeableViews>
    </>
  );
}