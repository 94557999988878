import React from 'react'
import { useHistory } from 'react-router-dom';
import RepoTypeMetadataEdit from '@components/Configuration/RepoTypeMetadata/RepoTypeMetadataEdit'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
const qs = require('qs')

export default function EditRepoTypeMetadata() {
  let params = qs.parse(useHistory().location.search.replace(/\?/, ''))
  
  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/repoTypeMetadata", name: "repoTypeMetadata" },
        { href: "/#/admin/repoTypeMetadata/" + useHistory().location.pathname.split('update/')[1], name: "repoTypeMetadata"},
        params.key ? {name: "repoTypeMetadata_update"} : {name: "repoTypeMetadata_new"} 
      ]} />
      <RepoTypeMetadataEdit id={params.key}/>
    </div>
  )
}