import React from 'react'
import { Card, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { host } from '@utils/config'

import TextFieldCommon from '@components/Common/TextField';
import AutoCompleteCommon from '@components/Common/AutoComplete';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import SelectorCommon from '@components/Common/Selector';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function UpdateUserForm(props) {
    const [t, i18n] = useTranslation('common')

    const handleReset = () => {
        props.handleReset()
    }

    return (
        <>
            <Typography component="h2" className="admin__card--title">
                {
                    props.tag === 'CHANGE_PASSWORD'
                        ? t('titles.users.changePassword')
                        : t('titles.users.update.user')
                }
            </Typography>
            <div >
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.user.profile')}</Typography>
            </div>
            <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
                <Grid container>
                    {props.tag !== 'CHANGE_PASSWORD' ?
                        <>
                            <TextFieldCommon
                                className="width__33"
                                title={t('labels.users.firstName')}
                                id="firstName"
                                required={true}
                                isOpen={props.isFirstName}
                                message={t('messages.error.users.firstName')}
                                label={t('common.labels.required')}
                                type="text"
                                value={props.firstName}
                                fullWidth={true}
                                handleChangeInput={props.setFirstName}
                            />
                            <TextFieldCommon
                                className="width__33"
                                title={t('labels.users.lastName')}
                                id="lastName"
                                required={true}
                                isOpen={props.isLastName}
                                message={t('messages.error.users.lastName')}
                                label={t('common.labels.required')}
                                type="text"
                                value={props.lastName}
                                fullWidth={true}
                                handleChangeInput={props.setLastName}
                            />
                            <AutoCompleteCommon
                                title={t('titles.organizations.organization')}
                                id="organization"
                                tag="autocomplete-organization"
                                vc={true}
                                path={host + "/organizations?_sort=name:ASC"}
                                required={true}
                                isOpen={props.isOrganization}
                                message={t('messages.error.users.organization')}
                                language={i18n.language}
                                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                                selectedValue={props.organization}
                                handleChange={props.setOrganization}
                            />
                            <AutoCompleteCommon
                                title={t('labels.users.job')}
                                id="job"
                                tag="autocomplete-checkbox"
                                vc={true}
                                path={host + "/controlled-vocabularies?field=job"}
                                required={false}
                                optional={true}
                                isOpen={props.isJob}
                                message={t('messages.error.users.job')}
                                selectedValue={props.selectedJob}
                                handleChange={props.handleChangeJobs}
                                language={i18n.language}
                                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                            />
                            <AutoCompleteCommon
                                title={t('labels.users.subject')}
                                id="subject"
                                tag="autocomplete-checkbox"
                                vc={true}
                                path={host + "/controlled-vocabularies?field=subject"}
                                required={false}
                                optional={true}
                                isOpen={props.isSubject}
                                selectedValue={props.selectedSubject}
                                handleChange={props.handleChangeSubject}
                                language={i18n.language}
                                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                            />
                            <Grid container className="textField__container">
                                <Grid item xs={12} md={12}>
                                    <Typography id="subjectNotifications-lable" className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                        <div dangerouslySetInnerHTML={{ __html: t('labels.users.emailNotifications') }} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        className="checkbox__text"
                                        label={t('labels.users.subjectNotifications')}
                                        control={
                                            <Checkbox
                                                id="subjectNotifications-input"
                                                checked={props.subjectNotifications}
                                                onChange={(event) => props.setSubjectNotifications(event.target.checked)}
                                                name="subjectNotifications"
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {props.cienciaid &&
                                <TextFieldCommon
                                    className="width__33"
                                    title={t('labels.users.scienceID')}
                                    id="cienciaid"
                                    type="text"
                                    disabled
                                    value={props.cienciaid}
                                    fullWidth={true}
                                    handleChangeInput={props.setCienciaId}
                                />}
                            <TextFieldCommon
                                className="width__33"
                                title={t('labels.users.phone')}
                                id="phone"
                                type="text"
                                optional={true}
                                fullWidth={true}
                                value={props.phone}
                                handleChangeInput={props.setPhone}
                            />
                            <SelectorCommon
                                className="width__50"
                                title={t('labels.users.language')}
                                id="lang"
                                tag="autocomplete-simple"
                                label={t('common.labels.required')}
                                required={true}
                                message={t('messages.error.users.language')}
                                selectedValue={props.selectedLanguage}
                                handleChangeAutocomplete={props.setSelectedLanguage}
                                dataValues={props.languages}
                            />
                        </>
                        :
                        <>
                            <TextFieldCommon
                                className="width__33"
                                title={t('labels.users.oldPassword')}
                                id="oldPassword"
                                required={true}
                                label={t('common.labels.required')}
                                type="password"
                                renderIcon={true}
                                showPassword={props.showOldPassword}
                                handleClickShowPassword={props.handleClickShowOldPassword}
                                handleMouseDownPassword={props.handleMouseDownPassword}
                                fullWidth={true}
                                handleChangeInput={props.setOldPassword}
                            />
                            <TextFieldCommon
                                className="width__33"
                                title={t('labels.users.newPassword')}
                                id="password"
                                required={true}
                                isOpen={props.isPassword}
                                message={t('messages.error.users.password.error')}
                                label={t('common.labels.required')}
                                type="password"
                                renderIcon={true}
                                value={props.password}
                                showPassword={props.showPassword}
                                handleClickShowPassword={props.handleClickShowPassword}
                                handleMouseDownPassword={props.handleMouseDownPassword}
                                fullWidth={true}
                                handleChangeInput={props.setPassword}
                            />
                            <TextFieldCommon
                                className="width__33"
                                title={t('labels.users.confirmPassword')}
                                id="confirmPassword"
                                required={true}
                                isOpen={props.isConfirmPassword}
                                message={t('messages.error.users.confirmPassword')}
                                label={t('common.labels.required')}
                                type="password"
                                renderIcon={true}
                                value={props.confirmPassword}
                                password={props.password}
                                showPassword={props.showConfirmPassword}
                                handleClickShowPassword={props.handleClickShowConfirmPassword}
                                handleMouseDownPassword={props.handleMouseDownPassword}
                                fullWidth={true}
                                handleChangeInput={props.setConfirmPassword}
                            />
                        </>
                    }
                </Grid>
                <Grid container className="form__button--justify-left">
                    <SubmitButtonCommon
                        id="user-save-button"
                        className="form__button--color-success"
                        title={t('common.buttons.save')}
                        handleClick={props.handleClick} />
                    <SubmitButtonCommon
                        id="user-cancel-button"
                        className="form__button--color-error"
                        title={t('common.buttons.cancel')}
                        goBack />
                </Grid>
            </Card>
        </>
    )
}
