import React, { useState, useEffect, useContext } from 'react';
import { Typography, Tooltip, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import { UserContext } from '@context/userContext';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import TableCommon from '@components/Common/Table';

import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"
import '@styles/Common/Common.css'


export default function IndexesTable () {
  const [t, i18n] = useTranslation('common')

  const [user] = useContext(UserContext)
  const [headers, setHeaders] = useState(null)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.configurations.index.title'),
        value: "key",
        indexTitle: true,
        sort: true
      },
      {
        name: t('labels.configurations.index.show'),
        value: ["body", "show"],
        sort: true,
        indexField: true,
      }
    ])
  }, [t, setHeaders, i18n.language])

  const handleClickEdit = (event) => {

  }

  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { name: "indexes" }
      ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-indexes-title" component="h2" className="admin__card--title">{t('titles.configurations.index.title')}</Typography>
        </Grid>
      </Grid>
      {headers && <TableCommon
        key='all'
        headers={headers}
        backendPath="/configurations"
        frontendPath="/indexes"
        sort="createdAt"
        controller="configuration.index"
        filter={"type=INDEX"}
        hasRemoveButton={false}
        hasAddButton={false}  
      />}
    </div>
  );
}