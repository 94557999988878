import React, { useState, useContext, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import RadioCommon from '@components/Common/Radio';
import WYSIWYGeditorCommon from '@components/Common/WYSIWYGeditor';

import { host } from '@utils/config';
import getRequest from '@utils/functions/axiosRequest'
import "@styles/Common/Common.css"

const _ = require('lodash');

export default function PostForm(props) {
  const [t, i18n] = useTranslation("common")

  const [title, setTitle] = useState(props.title || {})
  const [body, setBody] = useState(props.body || {})
  const [visible, setVisible] = useState(props.visible === false ? false : props.visible === true ? true : true)
  const [slug, setSlug] = useState(props.slug || '')


  const [langs, setLangs] = useState(null)

  const [isErrorTitle, setIsErrorTitle] = useState(false)
  const [isErrorBody, setIsErrorBody] = useState(false)
  const [isErrorSlug, setIsErrorSlug] = useState(false)

  const validatePost = () => {
    let res = true
    setIsErrorTitle(false)
    for (let lang of langs) {
      if (!title[lang]) {
        res = false
        setIsErrorTitle(true)
      }
    }

    setIsErrorBody(false)
    for (let lang of langs) {
      if (!body[lang]) {
        res = false
        setIsErrorBody(true)
      }
    }
    setIsErrorSlug(false)
    if (!slug || !/^[a-z0-9]+(?:-[a-z0-9]+)*$/gm.test(slug)) {
      res = false
      setIsErrorSlug(true)
    }
    return res
  }

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setLangs])


  const handleSubmitPosts = () => {
    //TODO: UPLOAD IMAGE
    for (let doc of document.getElementsByClassName("se-code-view-enabled")) {
      doc.click()
      doc.click()
    }
    if (validatePost()) {
      let post = {
        title: { ...title },
        body: { ...body },
        slug: slug,
        visible: visible
      }
      props.submitPost(post)
    }
    else {
      props.snackForPost("error", t('messages.error.common.validation'))
    }
  }

  const handleChangeSlug = (value) => {
    let newValues = { ...slug }
    newValues = value
    setSlug(newValues)
  }

  const handleChangeTitle = (value, lang) => {
    let newValues = { ...title }
    newValues[lang] = value
    setTitle(newValues)
  }

  const handleSpreadTitle = (event, value) => {
    if (value !== undefined && value !== '') {
      let newValues = { ...title }
      langs.map(l => newValues[l] === '' || newValues[l] === undefined || event.type === 'dblclick' ? newValues[l] = value : '')
      setTitle(newValues)

    }
  }


  const handleChangeContent = (value, lang) => {
    let newValues = body
    newValues[lang] = value
    setBody(newValues)
  }

  return (
    <>
      <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
        <Grid container>
          <Grid container className="textField__container--right">
            <TextFieldCommon
              title={t('labels.post.slug')}
              id={"slug"}
              required={true}
              value={slug}
              isOpen={isErrorSlug}
              fullWidth={true}
              message={t('messages.error.post.slug')}
              label={t('common.labels.required')}
              type="text"
              handleChangeInput={(value) => handleChangeSlug(value)}
            />
            {langs && langs.map((lang, index) =>
              <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                <TextFieldCommon
                  title={t('labels.post.title') + " <sup>" + lang + "</sup>"}
                  id={"title" + lang}
                  required={true}
                  value={title[lang]}
                  isOpen={isErrorTitle}
                  message={t('messages.error.post.title')}
                  label={t('common.labels.required')}
                  type="text"
                  fullWidth={true}
                  handleChangeInput={(value) => handleChangeTitle(value, lang)}
                  handleSpreadInput={(event, value) => handleSpreadTitle(event, value)}
                  renderIcon={true}
                />
              </Grid>
            )}
          </Grid>
          <Grid container className="textField__container">
            {langs && langs.map((lang, index) =>
              <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                <Grid item xs={12} md={12}>
                  <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: t('labels.post.body') + " <sup>" + lang + "</sup>" }} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <WYSIWYGeditorCommon
                    id={"content" + lang}
                    lang={i18n.language}
                    defaultValue={body[lang]}
                    handleChange={(value) => handleChangeContent(value, lang)}
                  />
                  {isErrorBody && <FormHelperText id="content-error" error>{t('messages.error.post.body')}</FormHelperText>}
                </Grid>
              </Grid>
            )}
            <RadioCommon
              title={t('labels.post.visible')}
              value={visible}
              firstLabel={t('common.labels.true')}
              firstCondition={true}
              secondLabel={t('common.labels.false')}
              secondCondition={false}
              defaultValue={visible}
              handleChange={(value) => setVisible(value === 'false' ? false : true)}
            />
          </Grid>
        </Grid>
      </Card>
      <Grid container className="form__button--justify-left">
        <SubmitButtonCommon
          id="post-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={handleSubmitPosts} />
        <SubmitButtonCommon
          id="post-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          goBack />
      </Grid>
    </>
  )
}
