import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import Metatags from '../../Metatags';
import adminAccess from '@utils/comparators/AdminAccess'


export default function PostPage(props) {
  const [t, i18n] = useTranslation('common')
  const [post, setPost] = useState(props.post)
  const [langs, setLangs] = useState(null)
  let history = useHistory()
  const [user] = useContext(UserContext)

  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setPost])

  if (post) return (
    <>
      <Metatags title={getTranslation(post.title, i18n.language, i18n.languages[i18n.languages.length - 1])} description={getTranslation(post.body, i18n.language, i18n.languages[i18n.languages.length - 1])} />
      {props.tag !== 'public' ?
        <>
          <Grid container>
            <Grid item xs={6}>
              <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{props.tag !== 'public' ? t('titles.post.post') : getTranslation(post.title, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
            </Grid>
            {props.tag !== "public" && <Grid item xs={6} className="admin__align--left">
              {user && adminAccess('post', 'update', user.group.permission) &&
                <Tooltip title={t('tooltip.post.edit')}>
                  <Button
                    id="backoffice-repositories-add-button"
                    className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/posts/update/' + id)}
                    startIcon={
                      <EditIcon className="admin__icon--create" />
                    }
                    role="adminCards"
                  >
                    {t("common.buttons.edit")}
                  </Button>
                </Tooltip>
              }
            </Grid>}
          </Grid>
          {props.tag !== "public" && <div >
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.post.show')}</Typography>
          </div>}
          <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
            <Grid container>
              <TextLable
                id={"slug"}
                title={t('labels.post.slug')}
                value={post.slug}
              />
              {langs && langs.filter(lang => post.title[lang]).map((lang, index) =>
                <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"title" + lang}
                    title={t('labels.post.title') + " <sup>" + lang + "</sup>"}
                    value={post.title[lang]}
                  />
                </Grid>
              )}
              {langs && langs.filter(lang => post.body[lang]).map((lang, index) =>
                <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"content" + lang}
                    title={t('labels.post.body') + " <sup>" + lang + "</sup>"}
                    value={post.body[lang]}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
          <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
            <SubmitButtonCommon
              id="post-back-button"
              className="form__button--color-error"
              title={t('table.confirm.backButton')}
              goBack />
          </Grid>
        </>
        :
        <>
          <Typography id="post-title" component="h1" className="index__title content--card admin__card--title admin__form--title-margin">{getTranslation(post.title, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
          <Card elevation={0} className="MuiBox-root-43 border__radius--8px content--card">
            <Grid container>
              <Typography variant="body1" color="textSecondary" component="div">
                <div id="post-content" className="indexes__info--text" dangerouslySetInnerHTML={{ __html: getTranslation(post.body, i18n.language, i18n.languages[i18n.languages.length - 1]) }} />
              </Typography>
            </Grid>
          </Card>
        </>
      }
    </>
  )

  return null
}
