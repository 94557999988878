import React, { useState, useEffect } from 'react'
import PostPage from '@components/Post/Post'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import { useTranslation } from 'react-i18next';
import getTranslation from '@utils/functions/utilities.js'

import '@styles/backOffice/Post/Posts.css'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'
export default function Post() {
  const [t, i18n] = useTranslation('common')
  const [post, setPost] = useState(null)
  let { id } = useParams()
  
  useEffect(() => {
    getRequest(host + '/posts/' + id)
        .then(res => {
          setPost(res)
    })
  },[setPost, id])

  return (
    <>
    {
      post &&
      <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin" , name: "admin"},
        { href: "/#/admin/posts" , name: "posts"},
        { name: "post" }
      ]} />
      <PostPage post={post}/>
      </div>
    }
    </>
      )
}
