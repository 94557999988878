import React, { useState, useEffect } from 'react'
import { Grid, Card, Typography } from '@material-ui/core';
import TextLable from '@components/Common/TextLable'

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import SubmitButtonCommon from '@components/Common/SubmitButton';

export default function RepoTypeMetadataView(props) {
  const [t, i18n] = useTranslation('common')
  const [config, setConfig] = useState(null)
  const [langs, setLangs] = useState(null)
  let history = useHistory()
  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/configurations/' + id)
      .then(res => setConfig(res))

    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setConfig, setLangs])
  return (
    <>
      {config && config.body && config.body.attributes.length > 0 ?
        config.body.attributes.map((attr,index) =>
        props.id === attr.id &&
        <React.Fragment key={attr.id + index}>
        <Card elevation={0} className="MuiBox-root-43">
          <Grid container>
          <TextLable
            id={'attr-'+ index + '-id'}
            title={t('labels.configurations.repoTypeMetadata.key')}
            value={attr.id.split('###')[2]}
            />
          <TextLable
            id={'attr-'+ index + '-order'}
            title={t('labels.configurations.repoTypeMetadata.order')}
            value={attr.order + 1}
          />

          {langs && langs.filter(lang => attr.i18n[lang]).map((lang, indexLang) =>
                                <Grid key={lang + indexLang} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                    <TextLable
                                        id={lang}
                                        title={t('labels.configurations.repoTypeMetadata.title') + ` <sup>${lang}</sup>`}
                                        value={attr.i18n[lang]}
                                    />
                                </Grid>
            )}
          <TextLable
            id={'attr-'+ index + '-type'}
            title={t('labels.configurations.repoTypeMetadata.format.title')}
            value={t('labels.configurations.repoTypeMetadata.format.' + attr.type)}
            />
          <TextLable
            id={'attr-'+ index + '-required'} 
            title={t('labels.configurations.repoTypeMetadata.required')}
            value={attr.required.toString()}
            />
          {attr.multiple !== undefined && <TextLable
            id={'attr-'+ index + '-multiple'} 
            title={t('labels.configurations.repoTypeMetadata.multiple')}
            value={attr.multiple.toString()}
            />}
          {attr.multilang !== undefined && <TextLable
            id={'attr-'+ index + '-multilang'} 
            title={t('labels.configurations.repoTypeMetadata.multilang')}
            value={attr.multilang.toString()}
            />}
          </Grid>
          {attr.options && attr.options.length > 0 && 
          <Typography className="textField__typography--subtitle" id="configurations-general-gravatar-title" variant="h5" component="h5" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.repoTypeMetadata.optionTitle') }} /></Typography>
          }
          {attr.options && attr.options.length > 0 && attr.options.map((opt,optIndex) => 
          attr.multilang ?
          <React.Fragment key={attr.id + '-' + index + '-' + optIndex}>
          <Card variant="outlined">
          <Grid key={attr.id + '-' + index} item xs={12} md={12} className="fields__padding--left">
           <TextLable
           id={'attr-'+ index + '-option-' + optIndex} 
           title={t('labels.configurations.repoTypeMetadata.optionValue') + ` ${optIndex}`}
           value={opt.value}
           />
           </Grid>
           <Grid container>
           {langs && langs.map((lang, indexLang) => 
            <Grid key={attr.id + lang + indexLang} item xs={12} md={6} className={indexLang % 2 === 0 ? "fields__padding--left" : "fields__padding--right"}>
             <TextLable
             id={'attr-'+ index + '-option-' + optIndex} 
             title={t('labels.configurations.repoTypeMetadata.optionLabel') + ` ${optIndex} <sup>${lang}</sup>`}
             value={opt.i18n[lang]}
             />
            </Grid>
           )}
           </Grid>
           </Card>
          </React.Fragment> 
           : <TextLable
              id={'attr-'+ index + '-option-' + optIndex} 
              title={t('labels.configurations.repoTypeMetadata.optionValue') + ` ${optIndex}`}
              value={opt.value}
            />
          )}

        </Card>
        </React.Fragment>
        )
        :
        <Card elevation={0} className="MuiBox-root-43">
          <Grid container>
          <Typography id="backoffice-repoTypeMetadata-title" variant="subtitle1" component="p" >{t('labels.configurations.repoTypeMetadata.notFound')}</Typography>
          </Grid>
        </Card>
        }
      <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
        <SubmitButtonCommon
          id="repoTypeMetadata-back-button"
          className="form__button--color-error"
          title={t('table.confirm.backButton')}
          handleClick={() => props.handleGoBack()} />
      </Grid>
    </>
  )
}
