
export const adminAcess = (controller,method,permissions) => {
  for(let permission of permissions){
    if(permission.controller === controller && permission.method === method){
      return true
    }
  }
  return false
}

export default adminAcess