import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import HelpsMenu from '@components/Help/HelpsMenu'


export default function HelpMenu() {
    const [t, i18n] = useTranslation("common")
    const [helps, setHelps] = useState(null)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        getRequest(host + '/helps')
            .then(res => {
                setHelps(res.entities)
                setLoad(true)
            })
    }, [setHelps])
    return (
        <>
            {load &&
                <div className="admin__container-bottom">
                    <div className="content--card">
                        <BreadCrumbs crumbs={[
                            { href: "/#/", name: "home" },
                            { href: "/#/admin", name: "admin" },
                            { name: "helps" }
                        ]} />
                    </div>
                    <HelpsMenu helps={helps} />
                </div>
            }
        </>
    )
}