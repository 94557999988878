import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import { UserContext } from '@context/userContext';

import TableCommon from '@components/Common/Table';

import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"
import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

const qs = require('qs')

export default function ControlledVocabularies() {
  const [t, i18n] = useTranslation('common')
  let history = useHistory()
  const location = useLocation()

  const query = qs.parse(location.search.replace(/\?/, ''))
  const [user] = useContext(UserContext)

  const [headers, setHeaders] = useState(null)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.controlledVocabulary.value'),
        value: "value",
        sort: true
      },
      {
        name: t('labels.controlledVocabulary.labelWOutLang'),
        value: "i18n",
        sort: true
      }
    ])
  }, [t, setHeaders, i18n.language, query.field])

  return (
    <div className="admin__container-bottom" >
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin", name: "admin" },
      { name: t('titles.controlledVocabulary.' + query.field), fullTitle:true }
    ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-vc-title" component="h2" className="admin__card--title">{t('titles.controlledVocabulary.' + query.field)}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {(user && user.group.permission.find(p => p.controller === "controlled-vocabulary" && p.method === "create")) &&
            <Tooltip title={t('tooltip.controlledVocabulary')}>
              <Button id="backoffice-vc-add-button" className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/controlled-vocabularies/new?field=' + query.field)}
                startIcon={
                  <AddIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.create")}
              </Button>
            </Tooltip>
          }
        </Grid>
      </Grid>
      {headers && <TableCommon
        key='all'
        headers={headers}
        backendPath="/controlled-vocabularies"
        frontendPath="/controlled-vocabularies"
        sort="value"
        controller="controlled-vocabulary"
        filter={"field=" + query.field}
      />
      }
    </div>
  );
}
