import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import { useHistory } from 'react-router-dom'

import { UserContext } from '@context/userContext';
import adminAccess from '@utils/comparators/AdminAccess'

import TableCommon from '@components/Common/Table'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'


import '@styles/backOffice/Group/Groups.css'
import '@styles/Common/Common.css'

export default function Groups() {
  const [t, i18n] = useTranslation('common')
  const [headers, setHeaders] = useState(null)
  let history = useHistory()

  const [user] = useContext(UserContext)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.groups.name'),
        value: "name",
        sort: true
      },
      {
        name: t('labels.groups.description'),
        value: "description",
        sort: true
      }
    ])
  }, [t, setHeaders, i18n.language])

  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/groups", name: "groups" }
      ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-groups-title" component="h2" className="admin__card--title">{t('titles.groups.title')}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {(user && adminAccess('group', 'create', user.group.permission)) && <Tooltip title={t('tooltip.groups.create')}>
            <Button id="backoffice-groups-add-button" className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/groups/new')}
              startIcon={
                <AddIcon className="admin__icon--create" />
              }
              role="adminCards"
            >
              {t("common.buttons.create")}
            </Button>
          </Tooltip>
          }
        </Grid>
      </Grid>
      {headers && < TableCommon
        headers={headers}
        backendPath="/groups"
        frontendPath="/groups"
        sort="name"
        hasActions={true}
        controller="group"
      />}
    </div>
  );
}
