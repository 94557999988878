import React, { useState, useEffect, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import { Tooltip, Button, Grid } from '@material-ui/core';

import { UserContext } from '@context/userContext';
import AddIcon from '@material-ui/icons/Add';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import TableCommon from '@components/Common/Table';
import adminAccess from '@utils/comparators/AdminAccess'

import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"
import '@styles/Common/Common.css'


export default function ControlledVocabularies() {
  const [t, i18n] = useTranslation('common')
  let history = useHistory()

  const [user] = useContext(UserContext)
  const [headers, setHeaders] = useState(null)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.controlledVocabulary.value'),
        value: "value",
        sort: true
      },
      {
        name: t('titles.controlledVocabulary.badge'),
        value: "badge.url",
        sort: false
      },
      {
        name: t('labels.controlledVocabulary.labelWOutLang'),
        value: "i18n",
        sort: true
      }
    ])
  }, [t, setHeaders, i18n.language])
  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { name: "badges" }
      ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-badge-title" component="h2" className="admin__card--title">{t('titles.controlledVocabulary.badges')}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {(user && adminAccess('controlled-vocabulary', 'create', user.group.permission)) &&
            <Tooltip title={t('tooltip.configurations.badges')}>
              <Button id="backoffice-users-add-button" className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/badges/new')}
                startIcon={
                  <AddIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.create")}
              </Button>
            </Tooltip>
          }
        </Grid>
      </Grid>
      {headers && <TableCommon
        key='all'
        headers={headers}
        backendPath="/controlled-vocabularies"
        frontendPath="/badges"
        sort="value"
        controller="controlled-vocabulary"
        filter={"field=badge"}
      />}
    </div>
  );
}
