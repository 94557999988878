import React from 'react'
import keep from '@utils/assets/KEEP.svg'
import brandit from '@utils/assets/BRANDIT.svg'
import compete from '@utils/assets/COMPETE.svg'
import portugal from '@utils/assets/PORTUGAL_2020.svg'
import uniao from '@utils/assets/UNIAO_EUROPEIA.svg'
import republica from '@utils/assets/REPUBLICA_PORTUGUESA.svg'
import fct from '@utils/assets/FCT.svg'
import nova from '@utils/assets/UNIVERSIDADE_NOVA.svg'

export default function Footer() {

  return (
    <footer className="footer">
      <div className="footer__left">
        <div className="footer__block--margin">
          <a href="https://www.compete2020.gov.pt/" target="_blank" rel="noopener noreferrer"><img src={compete} alt={compete} className="footer__logos"></img></a>
          <a href="https://www.portugal2020.pt/" target="_blank" rel="noopener noreferrer"><img src={portugal} alt={portugal} className="footer__logos"></img></a>
          <a href="https://ec.europa.eu/info/index_pt" target="_blank" rel="noopener noreferrer"><img src={uniao} alt={uniao} className="footer__logos"></img></a>
        </div>
        <div>
          <a href="https://www.portugal.gov.pt/pt/gc21/area-de-governo/cultura" target="_blank" rel="noopener noreferrer"><img src={republica} alt={republica} className="footer__logos"></img></a>
          <a href="https://www.fct.pt/" target="_blank" rel="noopener noreferrer"><img src={fct} alt={fct} className="footer__logos"></img></a>
          <a href="https://www.unl.pt/" target="_blank" rel="noopener noreferrer"><img src={nova} alt={nova} className="footer__logos"></img></a>
        </div>
      </div>
      <div className="footer__right">
        <a href="https://www.keep.pt/" target="_blank" rel="noopener noreferrer"><img src={keep} alt={keep} className="footer__logos"></img></a>
        <a href="https://www.brandit.pt/" target="_blank" rel="noopener noreferrer"><img src={brandit} alt={brandit} className="footer__logos"></img></a>
      </div>
    </footer>
  )
}
