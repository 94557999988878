import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';


import { useTranslation } from 'react-i18next';
import SubmitButtonCommon from '@components/Common/SubmitButton';

import { host } from '@utils/config';
import getRequest from '@utils/functions/axiosRequest'
import "@styles/Common/Common.css"

const _ = require('lodash');

export default function IndexForm(props) {
  const [t, i18n] = useTranslation("common")

  const [body, setBody] = useState(props.body || {})
  const [langs, setLangs] = useState(null)

  const [isErrorBody, setIsErrorBody] = useState(false)


  const validateIndex = () => {
    let res = true
    setIsErrorBody(false)
    if (body.show && !body.fields.includes(body.show) && body.show !== 'none') {
      res = false
      setIsErrorBody(true)
    }
    return res
  }

  const handleChange = (value) => {
    let newBody = { ...body }
    if (value && value.includes(t('labels.configurations.index.add'))) {
      value = value.split(t('labels.configurations.index.add') + ' ')[1].replace(/\"/g, '')
      newBody['show'] = value
      newBody['fields'].push(value)
    }
    else
      newBody['show'] = value !== null ? value : ""
    setBody(newBody)
  }

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setLangs])


  const handleSubmitIndexes = () => {
    //TODO: UPLOAD IMAGE
    for (let doc of document.getElementsByClassName("se-code-view-enabled")) {
      doc.click()
      doc.click()
    }
    if (validateIndex()) {
      let index = {
        body: { ...body },
      }
      props.submitIndex(index)
    }
    else {
      props.snackForIndex("error", t('messages.error.common.validation'))
    }
  }
  return (
    <>
      <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
        <Grid container>
          <Grid item xs={12} md={12} >
            <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
              {t('labels.configurations.index.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} >
            <TextField disabled value={t('titles.controlledVocabulary.' + props.id.split('###')[1])} variant="outlined" />
          </Grid>
          <Grid item xs={12} md={12} >
            <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
              {t('labels.configurations.index.show')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} >
            <Autocomplete
              key={"additional-field-" + i18n.language}
              id="additional-field"
              disableClearable
              options={[...body.fields]}
              value={body.show}
              getOptionLabel={(option) => t('labels.configurations.index.' + option)}
              onChange={(event, value) => handleChange(value)}
              renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
            />
          </Grid>
          {isErrorBody &&
            <FormHelperText id="additional-field-error" error>{t("messages.error.configurations.indexes.field")}</FormHelperText>
          }
        </Grid>
      </Card>
      <Grid container className="form__button--justify-left">
        <SubmitButtonCommon
          id="index-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={handleSubmitIndexes} />
        <SubmitButtonCommon
          id="index-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          goBack />
      </Grid>
    </>
  )
}