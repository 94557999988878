
import React from "react";
import {MapContext, osm} from './Map'
import { useContext, useEffect } from "react";
import OLTileLayer from "ol/layer/Tile";
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {Style, Icon} from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';

export const Layers = ({ children }) => {
    return <div>{children}</div>;
  };

  
  
export const TileLayer = ({ zIndex = 0, center = '' }) => {
    const { map } = useContext(MapContext); 
    useEffect(() => {
      if (!map) return;
      
      
      
      let tileLayer = new OLTileLayer({
        source: osm(),
        zIndex,
      });
      const iconFeature = new Feature({
        geometry: new Point(fromLonLat(center)),
        name: 'Keep Solutions',
      });
      let iconLayer = new VectorLayer({
        source: new VectorSource({
          features: [iconFeature]
        }),
        style: new Style({
          image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: 'https://openlayers.org/en/latest/examples/data/icon.png'
          })
        })
      })


      map.addLayer(tileLayer);
      map.addLayer(iconLayer);
      

      tileLayer.setZIndex(zIndex);
      
      return () => {
        if (map) {
          map.removeLayer(tileLayer);
        }
      };
    }, [map]);
    return null;
  };
