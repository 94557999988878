import React from 'react'

import UserPage from '@components/User/User'

import '@styles/backOffice/User/Users.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function User() {
  return (
    <div className="admin__container-bottom">
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/users", name: "users" },
        { name: "user" },
      ]} />
      <UserPage />
    </div>
  )
}
