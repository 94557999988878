import React, { useEffect, useState } from 'react'
import { Card, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'

import '@styles/frontOffice/Home.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import getTranslation from '@utils/functions/utilities.js'

export default function CookiesPolicy() {
    const [t, i18n] = useTranslation("common")
    const [cookiesPolicy, setCookiesPolicy] = useState(null)

    useEffect(() => {
        getRequest(host + '/configurations/public?type=COOKIESPOLICY')
            .then(res => {
                setCookiesPolicy(res[0])
            })
    }, [setCookiesPolicy])

    return (
        <div className="about__container public__container--padding--bottom">
            <div className="content--card">
                <BreadCrumbs crumbs={[
                    { href: "/#/", name: "home" },
                    { name: "cookiePolicy" }
                ]} />
            </div>
            {(cookiesPolicy) &&
                <>
                    <Typography id="about-title" component="h1" className="index__title content--card admin__card--title admin__form--title-margin">{t('cookie.title')}</Typography>
                    <Card elevation={0} className="MuiBox-root-43 border__radius--8px content--card margin__bottom">
                        <div id="about-content" className="indexes__info--text" dangerouslySetInnerHTML={{ __html: getTranslation(cookiesPolicy.body, i18n.language, i18n.languages[i18n.languages.length - 1]) }} >
                        </div>
                    </Card>
                </>
            }
        </div>
    )
}