import React, { useState, useEffect } from 'react'

import NewsPage from '@components/NewsItem/NewsItem'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import getTranslation from '@utils/functions/utilities.js'
import { host } from '@utils/config'
import { useTranslation } from 'react-i18next';

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewsItem() {
  const [t, i18n] = useTranslation('common')
  const [news, setNews] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/news-items/' + id)
      .then(res => {
        setNews(res)
      })
  }, [id])
  return (
    <>
    {news && 
    <div className="about__container public__container--padding-bottom" >
        <div className="content--card">
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { name: "news-item"},
          { name: `${getTranslation(news.title, i18n.language, i18n.languages[i18n.languages.length - 1])}`, fullTitle: true }
        ]} />
      </div>
      <NewsPage tag="public" news={news}/>
    </div>
    }
    </>
  )
}
