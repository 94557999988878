import React, { useContext, useState, useEffect } from 'react'
import { Typography, Grid, Tooltip } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"

import SubmitButtonCommon from '@components/Common/SubmitButton';
import TableCommon from '@components/Common/Table'
import HighlightForm from '@components/Highlight/HighlightForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import getTranslation from '@utils/functions/utilities.js'
import getRequest from '@utils/functions/axiosRequest'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';


const qs = require('qs')
export default function NewHighlight() {
  const params = qs.parse(useHistory().location.search.replace(/\?/, ''))
  const [t, i18n] = useTranslation("common")
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [selectedValue, setSelectedValue] = useState()
  const [repository, setRepository] = useState(null)
  const [field, setField] = useState(params.field || "repositoryType")
  const [headers, setHeaders] = useState(null)

  let history = useHistory()

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.repositories.nameTable'),
        value: "name",
        sort: true
      },
      {
        name: t('labels.repositories.repositoryType'),
        value: "repositoryType",
        sort: true
      },
      {
        name: t('labels.repositories.url'),
        value: "url",
      },
      {
        name: t('labels.repositories.software'),
        value: "software",
        sort: true
      }
    ])
    if (params.field && params.selectedValue) {
        setField(params.field)
        getRequest(host + '/controlled-vocabularies/' + params.selectedValue)
          .then(res => {
            setSelectedValue(res)
          })
      }
      else {
        setField("repositoryType")
        setSelectedValue(null)
      }


  }, [setHeaders, t, i18n.language, setSelectedValue, params.field, params.selectedValue])

  const handleChangeValue = (value) => {
    setSelectedValue(value)
    if (value) {
      history.push("/admin/highlights/new?" + qs.stringify({ ...params, _start: 0, field: field, selectedValue: value._id }))
    }
    else {
      delete params["selectedValue"]
      delete params["field"]
      history.push("/admin/highlights/new?" + qs.stringify({ ...params, _start: 0 }))
    }
  }

  const handleChangeField = (event) => {
    setField(event.target.value)
    setRepository(null)
  }
  
  const submitHighlight = async (highlight) => {
    try {
      await Axios.post(host + '/highlights', highlight)
      snackForHighlight("success", t('messages.success.highlights.create'))
      history.push("/admin/highlights")
    }
    catch {
      snackForHighlight("error", t('messages.error.highlights.create'))
    }
  }

  const snackForHighlight = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
    <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin" , name: "admin"},
        { href: "/#/admin/highlights" , name: "highlights"},
        {name: "highlights_new"}
      ]} />
      <Typography component="h2" className="admin__card--title">{t('titles.highlights.create')}</Typography>
      
      {repository ?
      <HighlightForm
        repository={repository}
        snackForHighlight={snackForHighlight}
        submitHighlight={submitHighlight}
        submitLable={t('highlights.form.submit.create')}
      />
    :
    headers && 
    <>
    <TableCommon
        key={selectedValue ? getTranslation(selectedValue.i18n,i18n.language,i18n.languages[i18n.languages.length-1]) : 'all'}
        field={field}
        selectedValue={selectedValue}
        setSelectedValue={handleChangeValue}
        handleChangeField={handleChangeField}
        headers={headers}
        backendPath="/repositories"
        frontendPath="/highlights/new"
        sort="name"
        controller="repository"
        hasRemoveButton={false}
        filter={selectedValue ? field + '=' + getTranslation(selectedValue.i18n,i18n.language,i18n.languages[i18n.languages.length-1]) : ''}
        ActionPanel={(data, index) =>
            <Tooltip placement="top" title={t('tooltip.highlights.repository')}>
            <IconButton id={"highlight-repository-icon-" + index} onClick={(event) => event.preventDefault()}>
              <EditIcon fontSize="small" />
            </IconButton>
            </Tooltip>
        }
        handleSetRepository={setRepository}
        />
    <Grid container className="form__button--justify-left">
    <SubmitButtonCommon
        id="highlight-cancel-button"
        className="form__button--color-error"
        title={t('common.buttons.cancel')}
        goBack/>
    </Grid>
    </>
    }
    </div>
  )
}
