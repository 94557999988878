import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import "@styles/Common/Common.css";

export default function WYSWYGeditor(props) {
  const ptObject = {
    toolbar: {
      default: 'Default',
      save: 'Guardar',
      font: 'Fonte',
      formats: 'Formato',
      fontSize: 'Tamanho da fonte',
      bold: 'Negrito',
      underline: 'Sublinhado',
      italic: 'Itálico',
      strike: 'Riscado',
      subscript: 'Subscript',
      superscript: 'Superscript',
      removeFormat: 'Remover Formatação',
      fontColor: 'Cor da Fonte',
      hiliteColor: 'Hilite Color',
      indent: 'Indentar',
      outdent: 'Outdent',
      align: 'Alinhar',
      alignLeft: 'Alinhar à esquerda',
      alignRight: 'Alinhar à direita',
      alignCenter: 'Centrar',
      alignJustify: 'justificar',
      list: 'lista',
      orderList: 'Lista Ordenada',
      unorderList: 'Lista não ordenada',
      horizontalRule: 'Linha Horizontal',
      hr_solid: 'solido',
      hr_dotted: 'pontilhado',
      hr_dashed: 'tracejado',
      table: 'Tabela',
      link: 'Endereço da imagem',
      image: 'Imagem',
      video: 'Vídeo',
      fullScreen: 'Ecrã inteiro',
      showBlocks: 'Mostrar blocos',
      codeView: 'Ver código',
      undo: 'Voltar atrás',
      redo: 'Redo',
      preview: 'Preview',
      print: 'print',
      tag_p: 'Paragraph',
      tag_div: 'Normal (DIV)',
      tag_h: 'Header',
      tag_blockquote: 'Quote',
      tag_pre: 'Code',
      template: 'Template'
    },
    dialogBox: {
      linkBox: {
        title: 'Inserir Link',
        url: 'URL do Link',
        text: 'Texto do Link',
        newWindowCheck: 'Abrir numa nova Janela'
      },
      imageBox: {
        title: 'Inserir Imagem',
        file: 'Selecionar Ficheiros',
        url: 'URL da Imagem',
        altText: 'Texto Alternativo'
      },
      videoBox: {
        title: 'Inserir Video',
        url: 'URL de incorporação de conteúdos multimédia, YouTube'
      },
      caption: 'Inserir descrição',
      close: 'Fechar',
      submitButton: 'Submeter',
      revertButton: 'Reverter',
      proportion: 'proporções de restrição',
      width: 'Largura',
      height: 'Altura',
      basic: 'Básico',
      left: 'Esquerda',
      right: 'Direita',
      center: 'Centro'
    },
    controller: {
      edit: 'Editar',
      remove: 'Remover',
      insertRowAbove: 'Adicionar linha acima',
      insertRowBelow: 'Adicionar linha abaixo',
      deleteRow: 'Eliminar Linha',
      insertColumnBefore: 'Adicionar coluna antes',
      insertColumnAfter: 'Adicionar coluna depois',
      deleteColumn: 'Eliminar Coluna',
      resize100: 'Redimensionar 100%',
      resize75: 'Redimensionar 75%',
      resize50: 'Redimensionar 50%',
      resize25: 'Redimensionar 25%',
      mirrorHorizontal: 'Espelho, Horizontal',
      mirrorVertical: 'Espelho, Vertical',
      rotateLeft: 'Rodar à esquerda',
      rotateRight: 'Rodar à direita',
      maxSize: 'Tamanho Maxímo',
      minSize: 'Tamanho Minímo',
      tableHeader: 'Cabeçalho da Tabela',
      mergeCells: 'Juntar células',
      splitCells: 'Separar células',
      HorizontalSplit: 'Divisão horizontal',
      VerticalSplit: 'Divisão vertical'
    }
  }

  return (
    <div className="wyswyg--padding">
      <SunEditor
        lang={props.lang === 'pt' ? ptObject : 'en'}
        defaultValue={props.defaultValue}
        id={props.id}
        onChange={props.handleChange}
        className="sun-editor"
        setOptions={{
          id: props.id + "-input",
          height: 350,
          buttonList: [
            ['blockquote', 'align', 'font', 'fontColor',
              'fontSize',
              'formatBlock',
              'hiliteColor',
              'horizontalRule',
              'lineHeight',
              'list',
              'image',
              'preview',
              'paragraphStyle',
              'table',
              'link',
              'textStyle',
              'codeView'
            ]]  // Or Array of button list, eg. [['font', 'align'], ['image']]
          // Other option
        }}
      />
    </div>
  );
}
