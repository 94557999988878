import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { FormControl, ListItemText, MenuItem, Select, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import '@styles/Common/Common.css'

export default function SelectorCommon(props) {
  const [t] = useTranslation('common')

  const handleChangeAutocomplete = (event, value) => {
    event.preventDefault()
    props.handleChangeAutocomplete(value)
  }

  const handleChangeSelect = (event) => {
    event.preventDefault()
    props.handleChangeSelect(event.target.value)
  }

  const handleClick = (event) => {
    event.preventDefault()
    props.handleClick()
  }

  const multiButtons = (addButton, removeButton, editButton) => {
    return (
      <>
        {addButton &&
          <Button id={props.id + '-add-button'} className="form__button--add" size="medium" onClick={handleClick}>
            <AddIcon className="form__icon--add" />
          </Button>
        }
        {removeButton &&
          <Button className="form__button--remove" size="medium" onClick={handleClick}>
            <RemoveIcon className="form__icon--remove" />
          </Button>
        }
        {editButton &&
          <Tooltip placement="top" title={t('tooltip.repositories.edit')}>      
            <IconButton id={'repository-edit-button-'} aria-label="arrow drop Up" size="small" onClick={handleClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
      </>
    )
  }

  return (
    <Grid container className="textField__container">
      <Grid item xs={12} md={12}>
        <Typography id={props.id + '-lable'} className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
          {props.title + (props.optional ? " (" + t('labels.common.optional') + ")" : props.required ? " *" : "")}
        </Typography>
      </Grid>
      <Grid item xs={10} md={12}>
        <div>
          {props.tag === 'autocomplete-simple' &&
            <div className="form__flex--multiple">
              <div className="selectComponent--input">
                <Autocomplete
                  className={props.className + " margin__top--9px"}
                  id={props.id + '-input'}
                  name={props.id}
                  popupIcon={<ExpandMoreIcon />}
                  options={props.dataValues}
                  value={props.selectedValue}
                  disabled={props.disable}
                  onChange={(event, value) => handleChangeAutocomplete(event, value)}
                  getOptionLabel={(selected) => selected.name}
                  renderInput={(params) => <TextField {...params}
                    size="small" variant="outlined" />}
                />
              </div>
              {multiButtons(props.addButton, props.removeButton, props.editButton)}
            </div>
          }
          {props.tag === 'autocomplete-text' &&
            <div className="form__flex--multiple">
              <div className="selectComponent--input">
                <Autocomplete
                  className={props.className + " margin__top--9px"}
                  id={props.id + '-input'}
                  name={props.id}
                  popupIcon={<ExpandMoreIcon />}
                  options={props.dataValues}
                  value={props.selectedValue}
                  disabled={props.disable}
                  onChange={(event, value) => handleChangeAutocomplete(event, value)}
                  getOptionLabel={(selected) => props.id === "statistics" ? t('controlledVocabulary.statistics.' + selected) : selected}
                  renderInput={(params) => <TextField {...params}
                    size="small" variant="outlined" />}
                />
              </div>
              {multiButtons(props.addButton, props.removeButton)}
            </div>
          }
          {props.tag === 'simple-value' &&
            <div className="form__flex--multiple">
              <FormControl className="selectComponent--input">
                <Select
                  className={props.className + " margin__top--9px"}
                  id={props.id + '-input'}
                  name={props.id}
                  IconComponent={ExpandMoreIcon}
                  classes={{
                    icon: "arrowicon__color"
                  }}
                  required={props.required && props.required}
                  value={props.selectedValue}
                  onChange={handleChangeSelect}
                  input={<OutlinedInput margin="dense" />}
                  label={props.required ? props.label + " *" : ""}
                >
                  {props.dataValues.map((data) => {
                    return (
                      <MenuItem id={props.id} key={data._id} value={data._id}>
                        {data.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {multiButtons(props.addButton, props.removeButton, props.editButton)}
            </div>
          }
          {props.tag === 'simple-vc' &&
            <div className="form__flex--multiple">
              <FormControl className="selectComponent--input">
                <Select
                  className={props.className + " margin__top--9px"}
                  id={props.id + '-input'}
                  name={props.id}
                  IconComponent={ExpandMoreIcon}
                  classes={{
                    icon: "arrowicon__color"
                  }}
                  displayEmpty={true}
                  required={props.required && props.required}
                  value={props.selectedValue}
                  onChange={handleChangeSelect}
                  label={props.required ? props.label + " *" : ""}
                  input={<OutlinedInput margin="dense" />}
                  renderValue={(selected) => {

                    return selected ? selected.i18n[props.language] : "N/D"
                  }}
                >
                  {props.dataValues.map((data) => {
                    return (
                      <MenuItem key={data.value} value={data}>
                        <ListItemText primary={data.i18n[props.language]} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {multiButtons(props.addButton, props.removeButton, props.editButton)}
            </div>
          }
          {props.tag === 'multiple' &&
            <div className="form__flex--multiple">
              <FormControl className="selectComponent--input">
                <Select
                  className={props.className + " margin__top--9px"}
                  id={props.id + '-input'}
                  name={props.id}
                  IconComponent={ExpandMoreIcon}
                  classes={{
                    icon: "arrowicon__color"
                  }}
                  required={props.required && props.required}
                  multiple
                  label={props.required ? props.label + " *" : ""}
                  value={props.selectedValue}
                  onChange={handleChangeSelect}
                  input={<OutlinedInput margin="dense" />}
                  renderValue={(selected) => {
                    let newSelect = []
                    selected.map(value => {
                      newSelect.push(value.i18n[props.language])
                      return newSelect
                    })
                    return newSelect.join(', ')
                  }}
                >
                  {props.dataValues.map((data) => {

                    return (
                      <MenuItem id={props.id} key={data.key} value={data}>
                        <ListItemText primary={data.i18n[props.language]} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {multiButtons(props.addButton, props.removeButton, props.editButton)}
            </div>
          }
          {props.tag === 'checkbox' &&
            <div className="form__flex--multiple">
              <FormControl className="selectComponent--input">
                <Select
                  className={props.className + " margin__top--9px"}
                  id={props.id + '-input'}
                  name={props.id}
                  IconComponent={ExpandMoreIcon}
                  classes={{
                    icon: "arrowicon__color"
                  }}
                  required={props.required && props.required}
                  multiple
                  label={props.required ? props.label + " *" : ""}
                  value={props.selectedValue}
                  onChange={handleChangeSelect}
                  input={<OutlinedInput margin="dense" />}
                  renderValue={(selected) => {
                    let newSelect = []
                    selected.map(value => {
                      newSelect.push(value.i18n[props.language])
                      return newSelect
                    })
                    return newSelect.join(', ')
                  }}
                >
                  {props.dataValues.map((data) => {
                    return (
                      <MenuItem id={props.id} key={data.key} value={data}>
                        <Checkbox checked={props.selectedValue.indexOf(data) > -1} />
                        <ListItemText primary={data.i18n[props.language]} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {multiButtons(props.addButton, props.removeButton, props.editButton)}
            </div>
          }
          {
            (props.required && props.isOpen) &&
            <FormHelperText id={props.id + '-error'} error>{props.message && props.message}</FormHelperText>
          }
        </div>
      </Grid>
      {props.previous && <Grid item xs={12}>
        <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
          {t('labels.workflows.previous') + props.previous.join('; ')}
        </Typography>
      </Grid>}
    </Grid>
  )
}