import React, { useState, useEffect, useContext } from 'react'
import { Typography } from '@material-ui/core'
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Axios from 'axios'
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"
import SubmitButtonCommon from '@components/Common/SubmitButton';
import { TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';


import '@styles/frontOffice/User/User.css'
import "@styles/frontOffice/User/Login.css"
import { useTranslation } from 'react-i18next'

export default function ForgotPassword() {
    const [t] = useTranslation("common")
    const [email, setEmail] = useState('')
    const [isEmail, setIsEmail] = useState(false)
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

    const handleClick = async () => {
        const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let validate = true
        if (!email.match(emailRegExp)) {
            setIsEmail(true)
            validate = false
        }
        if (validate) {
            try {
                await Axios.post(host + "/auth/forgot-password", { email: email })
                setIsSnackBar(true)
                setTag("success")
                setMessage(t('messages.success.users.forgotPassword'))
            } catch {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.users.forgotPassword'))
            }
        }else{
            setIsSnackBar(true)
            setTag("error")
            setMessage(t('messages.error.users.forgotPassword'))
        }
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    return (
        <div className="login__container--center public__container--padding">
            <Card elevation={1} className="login__container">
                <Typography component="h2" className="login__title">{t('titles.users.resetPassword')} </Typography>
                <div className="login__textfield--padding login__textfield--margin-top">
                    <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.user.forgotPassword')}</Typography>
                    <TextField
                        size="small"
                        variant="outlined"
                        label={t('labels.users.email')}
                        margin="dense"
                        fullWidth
                        id="email"
                        type="email"
                        onChange={handleChangeEmail}
                        aria-describedby="component-error-email"
                    />
                    {isEmail && <FormHelperText error>{t('messages.error.users.email')}</FormHelperText>}

                    <Grid container className="form__button--justify-left login__textfield--margin-bottom">
                        <SubmitButtonCommon
                            className="form__button--color-success"
                            title={t('common.buttons.reset')}
                            handleClick={handleClick} />
                        <SubmitButtonCommon
                            className="form__button--color-error"
                            title={t('common.buttons.cancel')}
                            goBack />
                    </Grid>
                </div>
            </Card >
        </div >
    )

}