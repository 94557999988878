import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import Metatags from '../../Metatags';
import adminAccess from '@utils/comparators/AdminAccess'


export default function HelpPage(props) {
    const [t, i18n] = useTranslation('common')
    const [help, setHelp] = useState(props.help)
    const [langs, setLangs] = useState(null)
    let history = useHistory()
    const [user] = useContext(UserContext)

    let { id } = useParams()

    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
            })
    }, [setHelp])

    if (help) return (
        <>
            <Metatags title={getTranslation(help.title, i18n.language, i18n.languages[i18n.languages.length - 1])} description={getTranslation(help.body, i18n.language, i18n.languages[i18n.languages.length - 1])} />
            <>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography id="backoffice-help-title" component="h2" className="admin__card--title">{props.tag !== 'public' ? t('titles.help.help') : getTranslation(help.title, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
                    </Grid>
                    <Grid item xs={6} className="admin__align--left">
                        {user && adminAccess('help', 'update', user.group.permission) &&
                            <Tooltip title={t('tooltip.help.edit')}>
                                <Button
                                    id="backoffice-help-add-button"
                                    className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/helps/update/' + id)}
                                    startIcon={
                                        <EditIcon className="admin__icon--create" />
                                    }
                                    role="adminCards"
                                >
                                    {t("common.buttons.edit")}
                                </Button>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
                <div>
                    <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.help.show')}</Typography>
                </div>
                <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
                    <Grid container>
                        <TextLable
                            id={"order"}
                            title={t('labels.help.order')}
                            value={help.order}
                        />
                        {langs && langs.filter(lang => help.title[lang]).map((lang, index) =>
                            <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                <TextLable
                                    id={"title" + lang}
                                    title={t('labels.help.title') + " <sup>" + lang + "</sup>"}
                                    value={help.title[lang]}
                                />
                            </Grid>
                        )}
                        {langs && langs.filter(lang => help.body[lang]).map((lang, index) =>
                            <Grid key={lang + index} style={{ overflow: "visible", overflowWrap: "anywhere" }} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                <TextLable
                                    id={"content" + lang}
                                    title={t('labels.help.body') + " <sup>" + lang + "</sup>"}
                                    value={help.body[lang]}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Card>
                <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
                    <SubmitButtonCommon
                        id="help-back-button"
                        className="form__button--color-error"
                        title={t('table.confirm.backButton')}
                        goBack />
                </Grid>
            </>


        </>
    )

    return null
}
