import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts"

import '@styles/Common/Common.css'
import Typography from '@material-ui/core/Typography';


import '@styles/Common/Common.css'
import getTranslation from '@utils/functions/utilities.js'


export default function LineChart(props) {
  const [t, i18n] = useTranslation("common")
  const [data, setData] = useState([])
  const [title, setTitle] = useState("")
  const [series, setSeries] = useState(null)
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#5A2A27', '#546E7A', '#D4526E', '#A5978B', '#F86624', '#81D4FA', '#A300D6', '#C7F464', '#5653FE', '#8D5B4C', '#33B2DF', '#2E294E'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: '',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: null,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 260,
          height: 300
        },
        legend: {
          position: 'top',
          offsetY: 15,
        },
        grid: {
          padding: {
            bottom: 50
          }
        }
      }
    }]
  })

  useEffect(() => {
    setTitle(getTranslation(props.title, i18n.language, i18n.languages[i18n.languages.length - 1]))

    let newData = props.data.slice(0, 12).sort((a, b) => {
      if (a.key < b.key)
        return -1
      else
        return 1
    })

    setData(newData)
    let newOptions = { ...options, xaxis: { categories: newData.map(d => d.key) }, title: { text: getTranslation(props.title, i18n.language, i18n.languages[i18n.languages.length - 1]) } }
    setOptions(newOptions)
    let newSeries = []
    let lastValues = []

    for (let k in props.keys) {
      newSeries[k] = {
        name: getTranslation(props.keys[k].i18n, i18n.language, i18n.languages[i18n.languages.length - 1]), data: newData.map((d) => {
          if (d[props.keys[k].value]) {
            lastValues[k] = d[props.keys[k].value];
          } else if (!lastValues[k]) {
            lastValues[k] = null
          }
          return lastValues[k]
        })
      }
    }
    setSeries(newSeries)

  }, [setData, props.data, i18n.language])

  return (
    <>
      {
        <>
          {(series && options && options.xaxis && options.xaxis.categories) && <ReactApexChart
            options={options}
            series={series}
            type="line"
          />}
        </>
      }
    </>
  )
}