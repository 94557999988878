export default function convertBytesToMbsOrKbs(filesize) {
    var size = '';

    if (filesize >= 1048576) {
        size = filesize / 1048576 + 'MB';
    } else if (filesize >= 1024) {
        size = filesize / 1024 + ' kB';
    } else {
        size = filesize + ' B';
    }

    return size;
}