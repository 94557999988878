import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import Metatags from '../../Metatags';


export default function IndexPage(props) {
  const [t, i18n] = useTranslation('common')
  const [index, setIndex] = useState(props.index)
  const [langs, setLangs] = useState(null)
  let history = useHistory()
  const [user] = useContext(UserContext)

  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setIndex])

  if (index) return (
    <>
      <Metatags title={t("labels.configurations.index.title")} description={t('titles.controlledVocabulary.' + index.key.split('###')[1])} />
      <>
        <Grid container>
          <Grid item xs={6}>
            <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('labels.configurations.index.title')}</Typography>
          </Grid>
          <Grid item xs={6} className="admin__align--left">
            {user &&
              <Tooltip title={t('tooltip.configurations.index.edit')}>
                <Button
                  id="backoffice-configuration-index-edit-button"
                  className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/indexes/update/' + id)}
                  startIcon={
                    <EditIcon className="admin__icon--create" />
                  }
                  role="adminCards"
                >
                  {t("common.buttons.edit")}
                </Button>
              </Tooltip>
            }
          </Grid>
        </Grid>
        <div>
          <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.configuration.index.show')}</Typography>
        </div>
        <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
          <Grid container>
            <TextLable
              id="title"
              title={t('labels.configurations.index.title')}
              value={t('titles.controlledVocabulary.' + index.key.split('###')[1])}
            />
            <TextLable
              id="show"
              title={t('labels.configurations.index.show')}
              value={index.body.show === 'none' ? t('labels.configurations.index.none') : t('labels.configurations.index.' + index.body.show)}
            />
          </Grid>
        </Card>
        <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
          <SubmitButtonCommon
            id="index-back-button"
            className="form__button--color-error"
            title={t('table.confirm.backButton')}
            goBack />
        </Grid>
      </>
    </>
  )

  return null
}