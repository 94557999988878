import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { host } from '@utils/config';
import "@styles/Common/Common.css";

export default function Badge(props) {

  return (
    <>
      {props.tag === 'avatar' ?
        <Avatar className={props.className ? props.className : "controlledVocabulary__form--badge--size"} title={props.name} alt={props.name} src={host + props.url} />
        :
        <img title={props.name} alt={props.name} className="controlledVocabulary__form--badge--size" src={props.url} />
      }
    </>
  )

}
