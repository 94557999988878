import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';


export default function NotificationPage() {
  const [t] = useTranslation('common')
  const [notification, setNotification] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/notifications/' + id)
      .then(res => {
        setNotification(res)
      })
  }, [setNotification])

  return (
    <>
      <Typography id="backoffice-notification-title" component="h2" className="admin__card--title">{t('titles.notifications.notification')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.notification.show')}</Typography>
      </div>
      {notification &&
        <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
          <Grid container>
            <TextLable
              id="title"
              title={t('labels.notifications.title')}
              value={notification.title}
            />
            <TextLable
              id="action"
              title={t('labels.notifications.body.action')}
              value={notification.body.action}
            />
            <TextLable
              id="to"
              title={t('labels.notifications.to')}
              value={notification.to}
            />
            <TextLable
              id="link"
              title={t('labels.notifications.body.link')}
              value={notification.body.link}
            />
            <TextLable
              id="createdAt"
              title={t('labels.notifications.createdAt')}
              value={notification.createdAt}
            />
            <TextLable
              id="subject"
              title={t('labels.notifications.body.subject')}
              value={notification.body.subject}
            />
            {notification.body.text && <TextLable
              id="text"
              title={t('labels.notifications.body.text')}
              value={notification.body.text}
            />}
            {notification.body.html && <TextLable
              id="html"
              title={t('labels.notifications.body.html')}
              value={notification.body.html}
            />}
          </Grid>
        </Card>
      }
      <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
        <SubmitButtonCommon
          className="form__button--color-error"
          title={t('table.confirm.backButton')}
          goBack />
      </Grid>
    </>
  )
}