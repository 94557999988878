import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { SnackBarContext } from "@context/snackBarContext.js"
import { host } from '@utils/config'
import Axios from "axios"
import TableCommon from '@components/Common/Table'

import { UserContext } from '@context/userContext';

import adminAccess from '@utils/comparators/AdminAccess'

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'


export default function Helps() {
    const [t, i18n] = useTranslation("common")
    let history = useHistory()
    const [headers, setHeaders] = useState(null)
    const [key, setKey] = useState(0)
    const [user] = useContext(UserContext)
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)


    useEffect(() => {
        setHeaders([
            {
                name: t('labels.help.title'),
                value: "title",
                sort: true
            },
            {
                name: t('labels.help.order'),
                value: "order",
                sort: true
            }
        ])
    }, [setHeaders, i18n.language])

    const handleSwitchOrder = async (event, help, inc) => {
        event.stopPropagation()
        let newHelp = { ...help }
        newHelp.order = newHelp.order + inc
        await Axios.put(host + '/helps/' + newHelp._id, newHelp)
            .then(() => {
                setIsSnackBar(true)
                setTag("success")
                setMessage(t('messages.success.help.update'))
                setKey(key + 1)
            })
            .catch(() => {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.help.update'))
            })

    }

    const handleDeleteAttribute = async (event, help) => {
        event.stopPropagation()
        await Axios.delete(host + '/helps/' + help._id).then(() => {
            setIsSnackBar(true)
            setTag("success")
            setMessage(t('messages.success.help.update'))
            setKey(key + 1)
        })
            .catch(() => {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.help.update'))
            })
    }

    return (
        <div className="admin__container-bottom" >
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { href: "/#/admin/helps", name: "helps" }
            ]} />
            <Grid container>
                <Grid item xs={6}>
                    <Typography id="backoffice-cms-title" component="h2" className="admin__card--title">{t('titles.help.title')}</Typography>
                </Grid>
                <Grid item xs={6} className="admin__align--left">
                    {(user && adminAccess('help', 'create', user.group.permission)) && <Tooltip title={t('tooltip.help.create')}>
                        <Button id="cms-add-button" className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/helps/new')}
                            startIcon={
                                <AddIcon className="admin__icon--create" />
                            }
                            role="adminCards"
                        >
                            {t("common.buttons.create")}
                        </Button>
                    </Tooltip>}
                </Grid>
            </Grid>
            {headers && <TableCommon
                key={key}
                headers={headers}
                backendPath="/helps"
                frontendPath="/helps"
                sort="order"
                controller="help"
                filter={'group=admin'}
                handleSwitchOrder={(event, value, order) => handleSwitchOrder(event, value, order)}
                handleDeleteAttribute={(event, value) => handleDeleteAttribute(event, value)}
            />}
        </div>
    );
}