import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next'
import '@styles/Common/Common.css'

const _ = require('lodash');

function renderTranslation(word, t, tag) {
  switch (tag) {
    default:
      break
    case 'controller':
      return t('labels.groups.permissions.controller.' + word)
    case 'method':
      return t('labels.groups.permissions.method.' + word)
  }
}

export default function Permissions(props) {
  const [t] = useTranslation('common')
  return (
    <>
      {
        props.permissions.map((permission, index) => {
          return (
            <Grid container key={index} className="groups__checkbox--container">
              <Grid item xs={12} md={12}>
                {index === 0 &&
                  <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: renderTranslation(permission.controller, t, "controller") }} />
                  </Typography>
                }
                {(index > 0 && props.permissions[index - 1].controller !== permission.controller) &&
                  <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: renderTranslation(permission.controller, t, "controller") }} />
                  </Typography>
                }
              </Grid>
              <Grid item xs={10} md={12}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={<Checkbox id={"checkbox-"+index} checked={props.selectedPermissions.find(perm => _.isEqual(perm, permission)) ? true : false} onChange={(event) => props.handleChangeCheckbox(event, permission)} name={permission.method}></Checkbox>}
                    label={renderTranslation(permission.method, t, "method")}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )
        })
      }
    </>
  )
}