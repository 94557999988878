import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import TableCommon from '@components/Common/Table'

import { useTranslation } from 'react-i18next';

import '@styles/backOffice/Notification/Notifications.css'
import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Notifications() {
  const [t,i18n] = useTranslation("common")
  const [headers,setHeaders] = useState(null)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.notifications.title'),
        value: "title",
        sort: true
      },
      {
        name: t('labels.notifications.to'),
        value: "to",
        sort: true
      },
      {
        name: t('labels.notifications.createdAt'),
        value: "createdAt",
        sort: true,
        date: true
      }
    ])
  },[setHeaders,i18n.language])

  return (
    <div className="admin__container-bottom">
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin", name: "admin" },
      { name: "notifications" },
    ]} />
      <Typography id="backoffice-notification-title" component="h2" className="admin__card--title">{t('titles.notifications.title')}</Typography>
      {headers && <TableCommon
        headers={headers}
        backendPath="/notifications"
        frontendPath="/notifications"
        sort="createdAt"
        controller="notification"
        order="desc"
        hasActions={false}
        hasAddButton={false}
      />}
    </div>
  );
}
