
import React, { useState, useContext, useEffect } from 'react';
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Axios from 'axios';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import { SnackBarContext } from "@context/snackBarContext.js"

import TextFieldCommon from '@components/Common/TextField';
import RadioCommon from '@components/Common/Radio';
import { useTranslation } from 'react-i18next';

import SubmitButtonCommon from '@components/Common/SubmitButton';

export default function SmtpAccordion(props) {
  const [t] = useTranslation('common')
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  const [smtp, setSMTP] = useState(null)
  const [secure, setSecure] = useState('')
  const [rejectUnauthorized, setRejectUnauthorized] = useState('')
  const [requireTLS, setRequireTLS] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  
  
  
  const handleChangeisEdit = () => {
    setIsEdit(!isEdit)
    props.setDisabled(!props.disabled)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (id, value) => {
    let newSMTP = { ...smtp }
    if (id === 'provider') {
      newSMTP.body[id] = value
    }
    if (id.match(/providerOptions\..*/)) {
      let newId = id.split('.')[1]
      switch (newId) {
        default:
          newSMTP.body.providerOptions[newId] = value
          break
        case 'secure':
          setSecure(value === 'false' ? false : true)
          newSMTP.body.providerOptions[newId] = value === 'false' ? false : true
          break
        case 'rejectUnauthorized':
          setRejectUnauthorized(value === 'false' ? false : true)
          newSMTP.body.providerOptions[newId] = value === 'false' ? false : true
          break
        case 'requireTLS':
          setRequireTLS(value === 'false' ? false : true)
          newSMTP.body.providerOptions[newId] = value === 'false' ? false : true
          break
      }
    }
    if (id.match(/settings\..*/)) {
      let newId = id.split('.')[1]
      newSMTP.body.settings[newId] = value
    }
    setSMTP(newSMTP)
  }

  const handleUpdateSmtp = async () => {
    await Axios.put(host + '/configurations/' + smtp._id, smtp)
      .then(() => {
        props.setDisabled(!props.disabled)
        setIsSnackBar(true)
        setTag("success")
        setMessage(t('messages.success.configurations.update'))
      })
      .catch(() => {
        setIsSnackBar(true)
        setTag("error")
        setMessage(t('messages.error.configurations.update'))
      })
  }


  useEffect(() => {
    getRequest(host + '/configurations?type=SMTP')
    .then(res => {
      setSMTP({...res.entities[0]})
      setSecure(res.entities[0].body.providerOptions.secure)
      setRejectUnauthorized(res.entities[0].body.providerOptions.rejectUnauthorized)
      setRequireTLS(res.entities[0].body.providerOptions.requireTLS)
    })
  }, [setSMTP, setSecure, setRejectUnauthorized, setRequireTLS, isEdit])


  return (
    <>
    <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
      {smtp &&
        <>
          <TextFieldCommon
            className="width__33"
            title={t('labels.configurations.smtpObj.provider')}
            id="configurations-smtp-provider"
            required={false}
            value={smtp.body.provider}
            type="text"
            disabled={true}
            fullWidth={true}
            handleChangeInput={(value) => handleChange("provider", value)}
          />
          <TextFieldCommon
            className="width__50"
            title={t('labels.configurations.smtpObj.providerOptions.host')}
            id="configurations-smtp-host"
            required={false}
            value={smtp.body.providerOptions.host}
            type="text"
            disabled={props.disabled}
            fullWidth={true}
            handleChangeInput={(value) => handleChange("providerOptions.host", value)}
          />
          <TextFieldCommon
            className="width__10"
            title={t('labels.configurations.smtpObj.providerOptions.port')}
            id="configurations-smtp-port"
            required={false}
            value={smtp.body.providerOptions.port}
            type="text"
            disabled={props.disabled}
            fullWidth={true}
            handleChangeInput={(value) => handleChange("providerOptions.port", value)}
          />
          <RadioCommon
            title={t('labels.configurations.smtpObj.providerOptions.secure')}
            id="configurations-smtp-secure"
            value={secure}
            firstLabel={t('common.labels.true')}
            firstCondition={true}
            secondLabel={t('common.labels.false')}
            secondCondition={false}
            defaultValue={smtp.body.providerOptions.secure}
            disabled={props.disabled}
            handleChange={(value) => handleChange("providerOptions.secure", value)}
          />
          <TextFieldCommon
            className="width__33"
            title={t('labels.configurations.smtpObj.providerOptions.username')}
            value={smtp.body.providerOptions.username}
            id="configurations-smtp-username"
            type="text"
            disabled={props.disabled}
            fullWidth={true}
            handleChangeInput={(value) => handleChange("providerOptions.username", value)}
          />
          <TextFieldCommon
            className="width__33"
            title={t('labels.configurations.smtpObj.providerOptions.password')}
            value={smtp.body.providerOptions.password}
            id="configurations-smtp-password"
            type="password"
            renderIcon={true}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            disabled={props.disabled}
            fullWidth={true}
            handleChangeInput={(value) => handleChange("providerOptions.password", value)}
          />
          <RadioCommon
            title={t('labels.configurations.smtpObj.providerOptions.rejectUnauthorized')}
            value={rejectUnauthorized}
            id="configurations-smtp-rejectUnauthorized"
            defaultValue={smtp.body.providerOptions.rejectUnauthorized}
            firstLabel={t('common.labels.true')}
            firstCondition={true}
            secondLabel={t('common.labels.false')}
            secondCondition={false}
            disabled={props.disabled}
            handleChange={(value) => handleChange("providerOptions.rejectUnauthorized", value)}
          />
          <RadioCommon
            title={t('labels.configurations.smtpObj.providerOptions.requireTLS')}
            id="configurations-smtp-requireTLS"
            value={requireTLS}
            defaultValue={smtp.body.providerOptions.requireTLS}
            disabled={props.disabled}
            firstLabel={t('common.labels.true')}
            firstCondition={true}
            secondLabel={t('common.labels.false')}
            secondCondition={false}
            handleChange={(value) => handleChange("providerOptions.requireTLS", value)}
          />
          <TextFieldCommon
            className="width__10"
            title={t('labels.configurations.smtpObj.providerOptions.connectionTimeout')}
            id="configurations-smtp-connectionTimeout"
            value={smtp.body.providerOptions.connectionTimeout}
            type="text"
            disabled={props.disabled}
            fullWidth={true}
            handleChangeInput={(value) => handleChange("providerOptions.connectionTimeout", value)}
          />
          <TextFieldCommon
            className="width__50"
            title={t('labels.configurations.smtpObj.settings.from')}
            id="configurations-smtp-from"
            value={smtp.body.settings.from}
            type="text"
            disabled={props.disabled}
            fullWidth={true}
            handleChangeInput={(value) => handleChange("settings.from", value)}
          />
        </>
      }
    </Card>
    {!props.disabled && <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
        <SubmitButtonCommon
          id="configurations-smtp-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          disabled={props.disabled}
          handleClick={handleUpdateSmtp} />
        <SubmitButtonCommon
          id="configurations-smtp-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          handleClick={handleChangeisEdit} />
      </Grid>
      }
    </>
  )
}
