import React, { useState, useEffect } from 'react'
import HelpPage from '@components/Help/Help'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import { useTranslation } from 'react-i18next';


import BreadCrumbs from '@components/Common/BreadCrumbs.js'
export default function Help() {
    const [t, i18n] = useTranslation('common')
    const [help, setHelp] = useState(null)
    let { id } = useParams()

    useEffect(() => {
        getRequest(host + '/helps/' + id)
            .then(res => {
                setHelp(res)
            })
    }, [setHelp, id])

    return (
        <>
            {
                help &&
                <div className="admin__container-bottom" >
                    <BreadCrumbs crumbs={[
                        { href: "/#/", name: "home" },
                        { href: "/#/admin", name: "admin" },
                        { href: "/#/admin/helps", name: "helps" },
                        { name: "help" }
                    ]} />
                    <HelpPage help={help} />
                </div>
            }
        </>
    )
}