import React, { useState, useEffect, useContext } from 'react'
import { Typography, Card } from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import '@styles/frontOffice/User/User.css'
import { useTranslation } from 'react-i18next'

import CreateUserForm from '@components/User/CreateUserForm'

import { SnackBarContext } from "@context/snackBarContext.js"
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewUser() {
  const [t] = useTranslation('common')

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [organization, setOrganization] = useState(null)
  const [phone, setPhone] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [job, setJob] = useState(null)
  const [subject, setSubject] = useState(null)

  const [terms, setTerms] = useState(null)
  const [checkedTerms, setCheckedTerms] = useState(null)

  const [organizations, setOrganizations] = useState([])

  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [selectedJobs, setSelectedJobs] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [subjectNotifications, setSubjectNotifications] = useState(false)

  const [selectedLanguage, setSelectedLanguage] = useState(null)

  const [isEmail, setIsEmail] = useState(false)
  const [isPassword, setIsPassword] = useState(false)
  const [isConfirmPassword, setIsConfirmPassword] = useState(false)
  const [isFirstName, setIsFirstName] = useState(false)
  const [isLastName, setIsLastName] = useState(false)
  const [isOrganization, setIsOrganization] = useState(false)
  const [isJob, setIsJobs] = useState(false)
  const [isSubject, setIsSubject] = useState(false)
  const [isLanguage, setIsLanguage] = useState(false)
  const [isTerm, setIsTerm] = useState(false)
  const [langs, setLangs] = useState(null)

  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  let history = useHistory()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeJobs = (value) => {
    if (value) {
      let newJobs = [...value]
      let refactor = []
      for (let job of newJobs) {
        refactor.push({
          key: job.key,
          field: job.field,
          value: job.value,
          i18n: { ...job.i18n }
        })
      }
      setJob(refactor)
      setSelectedJobs(value)
    }
  };

  const handleChangeSubject = (value) => {
    if (value) {
      let newSubject = [...value]
      let refactor = []
      for (let subject of newSubject) {
        refactor.push({
          key: subject.key,
          field: subject.field,
          value: subject.value,
          i18n: {
            pt: subject.i18n.pt,
            en: subject.i18n.en
          }
        })
      }
      setSubject(refactor)
      setSelectedSubject(value)
    }
  };

  const handleChangeOrganization = (value) => {
    if (value) {
      setSelectedOrganization({ ...value })
      setOrganization({ ...value })
    }
  }

  const handleCreateUser = async () => {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/

    let validate = true
    if (!email.match(emailRegExp)) {
      setIsEmail(true)
      validate = false
    }
    if (!password.match(passRegExp)) {
      setIsPassword(true)
      validate = false
    }
    if (password !== confirmPassword) {
      setIsConfirmPassword(true)
      validate = false
    }
    if (firstName === '') {
      setIsFirstName(true)
      validate = false
    }
    if (lastName === '') {
      setIsLastName(true)
      validate = false
    }
    if (!job) {
      setIsJobs(true)
      validate = false
    }
    if (!selectedOrganization) {
      setIsOrganization(true)
      validate = false
    }
    if (!selectedLanguage) {
      setIsLanguage(true)
      validate = false
    }
    if (!checkedTerms) {
      setIsTerm(true)
      validate = false
    }
    if (validate) {
      let date = new Date()
      let newTerms = [...terms]
      newTerms[newTerms.length - 1]['accepted_at'] = date.toISOString()
      try {
        let newRegisterUser = {
          email: email,
          username: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          organization: organization._id,
          job: job,
          subject: subject,
          subjectNotifications: subjectNotifications,
          phone: phone,
          lang: selectedLanguage.value,
          terms: newTerms
        }

        await Axios.post(host + '/auth/local/register', newRegisterUser)
        setIsSnackBar(true)
        setTag("success")
        setMessage(t('messages.success.users.create'))
        history.push("/admin")
      }
      catch {
        setIsSnackBar(true)
        setTag("error")
        setMessage(t('messages.error.users.create'))
      }
    }
    else {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.users.register.validation'))

    }
  }

  useEffect(() => {
    getRequest(host + '/configurations/public?type=TERM&&objectType=USER')
      .then(res => {
        setTerms(res)
      })
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setTerms, setLangs])

  useEffect(() => {
    getRequest(host + '/organizations')
      .then(res => {
        setOrganizations(res.entities)
      })
  }, [setOrganizations])

  useEffect(() => {
    const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/
    if (password.match(passRegExp) && password.length >= 13)
      setIsPassword(false)
    if (password === confirmPassword)
      setIsConfirmPassword(false)
  }, [password, confirmPassword])



  return (
    <div className="admin__container-bottom">
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/users", name: "users" },
        { name: "users_new" },
      ]} />
      <Typography component="h2" className="admin__card--title">{t('titles.users.create')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.user.form')}</Typography>
      </div>
      <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
        {organizations.length > 0 && <CreateUserForm
          setEmail={setEmail}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
          setPhone={setPhone}
          showPassword={showPassword}
          showConfirmPassword={showConfirmPassword}
          setSelectedLanguage={setSelectedLanguage}
          languages={langs ? langs.map(lang => {
            return {
              name: t('common.labels.language.' + lang), value: lang
            }
          }) : []}
          subjectNotifications={subjectNotifications}
          setSubjectNotifications={setSubjectNotifications}
          selectedJobs={selectedJobs}
          selectedSubject={selectedSubject}
          selectedOrganization={selectedOrganization}
          organizations={organizations}
          terms={terms}
          checkedTerms={checkedTerms}
          setCheckedTerms={setCheckedTerms}
          isEmail={isEmail}
          isPassword={isPassword}
          isConfirmPassword={isConfirmPassword}
          isFirstName={isFirstName}
          isLastName={isLastName}
          isJob={isJob}
          isSubject={isSubject}
          isLanguage={isLanguage}
          isOrganization={isOrganization}
          isTerm={isTerm}
          password={password}
          confirmPassword={confirmPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          handleClickShowPassword={handleClickShowPassword}
          handleClickShowConfirmPassword={handleClickShowConfirmPassword}
          handleChangeJobs={handleChangeJobs}
          handleChangeSubject={handleChangeSubject}
          handleChangeOrganization={handleChangeOrganization}
          handleCreateUser={handleCreateUser}
        />}
      </Card>
    </div>
  );
}
