import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useParams } from 'react-router-dom';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'

import TextLable from '@components/Common/TextLable'
import Tab from '@components/Common/Tab/Tab'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import Terms from '@components/Configuration/Terms.js'
import adminAccess from '@utils/comparators/AdminAccess'


export default function User() {
    const [t, i18n] = useTranslation('common')
    const [user, setUser] = useState(null)
    const [admin] = useContext(UserContext)
    let history = useHistory()

    let { id } = useParams()

    useEffect(() => {
        getRequest(host + '/users/' + id)
            .then(res => {
                setUser(res)
            })
    }, [setUser])

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.users.user')}</Typography>
                </Grid>
                {adminAccess('user', 'update', admin.group.permission) &&
                    <Grid item xs={6} className="admin__align--left">
                        <Tooltip title={t('tooltip.users.edit')}>
                            <Button
                                id="backoffice-repositories-add-button"
                                className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/users/update/' + id)}
                                startIcon={
                                    <EditIcon className="admin__icon--create" />
                                }
                                role="adminCards"
                            >
                                {t("common.buttons.edit")}
                            </Button>
                        </Tooltip>
                    </Grid>}
            </Grid>
            <div >
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.user.show')}</Typography>
            </div>
            {user && <Card elevation={0} className="margin__top--32px">
                <Tab
                    headers={user.organization ? [t('titles.users.user'), t('titles.organizations.organization')] : [t('titles.users.user')]}
                    components={
                        [
                            <>
                                <TextLable
                                    id="cienciaid"
                                    title={t('labels.users.scienceID')}
                                    value={user.cienciaid}
                                />
                                <Grid container>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} className="fields__padding--right">
                                            <TextLable
                                                id="firstName"
                                                title={t('labels.users.firstName')}
                                                value={user.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="fields__padding--left">
                                            <TextLable
                                                id="lastName"
                                                title={t('labels.users.lastName')}
                                                value={user.lastName}
                                            />
                                        </Grid>
                                    </Grid>
                                    {user.organization &&
                                        <TextLable
                                            id='organization'
                                            title={t('titles.organizations.organization')}
                                            value={getTranslation(user.organization.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                                        />
                                    }
                                    <TextLable
                                        id="email"
                                        title={t('labels.users.email')}
                                        value={user.email}
                                    />
                                    <TextLable
                                        id="job"
                                        title={t('labels.users.job')}
                                        value={user.job && user.job.length > 0 ? user.job.map(job => getTranslation(job.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])) : t('common.labels.notDefined')}
                                        isArray={user.job && user.job.length > 0}
                                    />
                                    <TextLable
                                        id="subject"
                                        title={t('labels.users.subject')}
                                        value={user.subject && user.subject.length > 0 ? user.subject.map(subject => getTranslation(subject.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])) : t('common.labels.notDefined')}
                                        isArray={user.subject && user.subject.length > 0}
                                    />
                                    <TextLable
                                        id='subjectNotifications'
                                        title={t('labels.users.subjectNotifications')}
                                        value={user.subjectNotifications ? t('common.labels.true') : t('common.labels.false')}
                                    />
                                    <TextLable
                                        id="phone"
                                        title={t('labels.users.phone')}
                                        value={user.phone ? user.phone : t('common.labels.notDefined')}
                                    />
                                    <TextLable
                                        id="lang"
                                        title={t('labels.users.language')}
                                        value={t('common.labels.language.' + user.lang)}
                                    />
                                    <TextLable
                                        id="provider"
                                        title={t('labels.users.provider')}
                                        value={user.provider}
                                    />
                                    <TextLable
                                        id="group"
                                        title={t('labels.users.group')}
                                        value={getTranslation(user.group.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                                    />
                                    {user.group.name["en"] === 'domain' &&
                                        <TextLable
                                            id="domain"
                                            title={t('labels.users.domain')}
                                            value={getTranslation(user.domain[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                                        />}
                                    {user.terms && (
                                        <Terms
                                            showTerms={true}
                                            terms={user.terms}
                                            value={t('labels.users.terms')}
                                            acceptedAtLabel={t('labels.users.termsAcceptedAt')}
                                        />
                                    )
                                    }
                                </Grid>
                            </>,
                            <>
                                {
                                    user.organization &&
                                    <Grid container>
                                        <TextLable
                                            id="organization-name"
                                            title={t('labels.organizations.name')}
                                            value={getTranslation(user.organization.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                                        />
                                        <TextLable
                                            id="organization-organizationType"
                                            title={t('labels.organizations.organizationType')}
                                            value={user.organization.organizationType && user.organization.organizationType.length > 0 ? getTranslation(user.organization.organizationType[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('notDefined')}
                                        />
                                        <TextLable
                                            id="organization-url"
                                            title={t('labels.organizations.url')}
                                            value={user.organization.url}
                                            url={true}
                                        />
                                        <TextLable
                                            id="organization-email"
                                            title={t('labels.organizations.email')}
                                            value={user.organization.email}
                                            url={true}
                                            mailto={true}
                                        />
                                        <TextLable
                                            id="organization-country"
                                            title={t('labels.organizations.country')}
                                            value={user.organization.country && user.organization.country.length > 0 ? getTranslation(user.organization.country[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('notDefined')}
                                        />
                                        <TextLable
                                            id="organization-address"
                                            title={t('labels.organizations.address')}
                                            value={user.organization.address}
                                        />
                                    </Grid>
                                }
                            </>

                        ]} />
            </Card>}
            <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
                <SubmitButtonCommon
                    id="user-back-button"
                    className="form__button--color-error"
                    title={t('table.confirm.backButton')}
                    goBack />
            </Grid>
        </>
    )
}
