import React from 'react'

import ControlledVocabularyPage from '@components/ControlledVocabulary/ControlledVocabulary'

import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import '@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css'

export default function ControlledVocabulary() {
  const [t, i18n] = useTranslation('common')
  const history = useHistory()
  return (
    <div className="admin__container-bottom">
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/" + (history.location.pathname.includes('badges') ? "badges" : "controlled-vocabularies" + history.location.search ) ,name: history.location.pathname.includes('badges') ? "badges" : t('titles.controlledVocabulary.' + history.location.search.split('field=')[1]), fullTitle: history.location.pathname.includes('badges') ? false : true },
        { name: history.location.pathname.includes('badges') ? "badge" : "controlled-vocabulary" }
      ]} />
      <ControlledVocabularyPage />
    </div>
  )
}
