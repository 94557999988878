import React, { useState, useEffect } from 'react'
import FAQPage from '@components/FAQ/FAQ'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import { useTranslation } from 'react-i18next';


import BreadCrumbs from '@components/Common/BreadCrumbs.js'
export default function FAQ() {
    const [t, i18n] = useTranslation('common')
    const [faq, setFAQ] = useState(null)
    let { id } = useParams()

    useEffect(() => {
        getRequest(host + '/faqs/' + id)
            .then(res => {
                setFAQ(res)
            })
    }, [setFAQ, id])

    return (
        <>
            {
                faq &&
                <div className="admin__container-bottom" >
                    <BreadCrumbs crumbs={[
                        { href: "/#/", name: "home" },
                        { href: "/#/admin", name: "admin" },
                        { href: "/#/admin/faqs", name: "faqs" },
                        { name: "faq" }
                    ]} />
                    <FAQPage faq={faq} />
                </div>
            }
        </>
    )
}
