import React, { useState, useContext, useEffect } from 'react';
import { Card, Grid, Tooltip, IconButton } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';

import { DropzoneArea } from 'material-ui-dropzone';
import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import WYSIWYGeditorCommon from '@components/Common/WYSIWYGeditor';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { host } from '@utils/config';
import getRequest from '@utils/functions/axiosRequest'
import "@styles/Common/Common.css"

const _ = require('lodash');

export default function NewsForm(props) {
  const [t, i18n] = useTranslation("common")

  const [image, setImage] = useState(props.image || null)
  const [undo, setUndo] = useState(null)
  const [uploadImage, setUploadImage] = useState([])
  const [title, setTitle] = useState(props.title || {})
  const [description, setDescription] = useState(props.description || {})
  const [body, setBody] = useState(props.body || {})
  const [newsDate, setNewsDate] = useState(props.newsDate || null)
  const [startDate, setStartDate] = useState(props.startDate || null)
  const [endDate, setEndDate] = useState(props.endDate || null)

  const [langs, setLangs] = useState(null)
  const [uploadSize, setUploadSize] = useState(null)
  const [showText, setShowText] = useState(true)
  const [isErrorTitle, setIsErrorTitle] = useState(false)
  const [isErrorDescription, setIsErrorDescription] = useState(false)
  const [isErrorBody, setIsErrorBody] = useState(false)
  const [isErrorNewsDate, setIsErrorNewsDate] = useState(false)
  const [isErrorStartDate, setIsErrorStartDate] = useState(false)
  const [isErrorEndDate, setIsErrorEndDate] = useState(false)

  const validateNews = () => {
    let res = true
    setIsErrorTitle(false)
    setIsErrorDescription(false)
    setIsErrorBody(false)
    setIsErrorNewsDate(false)
    setIsErrorStartDate(false)
    setIsErrorEndDate(false)
    for (let lang of langs) {
      if (!title[lang]) {
        res = false
        setIsErrorTitle(true)
      }
      if (!body[lang]) {
        res = false
        setIsErrorBody(true)
      }
      if (!description[lang]) {
        res = false
        setIsErrorDescription(true)
      }
    }
    if (!newsDate) {
      res = false
      setIsErrorNewsDate(true)
    }
    if (!startDate) {
      res = false
      setIsErrorStartDate(true)
    }
    if (!endDate) {
      res = false
      setIsErrorEndDate(true)
    }

    return res
  }

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
    getRequest(host + '/configurations/public?type=UPLOADSIZE')
      .then(res => {
        setUploadSize(res[0])
      })
  }, [setLangs, setUploadSize])


  const handleSubmitNews = () => {
    for (let doc of document.getElementsByClassName("se-code-view-enabled")) {
      doc.click()
      doc.click()
    }
    if (validateNews()) {
      let news = {
        title: { ...title },
        body: { ...body },
        description: { ...description },
        newsDate: newsDate,
        startDate: startDate,
        endDate: endDate,
        newImage: uploadImage[0],
        image: image,
        ...(undo && { undo: undo })
      }
      props.submitNews(news)
    }
    else {
      props.snackForNews("error", t('messages.error.common.validation'))
    }
  }

  const handleChange = (value, lang, tag, set) => {
    let newValues = { ...tag }
    newValues[lang] = value
    set(newValues)
  }

  const handleSpread = (event, value, tag, set) => {
    if (value !== undefined && value !== '') {
      let newValues = { ...tag }
      langs.map(l => newValues[l] === '' || newValues[l] === undefined || event.type === 'dblclick' ? newValues[l] = value : '')
      set(newValues)

    }
  }

  const handleChangeContent = (value, lang) => {
    let newValues = body
    newValues[lang] = value
    setBody(newValues)
  }

  const handleChangeFile = (file) => {
    if (file.length !== 0) {
      setShowText(false)
    }
    else {
      setShowText(true)
    }
    setUploadImage(file)
  }

  const handleDeleteImage = () => {
    let newImage = { ...image }
    setImage(null)
    setUndo(newImage)
  }

  const handleUndoImage = () => {
    let newImage = { ...undo }
    setUndo(null)
    setImage(newImage)
  }

  return (
    <>
      <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
        <Grid container>
          <Grid container className="textField__container--right">
            {langs && langs.map((lang, index) =>
              <Grid key={"news-title-" + lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                <TextFieldCommon
                  title={t('labels.news.title') + " <sup>" + lang + "</sup>"}
                  id={"title" + lang}
                  required={true}
                  value={title[lang]}
                  isOpen={isErrorTitle}
                  message={t('messages.error.news.title')}
                  label={t('common.labels.required')}
                  type="text"
                  fullWidth={true}
                  handleChangeInput={(value) => handleChange(value, lang, title, setTitle)}
                  handleSpreadInput={(event, value) => handleSpread(event, value, title, setTitle)}
                  renderIcon={true}
                />
              </Grid>
            )}
            {langs && langs.map((lang, index) =>
              <Grid key={"news-description-" + lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                <TextFieldCommon
                  title={t('labels.news.description') + " <sup>" + lang + "</sup>"}
                  id={"description" + lang}
                  required={true}
                  value={description[lang]}
                  isOpen={isErrorDescription}
                  message={t('messages.error.news.description')}
                  label={t('common.labels.required')}
                  type="text"
                  fullWidth={true}
                  multiline
                  handleChangeInput={(value) => handleChange(value, lang, description, setDescription)}
                  handleSpreadInput={(event, value) => handleSpread(event, value, description, setDescription)}
                  renderIcon={true}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
              {t('labels.news.news_date')}
            </Typography>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="datePicker__container"
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format="yyyy/MM/dd"
              openTo="date"
              id="newsDate-picker-inline"
              label={t('labels.repositories.datePicker')}
              error={isErrorNewsDate}
              helperText={isErrorNewsDate ? t('messages.error.news.news_date') : ''}
              value={newsDate}
              onChange={(value) => setNewsDate(value)}
            />
          </MuiPickersUtilsProvider>
          <Grid container item xs={12} md={12}>
            <Grid item xs={12} md={6} className="fields__padding--right">
              <Grid item xs={12} md={12}>
                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  {t('labels.news.start_date')}
                </Typography>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="datePicker__container"
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="yyyy/MM/dd"
                  openTo="date"
                  id="startDate-picker-inline"
                  label={t('labels.repositories.datePicker')}
                  error={isErrorStartDate}
                  helperText={isErrorStartDate ? t('messages.error.news.start_date') : ''}
                  value={startDate}
                  onChange={(value) => setStartDate(value)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6} className="fields__padding--left" >
              <Grid item xs={12} md={12}>
                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  {t('labels.news.end_date')}
                </Typography>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="datePicker__container"
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="yyyy/MM/dd"
                  openTo="date"
                  id="endDate-picker-inline"
                  label={t('labels.repositories.datePicker')}
                  error={isErrorEndDate}
                  helperText={isErrorEndDate ? t('messages.error.news.end_date') : ''}
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container className="textField__container">
            <Grid item xs={12} md={12}>
              <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                {t('labels.news.imgage')}
              </Typography>
            </Grid>
            <Grid container justify='center' alignItems={undo ? 'center' : 'flex-start'} item xs={12} md={6}>
              {image ?
                <>
                  <Grid item xs={12} md={11} className="fields__padding--right">
                    <img className="repositoryform__image--size" src={host + image.url} alt={title[i18n.language]} />
                  </Grid>
                  <Grid item xs={12} md={1} >
                    <Tooltip placement="top" title={t('tooltip.repositories.logo_remove')}>
                      <IconButton id="image-remove-button" onClick={handleDeleteImage} size='small'>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
                :
                undo ?
                  <Grid item xs={12} md={6} >
                    <SubmitButtonCommon
                      id="news-image-undo-button"
                      className="form__button--color-success"
                      title={t('common.buttons.undo')}
                      handleClick={handleUndoImage} />
                  </Grid>
                  :
                  ""
              }
            </Grid>
            {
              uploadSize &&
              <Grid item xs={12} md={(image || undo) ? 6 : 12} className={(image || undo) ? "fields__padding--left" : ""}>
                <DropzoneArea
                  className="dropzone__style"
                  initialFiles={uploadImage}
                  dropzoneText={showText ? t('common.labels.dropzone.title') : null}
                  classes={{
                    icon: showText ? "dropZone__color--grey" : "dropZone__color--white"
                  }}
                  filesLimit={1}
                  maxFileSize={uploadSize.body}
                  acceptedFiles={['image/*']}
                  onChange={handleChangeFile}
                  getFileAddedMessage={(filename) => (t('common.labels.dropzone.add', { file: filename }))}
                  getFileRemovedMessage={(filename) => (t('common.labels.dropzone.delete', { file: filename }))}
                  alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                />
              </Grid>
            }
          </Grid>
          <Grid container className="textField__container">
            {langs && langs.map((lang, index) =>
              <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                <Grid item xs={12} md={12}>
                  <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    <div dangerouslySetInnerHTML={{ __html: t('labels.news.body') + " <sup>" + lang + "</sup>" }} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <WYSIWYGeditorCommon
                    id={"content" + lang}
                    lang={i18n.language}
                    defaultValue={body[lang]}
                    handleChange={(value) => handleChangeContent(value, lang)}
                  />
                  {isErrorBody && <FormHelperText id="content-error" error>{t('messages.error.news.body')}</FormHelperText>}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
      <Grid container className="form__button--justify-left">
        <SubmitButtonCommon
          id="news-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={handleSubmitNews} />
        <SubmitButtonCommon
          id="news-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          goBack />
      </Grid>
    </>
  )
}
