import React, { useState } from 'react'
import { Accordion, AccordionSummary, Checkbox, ListItem, ListItemIcon, ListItemText, IconButton, Grid } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ClearIcon from '@material-ui/icons/Clear';
import Link from '@material-ui/core/Link';
import BadgeCommon from '@components/Common/Badge';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import getTranslation from '@utils/functions/utilities.js'

const qs = require('qs')

export default function Facets(props) {
  const [t, i18n] = useTranslation("common")
  const facets = props.facets;
  const [indexes, setIndexes] = useState(props.indexes)
  const [checked, setChecked] = useState(props.checked);
  const [expanded, setExpanded] = useState(props.expanded)
  let history = useHistory()
  const query = qs.parse(history.location.search.replace(/\?/, ''))
  const handleToggle = async (value) => {
    let filters = {}
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    let field = value.split("##")[0]
    let val = value.split("##")[1]

    if (currentIndex === -1) {
      newChecked.push(value);
      filters["facets." + newChecked.length + "." + field] = val
    } else {
      newChecked.splice(currentIndex, 1);
      for (const obj of Object.entries(query)) {
        if (obj[0].includes(field) && obj[1] === val)
          delete query[obj[0]];
      }
    }
    setChecked(newChecked)

    history.push("/repositories?" + qs.stringify({ ...query, _start: 0, ...filters }))
  };

  const handleExpand = (facet) => (event, isExpanded) => {
    let newExpanded = expanded
    newExpanded[facet] = isExpanded
    setExpanded(newExpanded)
  }

  const clearAllFilters = (event) => {
    event.preventDefault()
    setChecked([])
    for (const obj of Object.entries(query)) {
      if (obj[0].includes('facets'))
        delete query[obj[0]];
    }
    history.push("/repositories?" + qs.stringify({ ...query, _start: 0 }))
  }
  let i = 0;
  return (
    <div className="facets__div facets__sticky-top">
      {
        checked.length > 0 &&
        <Accordion
          square
          elevation={0}
          className="facets__card--margin-bottom adminDrawer__accordion--selected"
          defaultExpanded={true}
          onChange={handleExpand()}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <b>{t('labels.repositories.selected_facets')}</b>
          </AccordionSummary>
          <AccordionDetails role="repositories">
            <List>
              {checked.map(c => {
                let field = c.split('##')[0]
                let val = c.split('##')[1]
                let facet = indexes[field].find(i => i._id.value === val)
                if (facet)
                  return (
                    <ListItem className="facets__listItem" key={facet._id.key + i++}
                      title={getTranslation(facet._id.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}>
                      <FormControlLabel
                        className='facets__text--color'
                        label={
                          field !== "badge" ?
                            <Typography className='facets__formControl--typography' component="p">{getTranslation(facet._id.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
                            :
                            <Grid container className='facets__badge--selected'><BadgeCommon tag="avatar" name={getTranslation(facet._id.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])} url={facet._id.i18n.badge.url} /></Grid>
                        }
                        control={
                          <div className='facets__clear'>
                            <IconButton
                              className='facets__clear--button'
                              size="small"
                              onClick={() => handleToggle(facet._id.field + "##" + facet._id.value)}
                              disableRipple
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        }
                      />
                    </ListItem>)
              })

              }
              <Link href="#" onClick={(event) => clearAllFilters(event)} >
                <Typography className='facets__formControl--typography' component="p">{t('labels.repositories.clear_facets')}</Typography>
              </Link>
            </List>
          </AccordionDetails>
        </Accordion>
      }
      {Object.keys(facets).map((facet) => (
        <Accordion
          square
          elevation={0}
          key={facet}
          className="facets__card--margin-bottom adminDrawer__accordion--selected"
          defaultExpanded={expanded[facet]}
          onChange={handleExpand(facet)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <b>{t('labels.repositories.' + facet)}</b>
          </AccordionSummary>
          <AccordionDetails role="repositories">
            {facets[facet].length > 0 ?
              <List>
                {facets[facet].map(res => (
                  <ListItem className="facets__listItem" key={facet + "##" + res._id.value + i++}
                    title={getTranslation(res._id.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) + " (" + res.count + ")"}>
                    <FormControlLabel
                      className='facets__text--color'
                      label={facet !== "badge" ?
                        <Typography className='facets__formControl--typography' component="p">{getTranslation(res._id.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) + ` (${res.count})`}</Typography>
                        : <Grid container className='facets__badge'><BadgeCommon tag="avatar" name={getTranslation(res._id.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) + ` (${res.count})`} url={res._id.i18n.badge.url} /><Typography className='facets__badge--typography' component="p"> ({res.count})</Typography></Grid>
                      }
                      control={
                        <div className='facets__checkbox'>
                          <Checkbox
                            size="small"
                            checked={checked.indexOf(facet + "##" + res._id.value) !== -1}
                            onClick={() => handleToggle(facet + "##" + res._id.value)}
                            disableRipple
                          />
                        </div>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              : <Typography className='facets__nocontent--typography facets__text--color' component="p">{t('labels.repositories.no_facets')}</Typography>
            }
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}