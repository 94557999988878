import React, { useContext } from 'react'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"

import NewsForm from '@components/NewsItem/NewsForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewNewsItem() {
  const [t] = useTranslation("common")
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  let history = useHistory()

  const submitNews = async (news) => {
    let newImage;
    if (news.newImage && typeof news.newImage !== "undefined") {
      const data = new FormData()
      data.append('files', news.newImage)

      newImage = await Axios.post(host + "/upload", data)
      newImage = newImage.data[0]
      news.image = { url: newImage.url, _id: newImage._id }
    }

    delete news.newImage
    
    try {
      await Axios.post(host + '/news-items', news)
      snackForNews("success", t('messages.success.news.create'))
      history.goBack()
    }
    catch {
      snackForNews("error", t('messages.error.news.create'))
    }
  }

  const snackForNews = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
    <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin" , name: "admin"},
        { href: "/#/admin/news" , name: "news"},
        {name: "news_new"}
      ]} />
      <Typography component="h2" className="admin__card--title">{t('titles.news.create')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.news-item.form')}</Typography>
      </div>
      <NewsForm
        snackForNews={snackForNews}
        submitNews={submitNews}
        submitLable={t('news.form.submit.create')}
      />
    </div>
  )
}
