import React, { useState, useContext } from "react"
import { useHistory } from 'react-router-dom';
import { Typography } from "@material-ui/core";

import Axios from "axios"
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"
import StatisticsForm from "@components/Statistic/StatisticForm";
import { useTranslation } from "react-i18next";

import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"

const qs = require('qs')

export default function NewStatistic(props) {
    const [t] = useTranslation("common")

    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
    const [value, setValue] = useState('')
    const [labels, setLabels] = useState({})
    const [type, setType] = useState('')
    const [configs, setConfigs] = useState([{ value: "", i18n: {} }])

    const [isErrorType, setIsErrorType] = useState(false)
    const [isErrorValue, setIsErrorValue] = useState(false)
    const [isErrorConfigs, setIsErrorConfigs] = useState(false)

    let history = useHistory()

    const handleCreateStatistic = async () => {
        let newType;
        let validate = true
        if (type === '') {
            setIsErrorType(true)
            validate = false
        }
        if (value === '') {
            setIsErrorValue(true)
            validate = false
        }

        if (configs.length > 0 && !configs[configs.length - 1].value)
            configs.splice(configs.length - 1, 1)

        if (type === t('labels.statistics.type.MULTITIMESERIES')) {
            newType = "MULTITIMESERIES"
            for (let config of configs)
                if (!config.value) {
                    setIsErrorConfigs(true)
                    validate = false
                }
        }
        else if (type === t('labels.statistics.type.TIMESERIES'))
            newType = "TIMESERIES"
        else newType = "VALUE"

        if (validate) {
            try {

                let newStatistic = {
                    value: value,
                    i18n: {...labels},
                    type: newType,
                }

                if (newType === "MULTITIMESERIES") newStatistic.configs = configs.map(config => {
                    return {
                        value: config.value,
                        i18n: { ...config.i18n }
                    }
                })

                await Axios.post(host + '/statistics', newStatistic)
                setIsSnackBar(true)
                setTag("success")
                setMessage(t('messages.success.statistics.create'))
                history.goBack()
            }
            catch {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.statistics.create'))
            }
        }
        else if(configs.length === 0) {
            setConfigs([{ value: "", i18n: {} }])
        }
    }

    return (
        <div className="admin__container-bottom">
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin", name: "admin" },
          { href: "/#/admin/statistics", name: "statistics" },
          { name: "statistics_new" },
        ]} />
            <Typography id="statistic-create-title" component="h2" className="admin__card--title">{t('titles.statistics.create') + ' ' + t('titles.statistics.statistic')}</Typography>
            <div >
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.statistic.form')}</Typography>
            </div>
            <StatisticsForm
                value={value}
                type={type}
                configs={configs}
                setValue={setValue}
                labels={labels}
                setLabels={setLabels}
                setType={setType}
                setConfigs={setConfigs}
                types={[t('labels.statistics.type.VALUE'), t('labels.statistics.type.TIMESERIES'), t('labels.statistics.type.MULTITIMESERIES')]}

                isErrorType={isErrorType}
                isErrorValue={isErrorValue}
                isErrorConfigs={isErrorConfigs}
                renderSubmit={true}
                handleClick={handleCreateStatistic}
            />
        </div>
    )

}
