import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextFieldCommon from '@components/Common/TextField';
import RadioCommon from '@components/Common/Radio';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Axios from "axios"
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import SubmitButtonCommon from '@components/Common/SubmitButton';

import { SnackBarContext } from "@context/snackBarContext.js"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function ControlledVocabularyPage() {

  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  let history = useHistory()

  const [t, i18n] = useTranslation('common')
  const [format, setFormat] = useState(null);
  const [subject, setSubject] = useState({});
  const [text, setText] = useState({});
  const [html, setHtml] = useState({});
  const [langs, setLangs] = useState(null)

  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/configurations/' + id)
      .then(res => {
        setFormat(res.body.format);
        if (res.body.subject) {
          setSubject(res.body.subject);
        }
        if (res.body.text) {
          setText(res.body.text);
        }
        if (res.body.html) {
          setHtml(res.body.html);
        }
      })

    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setFormat, setSubject, setText, setHtml, setLangs])

  const handleUpdateTemplate = async () => {
    await Axios.put(host + '/configurations/' + id, {
      body: {
        format: format,
        subject: subject,
        text: text,
        html: html
      }
    }).then(() => {
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.configurations.update'))
      history.goBack();
    })
      .catch(() => {
        setIsSnackBar(true)
        setTag("error")
        setMessage(t('messages.error.configurations.update'))
      })
  }

  const handleChangeSubject = (value, lang) => {
    let newValue = {...subject}
    newValue[lang] = value
    setSubject(newValue)
  }

  const handleSpreadSubject = (event, value) => {
    if (value !== undefined && value !== ''){
      let newValue = {...subject}
      langs.map(l => newValue[l] === '' || event.type === 'dblclick' ? newValue[l] = value : '') 
      setSubject(newValue)
    }
  }

  const handleChangeText = (value, lang) => {
    let newValue = {...text}
    newValue[lang] = value
    setText(newValue)
  }

  const handleSpreadText = (event, value) => {
    if (value !== undefined && value !== ''){
      let newValue = {...text}
      langs.map(l => newValue[l] === '' || event.type === 'dblclick' ? newValue[l] = value : '') 
      setText(newValue)
    }
  }

  const handleChangeHtml = (value, lang) => {
    let newValue = {...html}
    newValue[lang] = value
    setHtml(newValue)
  }

  const handleSpreadHtml = (event, value) => {
    if (value !== undefined && value !== ''){
      let newValue = {...html}
      langs.map(l => newValue[l] === '' || event.type === 'dblclick' ? newValue[l] = value : '') 
      setHtml(newValue)
    }
  }

  return (
    <>
      <Typography component="h2" className="admin__card--title">{
        t('adminDrawer.configurations.emailTemplate_update')
      }</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.configuration.emailTemplate')}</Typography>
      </div>
      {id &&
        <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
          <RadioCommon
            id="configurations-template-format"
            title={t('labels.configurations.templateObj.format.title')}
            value={format}
            firstLabel={t('labels.configurations.templateObj.format.text')}
            firstCondition="text"
            secondLabel={t('labels.configurations.templateObj.format.html')}
            secondCondition="html"
            defaultValue={format}
            handleChange={setFormat}
          />

          <Grid container className="textField__container">
            {langs && langs.map((lang, index) =>
              <Grid item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                <TextFieldCommon
                  id={"configurations-template-subject-" + lang}
                  title={t('labels.configurations.templateObj.subject', { lang: lang })}
                  value={subject[lang]}
                  type="text"
                  fullWidth={true}
                  handleChangeInput={(value) => handleChangeSubject(value, lang)}
                  handleSpreadInput={(event, value) => handleSpreadSubject(event, value)}
                  renderIcon={true}
                />
              </Grid>
            )}
          </Grid>
          <Grid container className="textField__container">
            {langs && langs.map((lang, index) =>
              <Grid item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                {format === 'text' && <TextFieldCommon
                  id={"configurations-template-text-" + lang}
                  title={t('labels.configurations.templateObj.text', { lang: lang })}
                  value={text[lang]}
                  type="text"
                  fullWidth={true}
                  handleChangeInput={(value) => handleChangeText(value, lang)}
                  handleSpreadInput={(event, value) => handleSpreadText(event, value)}
                  renderIcon={true}
                />}
                {format === 'html' && <TextFieldCommon
                  id={"configurations-template-html-" + lang}
                  title={t('labels.configurations.templateObj.html', { lang: lang })}
                  value={html[lang]}
                  type="text"
                  multiline
                  fullWidth={true}
                  handleChangeInput={(value) => handleChangeHtml(value, lang)}
                  handleSpreadInput={(event, value) => handleSpreadHtml(event, value)}
                  renderIcon={true}
                />}
              </Grid>
            )}
          </Grid>
        </Card>}
      <Grid container className="margin__top--10px form__button--justify-center">
        <SubmitButtonCommon
          id="configurations-template-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={handleUpdateTemplate} />
        <SubmitButtonCommon
          id="configurations-template-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          goBack />
      </Grid>
    </>
  )
}