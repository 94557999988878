import { CardContent, CardHeader, Grid } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import TextFieldCommon from '@components/Common/TextField';
import Typography from '@material-ui/core/Typography';

import '@styles/Common/Common.css'

export default function ConfigsForm(props) {
    const [t] = useTranslation('common')


    const handleAddField = (event) => {
        let values = [...props.configs]
        values.push({ value: "", i18n: {} })
        props.setConfigs(values)
    }

    const handleRemoveField = (event, index) => {
        let values = [...props.configs]
        values.splice(index, 1)
        props.setConfigs(values)
    }

    const setValue = (item, index) => (value) => {
        let values = [...props.configs]
        if (item === "value") {
            values[index][item] = value
        }
        else
            values[index].i18n[item] = value
        props.setConfigs(values)
    }

    const spreadValue = (event, value, index) => {
        if (value !== undefined && value !== '') {
            let values = [...props.configs]
            props.langs.map(l => values[index].i18n[l] === '' || values[index].i18n[l] === undefined || event.type === 'dblclick' ? values[index].i18n[l] = value : '')
            props.setConfigs(values)
        }

    }
    return (
        <>
            {props.configs && props.configs.map((config, index) =>
                <Grid container className="textField__container" key={index}>
                    {index === 0 ?
                        <Grid item xs={12} md={12}>
                            <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                {t('labels.statistics.configs')}
                            </Typography>
                        </Grid> :
                        <Grid item xs={12} md={12} />
                    }
                    <Grid item xs={12} md={12}>
                        <div className="textField__multi--margin--top">
                            <div className="form__flex--multiple">
                                <TextFieldCommon
                                    title={t('labels.statistics.value')}
                                    id={"value-" + index}
                                    required={true}
                                    value={config.value}
                                    isOpen={!config.value && props.isErrorConfigs}
                                    message={t('messages.error.statistics.value')}
                                    label={t('common.labels.required')}
                                    type="text"
                                    fullWidth={true}
                                    handleChangeInput={setValue('value', index)}
                                />
                                {
                                    index === props.configs.length - 1 ?
                                        <Button id={"statistic-add-value"} className="form__button--add" size="medium" onClick={handleAddField}>
                                            <AddIcon className="form__icon--add" />
                                        </Button>
                                        :
                                        <Button id={"statistic-removr-value-" + index} className="form__button--remove" size="medium" onClick={(event) => handleRemoveField(event, index)}>
                                            <RemoveIcon className="form__icon--remove" />
                                        </Button>
                                }
                            </div>
                            <Grid container>
                                {props.langs && props.langs.map((lang, i) =>
                                    <Grid key={lang + i} item xs={12} md={6} className={i % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                        <TextFieldCommon
                                            title={t('labels.controlledVocabulary.label', { lang: lang })}
                                            id={"label-" + index + "-" + lang}
                                            required={true}
                                            value={config.i18n[lang]}
                                            isOpen={!config.i18n[lang] && props.isErrorConfigs}
                                            message={t('messages.error.statistics.i18n.en')}
                                            label={t('common.labels.required')}
                                            type="text"
                                            fullWidth={true}
                                            handleChangeInput={setValue(lang, index)}
                                            handleSpreadInput={(event, value) => spreadValue(event, value, index)}
                                            renderIcon={true}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>)}
        </>
    )
}