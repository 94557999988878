import React, { useState, useContext, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { CardHeader, Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import { useHistory, useLocation } from 'react-router-dom'

import Axios from 'axios'
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"
import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import { useTranslation } from 'react-i18next'


import '@styles/frontOffice/User/User.css'
const qs = require('qs')

export default function ResetPassword() {
    const location = useLocation()
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
    const [t] = useTranslation("common")

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isPassword, setIsPassword] = useState(false)
    const [isConfirmPassword, setIsConfirmPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const query = qs.parse(location.search.replace(/\?/, ''))

    let history = useHistory()

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClick = async () => {
        const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/
        let validate = true
        if (!password.match(passRegExp)) {
            setIsPassword(true)
            validate = false
        }
        if (password !== confirmPassword) {
            setIsConfirmPassword(true)
            validate = false
        }
        if (validate) {
            try {
                await Axios.post(host + "/auth/reset-password", {
                    code: query.code,
                    password: password,
                    passwordConfirmation: confirmPassword
                })
                setIsSnackBar(true)
                setTag("success")
                setMessage(t('messages.success.users.resetPassword'))
                history.push("/login")
            } catch (error) {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.users.resetPassword'))
            }
        } else {
            setIsSnackBar(true)
            setTag("error")
            setMessage(t('messages.error.users.resetPassword'))
        }
    }

    useEffect(() => {
        const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/
        if (password.match(passRegExp) && password.length >= 13)
            setIsPassword(false)
        if (password === confirmPassword)
            setIsConfirmPassword(false)
    }, [password, confirmPassword])

    return (
        <div className="login__container--center public__container--padding">
            <Card elevation={1} className="login__container">
                <Typography component="h2" className="login__title">{t('titles.users.resetPassword')} </Typography>
                <div className="login__textfield--padding login__textfield--margin-top">
                    <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.user.resetPassword')}</Typography>
                    <TextFieldCommon
                        title={t('labels.users.newPassword')}
                        id="password"
                        required={true}
                        isOpen={isPassword}
                        message={t('messages.error.users.password.error')}
                        type="password"
                        renderIcon={true}
                        showPassword={showPassword}
                        value={password}
                        handleClickShowPassword={handleClickShowPassword}
                        handleMouseDownPassword={handleMouseDownPassword}
                        fullWidth={true}
                        handleChangeInput={setPassword}
                    />
                    <TextFieldCommon
                        title={t('labels.users.confirmPassword')}
                        id="confirmPassword"
                        required={true}
                        isOpen={isConfirmPassword}
                        message={t('messages.error.users.confirmPassword')}
                        type="password"
                        renderIcon={true}
                        showPassword={showConfirmPassword}
                        value={confirmPassword}
                        password={password}
                        handleClickShowPassword={handleClickShowConfirmPassword}
                        handleMouseDownPassword={handleMouseDownPassword}
                        fullWidth={true}
                        handleChangeInput={setConfirmPassword}
                    />
                    <Grid container className="form__button--justify-left login__textfield--margin-bottom">
                        <SubmitButtonCommon
                            className="form__button--color-success"
                            title={t('common.buttons.save')}
                            handleClick={handleClick} />
                        <SubmitButtonCommon
                            className="form__button--color-error"
                            title={t('common.buttons.cancel')}
                            goBack />
                    </Grid>
                </div>
            </Card>
        </div>
    )

}