import React, { useState, useEffect } from 'react'
import IndexPage from '@components/Repository/Index'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import { useTranslation } from 'react-i18next';


import BreadCrumbs from '@components/Common/BreadCrumbs.js'
export default function Index() {
  const [t, i18n] = useTranslation('common')
  const [index, setIndex] = useState(null)
  let { id } = useParams()
  
  useEffect(() => {
    getRequest(host + '/configurations/' + id)
        .then(res => {
          setIndex(res)
    })
  },[setIndex, id])

  return (
    <>
    {
      index &&
      <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin" , name: "admin"},
        { href: "/#/admin/indexes" , name: "indexes"},
        { name: "index" }
      ]} />
      <IndexPage index={index}/>
      </div>
    }
    </>
      )
}
