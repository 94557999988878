import React, { useEffect, useState, useContext } from 'react';
import { CardHeader, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

import TableCommon from '@components/Common/Table'

import '@styles/backOffice/User/Users.css'
import ActionPanel from '@components/Workflow/ActionPanel';
import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

const qs = require('qs')

export default function Workflows() {
  let history = useHistory()

  const [condition, setCondition] = useState(null)
  const [t, i18n] = useTranslation('common')
  const [headers, setHeaders] = useState(null)
  const [title, setTitle] = useState("")

  const [load, setLoad] = useState(false)
  let query = qs.parse(history.location.search.replace(/\?/, ''))
  let field = query.field

  useEffect(() => {
    setLoad(false)


    if (field) {
      setCondition(field)
      setTitle(t('labels.workflows.' + field))
      setHeaders([
        {
          name: t('labels.workflows.objectType'),
          value: "objectType",
          sort: true,
          translation: "titles.workflows.objectType."
        },
        {
          name: t('labels.workflows.type'),
          value: "type",
          translation: "titles.workflows.type."
        },
        {
          name: t('labels.workflows.createdAt'),
          value: "createdAt",
          sort: true,
          date: true
        },
        ...(field === "created" ? [{
          name: t('labels.workflows.createdBy'),
          value: 'createdBy',
          sort: true
        }] : []),
        {
          name: t('labels.workflows.objectDescription'),
          value: ['object', 'name', i18n.language],
          sort: true,
        },
        ...((field === "rejected" || field === "assigned" || field === "approved") ? [{
          name: field === "rejected" ? t('labels.workflows.rejectedBy') : field === "assigned" ? t('labels.workflows.assignedAt') : t('labels.workflows.approvedBy'),
          value: ['assigned', 'email'],
          sort: true
        }] :
          []
        ),
      ])
    }
    else {
      setCondition(null)
      setTitle(t('titles.workflows.title'))
      setHeaders([
        {
          name: t('labels.workflows.objectType'),
          value: "objectType",
          sort: true,
          translation: "titles.workflows.objectType."
        },
        {
          name: t('labels.workflows.condition'),
          value: "condition",
          sort: true,
          translation: "titles.workflows.condition."
        },
        {
          name: t('labels.workflows.type'),
          value: "type",
          translation: "titles.workflows.type."
        },
        {
          name: t('labels.workflows.createdAt'),
          value: "createdAt",
          sort: true,
          date: true
        },
        {
          name: t('labels.workflows.createdBy'),
          value: "createdBy",
          sort: true
        },
        {
          name: t('labels.workflows.objectDescription'),
          value: ['object', 'name', i18n.language],
          sort: true,
        },
      ])
    }
    setLoad(true)
  }, [i18n.language, setHeaders, history.location])


  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/requests", name: "requests" },
        ...(field !== undefined ? [{ name: t('labels.workflows.' + field), fullTitle: true }] : '')
      ]} />
      <Typography component="h2" className="admin__card--title">{title}</Typography>
      {
        load &&
        <TableCommon
          title={title}
          headers={headers}
          controller='request'
          backendPath="/requests"
          frontendPath="/requests"
          filter={condition ? "condition=" + condition.toUpperCase() : null}
          sort="createdAt"
          order="desc"
          ActionPanel={ActionPanel}
          hasActions={condition !== "rejected" && condition !== "approved"}
        />
      }
    </div>
  );
}
