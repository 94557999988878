import React, { useEffect, useState, useContext } from "react"
import { useHistory, useParams } from 'react-router-dom';

import Axios from "axios"
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"
import ControlledVocabularyForm from "@components/ControlledVocabulary/ControlledVocabularyForm";
import { useTranslation } from "react-i18next";
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"

export default function NewControlledVocabulary(props) {
    const [t] = useTranslation("common")

    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
    const [controlledVocabulary, setControlledVocabulary] = useState(null)
    const [field, setField] = useState('')
    const [value, setValue] = useState('')
    const [labels, setLabels] = useState({})
    const [badge, setBadge] = useState(null)
    const [undo, setUndo] = useState(null)
    const [info, setInfo] = useState({})
    const [uploadImage, setUploadImage] = useState(null)
    const [langs, setLangs] = useState(null)


    const [isErrorValue, setIsErrorValue] = useState(false)
    const [isErrorUpload, setIsErrorUpload] = useState(false)
    const [isErrorInfo, setIsErrorInfo] = useState(false)
    const [isErrorLabels, setIsErrorLabels] = useState(false)


    const [showText, setShowText] = useState(true)

    let { id } = useParams();
    let history = useHistory()

    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
                setIsErrorInfo(res[0].body.map(l => false))
                setIsErrorLabels(res[0].body.map(l => false))
            })
    }, [id, setControlledVocabulary])

    const handleChangeFile = (file) => {
        if (file.length !== 0) {
            setShowText(false)
        }
        else {
            setShowText(true)
        }
        setUploadImage(file)
    }

    const handleReset = () => {
        setValue(controlledVocabulary.value)
        setLabels(controlledVocabulary.i18n)
        setInfo(controlledVocabulary.info)
    }

    const handleUpdateControlledVocabulary = async () => {
        let validate = true
        if (value === '') {
            setIsErrorValue(true)
            validate = false
        } else setIsErrorValue(false)
        if (langs.some(l => !labels[l] || labels[l] === "")) {
            setIsErrorLabels(langs.map(l => !labels[l] || labels[l] === ""))
            validate = false
        } else setIsErrorLabels(langs.map(l => false))
        if (info && Object.keys(info).length > 0 && (langs.some(lang => !info[lang] || info[lang] === '') && langs.some(lang => (info[lang] && info[lang] !== ''))) || info && langs.some(lang => (info[lang] && info[lang].length > 500))) {
            setIsErrorInfo(langs.map(lang => !info[lang] || (info[lang].length > 500 || info[lang] === '')))
            validate = false
        } else setIsErrorInfo(langs.map(l => false))
        if (field === 'badge' && uploadImage.length === 0 && !badge) {
            setIsErrorUpload(true)
            validate = false
        }
        if (validate) {
            if (field === 'badge') {
                try {
                    let newControlledVocabulary = { ...controlledVocabulary }
                    newControlledVocabulary.value = value
                    newControlledVocabulary.i18n = labels
                    if (uploadImage.length > 0) {
                        const data = new FormData()
                        data.append('files', uploadImage[0])
                        let res = await Axios.post(host + "/upload", data)
                        let newLogo = res.data[0]
                        let logo = { url: newLogo.url, _id: newLogo._id }
                        newControlledVocabulary.i18n.badge = logo
                    }
                    else {
                        newControlledVocabulary.i18n.badge = badge
                    }
                    newControlledVocabulary.info = info
                    await Axios.put(host + '/controlled-vocabularies/' + newControlledVocabulary._id, newControlledVocabulary)
                    setIsSnackBar(true)
                    setTag("success")
                    setMessage(t('messages.success.controlledVocabulary.update'))
                    history.goBack()
                }
                catch {
                    setIsSnackBar(true)
                    setTag("error")
                    setMessage(t('messages.error.controlledVocabulary.update'))
                }
            }
            else {
                try {
                    let newControlledVocabulary = { ...controlledVocabulary }
                    newControlledVocabulary.value = value
                    newControlledVocabulary.i18n = labels
                    newControlledVocabulary.info = info
                    await Axios.put(host + '/controlled-vocabularies/' + newControlledVocabulary._id, newControlledVocabulary)
                    setIsSnackBar(true)
                    setTag("success")
                    setMessage(t('messages.success.controlledVocabulary.update'))
                    history.goBack()
                }
                catch {
                    setIsSnackBar(true)
                    setTag("error")
                    setMessage(t('messages.error.controlledVocabulary.update'))
                }
            }
        }
    }

    const handleDeleteLogo = () => {
        let newLogo = { ...badge }
        setBadge(null)
        setUndo(newLogo)
    }

    const handleUndoLogo = () => {
        let newLogo = { ...undo }
        setUndo(null)
        setBadge(newLogo)
    }


    useEffect(() => {
        getRequest(host + '/controlled-vocabularies/' + id)
            .then(res => {
                setField(res.field)
                setValue(res.value)
                setLabels(res.i18n)
                setInfo(res.info)
                if (res.field === 'badge') {
                    setBadge(res.i18n.badge)
                }
                setControlledVocabulary(res)
            })
    }, [setControlledVocabulary, setField, setValue, setLabels, setInfo])

    return (
        <div className="admin__container-bottom">
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { href: "/#/admin/" + (history.location.pathname.includes('badges') ? "badges" : "controlled-vocabularies?field=" + field), name: history.location.pathname.includes('badges') ? "badges" : t('titles.controlledVocabulary.' + field), fullTitle: history.location.pathname.includes('badges') ? false : true },
                { name: history.location.pathname.includes('badges') ? "badges_update" : t('titles.controlledVocabulary.update') + ' ' + t('titles.controlledVocabulary.' + field), fullTitle: history.location.pathname.includes('badges') ? false : true }
            ]} />
            {controlledVocabulary && <ControlledVocabularyForm
                tag="UPDATE"
                field={field}
                value={value}
                setValue={setValue}
                labels={labels}
                setLabels={setLabels}
                info={info}
                setInfo={setInfo}
                badge={badge}
                undo={undo}
                showText={showText}
                handleChangeFile={handleChangeFile}
                isErrorValue={isErrorValue}
                isErrorUpload={isErrorUpload}
                isErrorInfo={isErrorInfo}
                isErrorLabels={isErrorLabels}
                handleUndoLogo={handleUndoLogo}
                handleDeleteLogo={handleDeleteLogo}
                handleClick={handleUpdateControlledVocabulary}
                handleReset={handleReset}
            />}
        </div>
    )

}
