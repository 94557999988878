import React from 'react'
import { Grid, Card, Typography } from '@material-ui/core'
import List from '@material-ui/core/List';
import TablePagination from '@material-ui/core/TablePagination';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import BadgeCommon from '@components/Common/Badge';

import { useTranslation } from 'react-i18next'
import getTranslation from '@utils/functions/utilities.js'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'
export default function IndexesPage(props) {
  const [t, i18n] = useTranslation('common')
  const indexes = props.indexes
  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage)
  }

  const handleShowInfo = (index) => {
    let split = props.showField.split('.')
    let finalObj = index
    for (let field of split) {
      finalObj = finalObj[field]
    }
    if (typeof finalObj === 'object')
      return getTranslation(finalObj, i18n.language, i18n.languages[i18n.languages.length - 1])
    return finalObj
  }

  return (
    <>
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { name: "indexes" },
        { name: `${t('navBar.indexes.' + (props.field === 'lang' ? 'language' : props.field))}`, fullTitle: true }
      ]} />
      <Typography id="indexes-title" component="h1" className="admin__card--title admin__form--title-margin index__title">{t('navBar.indexes.' + (props.field === 'lang' ? 'language' : props.field))}</Typography>
      {props.count ?
        <>

          <List id="indexes-list" dense>
            <Grid spacing={2} justify="flex-start" container>
              {indexes[props.field] && indexes[props.field].map((index, i) => {
                return <Grid key={index._id.value} item xs={props.showField !== 'none' ? 12 : 6} md={props.showField !== 'none' ? 12 : 4}>
                  <div key={index._id.value}>
                    <Card elevation={0} className={props.showField !== 'none' ? "indexes__cards" : "indexes--cards__none"}>
                      <div className={props.showField !== 'none' ? "margin__left--87px" : ""}>
                        <div className="indexes__div--index">
                          <a className="indexes__label--text" id={"indexes-list-" + i} href={'/#/repositories?facets.1.' + props.field + '=' + index._id.value}>
                            <div className="indexes__number--text">{index.count}</div>
                            {
                              props.field !== "badge" ?
                                <div>{getTranslation(index._id.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}</div>
                                : <div className="indexes__div--index" ><BadgeCommon className="indexes__badge--size" tag="avatar" name={getTranslation(index._id.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])} url={index._id.i18n.badge.url} /></div>
                            }
                          </a>
                        </div>
                      </div>
                      {props.showField !== 'none' &&
                        <div id={"info-" + i} className="margin__left--87px margin__right--12px indexes__info--text">
                          {handleShowInfo(index._id)}
                        </div>}
                    </Card>
                  </div>
                </Grid>
              })}
            </Grid>
          </List>
          <div>
            <Grid className="repositories__pagination--margin--justify" container>
              <TablePagination
                id="indexes-pagination"
                component="div"
                rowsPerPageOptions={[10, 25, 100]}
                count={props.count}
                page={props.page}
                onChangePage={handleChangePage}
                rowsPerPage={props.pageSize}
                labelRowsPerPage={t('labels.repositories.itemsPerPage')}
                onChangeRowsPerPage={props.handleChangePageSize}
                classes={{
                  selectIcon: "arrowicon__color",
                  actions: "arrowicon__color"
                }}
              />
            </Grid>
          </div>
        </>
        :
        <div className="about__container public__container--padding-bottom">
          <div><h2>{t('labels.repositories.notFound')}</h2></div>
        </div>
      }
    </>
  )
}
