import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import getRequest from '@utils/functions/axiosRequest'
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import 'date-fns';
import { DialogContext } from '@context/dialogContext'
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import HelpIcon from '@material-ui/icons/Help';

import { UserContext } from '@context/userContext';
import adminAccess from '@utils/comparators/AdminAccess'

import "@styles/frontOffice/Repository/RepositoryForm.css"
import "@styles/Common/Common.css"
import getTranslation from '@utils/functions/utilities.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Chip, IconButton, InputAdornment } from "@material-ui/core"

export default function AutoCompleteCommon(props) {
  const [t, i18n] = useTranslation("common")
  const [values, setValues] = useState([])
  const [start, setStart] = useState(0)
  const [search, setSearch] = useState("")
  const [limit, setLimit] = useState(200)
  const [setDialogOpen, setDialogMessage, setDialogCancel, setDialogConfirm, setDialogAction] = useContext(DialogContext)
  const [preValues, setPreValues] = useState([])
  const [user] = useContext(UserContext)
  useEffect(() => {
    let newPreValues = []
    if (props.preValues) {
      let newVal = ""
      for (let val of props.preValues)
        newVal += "&value=" + val

      getRequest(props.path + "&toLog=false" + newVal)
        .then(res => {
          if (res.count > 0) {
            newPreValues = res.entities
            setPreValues(res.entities)
          }
          fetchData(newPreValues)
        })
    }
    else {
      fetchData([])
    }

  }, [setValues, i18n.language])

  const handleScroll = (event) => {
    if (props.vc && start < limit && Math.round(event.currentTarget.scrollHeight - event.currentTarget.scrollTop) === event.currentTarget.clientHeight) {
      let path;
      if (!props.path.includes('?')) {
        path = props.path + "?toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=" + start + (search.length >= 2 ? ("&_s=" + search) : "") + "&lang=" + i18n.language
      } else if (props.path.includes('?_sort')) {
        path = props.path + "&toLog=false&_limit=20&_start=" + start + (search.length >= 2 ? ("&_s=" + search) : "") + "&lang=" + i18n.language
      }
      else path = props.path + "&toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=" + start + (search.length >= 2 ? ("&_s=" + search) : "") + "&lang=" + i18n.language
      getRequest(path)
        .then(res => {
          if (res.entities.length === 0) {
            setLimit(values.length)
          }
          else {
            setLimit(res.count)
            setValues(values.concat(res.entities))
            setStart(start + 20)
          }
        })
    }
  }

  const handleChangeInput = async (value) => {
    if (!value || value === "") {
      let path;
      if (!props.path.includes('?')) {
        path = props.path + "?toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=0&lang=" + i18n.language
      } else if (props.path.includes('?_sort')) {
        path = props.path + "&toLog=false&_limit=20&_start=0&lang=" + i18n.language
      }
      else path = props.path + "&toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=0&lang=" + i18n.language
      getRequest(path)
        .then(res => {
          setValues(preValues.concat(res.entities))
          setStart(20)
        })
    }
    else if (value && value.length >= 2) {
      let path;
      if (!props.path.includes('?')) {
        path = props.path + "?toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=0&lang=" + i18n.language + (value.length >= 2 ? ("&_s=" + value) : "")
      } else if (props.path.includes('?_sort')) {
        path = props.path + "&toLog=false&_limit=20&_start=0&lang=" + i18n.language + (value.length >= 2 ? ("&_s=" + value) : "")
      }
      else if (props.path.includes('/users?email_contains='))
        path = props.path + (value.length >= 2 ? { s_: value } : "") + "&toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=0&lang=" + i18n.language
      else
        path = props.path + "&toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=0&lang=" + i18n.language + (value.length >= 2 ? ("&_s=" + value) : "")
      getRequest(path)
        .then(res => {
          let newValues = res.entities

          if (props.preValues)
            for (let val of props.preValues)
              newValues = newValues.filter(item => item.value !== val)

          setValues(preValues.concat(newValues))
          setStart(20)
        })
    }
    setSearch(value)
  }

  const fetchData = (newPreValues) => {
    let path;
    if (!props.path.includes('?')) {
      path = props.path + "?toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=0&lang=" + i18n.language
    } else if (props.path.includes('?_sort')) {
      path = props.path + "&toLog=false&_limit=20&_start=" + start + (search.length >= 2 ? ("&_s=" + search) : "") + "&lang=" + i18n.language
    }
    else path = props.path + "&toLog=false&_sort=" + i18n.language + ":ASC&_limit=20&_start=0&lang=" + i18n.language
    getRequest(path)
      .then(res => {
        let newValues = res.entities
        if (props.preValues)
          for (let val of props.preValues)
            newValues = newValues.filter(item => item.value !== val)

        newPreValues = newPreValues.concat(newValues)
        setValues(newPreValues)
        setStart(start + 20)
      })
  }

  const handleAddField = (event) => {
    let values = [...props.selectedValue]
    values.push(null)
    props.handleChange(values)
  }

  const handleRemoveField = (index) => () => {
    let values = [...props.selectedValue]
    values.splice(index, 1)
    if (values.length === 0)
      values.push(null)
    props.handleChange(values)
  }

  const handleRemoveData = async (event, index) => {
    event.stopPropagation()
    setDialogMessage(t('table.confirm.delete'))
    setDialogCancel(t('table.confirm.cancelButton'))
    setDialogConfirm(t('table.confirm.confirmButton'))
    let obj = {
      function: handleRemoveField(index),
    }
    setDialogAction(obj)
    setDialogOpen(true)
  }

  const handleChangeArrayInput = (value, index) => {
    let values = [...props.selectedValue]
    if (!value)
      values.splice(index, 1)
    else values[index] = value

    if (values.length === 0) props.handleChange([null])
    else props.handleChange(values)
  }


  const handleChange = (event, index, tag, lang = '') => {
    let values = [...props.selectedValue]
    if (tag === 'description') {
      if (!values[index][tag]) {
        values[index][tag] = props.langs.reduce((l, val) => ({ ...l, [val]: '' }), {})
      }
      values[index][tag][lang] = event.target.value
    }
    else if (tag === 'descriptionSpread') {
      if (values[index]['description'] && values[index]['description'][lang])
        props.langs.map(l => values[index]['description'][l] = values[index]['description'][lang])
    }
    else
      values[index][tag] = tag === "date" ? event : event.target.value

    props.handleChange(values)
  }

  const multiButtons = (index, disable) => {
    if (!disable)
      return (
        <>
          <Button className="form__button--remove" size="medium" onClick={(event) => handleRemoveData(event, index)}>
            <RemoveIcon className="form__icon--remove" />
          </Button>
          {
            index === props.selectedValue.length - 1 &&
            <Button className="form__button--add" size="medium" onClick={handleAddField}>
              <AddIcon className="form__icon--add" />
            </Button>
          }
        </>
      )
  }

  const handleClick = (event) => {
    event.preventDefault()
    props.handleClick()
  }

  const multiButtonsOrganization = (addButton, removeButton, editButton) => {
    return (
      <>
        {addButton &&
          <Button id={props.id + '-add-button'} className="form__button--add" size="medium" onClick={handleClick}>
            <AddIcon className="form__icon--add" />
          </Button>
        }
        {removeButton &&
          <Button className="form__button--remove" size="medium" onClick={handleClick}>
            <RemoveIcon className="form__icon--remove" />
          </Button>
        }
        {editButton && adminAccess("organization", "editRequest", user.group.permission) &&
          <Tooltip placement="top" title={t('tooltip.organizations.edit')}>
            <IconButton id={'repository-edit-button-'} aria-label="arrow drop Up" size="small" onClick={handleClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
      </>
    )
  }

  return (
    <Grid container className="textField__container">
      {
        !props.singleMultipleValues ?
          <>
            {props.title ? <Grid item xs={12} md={12}>
              <Typography id={props.id + '-lable'} className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                {props.title + (props.optional ? " (" + t('labels.common.optional') + ")" : props.required ? " *" : "")}
                {props.tooltip &&
                  <Tooltip title={props.tooltip}>
                    <HelpIcon style={{ height: "auto", paddingLeft: "0.1rem" }} fontSize='small'></HelpIcon>
                  </Tooltip>}
              </Typography>
            </Grid> : ''}
            <Grid item xs={10} md={12} className={props.noMargin ? "" : "margin__top--9px"}>
              <div className="selectComponent--input">
                {props.tag === 'autocomplete-simple' &&
                  <Autocomplete
                    getOptionSelected={(option, value) => option.key === value.key}
                    className={props.className}
                    id={props.id + "-input"}
                    options={[...values]}
                    popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                    value={props.selectedValue}
                    filterOptions={options => options}
                    getOptionLabel={(selected) => selected.name ? selected.name : ""}
                    onChange={(event, value) => props.handleChange(value)}
                    onInputChange={(e, value) => { e && handleChangeInput(value) }}
                    ListboxProps={{ onScroll: handleScroll }}
                    renderOption={(option, { selected }) => {
                      if (preValues.find(val => val.value === option.value))
                        return <b>{getTranslation(option.i18n, props.language, props.fallbackLng)}</b>
                      else
                        return getTranslation(option.i18n, props.language, props.fallbackLng)
                    }}
                    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" size="small" />}
                  />
                }
                {props.tag === 'autocomplete-organization' &&
                  <div className="form__flex--multiple">
                    <div className="selectComponent--input">
                      <Autocomplete
                        getOptionSelected={(option, value) => option._id === value._id}
                        className={props.className}
                        id={props.id + "-input"}
                        options={[...values]}
                        popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                        value={props.selectedValue}
                        filterOptions={options => options}
                        getOptionLabel={(values) => {
                          return getTranslation(values.name, props.language, props.fallbackLng) || ""
                        }}
                        onChange={(event, value) => props.handleChange(value)}
                        onInputChange={(e, value) => { e && handleChangeInput(value) }}
                        ListboxProps={{ onScroll: handleScroll }}
                        renderOption={(option, { selected }) => {
                          return getTranslation(option.name, props.language, props.fallbackLng)
                        }}
                        renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" size="small" />}
                        disabled={props.disabled || false}
                      />
                    </div>
                    {multiButtonsOrganization(props.addButton, props.removeButton, props.editButton)}
                  </div>
                }
                {props.tag === 'autocomplete-vc' &&
                  <Autocomplete
                    getOptionSelected={(option, value) => option.key === value.key}
                    className={props.className}
                    id={props.id + "-input"}
                    options={[...values]}
                    filterOptions={options => options}
                    popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                    value={props.selectedValue}
                    getOptionLabel={(selected) => getTranslation(selected.i18n, props.language, props.fallbackLng)}
                    onChange={(event, value) => props.handleChange(value)}
                    onInputChange={(e, value) => { e && handleChangeInput(value) }}
                    ListboxProps={{ onScroll: handleScroll }}
                    renderOption={(option, { selected }) => {
                      if (preValues.find(val => val.value === option.value))
                        return <b>{getTranslation(option.i18n, props.language, props.fallbackLng)}</b>
                      else
                        return getTranslation(option.i18n, props.language, props.fallbackLng)
                    }}
                    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" size="small" />}
                    disabled={props.disabled || false}
                  />
                }
                {props.tag === 'autocomplete-group' &&
                  <Autocomplete
                    getOptionSelected={(option, value) => option._id === value._id}
                    className={props.className}
                    id={props.id + "-input"}
                    options={[...values]}
                    filterOptions={options => options}
                    popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                    value={props.selectedValue}
                    getOptionLabel={(selected) => getTranslation(selected.name, props.language, props.fallbackLng)}
                    onChange={(event, value) => props.handleChange(value)}
                    onInputChange={(e, value) => { e && handleChangeInput(value) }}
                    ListboxProps={{ onScroll: handleScroll }}
                    renderOption={(option, { selected }) => {
                      if (preValues && preValues.find(val => val.value === option.value))
                        return <b>{getTranslation(option.name, props.language, props.fallbackLng)}</b>
                      else
                        return getTranslation(option.name, props.language, props.fallbackLng)
                    }}
                    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" size="small" />}
                  />
                }
                {props.tag === 'autocomplete-owner' &&
                  <Autocomplete
                    getOptionSelected={(option, value) => option._id === value._id}
                    className={props.className}
                    id={props.id + "-input"}
                    options={[...values]}
                    popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                    value={props.selectedValue}
                    disabled={props.disabled}
                    getOptionLabel={(selected) => selected.email}
                    onChange={(event, value) => props.handleChange(value)}
                    onInputChange={(e, value) => { e && handleChangeInput(value) }}
                    ListboxProps={{ onScroll: handleScroll }}
                    renderOption={(option, { selected }) => {
                      if (preValues && preValues.find(val => val._id === option._id))
                        return <b>{option.email}</b>
                      else
                        return option.email
                    }}
                    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" size="small" />}
                  />
                }
                {
                  props.tag === 'autocomplete-checkbox' &&
                  <Autocomplete
                    getOptionSelected={(option, value) => option.key === value.key}
                    className={props.className}
                    multiple
                    autoComplete
                    popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                    id={props.id + "-input"}
                    options={[...values]}
                    filterOptions={options => options}
                    value={props.selectedValue || []}
                    disableCloseOnSelect
                    inputValue={search}
                    onInputChange={(e, value) => { e && handleChangeInput(value) }}
                    onChange={(event, value) => props.handleChange(value)}
                    getOptionLabel={(option) => getTranslation(option.i18n, props.language, props.fallbackLng)}
                    ListboxProps={{ onScroll: (event) => handleScroll(event) }}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => {
                        let style = (props.previous && props.previous.original && props.previous.original.some(prev => prev.value === option.value) || !props.previous) ? {} : { color: "#30A849", fontWeight: "bold" }
                        return <Chip
                          label={<span style={style}>{getTranslation(option.i18n, props.language, props.fallbackLng)}</span>}
                          {...getTagProps({ index })}

                        />
                      }
                      )
                    }
                    }
                    renderOption={(option, { selected }) => {
                      let sel = !!((props.selectedValue && props.selectedValue.find(selectedVal => selectedVal.value === option.value)) || selected)
                      let highlight = preValues.find(val => val.value === option.value)
                      return (
                        <>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={sel}
                          />
                          {highlight ? <b>{getTranslation(option.i18n, props.language, props.fallbackLng)}</b> : getTranslation(option.i18n, props.language, props.fallbackLng)}
                        </>
                      )
                    }
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={props.label} variant="outlined" size="small" />
                    )}
                    disabled={props.disabled || false}
                  />
                }
                {
                  (props.required && props.isOpen) &&
                  <FormHelperText id={props.id + '-error'} error>{props.message && props.message}</FormHelperText>
                }
              </div>
            </Grid>
            {props.previous && props.previous.original && !Array.isArray(props.previous.original) ? <Grid item xs={12}>
              <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                {t('labels.workflows.previous') + props.previous}
              </Typography>
            </Grid>
              : props.previous && props.previous.original && <Grid item xs={12}>
                <span className='MuiTypography-root textField__typography--preview MuiTypography-subtitle1 MuiTypography-colorTextSecondary MuiTypography-gutterBottom'>{t('labels.workflows.previous')}</span>
                {props.previous.original.map((prev, index) => {
                  let style = (props.selectedValue && props.selectedValue.some(sv => prev.value === sv.value)) ? {} : { color: "#C55700", fontWeight: "bold" }
                  return <React.Fragment key={"listOfValues-option" + index}>{index > 0 && <span style={{ fontSize: "0.813rem" }} className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorTextSecondary MuiTypography-gutterBottom">;</span>}<span style={style} className="MuiTypography-root textField__typography--preview MuiTypography-subtitle1 MuiTypography-colorTextSecondary MuiTypography-gutterBottom">{getTranslation(prev.i18n, props.language, props.fallbackLng)}</span>
                  </React.Fragment>
                }
                )}

              </Grid>
            }
          </> :
          props.selectedValue.map((val, index) => (
            <Grid container className="textField__container--multiple" key={index}>
              <Grid item xs={12} md={12}>
                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: props.title + (props.optional ? " (" + t('labels.common.optional') + ")" : "") }} />
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} className={props.noMargin ? "" : "margin__top--9px"}>
                <div className="textField__multi--margin--top">
                  {
                    props.tag === 'autocomplete-policy' &&
                    <div>
                      <div className="form__flex--multiple">
                        <Autocomplete
                          getOptionSelected={(option, value) => option.key === value.key}
                          className={val && val.diff ? val.cssClassName : props.className}
                          fullWidth
                          id={props.id + "-input"}
                          options={[...values]}
                          filterOptions={options => options}
                          value={val && val.diff && val.current ? val.current : val}
                          popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                          getOptionLabel={(selected) => selected.i18n ? getTranslation(selected.i18n, props.language, props.fallbackLng) : ""}
                          onChange={(event, value) => handleChangeArrayInput(value, index)}
                          onOpen={(e, value) => { e && handleChangeInput(value) }}
                          onInputChange={(e, value) => { e && handleChangeInput(value) }}
                          ListboxProps={{ onScroll: handleScroll }}
                          renderOption={(option, { selected }) => {
                            if (preValues.find(val => val.value === option.value))
                              return <b>{getTranslation(option.i18n, props.language, props.fallbackLng)}</b>
                            else
                              return getTranslation(option.i18n, props.language, props.fallbackLng)
                          }}
                          renderInput={(params) => <TextField {...params} label={props.label} size="small" variant="outlined" />}
                          disabled={props.disabled || false}
                        />
                        {multiButtons(index, props.disabled)}
                      </div>
                      {val && val.diff && val.prev && val.update &&
                        <Grid item xs={12}>
                          <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('labels.workflows.previous') + getTranslation(val.prev.i18n, props.language, props.fallbackLng)}
                          </Typography>
                        </Grid>
                      }
                      {props.selectedValue[index] &&
                        <div>
                          <Grid container className="textField__container--y">
                            {props.showDescription && props.langs && props.langs.map((lang, langIndex) =>
                              <Grid key={lang + index + langIndex} item xs={12} md={6} className={langIndex % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                  <div dangerouslySetInnerHTML={{ __html: t('labels.repositories.policy.text', { lang }) }} />
                                </Typography>

                                <TextField
                                  fullWidth
                                  size="small"
                                  id={props.id + "-description-" + lang}
                                  className={(val && val.diff && val.cssClassName != null ? val.cssClassName : "")}
                                  variant="outlined"
                                  multiline={true}
                                  value={val && val.diff && val.current && val.current.description ? getTranslation(val.current.description, lang, lang) : props.selectedValue[index].description ? getTranslation(props.selectedValue[index].description, lang, lang) : ""}
                                  onChange={(event) => handleChange(event, index, "description", lang)}
                                  disabled={props.disabled || false}
                                  InputProps={props.renderIcon && !props.disabled && {
                                    endAdornment: (
                                      <Tooltip title={t('tooltip.autoComplete')}>
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={(event) => handleChange(event, index, "descriptionSpread", lang)}
                                          >
                                            <LowPriorityIcon fontSize="small" />
                                          </IconButton>
                                        </InputAdornment>
                                      </Tooltip>
                                    )
                                  }}
                                />
                                {val && val.diff && val.prev && val.update &&
                                  <Grid item xs={12}>
                                    <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                      {t('labels.workflows.previous') + (val.prev.description ? val.prev.description[lang] : undefined)}
                                    </Typography>
                                  </Grid>
                                }
                                {props.isOpen && props.isOpen[index] && props.isOpen[index][langIndex] &&
                                  <FormHelperText id={props.id + '-error'} error>{props.message && props.message}</FormHelperText>
                                }
                              </Grid>
                            )}
                          </Grid>
                          <Grid container className="textField__container--y">
                            <Grid item xs={12}>
                              <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                {t('labels.repositories.policy.link')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                size="small"
                                id={props.id + "-link"}
                                className={(val && val.cssClassName != null ? val.cssClassName : "")}
                                variant="outlined"
                                value={val && val.diff && val.current ? val.current.link : props.selectedValue[index].link || ""}
                                onChange={(event) => handleChange(event, index, "link")}
                                disabled={props.disabled || false}
                              />
                              {val && val.diff && val.prev && val.update &&
                                <Grid item xs={12}>
                                  <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                    {t('labels.workflows.previous') + val.prev.link}
                                  </Typography>
                                </Grid>
                              }
                            </Grid>
                          </Grid>
                        </div>
                      }
                    </div>
                  }
                  {
                    props.tag === 'autocomplete-link' &&
                    <React.Fragment key={"link-" + index}>
                      <div className="form__flex--multiple">
                        <Autocomplete
                          getOptionSelected={(option, value) => option.key === value.key}
                          className={val && val.diff ? val.cssClassName : props.className}
                          fullWidth
                          filterOptions={options => options}
                          id={props.id + "-input"}
                          options={[...values]}
                          value={val && val.diff && val.current ? val.current : val}
                          popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                          getOptionLabel={(selected) => selected.i18n ? getTranslation(selected.i18n, props.language, props.fallbackLng) : ""}
                          onChange={(event, value) => handleChangeArrayInput(value, index)}
                          onOpen={(e, value) => { e && handleChangeInput(value) }}
                          onInputChange={(e, value) => { e && handleChangeInput(value) }}
                          ListboxProps={{ onScroll: handleScroll }}
                          renderInput={(params) => <TextField {...params} label={props.label} size="small" variant="outlined" />}
                          disabled={props.disabled || false}
                        />
                        {multiButtons(index, props.disabled)}
                      </div>
                      {val && val.diff && val.prev && val.update &&
                        <Grid item xs={12}>
                          <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('labels.workflows.previous') + getTranslation(val.prev.i18n, props.language, props.fallbackLng)}
                          </Typography>
                        </Grid>
                      }
                      {props.selectedValue[index] &&
                        <div>

                          <Grid item xs={12}>
                            <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                              {t('labels.repositories.policy.link')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size="small"
                              id={props.id + "-value"}
                              className={"selectComponent--input " + (val && val.diff && val.cssClassName != null ? val.cssClassName : "")}
                              variant="outlined"
                              value={val && val.diff && val.current ? val.current.link : props.selectedValue[index].link || ""}
                              onChange={(event) => handleChange(event, index, "link")}
                              disabled={props.disabled || false}
                            />
                          </Grid>
                        </div>}
                      {val && val.diff && val.prev && val.update &&
                        <Grid item xs={12}>
                          <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('labels.workflows.previous') + val.prev.link}
                          </Typography>
                        </Grid>
                      }
                    </React.Fragment>
                  }
                  {
                    (props.required && props.isOpen) &&
                    <FormHelperText id={props.id + '-error'} error>{props.message && props.message}</FormHelperText>
                  }
                </div>
              </Grid>
              <hr />
            </Grid>
          )
          )
      }
    </Grid >
  )
}
