import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import Terms from '@components/Configuration/Terms.js'

export default function ControlledVocabularyPage() {
    const [t, i18n] = useTranslation('common')
    const [organization, setOrganization] = useState(null)
    let { id } = useParams()

    let history = useHistory()
    const [user] = useContext(UserContext)

    useEffect(() => {
        getRequest(host + '/organizations/' + id)
            .then(res => {
                setOrganization(res)
            })
    }, [setOrganization])

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <Typography id="backoffice-organizations-title" component="h2" className="admin__card--title">{t('titles.organizations.organization')}</Typography>
                </Grid>
                <Grid item xs={6} className="admin__align--left">
                    {user &&
                        <Tooltip title={t('tooltip.organizations.edit')}>
                            <Button
                                id="backoffice-organizations-edit-button"
                                className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/organizations/update/' + id)}
                                startIcon={
                                    <EditIcon className="admin__icon--create" />
                                }
                                role="adminCards"
                            >
                                {t("common.buttons.edit")}
                            </Button>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
            <div >
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.organization.show')}</Typography>
            </div>
            {organization &&
                <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
                    <Grid container>
                        <TextLable
                            id="name"
                            title={t('labels.organizations.name')}
                            value={getTranslation(organization.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                        />
                        <TextLable
                            id="url"
                            title={t('labels.organizations.url')}
                            value={organization.url ? organization.url : t('common.labels.notDefined')}
                        />

                        <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('titles.organizations.contact')}
                        </Typography>

                        <TextLable
                            id="email"
                            title={t('labels.organizations.email')}
                            value={organization.email ? organization.email : t('common.labels.notDefined')}
                        />
                        <TextLable
                            id="phones"
                            title={t('labels.organizations.phones')}
                            value={organization.phones && organization.phones.length > 0 ? organization.phones : t('common.labels.notDefined')}
                            isArray={organization.phones && organization.phones.length > 0}
                        />
                        <TextLable
                            id="fax"
                            title={t('labels.organizations.fax')}
                            value={organization.fax ? organization.fax : t('common.labels.notDefined')}
                        />
                        <TextLable
                            id="address"
                            title={t('labels.organizations.address')}
                            value={organization.address ? organization.address : t('common.labels.notDefined')}
                        />
                        <TextLable
                            id="country"
                            title={t('labels.organizations.country')}
                            value={organization.country && organization.country[0] ? getTranslation(organization.country[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
                        />
                        <TextLable
                            id="gps-lat"
                            title={t('labels.organizations.gps.lat')}
                            value={organization.gps ? organization.gps.lat : t('common.labels.notDefined')}
                        />
                        <TextLable
                            id="gps-lng"
                            title={t('labels.organizations.gps.lng')}
                            value={organization.gps ? organization.gps.lng : t('common.labels.notDefined')}
                        />
                        <TextLable
                            id="schedule"
                            title={t('labels.organizations.schedule')}
                            value={organization.schedule ? getTranslation(organization.schedule, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
                        />

                        <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('titles.organizations.aditional')}
                        </Typography>

                        {organization.provider &&
                            <TextLable
                                id="provider"
                                title={t('labels.organizations.provider')}
                                value={organization.provider}
                            />}
                        {organization.ringgoldID &&
                            <TextLable
                                id="riggoldID"
                                title={t('labels.organizations.ringgoldID')}
                                value={organization.ringgoldID}
                            />
                        }

                        <TextLable
                            id="organizationType"
                            title={t('labels.organizations.organizationType')}
                            value={organization.organizationType && organization.organizationType[0] ? getTranslation(organization.organizationType[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
                        />
                        {/* <TextLable
                            id="liability"
                            title={t('labels.organizations.liability')}
                            value={organization.liability && organization.liability.length > 0 ? organization.liability.map(lia => getTranslation(lia.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])) : t('common.labels.notDefined')}
                            isArray={organization.liability && organization.liability.length > 0}
                        /> */}
                        <TextLable
                            id="legalForm"
                            title={t('labels.organizations.legalForm')}
                            value={organization.legalForm ? getTranslation(organization.legalForm.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
                        />
                        <TextLable
                            id="comments"
                            title={t('labels.organizations.comments')}
                            value={organization.comments ? getTranslation(organization.comments, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
                        />
                        <TextLable
                            id="notes"
                            title={t('labels.organizations.notes')}
                            value={organization.notes ? getTranslation(organization.notes, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
                        />
                        <Terms
                            showTerms={true}
                            terms={organization.terms}
                            value={t('labels.organizations.terms')}
                            acceptedAtLabel={t('labels.organizations.termsAcceptedAt')}
                            showUser={true}
                        />
                    </Grid>
                </Card>}
            <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
                <SubmitButtonCommon
                    id="organization-back-button"
                    className="form__button--color-error"
                    title={t('table.confirm.backButton')}
                    goBack />
            </Grid>
        </>
    )
}
