import React, { useState, createContext, useEffect } from 'react'

export const CaptchaKeyContext = createContext()

export const CaptchaKeyContextProvider = props => {
    const [captchaKey, setCaptchaKey] = useState(null)

    useEffect(() => {
        fetch('manifest.json')
            .then((r) => r.json())
            .then((data) => {
                setCaptchaKey(data["DRD_CAPTCHA_KEY"])
            })
    }, [setCaptchaKey])

    return (
        <CaptchaKeyContext.Provider value={[captchaKey]}>
            {props.children}
        </CaptchaKeyContext.Provider>
    )
}