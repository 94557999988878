import React, { useContext, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Visibility from '@material-ui/icons/Visibility';
import { SnackBarContext } from "@context/snackBarContext.js"


import { UserContext } from '@context/userContext';
import Axios from 'axios'
import { host } from '@utils/config'


import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import ImportRepository from '@components/Repository/ImportRepository.js'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';



const qs = require('qs')

export default function ImportFile() {
  const [t, i18n] = useTranslation("common")
  let history = useHistory()
  const [user] = useContext(UserContext)
  const params = qs.parse(useHistory().location.search.replace(/\?/, ''))
  const [response, setResponse] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [filename, setFilename] = useState('')
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  const submitFile = async (file) => {
    setFilename(file.name)
    let formData = new FormData()
    formData.append("files", file)
    let newFile = await Axios.post(host + "/upload", formData)
    if (newFile) {
      Axios.post(host + "/repositories/import", { id: newFile.data[0]._id })
        .then(res => {
          setDisabled(false)
          setResponse(res.data)
          handleBack(res.data)
        })
    }
  }
  const snackForImportation = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  const handleButtonClick = (event, id) => {
    event.stopPropagation()
    history.push('/admin/repositories/' + id)
  }

  const handleBackClick = (event) => {
    window.location.reload(true);
  }

  const handleBack = (data) => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      revivalBack(data)
    };
  };

  const revivalBack = (data) => {
    window.onpopstate = undefined;
    if (data.some(d => d.errors.length > 0))
      window.location.reload(true);
    else
      history.push('/admin/repositories/')

  };

  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/repositories", name: "repositories" },
        { name: "import" }
      ]} />
      <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.repositories.import')}</Typography>
      <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">
        <Trans i18nKey="help.repository.import_file" t={t}
          components={[<a href={host + "/repositories?action=export&format=csv"} />,
          <a href={host + "/repositories?action=export"} />,
          <a href={host + "/repositories?action=export&format=xml"} />
          ]} />
      </Typography>
      <ImportRepository
        submitFile={submitFile}
        disabled={disabled}
        snackForImportation={snackForImportation}
      />

      {response &&

        <Typography className="textField__typography--subtitle" variant="subtitle1" component="h1" color="textSecondary" gutterBottom>
          {t('messages.success.repositories.import')}
        </Typography>
      }

      {response && response.map((r, index) =>
        <Accordion defaultExpanded={r.errors.length === 0 ? false : true} key={r.name + '-' + index}>
          <AccordionSummary expandIcon={r.errors.length === 0 ? false : <ExpandMoreIcon />}>
            <Typography className='repositories__import--summary' component="h4" variant="subtitle1">{r.repository === 'file' ? filename : r.repository}</Typography>
            <Tooltip disableHoverListener={r.errors.length > 0} title={t('titles.repositories.seeRepo')}>
              <Button
                id={r.repository + "-" + index}
                className={r.errors.length === 0 ? 'repositories__importChip--success' : 'repositories__importChip--error'}
                disableRipple={r.errors.length > 0}
                onClick={(event) => r.errors.length === 0 && handleButtonClick(event, r.id)}>{r.errors.length === 0 ? <Visibility /> : t('messages.error.repositories.import_error')}
              </Button>
            </Tooltip>
          </AccordionSummary>
          {r.errors.length === 0 ?
            ''
            :
            <AccordionDetails className='repositories__import--error'>
              <TextLable
                id={"import-state-" + r.repository + '-' + index}
                value={"\n<ol>" + r.errors.map(error => { return `<li>${error.msg[i18n.language]}</li>` }).join('') + "</ol>"}
                url={false}
              />
            </AccordionDetails>
          }
        </Accordion>
      )}

      <>
        {!disabled && response && response.every(r => r.errors.length === 0) ?
          <Grid container className="form__button--justify-left repositoryform__buttons--margins">
            <SubmitButtonCommon
              id="repository-import-back-button"
              className="form__button--color-error"
              title={t('table.confirm.backButton')}
              goBack />
          </Grid>
          :
          !disabled &&
          <Grid container className="form__button--justify-left repositoryform__buttons--margins">
            <SubmitButtonCommon
              id="repository-import-back-button"
              className="form__button--color-error"
              title={t('table.confirm.backButton')}
              handleClick={(event) => handleBackClick(event)} />
          </Grid>
        }
      </>
    </div>
  );
}
