import React, { useEffect, useState } from 'react'

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config';

import RepositoryShow from '@components/Workflow/Repository/RepositoryShow.js'

export default function NewRepository(props) {
  const [repository, setRepository] = useState(null)

  useEffect(() => {
    getRequest(host + "/repositories/" + props.id)
      .then(res => {
        setRepository({
          ...res, organization: res.organization._id
        })
      })
  }, [setRepository])

  return (
    <>
      {repository && <RepositoryShow object={repository} />}
    </>
  )
}