import React, { useContext, useEffect, useState } from 'react'
import { Container } from '@material-ui/core'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import { SnackBarContext } from "@context/snackBarContext.js"

import RepositoryForm from '@components/Repository/RepositoryForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { UserContext } from '@context/userContext'

import '@styles/frontOffice/Repository/Repositories.css'
import '@styles/Common/Common.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewRepository(props) {
  const [t] = useTranslation("common")
  const [user] = useContext(UserContext)
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [errors, setErrors] = useState([])
  const [langs, setLangs] = useState(null)
  const [uploadSize, setUploadSize] = useState(null)
  let history = useHistory()

  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
    getRequest(host + '/configurations/public?type=UPLOADSIZE')
      .then(res => {
        setUploadSize(res[0])
      })
  }, [setLangs, setUploadSize])

  const submitRequest = async (repository) => {
    let newLogo;
    if (repository.newLogo) {
      const data = new FormData()
      data.append('files', repository.newLogo)

      newLogo = await Axios.post(host + "/upload", data)
      newLogo = newLogo.data[0]
      repository.logo = { url: newLogo.url, _id: newLogo._id }
    }
    delete repository.newLogo

    // if (!newLogo) snackForRepository("error", t('repositories.errors.create'))
    // else {
    if (newLogo) {
      repository.logo = { url: newLogo.url, _id: newLogo._id }
    }

    Axios.post(host + "/repositories/", {
      ...repository
    })
      .then(res => {
        if (res.data.objectType)
          snackForRepository("success", t('messages.success.repositories.createRequest'))
        else snackForRepository("success", t('messages.success.repositories.create'))
        history.goBack()
      })
      .catch(err => {
        if (err.response.data.message === 'Missing/Invalid repository fields') {
          setErrors(err.response.data)
          snackForRepository("error", t('messages.error.common.validation'))
        }
        else
          snackForRepository("error", t('messages.error.repositories.create'))
      }
      )
    // }
  }

  const snackForRepository = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    langs && uploadSize &&
    <div className={props.tag === 'admin' ? 'admin__container-bottom' : 'public__container--margin public__container--padding-bottom'}>
      {props.tag === 'admin' ?
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin", name: "admin" },
          { href: "/#/admin/repositories", name: "repositories" },
          { name: "repositories_new" }
        ]} /> :
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { name: "repositories_new" }
        ]} />
      }
      <Typography component="h2" className="admin__card--title">{t('titles.repositories.create')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.repository.form')}</Typography>
      </div>
      <RepositoryForm
        snackForRepository={snackForRepository}
        submitRepository={submitRequest}
        submitLable={t('repositories.submit.create')}
        langs={langs}
        uploadSize={uploadSize}
        errors={errors}
      />
    </div>
  )
}
