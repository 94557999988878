import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import RepositoryForm from '@components/Repository/RepositoryForm'
import Typography from '@material-ui/core/Typography';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import TableCommon from '@components/Common/Table'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import { SnackBarContext } from "@context/snackBarContext.js"
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import Axios from 'axios'


export default function ImportAPIRepository(props) {
    const { api, id } = useParams()
    let history = useHistory()
    const [t, i18n] = useTranslation("common")
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
    const [langs, setLangs] = useState(null)
    const [loadedRepo, setLoadedRepo] = useState(null)
    const [repository, setRepository] = useState(null)
    const [errors, setErrors] = useState([])
    const [uploadSize, setUploadSize] = useState(null)


    const snackForRepository = (tag, message) => {
        setTag(tag)
        setMessage(message)
        setIsSnackBar(true)
    }

    const peerReviewConvert = (peerReview, repoTypeMetadata, pr) => {
        if (peerReview === 'Peer review')
            repoTypeMetadata[0].body.attributes[pr].value = repoTypeMetadata[0].body.attributes[pr].options.find(opt => opt.key === "attroption###repoTypeMetadata###journals###peer_review_type-0")
        else if (peerReview.includes('Double blind'))
            repoTypeMetadata[0].body.attributes[pr].value = repoTypeMetadata[0].body.attributes[pr].options.find(opt => opt.key === "attroption###repoTypeMetadata###journals###peer_review_type-2")
        else if (peerReview.includes('Blind'))
            repoTypeMetadata[0].body.attributes[pr].value = repoTypeMetadata[0].body.attributes[pr].options.find(opt => opt.key === "attroption###repoTypeMetadata###journals###peer_review_type-1")
        else
            repoTypeMetadata[0].body.attributes[pr].value = {}

    }

    const journalsMetadata = (repository, repoTypeMetadata) => {
        let publisher = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###publishing_company')
        let issn = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###issn')
        let eissn = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###e_issn')
        let oa = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###access_types')
        let cc = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###cc_license')
        let cr = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###copyright')
        let pc = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###publication_costs')
        let pr = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###peer_review_type')
        let periodicity = repoTypeMetadata[0].body.attributes.findIndex(attr => attr.id === 'repoTypeMetadata###journals###periodicity')
        repoTypeMetadata[0].body.attributes[publisher].value = langs.reduce((l, val) => ({ ...l, [val]: repository.bibjson.publisher.name }), {})
        repoTypeMetadata[0].body.attributes[eissn].value = repository.bibjson.eissn
        repoTypeMetadata[0].body.attributes[issn].value = repository.bibjson.pissn
        repoTypeMetadata[0].body.attributes[cc].value = repoTypeMetadata[0].body.attributes[cc].options.find(opt => opt.value === repository.bibjson.license[0].type)
        if (repository.bibjson.oa_start)
            repoTypeMetadata[0].body.attributes[oa].value = [repoTypeMetadata[0].body.attributes[oa].options.find(opt => opt.key === "attroption###repoTypeMetadata###journals###access_types-0")]
        else
            repoTypeMetadata[0].body.attributes[oa].value = []

        if (repository.bibjson.copyright.author_retains)
            repoTypeMetadata[0].body.attributes[cr].value = repoTypeMetadata[0].body.attributes[cr].options.find(opt => opt.key === "attroption###repoTypeMetadata###journals###copyright-1")
        else
            repoTypeMetadata[0].body.attributes[cr].value = {}
        if (repository.bibjson.apc.has_apc)
            repoTypeMetadata[0].body.attributes[pc].value = true
        else
            repoTypeMetadata[0].body.attributes[pc].value = false

        peerReviewConvert(repository.bibjson.editorial.review_process[0], repoTypeMetadata, pr)
        repoTypeMetadata[0].body.attributes[periodicity].value = {}



        return repoTypeMetadata
    }

    const loadDoaj = async (repository) => {
        let repoType = await getRequest(host + '/controlled-vocabularies?key=' + encodeURIComponent('repositoryType###journals'))
        let repoTypeMetadata = await getRequest(host + '/configurations/public?type=REPOSITORYTYPE&&key=' + encodeURIComponent("repositoryType###journals"))
        let newRepo = {}
        newRepo.name = langs.reduce((l, val) => ({ ...l, [val]: repository.bibjson.title }), {})
        newRepo.description = langs.reduce((l, val) => ({ ...l, [val]: '' }), {})
        newRepo.url = repository.bibjson.ref.journal
        newRepo.repositoryType = repoType.entities[0]
        newRepo.keyWords = repository.bibjson.keywords.map(kw => langs.reduce((l, val) => ({ ...l, [val]: kw }), {}))
        if (repository.bibjson.alternative_title)
            newRepo.name.en = repository.bibjson.alternative_title


        newRepo.repoTypeMetadata = journalsMetadata(repository, repoTypeMetadata)
        setRepository(newRepo)
    }

    const loadOpendoar = async (repository) => {
        let contents = await getRequest(host + '/controlled-vocabularies?field=content&_limit=-1')
        let subjects = await getRequest(host + '/controlled-vocabularies?field=subject&_limit=-1')
        let softwares = await getRequest(host + '/controlled-vocabularies?field=software&_limit=-1')
        let repoType = await getRequest(host + '/controlled-vocabularies?key=' + encodeURIComponent('repositoryType###' + repository.repository_metadata.type))
        let repoTypeMetadata
        if (repoType.count > 0)
            repoTypeMetadata = await getRequest(host + '/configurations/public?type=REPOSITORYTYPE&&key=' + encodeURIComponent(repoType.entities[0].key))
        let newRepo = {}
        newRepo.name = langs.reduce((l, val) => ({ ...l, [val]: repository.repository_metadata.name[0].name }), {})
        newRepo.description = langs.reduce((l, val) => ({ ...l, [val]: '' }), {})
        newRepo.url = repository.repository_metadata.url
        newRepo.repositoryType = repoType.entities[0]
        newRepo.content = repository.repository_metadata.content_types.map(ct => contents.entities.find(c => c.value === ct)).filter(ct => ct !== undefined)
        newRepo.subject = repository.repository_metadata.content_subjects_phrases.map(subP => subjects.entities.find(s => s.i18n.en === subP.phrase)).filter(ct => ct !== undefined)
        newRepo.software = softwares.entities.filter(sw => sw.value === repository.repository_metadata.software.name)
        newRepo.repoTypeMetadata = repoTypeMetadata
        setRepository(newRepo)
    }

    const submitRequest = async (repository) => {
        let newLogo;
        if (repository.newLogo) {
            const data = new FormData()
            data.append('files', repository.newLogo)

            newLogo = await Axios.post(host + "/upload", data)
            newLogo = newLogo.data[0]
            repository.logo = { url: newLogo.url, _id: newLogo._id }
        }
        delete repository.newLogo

        if (newLogo) {
            repository.logo = { url: newLogo.url, _id: newLogo._id }
        }

        Axios.post(host + "/repositories/", {
            ...repository
        })
            .then(res => {
                if (res.data.objectType)
                    snackForRepository("success", t('messages.success.repositories.createRequest'))
                else snackForRepository("success", t('messages.success.repositories.create'))
                history.goBack()
            })
            .catch(err => {
                if (err.response.data.message === 'Missing/Invalid repository fields') {
                    setErrors(err.response.data)
                    snackForRepository("error", t('messages.error.common.validation'))
                }
                else
                    snackForRepository("error", t('messages.error.repositories.create'))
            }
            )
    }

    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => setLangs(res[0].body))
        getRequest(`${host}/imports/load/${api}/${id}`)
            .then(res => setLoadedRepo(res))
        getRequest(host + '/configurations/public?type=UPLOADSIZE')
            .then(res => {
                setUploadSize(res[0])
            })

    }, [api, id])

    useEffect(() => {
        if (loadedRepo) {
            if (api === 'opendoar') {
                loadOpendoar(loadedRepo)
            }
            else if (api === 'doaj')
                loadDoaj(loadedRepo)
        }

    }, [loadedRepo])
    return (
        <div className="admin__container-bottom" >
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { href: "/#/admin/repositories", name: "repositories" },
                { name: "import" }
            ]} />
            <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.repositories.import')}</Typography>
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">
                {t('help.repository.import_form', { api: t('common.buttons.import_' + api) })}
            </Typography>
            {
                repository &&
                <RepositoryForm
                    name={repository.name}
                    repositoryType={repository.repositoryType}
                    description={repository.description}
                    url={repository.url}
                    keyWords={repository.keyWords}
                    software={repository.software ? repository.software[0] : null}
                    content={repository.content}
                    subject={repository.subject}
                    langs={langs}
                    import={true}
                    uploadSize={uploadSize}
                    repoTypeMetadata={repository.repoTypeMetadata[0].body.attributes}
                    errors={errors}
                    snackForRepository={snackForRepository}
                    submitLable={t('repositories.submit.create')}
                    submitRepository={submitRequest}
                />
            }
        </div>
    )
}
