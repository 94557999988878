import React, { useContext } from "react"
import { useHistory } from 'react-router-dom';
import { Typography } from "@material-ui/core";

import Axios from "axios"
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"
import OrganizationForm from "@components/Organization/OrganizationForm";
import { useTranslation } from "react-i18next";
import { UserContext } from '@context/userContext'

import '@styles/backOffice/Organization/Organizations.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewOrganization() {
  const [t] = useTranslation("common")
  const [user] = useContext(UserContext)
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

  let history = useHistory()

  const submitRequest = async (org) => {
    Axios.post(host + "/organizations", {
      ...org
    })
      .then(res => {
        if (res.data.objectType)
          snackForOrganization("success", t('messages.success.organizations.createRequest'))
        else snackForOrganization("success", t('messages.success.organizations.create'))
        history.goBack()
      })
      .catch(err => {
        snackForOrganization("error", t('messages.error.organizations.create'))
      })
  }

  const snackForOrganization = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
      <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin" , name: "admin"},
          { href: "/#/admin/organizations" , name: "organizations"},
          { name: "organizations_new" }
        ]} />
      <Typography component="h2" className="admin__card--title">{t('titles.organizations.create')}</Typography>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.organization.form')}</Typography>
      </div>
      <OrganizationForm
        snackForOrganization={snackForOrganization}
        submitOrganization={submitRequest}
        submitLable={t('organizations.form.submit.create')}
      />
    </div>
  )
}
