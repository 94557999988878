import React from 'react'

import NotificationPage from '@components/Notification/Notification.js'

import '@styles/backOffice/Notification/Notifications.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Notification() {
  return (
    <div className="admin__container-bottom" >
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin", name: "admin" },
      { href: "/#/admin/notifications", name: "notifications"},
      { name: "notification" },
    ]} />
      <NotificationPage />
    </div>
  )
}
