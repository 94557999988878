import React, { useContext, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import cienciaid from '@utils/assets/logo-cienciaid.svg'
import Checkbox from '@material-ui/core/Checkbox';

import CaptchaCommon from '@components/Common/Captcha';

import Axios from 'axios';
import { host } from '@utils/config'

import { UserContext } from '@context/userContext';

import { useHistory, useLocation } from 'react-router-dom';

import { SnackBarContext } from "@context/snackBarContext.js"
import { CaptchaKeyContext } from '@context/captchaKeyContext';

import "@styles/frontOffice/User/Login.css"

export default function Login() {
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [t, i18n] = useTranslation('common')

  const [checked, setChecked] = useState((localStorage.getItem('remeberMe') === 'true') || false)

  const [user, setUser] = useState({
    email: localStorage.getItem('rememberMeEmail') || '',
    password: '',
    showPassword: false
  })


  const [captcha, setCaptcha] = useState('')
  const [captchaKey] = useContext(CaptchaKeyContext)

  const [isEmail, setIsEmail] = useState(false)
  const [isPassword, setIsPassword] = useState(false)
  const [isCaptcha, setIsCaptha] = useState(false)

  const setUserLogin = useContext(UserContext)[1]
  const recaptchaRef = React.createRef();
  let history = useHistory()
  const location = useLocation()

  const handleClickShowPassword = () => {
    setUser({ ...user, showPassword: !user.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCaptchaChange = (value) => {
    setCaptcha(value)
  }

  const handleCaptchaSubmit = () => {
    recaptchaRef.current.execute();
  }


  const handleClickAutoLogin = () => {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/
    let validate = true
    if (!user.email.match(emailRegExp)) {
      setIsEmail(true)
      validate = false
    } else setIsEmail(false)
    if (!user.password.match(passRegExp)) {
      setIsPassword(true)
      validate = false
    } else setIsPassword(false)

    if (captchaKey !== '' && !captcha) {
      setIsCaptha(true)
      validate = false
    } else setIsCaptha(false)

    if (validate) {
      localStorage.setItem('remeberMeEmail', user.email)
      let loginUser = {
        identifier: user.email,
        password: user.password
      }
      Axios.defaults.withCredentials = true;
      Axios.post(host + '/auth/local', loginUser)
        .then(res => {
          i18n.changeLanguage(res.data.user.lang)
          setUserLogin(res.data.user)
          setIsSnackBar(true)
          setTag("success")
          setMessage(t('messages.success.users.login'))
          if (location.state && location.state !== '') {
            history.push(location.state)
          }
          else {
            history.push('/home')
          }

        })
        .catch((error) => {
          if (error.response && error.response.data) {
            let message = error.response.data.message[0].messages[0].message;
            if (message === 'Your account has been blocked by an administrator') {
              setMessage(t('messages.error.users.login.blocked'))
            }
            else {
              if (message === 'Your account email was not confirmed') {
                setMessage(t('messages.error.users.login.confirmed'))
              }
              else {
                if (message === 'Your account email was not confirmed by your organization') {
                  setMessage(t('messages.error.users.login.confirmedByOrganization'))
                }
                else {
                  if (message === 'Identifier or password invalid.') {
                    setMessage(t('messages.error.users.login.password'))
                  }
                  else {
                    setMessage(t('messages.error.users.login.default'))
                  }
                }
              }
            }
          }
          else {
            setMessage(t('messages.error.users.login.default'))
          }
          setIsSnackBar(true)
          setTag("error")

        })
    }
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleClickAutoLogin()
    }
  }

  const handleChange = (e) => {
    let newUser = user

    newUser[e.target.id] = e.target.value
    setUser(newUser)
  }

  const handleChangeCheckbox = (event) => {
    if (event.target.checked === false) {
      localStorage.setItem('rememberMeEmail', '')
    }
    localStorage.setItem('remeberMe', event.target.checked)
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (location.search === "?emailConfirmed=true") {
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.users.email_confirmed'))
    } else if (location.search === '?confirmedByOrganization=true') {
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.users.organization_confirmed'))
    }
  }, [location])


  return (
    <Card elevation={1} className="login__container">
      <Typography className="login__title" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
        {t('titles.users.login.title')}
      </Typography>
      {history && history.location && history.location.state && history.location.state.includes('repositories/update') &&
        <FormHelperText className='login__required--error' error>{t('messages.error.users.login.required')}</FormHelperText>
      }
      <div className="login__textfield--padding login__textfield--margin-top">
        <TextField
          size="small"
          variant="outlined"
          label="Email"
          margin="dense"
          fullWidth
          id="email"
          type="email"
          onChange={handleChange}
          aria-describedby="component-error-email"
        />
        {isEmail && <FormHelperText error>{t('messages.error.users.email')}</FormHelperText>}
      </div>
      <div className="login__textfield--padding login__textfield--margin-top">
        <TextField
          size="small"
          variant="outlined"
          margin="dense"
          label="Password"
          fullWidth
          id="password"
          aria-describedby="component-error-password"
          onChange={handleChange}
          onKeyDown={handleEnter}
          type={user.showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {user.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {isPassword && <FormHelperText error>{t('messages.error.users.password.error')}</FormHelperText>}
      </div>

      <div className="login__textfield--padding login__textfield--margin-top login__div--flex-between">
        <div className="login__div--flex-column">
          <a href="#/forgotPassword">{t('titles.users.forgotPassword')}</a>
          <a href="#/register">{t('titles.users.register.title')}</a>
        </div>
        <div className="login__remember--me-text">
          <Checkbox
            id="remember-me-checkbox"
            className="login__checkbox-color"
            checked={checked}
            onChange={handleChangeCheckbox}
          />
          {t('titles.users.remeberMe')}
        </div>
      </div>

      <div className="login__textfield--margin-top login__container--center">
        <CaptchaCommon
          recaptchaRef={recaptchaRef}
          language={i18n.language}
          handleCaptchaChange={handleCaptchaChange}
          handleCaptchaSubmit={handleCaptchaSubmit}
          isOpen={isCaptcha}
          message={t('messages.error.users.captcha.error')}
        />
      </div>
      <div className="login__textfield--padding login__textfield--margin-top">
        <Button className="login__fullwidht" size="small" id="login" onClick={handleClickAutoLogin}>{t('titles.users.login.button')}</Button>
      </div>

      <Button href="#/saml/login" className="login__cienciaID--box button__transparent--text-color">
        <span className="login__with--text" style={{ flexDirection: 'column', display: "flex", alignItems: "center" }}>
          <Typography className="login__with--text" gutterBottom>
            {t('titles.users.scienceID.info')}
          </Typography>
          <img src={cienciaid} alt={cienciaid} className="login__science--id--button login__science--id--img"></img>
        </span>
      </Button>
    </Card >
  )
}
