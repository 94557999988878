import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import TableCommon from '@components/Common/Table'

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserContext } from '@context/userContext';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import '@styles/backOffice/Repository/Repositories.css'
import '@styles/Common/Common.css'

import getTranslation from '@utils/functions/utilities.js'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'



const qs = require('qs')
export default function TableRepositories() {
  const [t, i18n] = useTranslation("common")
  let history = useHistory()
  const [user] = useContext(UserContext)
  const params = qs.parse(useHistory().location.search.replace(/\?/, ''))

  const handleChangeField = (event) => {
    setField(event.target.value)
    setSelectedValue(null)
  }

  const handleChangeValue = (value) => {
    setSelectedValue(value)
    if (value) {
      history.push("/admin/repositories?" + qs.stringify({ ...params, _start: 0, field: field, selectedValue: value._id }))
    }
    else {
      delete params["selectedValue"]
      delete params["field"]
      history.push("/admin/repositories?" + qs.stringify({ ...params, _start: 0 }))
    }
  }

  const [field, setField] = useState(params.field || "repositoryType")
  const [selectedValue, setSelectedValue] = useState(null)

  const [headers, setHeaders] = useState(null)

  useEffect(() => {
    setHeaders([
      {
        name: t('labels.repositories.nameTable'),
        value: "name",
        sort: true
      },
      {
        name: t('labels.repositories.repositoryType'),
        value: "repositoryType",
        sort: true
      },
      {
        name: t('labels.repositories.url'),
        value: "url",
      },
      {
        name: t('labels.repositories.software'),
        value: "software",
        sort: true
      }
    ])
    if (params.field && params.selectedValue) {
      setField(params.field)
      getRequest(host + '/controlled-vocabularies/' + params.selectedValue)
        .then(res => {
          setSelectedValue(res)
        })
    }
    else {
      setField("repositoryType")
      setSelectedValue(null)
    }
  }, [setHeaders, t, i18n.language, setSelectedValue, params.field, params.selectedValue])

  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { name: "repositories" }
      ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.repositories.title')}</Typography>
        </Grid>
        <Grid item container xs={6} spacing={1} className="admin__align--left">
          {(user && user.group.permission.find(p => p.controller === "repository" && (p.method === "create" || p.method === "createRequest"))) &&
            <Grid item>
            <Tooltip title={t('tooltip.repositories.create')}>
              <Button
                id="backoffice-repositories-add-button"
                className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/repositories/new')}
                startIcon={
                  <AddIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.create")}
              </Button>
            </Tooltip>
            </Grid>
          }
          {(user && user.group.permission.find(p => p.controller === "repository" && (p.method === "import"))) &&
            <Grid item>
            <Tooltip title={t('tooltip.repositories.import')}>
              <Button
                id="backoffice-repositories-import-button"
                className="actionButton admin__button--import" size="medium" onClick={() => history.push('/admin/repositories/import')}
                startIcon={
                  <AddIcon className="admin__icon--import" />
                }
                role="adminCards"
              >
                {t("common.buttons.import")}
              </Button>
            </Tooltip>
            </Grid>
          }
        </Grid>
      </Grid>
      {headers && <TableCommon
        key={selectedValue ? getTranslation(selectedValue.i18n,i18n.language,i18n.languages[i18n.languages.length-1]) : 'all'}
        field={field}
        selectedValue={selectedValue}
        handleChangeField={handleChangeField}
        setSelectedValue={handleChangeValue}
        headers={headers}
        backendPath="/repositories"
        frontendPath="/repositories"
        sort="name"
        controller="repository"
        filter={selectedValue ? field + '=' + getTranslation(selectedValue.i18n,i18n.language,i18n.languages[i18n.languages.length-1]) : ''}
      />}
    </div>
  );
}
