import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"

import HighlightForm from '@components/Highlight/HighlightForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useParams } from 'react-router-dom'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function EditHighlights() {
  const [t] = useTranslation("common")

  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [highlight, setHighlight] = useState(null)

  let history = useHistory()
  const { id } = useParams()

  const submitHighlight = async (highlight) => {
    try {
      await Axios.put(host + '/highlights/' + id, highlight)
      snackForHighlight("success", t('messages.success.highlights.update'))
      history.goBack()
    }
    catch {
      snackForHighlight("error", t('messages.error.highlights.update'))
    }
  }

  useEffect(() => {
    getRequest(host + "/highlights/" + id)
      .then(res => {
        setHighlight(res)
      })
  }, [setHighlight, id])

  const snackForHighlight = (tag, message) => {
    setTag(tag)
    setMessage(message)
    setIsSnackBar(true)
  }

  return (
    <div className="admin__container-bottom">
      {highlight && <>
      <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin" , name: "admin"},
          { href: "/#/admin/highlights" , name: "highlights"},
          { name: "highlights_update"}
        ]} />
        <Typography component="h2" className="admin__card--title">{t('titles.highlights.update')}</Typography>
        <HighlightForm
          title={highlight.title}
          body={highlight.body}
          description={highlight.description}
          image={highlight.image}
          date={highlight.date}
          startDate={highlight.startDate}
          endDate={highlight.endDate}
          repository={highlight.repository}
          snackForHighlight={snackForHighlight}
          submitHighlight={submitHighlight}
          submitLable={t('highlight.form.submit.update')}
        />
      </>}
    </div>
  )
}