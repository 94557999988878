import React, { useState, useEffect } from 'react'
import HighlightPage from '@components/Highlight/Highlight'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewsItem() {
  const [highlight, setHighlight] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/highlights/' + id)
      .then(res => {
        setHighlight(res)
      })
  }, [id])
  return (
    <>
    {highlight && 
    <div className="admin__container-bottom" >
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin" , name: "admin"},
      { href: "/#/admin/highlights" , name: "highlights"},
      { name: "highlight" }
    ]} />
    <HighlightPage highlight={highlight} />
    </div>
    }
    </>
  )
}