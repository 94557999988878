import React, { useState, useContext } from 'react';
import { Chip, Grid, Typography } from "@material-ui/core"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import UpdateIcon from '@material-ui/icons/Update';
import BackspaceIcon from '@material-ui/icons/Backspace';

import adminAccess from '@utils/comparators/AdminAccess'
import "@styles/backOffice/Workflow/Request.css"
import "@styles/frontOffice/Repository/Repositories.css"
import { host } from '@utils/config'
import { UserContext } from '@context/userContext';

export default function TextLable(props) {
  const [isArray, setIsArray] = useState(props.isArray || false)
  const [history, setHistory] = useState(props.history)
  const [user] = useContext(UserContext)

  if (props.value)
    return (
      <Grid container className="textField__container">
        <Grid item xs={12} md={12}>
          {props.title && props.title !== '' ?
            <Typography id={props.id + "-title"} className="textField__typography" style={{ display: 'flex' }} variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
              <span dangerouslySetInnerHTML={{ __html: props.title }} />
              {history && history.action === "ADD" && <AddCircleOutlineIcon fontSize="small" className="request__color--green" />}
              {history && history.action === "UPDATE" && <UpdateIcon fontSize="small" className="request__color--yellow" />}
              {history && history.action === "REMOVED" && <BackspaceIcon fontSize="small" className="request__color--red" />}
            </Typography>
            : ''
          }
        </Grid>
        <Grid item xs={12} md={12}>
          {!props.image ?
            <Typography id={props.id + "-lable"} className="request__content--typography" variant="subtitle1" component="h3" color="textSecondary" gutterBottom>
              {!isArray ?
                props.url ?
                  props.mailto ?
                    <a href={"mailto: " + props.value}>{props.value}</a>
                    : <a href={props.value} target="_blank">{props.value}</a>
                  : props.org && user && adminAccess("organization", "editRequest", user.group.permission) ?
                    <a href={props.org}>{props.value}</a>
                    : <div dangerouslySetInnerHTML={{ __html: props.value }} />
                :
                props.value.map((val, index) =>
                  !props.url ?
                    <Chip
                      key={val}
                      label={val}
                      classes={{
                        label: "repositories__chip--label"
                      }}
                      className="request__chip--flex"
                      title={val}
                    />
                    : <div><a href={val} target="_blank">{val}</a>{index !== props.value.length - 1 ? ',\u00A0' : ''}</div>
                )

              }
            </Typography>
            :
            <img className="repositoryform__image--size" src={host + props.value} alt={props.value} />
          }
        </Grid>
      </Grid>
    )
  else return null
}
