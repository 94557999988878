import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import { UserContextProvider } from '@context/userContext'
import '@styles/index.css';
import '@styles/material.css';
import i18n from './i18n';
import Accessibility from './Accessibility';
import CookiePopup from './cookiePopup';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <UserContextProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Accessibility/>
        <Router />
        <CookiePopup />
      </Suspense>
    </UserContextProvider>
  </I18nextProvider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
