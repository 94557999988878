import { Card, Grid, Typography, Tooltip, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { DropzoneArea } from 'material-ui-dropzone';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';

import { host } from '@utils/config';

import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getRequest from '@utils/functions/axiosRequest'
import convertBytesToMbsOrKbs from '@utils/functions/byteConverter'

export default function ControlledVocabularyForm(props) {
    const [t, i18n] = useTranslation('common')
    const [langs, setLangs] = useState(null)
    const [uploadSize, setUploadSize] = useState(null)


    const handleReset = () => {
        props.handleReset()
    }

    const handleChangeLabel = (value, lang) => {
        let newValues = { ...props.labels }
        newValues[lang] = value
        props.setLabels(newValues)
    }

    const handleSpreadLabel = (event, value) => {
        if (value !== undefined && value !== '') {
            let newValues = { ...props.labels }
            langs.map(l => newValues[l] === '' || newValues[l] === undefined || event.type === 'dblclick' ? newValues[l] = value : '')
            props.setLabels(newValues)
        }
    }

    const handleChangeInfo = (value, lang) => {
        let newValues = { ...props.info }
        newValues[lang] = value
        props.setInfo(newValues)
    }

    const handleSpreadInfo = (event, value) => {
        if (value !== undefined && value !== '') {
            let newValues = { ...props.info }
            langs.map(l => newValues[l] === '' || newValues[l] === undefined || event.type === 'dblclick' ? newValues[l] = value : '')
            props.setInfo(newValues)
        }
    }

    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
            })
        getRequest(host + '/configurations/public?type=UPLOADSIZE')
            .then(res => {
                setUploadSize(res[0])
            })
    }, [setLangs, setUploadSize])
    return (
        <>
            <Typography component="h2" className="admin__card--title">{props.tag === 'UPDATE' ? t('titles.controlledVocabulary.update') + ' ' + t('titles.controlledVocabulary.' + props.field) : t('titles.controlledVocabulary.create') + ' ' + t('titles.controlledVocabulary.' + props.field)}</Typography>
            <div>
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.controlled-vocabulary.form')}</Typography>
            </div>
            <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
                <Grid container>
                    <TextFieldCommon
                        className="width__33"
                        title={t('labels.controlledVocabulary.value')}
                        id="value"
                        required={true}
                        value={props.value}
                        isOpen={props.isErrorValue}
                        message={t('messages.error.controlledVocabulary.value')}
                        label={t('common.labels.required')}
                        type="text"
                        fullWidth={true}
                        handleChangeInput={props.setValue}
                    />
                    <Grid container className="textField__container--right">
                        {langs && langs.map((lang, index) =>
                            <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                <TextFieldCommon
                                    title={t('labels.controlledVocabulary.label', { lang: lang })}
                                    id={lang}
                                    value={props.labels[lang]}
                                    label={t('common.labels.required')}
                                    isOpen={props.isErrorLabels[index]}
                                    type="text"
                                    required={true}
                                    fullWidth={true}
                                    message={t('messages.error.controlledVocabulary.label')}
                                    handleChangeInput={(value) => handleChangeLabel(value, lang)}
                                    handleSpreadInput={(event, value) => handleSpreadLabel(event, value)}
                                    renderIcon={true}
                                    disable={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {props.field !== 'badge' &&
                        <Grid container className="textField__container--right">
                            {langs && langs.map((lang, index) =>
                                <Grid key={"cv-info-" + lang + "-" + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                    <TextFieldCommon
                                        title={t('labels.controlledVocabulary.info') + "<sup> " + lang + "</sup>"}
                                        id={"info-" + lang}
                                        value={props.info && props.info[lang] ? props.info[lang] : null}
                                        type="text"
                                        fullWidth={true}
                                        required={true}
                                        message={t('messages.error.controlledVocabulary.info')}
                                        multiline
                                        isOpen={props.isErrorInfo[index]}
                                        handleChangeInput={(value) => handleChangeInfo(value, lang)}
                                        handleSpreadInput={(event, value) => handleSpreadInfo(event, value)}
                                        renderIcon={true}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    }
                    {props.field === 'badge' && <Grid container className="textField__container">
                        <Grid item xs={12} md={12}>
                            <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                {t('labels.controlledVocabulary.imgUpload')}
                            </Typography>
                        </Grid>
                        <Grid container justify='center' alignItems={props.undo ? 'center' : 'flex-start'} item xs={12} md={6}>
                            {props.badge ?
                                <>
                                    <Grid item xs={12} md={11} className="fields__padding--right">
                                        <img className="repositoryform__image--size" src={host + props.badge.url} alt={props.value} />
                                    </Grid>
                                    <Grid item xs={12} md={1} >
                                        <Tooltip placement="top" title={t('tooltip.repositories.logo_remove')}>
                                            <IconButton id="image-remove-button" onClick={props.handleDeleteLogo} size='small'>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </>
                                :
                                props.undo ?
                                    <Grid item xs={12} md={6} >
                                        <SubmitButtonCommon
                                            id="repository-save-button"
                                            className="form__button--color-success"
                                            title={t('common.buttons.undo')}
                                            handleClick={props.handleUndoLogo} />
                                    </Grid>
                                    :
                                    ""
                            }
                        </Grid>
                        <Grid item xs={12} md={(props.badge || props.undo) ? 6 : 12} className={(props.badge || props.undo) ? "fields__padding--left" : ""}>
                            {uploadSize && <DropzoneArea
                                className="dropzone__style"
                                dropzoneText={props.showText ? t('common.labels.dropzone.title') + "\n" + t('common.labels.dropzone.maxFileSize', { size: convertBytesToMbsOrKbs(uploadSize.body) }) + "\n" + t('common.labels.dropzone.acceptedFileTypes', { filetypes: "JPEG, PNG, SVG, WebP, GIF" }) : null}
                                classes={{
                                    icon: props.showText ? "dropZone__color--grey" : "dropZone__color--white"
                                }}
                                dropzoneParagraphClass="dropZone__image--text"
                                maxFileSize={uploadSize.body}
                                filesLimit={1}
                                acceptedFiles={['image/png', 'image/gif', 'image/jpeg', 'image/svg+xml', 'image/webp']}
                                onChange={props.handleChangeFile}
                                getFileAddedMessage={(filename) => (t('common.labels.dropzone.add', { file: filename }))}
                                getFileRemovedMessage={(filename) => (t('common.labels.dropzone.delete', { file: filename }))}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                            />
                            }
                            {props.isErrorUpload && <FormHelperText id="badge-error" error>{t('messages.error.controlledVocabulary.upload')}</FormHelperText>}
                        </Grid>
                    </Grid>}
                </Grid>
            </Card>
            {(props.renderSubmit || props.tag === 'UPDATE') &&
                <Grid container className="form__button--justify-left repositoryform__buttons--margins">
                    <SubmitButtonCommon
                        id="controlled-vocabulary-save-button"
                        className="form__button--color-success"
                        title={t('common.buttons.save')}
                        handleClick={props.handleClick} />
                    <SubmitButtonCommon
                        id="controlled-vocabulary-cancel-button"
                        className="form__button--color-error"
                        title={t('common.buttons.cancel')}
                        goBack />
                </Grid>
            }
        </>
    )
}
