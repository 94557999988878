import React, { useState, useContext, useEffect } from 'react';
import { Chip, Grid, Typography, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
import { DropzoneArea } from 'material-ui-dropzone';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete'

import { Card } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import getRequest from '@utils/functions/axiosRequest'
import convertBytesToMbsOrKbs from '@utils/functions/byteConverter'
import { host } from '@utils/config';

import { UserContext } from '@context/userContext';

import TextLable from '@components/Common/TextLable'
import Checkbox from '@material-ui/core/Checkbox';
import TextFieldCommon from '@components/Common/TextField';
import AutoCompleteCommon from '@components/Common/AutoComplete';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import InsertStatistics from '@components/Repository/InsertStatistics';
import Tab from '@components/Common/Tab/Tab'
import Terms from '@components/Configuration/Terms.js'


import "@styles/frontOffice/Repository/RepositoryForm.css"
import "@styles/Common/Common.css"
import getTranslation from '@utils/functions/utilities.js'

const _ = require('lodash');

export default function RepositoryForm(props) {
  const [t, i18n] = useTranslation("common")
  const [user] = useContext(UserContext)

  const [logo, setLogo] = useState(props.logo || null)
  const [undo, setUndo] = useState(null)
  const [name, setName] = useState(props.name || "")
  const [repositoryType, setRepositoryType] = useState(props.repositoryType || null)
  const [description, setDescription] = useState(props.description || "")
  const [url, setUrl] = useState(props.url || "")
  const [keyWords, setKeyWords] = useState(props.keyWords || [{}])
  const [software, setSoftware] = useState(props.software || null)
  const [content, setContent] = useState(props.content || null)
  const [contentFormat, setContentFormat] = useState(props.contentFormat || null)
  const [language, setLanguage] = useState(props.language || null)
  const [latitude, setLatitude] = useState(props.latitude || "")
  const [longitude, setLongitude] = useState(props.longitude || "")
  const [subject, setSubject] = useState(props.subject || null)
  const [utility, setUtility] = useState(props.utility || null)
  const [policy, setPolicy] = useState(props.policy || [null])
  const [link, setLink] = useState(props.link && props.link.length > 0 ? props.link : [null])
  const [domain, setDomain] = useState(props.domain || null)
  const [terms, setTerms] = useState(props.terms || null)
  const [checkedTerm, setCheckedTerm] = useState(false)
  const [organization, setOrganization] = useState(props.organization || null)
  const [badges, setBadges] = useState(props.badge || null)
  const [targetGroup, setTargetGroup] = useState(props.targetGroup || null)
  const [metadataFormat, setMetadataFormat] = useState(props.metadataFormat || null)
  const [creationDate, setCreationDate] = useState(props.creationDate || null)
  const [terminationDate, setTerminationDate] = useState(props.terminationDate || null)
  const [repoTypeMetadata, setRepoTypeMetadata] = useState(props.repoTypeMetadata && props.repoTypeMetadata.length > 0 ? props.repoTypeMetadata : null)
  const [owner, setOwner] = useState(props.owner || null)
  const [handlePrefix, setHandlePrefix] = useState(props.handlePrefix || "")

  const [stats, setStats] = useState([null])

  const [organizations, setOrganizations] = useState([])
  const [uploadImage, setUploadImage] = useState([])
  const [uploadSize, setUploadSize] = useState(props.uploadSize)
  const [showText, setShowText] = useState(true)
  const [langs, setLangs] = useState(props.langs)

  const [isErrorName, setIsErrorName] = useState(langs.map(l => false))
  const [isErrorRepositoryType, setIsErrorRepositoryType] = useState(false)
  const [isErrorDescription, setIsErrorDescription] = useState(langs.map(l => false))
  const [isErrorUrl, setIsErrorUrl] = useState(false)
  const [isErrorSoftware, setIsErrorSoftware] = useState(false)
  const [isErrorLanguage, setIsErrorLanguage] = useState(false)
  const [isErrorContent, setIsErrorContent] = useState(false)
  const [isErrorContentFormat, setIsErrorContentFormat] = useState(false)
  const [isErrorKeyWords, setIsErrorKeyWords] = useState(langs.map(l => false))
  const [isErrorUtility, setIsErrorUtility] = useState(false)
  const [isErrorSubject, setIsErrorSubject] = useState(false)
  const [isErrorDomain, setIsErrorDomain] = useState(false)
  const [isErrorOrganization, setIsErrorOrganization] = useState(false)
  const [isErrorTerm, setIsErrorTerm] = useState(false)
  const [isErrorLogo, setIsErrorLogo] = useState(false)
  const [isErrorLatitude, setIsErrorLatitude] = useState(false)
  const [isErrorLongitude, setIsErrorLongitude] = useState(false)
  const [isErrorTab, setIsErrorTab] = useState(new Array(false, false, false, false))
  const [repoTypeMetadataErrors, setRepoTypeMetadataErrors] = useState(props.repoTypeMetadata ? props.repoTypeMetadata.map(dm => (dm.multilang && (dm.type === "text" || dm.type === "longText")) ? langs.map(l => false) : false) : null)
  const [policyErrors, setPolicyErrors] = useState(props.policy ? props.policy.map(p => langs.map(l => false)) : null)
  const [showTerms, setShowTerms] = useState(props.terms || !props.object ? true : props.object.terms ? false : true)
  const [showDialog, setShowDialog] = useState(false)
  const [showLoseChangesDialog, setShowLoseChangesDialog] = useState(false)

  useEffect(() => {
    if (user && !props.organization && !props.import) {
      setOrganization(user.organization)
    }
  }, [setOrganizations, setOrganization, user])

  useEffect(() => {
    if (user && user.domain && user.domain[0] && !props.domain && !props.import) {
      setDomain(user.domain[0])
    }
  }, [user])

  const hasPermissions = () => {
    return (user.group.permission.some(perm => perm.controller === 'generalCoordinator') || (user.group.permission.some(perm => perm.controller === 'domainCoordinator') && domain && domain.value === user.domain[0].value))
  }

  const changeDomain = (domain) => {
    if (domain && domain.key) {
      if (props.domain && props.domain.key && props.domain.key !== domain.key) {
        setShowDialog(true)
      }
      setDomain(domain)
    }
  }

  const handleAcceptDomainChanger = (domain) => {
    setShowDialog(false);
  };

  const handleDisagreeDomainChanger = () => {
    setShowDialog(false);
    setDomain(props.domain)
  };

  const handleAcceptLoseChanges = (event, id) => {
    history.push("/admin/organizations/update/" + id)
  }

  const handleDisagreeLoseChanges = () => {
    setShowLoseChangesDialog(false);
  };

  const repoTypeMetadataValues = (attr, langs) => {
    let value
    if (attr.type === 'text' || attr.type === 'longText' || attr.type === 'url')
      if (attr.multilang)
        value = langs.reduce((l, val) => ({ ...l, [val]: '' }), {})
      else
        value = ''
    else if (attr.type === 'date')
      value = null
    else if (attr.type === 'bool')
      value = false
    else if (attr.type === 'listOfValues')
      if (!attr.multiple)
        value = {}
      else
        value = []
    if (attr.multiple && attr.type !== 'bool' && attr.type !== 'listOfValues')
      value = [value]

    return value
  }

  const repoTypeMetadataValuesHistory = (attr, index, history, lang) => {
    if (!history || !history.value)
      return "request__color--create"
    if (lang) {
      if (!history.value[index])
        return "request__color--create"
      else if (history.value[index][lang] !== attr[lang])
        return "request__color--update"
      else return ""
    }
    else {
      if (!history.value[index])
        return "request__color--create"
      else if (history.value[index] !== attr)
        return "request__color--update"
      else return ""
    }
  }

  const repoTypeMetadataListOfValuesHistory = (attr, index, history) => {
    if (!history || (history.action === "ADD" || !history.original[index]))
      return "request__color--create"
    if (history.action === "UPDATE") {
      if (!Array.isArray(attr)) {
        if (!history.original[index].value)
          return "request__color--create"
        else if (history.original[index].value.key !== attr.key)
          return "request__color--update"
        else return ""
      }
      else {
        if (!history.original[index].value)
          return ""
        else if (attr.length !== history.original[index].value.length || attr.some(val => !history.original[index].value.some(prev => prev.key === val.key)) || history.original[index].value.some(prev => !attr.some(val => prev.key === val.key))) {
          return (
            <Grid item xs={12}>
              <span className='MuiTypography-root textField__typography--preview MuiTypography-subtitle1 MuiTypography-colorTextSecondary MuiTypography-gutterBottom'>{t('labels.workflows.previous')}</span>
              {history.original[index].value.map((prev, index) => {
                let style = (attr.some(sv => prev.value === sv.value)) ? {} : { color: "#C55700", fontWeight: "bold" }
                return <React.Fragment key={"listOfValues-option" + index}>{index > 0 && <span style={{ fontSize: "0.813rem" }} className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorTextSecondary MuiTypography-gutterBottom">;</span>}<span style={style} className="MuiTypography-root textField__typography--preview MuiTypography-subtitle1 MuiTypography-colorTextSecondary MuiTypography-gutterBottom">{getTranslation(prev.i18n, i18n.language, i18n.fallbackLng)}</span>
                </React.Fragment>
              }
              )}
            </Grid>
          )

        }

      }
    }
  }


  const statsParse = (stats) => {
    let loadStats = []
    for (let stat of stats) {
      if (stat.objectType === "VALUE") {
        loadStats.push({
          value: stat.value,
          i18n: stat.body.title,
          type: "VALUE",
          _id: stat._id,
          val: stat.body.value
        })
      }
      else if (stat.objectType === "TIMESERIES") {
        for (let dateStat of stat.body.value) {
          loadStats.push({
            value: stat.value,
            i18n: stat.body.title,
            type: "TIMESERIES",
            _id: stat._id,
            date: dateStat.key,
            val: dateStat.value
          })
        }
      }
      else {
        for (let dateStat of stat.body.value) {
          let date = dateStat.key
          for (let key of Object.keys(dateStat)) {
            if (key !== "key")
              loadStats.push({
                _id: stat._id,
                i18n: stat.body.title,
                type: "MULTITIMESERIES",
                configs: stat.keys,
                date: date,
                key: stat.keys.find(k => k.value === key),
                val: dateStat[key],
                value: stat.value
              })
          }
        }
      }
    }
    return loadStats
  }

  useEffect(() => {
    if ((props.errors && props.errors.errors))
      validateRepository()
  }, [props.errors])

  useEffect(() => {
    let newErrorTab = isErrorTab ? [...isErrorTab.map(error => false)] : []
    if (props.history && props.object) {
      Object.keys(props.history).map(key => {
        if (key === 'name' || key === 'description' || key === 'url' || key === 'repositoryType'
          || key === 'domain' || key === 'software' || key === 'subject' || key === 'utility' || key === 'content'
          || key === 'lang' || key === 'contentFormat' || key === 'keyWords' || key === 'organization' || key === 'latitude'
          || key === 'longitude' || key === 'repoTypeMetadata') {
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
        }
        else if (key === 'policy') {
          newErrorTab[2] = true
          setIsErrorTab(newErrorTab)
        }
        else if (key === 'link' || key === 'targetGroup' || key === 'metadataFormat' || key === 'creationDate'
          || key === 'terminationDate' || key === 'owner' || key === 'badge') {
          newErrorTab[1] = true
          setIsErrorTab(newErrorTab)
        }
        else if (key === 'stats') {
          newErrorTab[3] = true
          setIsErrorTab(newErrorTab)
        }
      })
    }
  }, [props.history])

  useEffect(() => {
    if (props.stats && props.stats.length !== 0) {
      setStats(statsParse(props.stats))
    }
  }, [setStats])

  useEffect(() => {
    if (policy && policy[0] !== null)
      if (!policyErrors)
        setPolicyErrors(policy.map(p => langs.map(l => false)))
      else if (policyErrors.length < policy.length)
        setPolicyErrors([...policyErrors, langs.map(l => false)])
      else if (policyErrors.length > policy.length) {
        let newErrors = [...policyErrors]
        newErrors.splice(newErrors.length - 1, 1)
        setPolicyErrors(newErrors)

      }
  }, [policy])

  useEffect(() => {
    getRequest(host + '/configurations/public?type=TERM&&objectType=REPOSITORY')
      .then(res => {
        if (window.location.hash.includes('requests')) {
          setCheckedTerm(true)
          setShowTerms(false)
        }
        else if (props.terms) {
          setTerms([...props.terms, res[0]])
        }
        else {
          setTerms(res)
        }
      })
  }, [setTerms])

  useEffect(() => {
    if (repositoryType && langs)
      getRequest(host + '/configurations/public?type=REPOSITORYTYPE&&key=' + encodeURIComponent(repositoryType.key))
        .then(res => {
          if (res[0].body.attributes.length > 0) {
            if (props.repositoryType && props.repositoryType.key === repositoryType.key) {
              let attributes = res[0].body.attributes.filter(at => props.repoTypeMetadata.findIndex(dm => dm.id === at.id) === -1);
              if (attributes.length > 0) {
                attributes = attributes.map(attr => ({ ...attr, value: repoTypeMetadataValues(attr, langs) }))
                setRepoTypeMetadata([...props.repoTypeMetadata, ...attributes])
                setRepoTypeMetadataErrors([...props.repoTypeMetadata.map(dm => (dm.multilang && (dm.type === "text" || dm.type === "longText")) ? langs.map(l => false) : false), ...attributes.map(dm => (dm.multilang && (dm.type === "text" || dm.type === "longText")) ? langs.map(l => false) : false)])
              }
              else {
                setRepoTypeMetadata(props.repoTypeMetadata)
                setRepoTypeMetadataErrors(props.repoTypeMetadata.map(dm => (dm.multilang && (dm.type === "text" || dm.type === "longText")) ? langs.map(l => false) : false))
              }
            }
            else {
              setRepoTypeMetadata(res[0].body.attributes.map(attr => ({ ...attr, value: repoTypeMetadataValues(attr, langs) })))
              setRepoTypeMetadataErrors(res[0].body.attributes.map(dm => (dm.multilang && (dm.type === "text" || dm.type === "longText")) ? langs.map(l => false) : false))
            }
          }
          else {
            setRepoTypeMetadata(null)
            setRepoTypeMetadataErrors(null)
          }
        })
  }, [repositoryType, langs])

  const handleRepoTypeMetadata = (value, index, arrIndex) => {
    let newRepoTypeMetadata = [...repoTypeMetadata]
    if (arrIndex === undefined)
      newRepoTypeMetadata[index]['value'] = value
    else
      newRepoTypeMetadata[index]['value'][arrIndex] = value
    setRepoTypeMetadata(newRepoTypeMetadata)
  }

  const handleTextRepoTypeMetadata = (value, index, lang) => {
    let newRepoTypeMetadata = [...repoTypeMetadata]
    if (lang !== undefined)
      newRepoTypeMetadata[index]['value'][lang] = value
    else
      newRepoTypeMetadata[index]['value'] = value

    setRepoTypeMetadata(newRepoTypeMetadata)
  }



  const handleAddArrayDate = (index) => {
    let newRepoTypeMetadata = [...repoTypeMetadata]
    newRepoTypeMetadata[index]['value'].push(null)
    setRepoTypeMetadata(newRepoTypeMetadata)

  }
  const handleRemoveArrayDate = (index, dateIndex) => {
    let newRepoTypeMetadata = [...repoTypeMetadata]
    newRepoTypeMetadata[index]['value'].splice(dateIndex, 1)
    setRepoTypeMetadata(newRepoTypeMetadata)

  }


  const handleSpreadTextRepoTypeMetadata = (event, value, index) => {
    if (value !== undefined && value !== '') {
      let newRepoTypeMetadata = [...repoTypeMetadata]
      langs.map(l => newRepoTypeMetadata[index]['value'][l] === '' || newRepoTypeMetadata[index]['value'][l] === undefined || event.type === 'dblclick' ? newRepoTypeMetadata[index]['value'][l] = value : '')
      setRepoTypeMetadata(newRepoTypeMetadata)
    }
  }

  const handleChangeAutocomplete = (value, index) => {
    let newRepoTypeMetadata = [...repoTypeMetadata]
    if (newRepoTypeMetadata[index]['multiple']) {
      let duplicates = value.map(v => v.key).filter((val, i) => value.map(v => v.key).indexOf(val) !== i)
      newRepoTypeMetadata[index]['value'] = value.filter(v => !duplicates.some(opt => opt === v.key))
    }
    else
      newRepoTypeMetadata[index]['value'] = value
    setRepoTypeMetadata(newRepoTypeMetadata)
  }

  const handleChangeFile = (file) => {
    if (file.length !== 0) {
      setShowText(false)
    }
    else {
      setShowText(true)
    }
    setUploadImage(file)
  }

  const handleDeleteLogo = () => {
    let newLogo = { ...logo }
    setLogo(null)
    setUndo(newLogo)
  }

  const handleUndoLogo = () => {
    let newLogo = { ...undo }
    setUndo(null)
    setLogo(newLogo)
  }



  const validateRepository = () => {
    let newRepoTypeMetadataErrors = repoTypeMetadataErrors ? [...repoTypeMetadataErrors.map(error => Array.isArray(error) ? Array(error.length).map(x => false) : false)] : []
    let newPolicyErrors = policyErrors ? [...policyErrors.map(error => langs.map(l => false))] : []
    let newErrorTab = isErrorTab ? [...isErrorTab.map(error => false)] : []
    let res = true
    setIsErrorDomain(false)
    setIsErrorRepositoryType(false)
    setIsErrorSoftware(false)
    setIsErrorSubject(false)
    setIsErrorUtility(false)
    setIsErrorContent(false)
    setIsErrorLanguage(false)
    setIsErrorContentFormat(false)
    setIsErrorName(langs.map(l => false))
    setIsErrorDescription(langs.map(l => false))
    setIsErrorKeyWords(false)
    setIsErrorUrl(false)
    setIsErrorOrganization(false)
    setIsErrorLatitude(false)
    setIsErrorLongitude(false)
    setRepoTypeMetadataErrors(newRepoTypeMetadataErrors)
    setPolicyErrors(newPolicyErrors)
    setIsErrorTab(newErrorTab)
    for (let error of props.errors.errors) {
      switch (error) {
        case "domain":
          setIsErrorDomain(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "repositoryType":
          setIsErrorRepositoryType(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "software":
          setIsErrorSoftware(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "subject":
          setIsErrorSubject(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "utility":
          setIsErrorUtility(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "content":
          setIsErrorContent(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "lang":
          setIsErrorLanguage(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "contentFormat":
          setIsErrorContentFormat(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "name":
          let newNameErrors = langs.map(l => !name[l] || name[l] === "")
          setIsErrorName(newNameErrors)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "description":
          let newDescriptionErrors = langs.map(l => !description[l] || description[l] === "")
          setIsErrorDescription(newDescriptionErrors)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "keyWords":
          let newKeyWordsErrors = langs.map(l => keyWords.some(k => !k[l] || k[l] === ""));
          setIsErrorKeyWords(newKeyWordsErrors)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "url":
          setIsErrorUrl(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "organization":
          setIsErrorOrganization(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "latitude":
          setIsErrorLatitude(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        case "longitude":
          setIsErrorLongitude(true)
          newErrorTab[0] = true
          setIsErrorTab(newErrorTab)
          break
        default:
          if (error.includes('repoTypeMetadata')) {
            let index = error.split('.')[1]
            if (repoTypeMetadata[index].multilang && (repoTypeMetadata[index].type === "text" || repoTypeMetadata[index].type === "longText"))
              if (repoTypeMetadata[index].required) {
                if (repoTypeMetadata[index].multiple)
                  newRepoTypeMetadataErrors[index] = langs.map(l => repoTypeMetadata[index].value.some(k => !k[l] || k[l] === ""));
                else
                  newRepoTypeMetadataErrors[index] = langs.map(l => !repoTypeMetadata[index].value[l] || repoTypeMetadata[index].value[l] === "")
              }
              else {
                if (repoTypeMetadata[index].multiple)
                  newRepoTypeMetadataErrors[index] = langs.map(l => repoTypeMetadata[index].value.some(k => !k[l] || k[l] === "") && langs.map(l => !repoTypeMetadata[index].value.every(k => !k[l] || k[l] === "")));
                else {
                  newRepoTypeMetadataErrors[index] = langs.map((l) => (!repoTypeMetadata[index].value[l] || repoTypeMetadata[index].value[l] === ""))
                }

              }
            else
              newRepoTypeMetadataErrors[index] = true
            setRepoTypeMetadataErrors(newRepoTypeMetadataErrors)
            newErrorTab[0] = true
            setIsErrorTab(newErrorTab)
          } else if (error.includes('policy')) {
            let index = error.split('.')[1]
            newPolicyErrors[index] = langs.map(l => !policy[index].description[l] || policy[index].description[l] === "")
            setPolicyErrors(newPolicyErrors)
            newErrorTab[2] = true
            setIsErrorTab(newErrorTab)
          }
          break
      }
    }

    // if (!logo && uploadImage.length === 0) {
    //   setIsErrorLogo(true)
    //   res = false
    // } else setIsErrorLogo(false)
    return res
  }

  const handleSubmitRepository = async () => {
    //TODO: UPLOAD IMAGE
    if (showTerms && !checkedTerm) {
      setIsErrorTerm(true)
      props.snackForRepository("error", t('messages.error.repositories.term'))
      return
    } else setIsErrorTerm(false)
    let date = new Date()
    let newTerms = [...terms]
    if (!newTerms[newTerms.length - 1]['accepted_at'])
      newTerms[newTerms.length - 1]['accepted_at'] = date.toISOString()

    let repository = {
      _id: props._id,
      name: name,
      logo: logo,
      repositoryType: [repositoryType],
      description: description,
      url: url,
      link: link && link[0] !== null ? link : [],
      keyWords: keyWords,
      software: [software],
      content: content,
      contentFormat: contentFormat,
      lang: language,
      gps: {
        lat: latitude,
        lng: longitude
      },
      subject: subject,
      utility: utility,
      domain: [domain],
      policy: policy && policy[0] !== null ? policy : [],
      badge: badges,
      stats: stats && stats[0] !== null ? stats : [],
      ...(showTerms ? { terms: [newTerms[newTerms.length - 1]] } : { terms: newTerms }),
      owner: owner ? owner._id : user._id,
      handlePrefix: handlePrefix,
      organization: organization,
      targetGroup: targetGroup,
      metadataFormat: metadataFormat,
      creationDate: creationDate,
      terminationDate: terminationDate,
      repoTypeMetadata: repoTypeMetadata ? repoTypeMetadata : [],
      newLogo: uploadImage[0],
      ...(undo && { undo: undo })

    }
    props.submitRepository(repository)

  }


  const handleChangeDescription = (value, lang) => {
    let newValue = { ...description }
    newValue[lang] = value
    setDescription(newValue)
  }

  const handleChangeName = (value, lang) => {
    let newValue = { ...name }
    newValue[lang] = value
    setName(newValue)
  }

  const handleSpreadName = (event, value) => {
    if (value !== undefined && value !== '') {
      let newValue = { ...name }
      langs.map(lang => newValue[lang] === '' || newValue[lang] === undefined || event.type === 'dblclick' ? newValue[lang] = value : '')
      setName(newValue)
    }
  }

  const handleSpreadDescription = (event, value) => {
    if (value !== undefined && value !== '') {
      let newValue = { ...description }
      langs.map(lang => newValue[lang] === '' || newValue[lang] === undefined || event.type === 'dblclick' ? newValue[lang] = value : '')
      setDescription(newValue)
    }
  }

  function getNewId(el, historyEl) {
    let id = el._id
    if (historyEl === "stats") {
      if (el.type === "TIMESERIES" || el.type === "MULTITIMESERIES") id = id + "_" + el.date
      if (el.type === "MULTITIMESERIES") id = id + "_" + el.key.value
    }
    if (historyEl === "policy" || historyEl === "link") {
      id = id + "_" + el.key
    }
    return id
  }

  let history = useHistory()

  const handleOpenEdit = (event, id) => {
    setShowLoseChangesDialog(true)
  }

  let statsBag = {};
  let policyBag = {};
  let linkBag = {};

  if (props.history && props.history.stats && props.history.stats.original) {
    for (let prev of statsParse(props.history.stats.original)) {
      statsBag[getNewId(prev, "stats")] = { prev: prev, current: undefined, diff: true, type: prev.type, update: true }
    }
  }
  if (props.object && props.object.stats) {
    for (let current of statsParse(props.object.stats)) {
      if (!current) continue
      let current_id = getNewId(current, "stats")
      if (statsBag[current_id]) {
        statsBag[current_id].current = current
        statsBag[current_id].cssClassName = statsBag[current_id].prev.val === statsBag[current_id].current.val ? null : "request__color--update"
        statsBag[current_id].update = !(statsBag[current_id].prev.val === statsBag[current_id].current.val)
      } else {
        if (props.history && props.history.stats) {
          statsBag[current_id] = { prev: undefined, current: current, diff: true, cssClassName: "request__color--create", type: current.type, update: false }
        } else {
          statsBag[current_id] = { prev: undefined, current: current, diff: true, cssClassName: null, type: current.type, update: false }
        }
      }
    }
  }

  if (props.history && props.history.policy && props.history.policy.original) {
    for (let prev of props.history.policy.original) {
      policyBag[getNewId(prev, "policy")] = { prev: prev, current: undefined, diff: true, update: true }
    }
  }
  if (props.object && props.object.policy) {
    for (let current of props.object.policy) {
      if (!current) continue
      let current_id = getNewId(current, "policy")
      if (policyBag[current_id]) {
        policyBag[current_id].current = current
        if (policyBag[current_id].prev.description !== policyBag[current_id].current.description || policyBag[current_id].prev.link !== policyBag[current_id].current.link) {
          policyBag[current_id].cssClassName = "request__color--update"
          policyBag[current_id].update = true
        } else {
          policyBag[current_id].cssClassName = null
          policyBag[current_id].update = false
        }
      } else {
        if (props.history && props.history.policy) {
          policyBag[current_id] = { prev: undefined, current: current, diff: true, cssClassName: "request__color--create", update: false }
        } else {
          policyBag[current_id] = { prev: undefined, current: current, diff: true, cssClassName: null, update: false }
        }
      }
    }
  }

  if (props.history && props.history.link && props.history.link.original) {
    for (let prev of props.history.link.original) {
      linkBag[getNewId(prev, "link")] = { prev: prev, current: undefined, diff: true, update: true }
    }
  }
  if (props.object && props.object.link) {
    for (let current of props.object.link) {
      if (!current) continue
      let current_id = getNewId(current, "link")
      if (linkBag[current_id]) {
        linkBag[current_id].current = current
        if (linkBag[current_id].prev.link !== linkBag[current_id].current.link) {
          linkBag[current_id].cssClassName = "request__color--update"
          linkBag[current_id].update = true
        } else {
          linkBag[current_id].cssClassName = null
          linkBag[current_id].update = false
        }
      } else {
        if (props.history && props.history.link) {
          linkBag[current_id] = { prev: undefined, current: current, diff: true, cssClassName: "request__color--create", update: false }
        } else {
          linkBag[current_id] = { prev: undefined, current: current, diff: true, cssClassName: null, update: false }
        }
      }
    }
  }
  return (
    <>
      <Card elevation={0} className="margin__top--32px">
        <Tab
          isSimpleTab={true}
          headers={[t('titles.repositories.header.identification'), t('titles.repositories.header.additionalInformation'), t('titles.repositories.header.policies'), t('titles.repositories.header.statistics')]}
          error={isErrorTab}
          components={
            [<>
              <Grid container>
                {
                  langs && langs.map((lang, index) =>
                    <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                      <TextFieldCommon
                        className={props.history && props.history.name && props.history.name.original[lang] !== props.object.name[lang] ? props.history.name.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                        title={t('labels.repositories.name', { lang })}
                        id={"name-" + lang}
                        required={true}
                        value={props.object && props.object.name ? props.object.name[lang] : name[lang]}
                        lang={lang}
                        isOpen={isErrorName[index]}
                        message={t('messages.error.repositories.name')}
                        type="text"
                        fullWidth={true}
                        handleChangeInput={(value) => handleChangeName(value, lang)}
                        handleSpreadInput={(event, value) => handleSpreadName(event, value)}
                        previous={props.history && props.history.name && props.history.name.original && props.history.name.original[lang] !== props.object.name[lang] ? props.history.name.original : null}
                        disabled={props.history || props.condition != undefined ? true : false}
                        renderIcon={true}
                      />
                    </Grid>
                  )
                }
              </Grid>
              <Grid container>
                {langs && langs.map((lang, index) =>
                  <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                    <TextFieldCommon
                      className={props.history && props.history.description && props.history.description.original[lang] !== props.object.description[lang] ? props.history.description.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                      title={t('labels.repositories.description', { lang: lang })}
                      id={"description-" + lang}
                      required={true}
                      isOpen={isErrorDescription[index]}
                      value={props.object && props.object.description ? props.object.description[lang] : description[lang]}
                      message={t('messages.error.repositories.description')}
                      type="text"
                      lang={lang}
                      multiline={true}
                      fullWidth={true}
                      handleChangeInput={(value) => handleChangeDescription(value, lang)}
                      handleSpreadInput={(event, value) => handleSpreadDescription(event, value)}
                      previous={props.history && props.history.description && props.history.description.original && props.history.description.original[lang] !== props.object.description[lang] ? props.history.description.original : null}
                      disabled={props.history || props.condition != undefined ? true : false}
                      renderIcon={true}
                    />
                  </Grid>
                )}
              </Grid>
              <TextFieldCommon
                className={props.history && props.history.url ? props.history.url.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                title={t('labels.repositories.url')}
                id="url"
                required={true}
                isOpen={isErrorUrl}
                value={props.object && props.object.url ? props.object.url : url}
                message={t('messages.error.repositories.url')}
                type="text"
                fullWidth={true}
                handleChangeInput={setUrl}
                previous={props.history && props.history.url ? props.history.url.original : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                className={props.history && props.history.repositoryType ? props.history.repositoryType.action === "UPDATE" ? "request__color--update width__50" : "request__color--create width__50" : "width__50"}
                title={t('labels.repositories.repositoryType')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.repositoryType')}
                id="repositoryType"
                tag="autocomplete-vc"
                vc={true}
                path={host + "/controlled-vocabularies?field=repositoryType"}
                required={true}
                isOpen={isErrorRepositoryType}
                message={t('messages.error.repositories.repositoryType')}
                selectedValue={props.object && props.object.repositoryType ? props.object.repositoryType[0] : repositoryType}
                handleChange={setRepositoryType}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.repositoryType ? getTranslation(props.history.repositoryType.original[0].i18n, i18n.language, i18n.fallbackLng) : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              {(repositoryType || (props && props.object && props.object.repositoryType)) && repoTypeMetadata && repoTypeMetadataErrors && (
                <>
                  <Grid container className='repositories__padding--bottom-24px register__box--style'>
                    <Typography className="textField__typography--subtitle-margin" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('titles.repositories.header.repoTypeMetadata')}
                    </Typography>

                    {repoTypeMetadata.sort((attr1, attr2) => attr1.order - attr2.order).map((attr, index) =>
                      (attr.type === 'text' || attr.type === 'longText' || attr.type === 'url') ?
                        (!props.object ?
                          attr.multilang ? (
                            <Grid key={attr.id + '-label-' + index} container>
                              {langs && langs.map((lang, langIndex) =>
                                <Grid key={lang + index + langIndex} item xs={12} md={6} className={langIndex % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                  <TextFieldCommon
                                    key={attr.id + lang}
                                    className={props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original[index] && props.history.repoTypeMetadata.original[index].value[lang] !== repoTypeMetadata[index].value[lang] ? props.history.repoTypeMetadata.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                                    title={t(`repositoryTypes.${repositoryType ? repositoryType.value : props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`) + ' <sup>' + lang + '</sup>'}
                                    id={attr.id + '-' + lang}
                                    required={attr.required}
                                    isOpen={repoTypeMetadataErrors[index] && repoTypeMetadataErrors[index][langIndex] ? repoTypeMetadataErrors[index][langIndex] : false}
                                    value={!attr.multiple ? repoTypeMetadata[index].value[lang] : repoTypeMetadata[index].value}
                                    message={t('messages.error.configurations.repoTypeMetadata.value')}
                                    type="text"
                                    multiline={attr.type === 'longText'}
                                    fullWidth={true}
                                    handleChangeInput={(value) => !attr.multiple ? handleTextRepoTypeMetadata(value, index, lang) : handleTextRepoTypeMetadata(value, index)}
                                    handleSpreadInput={(event, value) => !attr.multiple ? handleSpreadTextRepoTypeMetadata(event, value, index) : null}
                                    previous={props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && props.history.repoTypeMetadata.original[index].value[lang] !== repoTypeMetadata[index].value[lang] ? props.history.repoTypeMetadata.original[index].value : null}
                                    disabled={props.history || props.condition != undefined ? true : false}
                                    renderIcon={true}
                                    addButton={attr.multiple && langIndex === langs.length - 1}
                                    lang={lang}
                                    langs={langs}
                                  />
                                </Grid>
                              )
                              }
                            </Grid>
                          ) : <TextFieldCommon
                            key={attr.id}
                            className={props.history && props.history.repoTypeMetadata ? props.history.repoTypeMetadata.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                            title={t(`repositoryTypes.${repositoryType ? repositoryType.value : props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`)}
                            id={attr.id}
                            required={attr.required}
                            isOpen={repoTypeMetadataErrors[index]}
                            value={repoTypeMetadata[index].value}
                            message={t('messages.error.configurations.repoTypeMetadata.value')}
                            type="text"
                            multiline={attr.type === 'longText'}
                            fullWidth={true}
                            handleChangeInput={(value) => handleTextRepoTypeMetadata(value, index)}
                            previous={props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && props.history.repoTypeMetadata.original[index].value !== repoTypeMetadata[index].value ? props.history.repoTypeMetadata.original[index].value : null}
                            disabled={props.history || props.condition != undefined ? true : false}
                            addButton={attr.multiple}
                          /> :
                          attr.multiple ?
                            attr.multilang ?
                              langs && attr.value.map((value, valueIndex) => {
                                return (

                                  langs.map((lang, indexLang) => <Grid key={lang + valueIndex} item xs={12} md={6} className={indexLang % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                    {
                                      valueIndex === 0 &&
                                      <Typography id={attr.id + '-' + lang + "-lable-" + valueIndex} className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                        <div dangerouslySetInnerHTML={{ __html: t(`repositoryTypes.${props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`) + ' <sup>' + lang + '</sup>' }} />
                                      </Typography>
                                    }
                                    <TextField
                                      className={(props.history && props.history.repoTypeMetadata ? props.history.repoTypeMetadata.action === 'ADD' ? "request__color--create" : repoTypeMetadataValuesHistory(value, valueIndex, props.history.repoTypeMetadata.original[index], lang) : "") + " margin__top--9px"}
                                      id={'-input'}
                                      size="small"
                                      variant="outlined"
                                      required={attr.required}
                                      value={value[lang]}
                                      disabled={props.history || props.condition != undefined ? true : false}
                                      fullWidth={true}
                                      name={attr.id + '-' + lang}
                                    />
                                    {
                                      props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && repoTypeMetadataValuesHistory(value, valueIndex, props.history.repoTypeMetadata.original[index], lang) === "request__color--update" ? <Grid item xs={12}>
                                        <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                          {t('labels.workflows.previous') + (props.history.repoTypeMetadata.original[index].value[valueIndex][lang])}
                                        </Typography>
                                      </Grid> : null
                                    }
                                  </Grid>

                                  )
                                )
                              }
                              )
                              :
                              attr.value.map((value, valueIndex) => {
                                return (
                                  <React.Fragment key={attr.id + "-" + valueIndex}>
                                    {
                                      valueIndex === 0 &&
                                      <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                        <div dangerouslySetInnerHTML={{ __html: t(`repositoryTypes.${props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`) }} />
                                      </Typography>
                                    }
                                    <TextField
                                      className={(props.history && props.history.repoTypeMetadata ? props.history.repoTypeMetadata.action === 'ADD' ? "request__color--create" : repoTypeMetadataValuesHistory(value, valueIndex, props.history.repoTypeMetadata.original[index]) : "") + " margin__top--9px"}
                                      id={'-input'}
                                      size="small"
                                      variant="outlined"
                                      required={attr.required}
                                      value={value}
                                      disabled={props.history || props.condition != undefined ? true : false}
                                      fullWidth={true}
                                      name={attr.id}
                                    />
                                    {
                                      props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && repoTypeMetadataValuesHistory(value, valueIndex, props.history.repoTypeMetadata.original[index]) === "request__color--update" ? <Grid item xs={12}>
                                        <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                          {t('labels.workflows.previous') + (props.history.repoTypeMetadata.original[index].value[valueIndex])}
                                        </Typography>
                                      </Grid> : null
                                    }
                                  </React.Fragment>
                                )
                              })
                            :
                            attr.multilang ?
                              (<Grid key={attr.id + '-label-' + index} container>
                                {langs && langs.map((lang, langIndex) =>
                                  <Grid key={lang + index + langIndex} item xs={12} md={6} className={langIndex % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                    <TextFieldCommon
                                      key={attr.id + lang}
                                      className={(props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && props.history.repoTypeMetadata.original[index].value[lang] !== repoTypeMetadata[index].value[lang] || props.history && props.history.repoTypeMetadata && !props.history.repoTypeMetadata.original) ? props.history.repoTypeMetadata.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                                      title={t(`repositoryTypes.${repositoryType ? repositoryType.value : props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`) + ' <sup>' + lang + '</sup>'}
                                      id={attr.id + '-' + lang}
                                      required={attr.required}
                                      isOpen={repoTypeMetadataErrors[index] && repoTypeMetadataErrors[index][langIndex] ? repoTypeMetadataErrors[index][langIndex] : false}
                                      value={!attr.multiple ? repoTypeMetadata[index].value[lang] : repoTypeMetadata[index].value}
                                      message={t('messages.error.configurations.repoTypeMetadata.value')}
                                      type="text"
                                      multiline={attr.type === 'longText'}
                                      fullWidth={true}
                                      handleChangeInput={(value) => !attr.multiple ? handleTextRepoTypeMetadata(value, index, lang) : handleTextRepoTypeMetadata(value, index)}
                                      handleSpreadInput={(event, value) => !attr.multiple ? handleSpreadTextRepoTypeMetadata(event, value, index) : null}
                                      previous={props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && props.history.repoTypeMetadata.original[index].value[lang] !== repoTypeMetadata[index].value[lang] ? props.history.repoTypeMetadata.original[index].value : null}
                                      disabled={props.history || props.condition != undefined ? true : false}
                                      renderIcon={true}
                                      addButton={attr.multiple && langIndex === langs.length - 1}
                                      lang={lang}
                                      langs={langs}
                                    />
                                  </Grid>
                                )
                                }
                              </Grid>)
                              :
                              <TextFieldCommon
                                key={attr.id}
                                className={props.history && props.history.repoTypeMetadata ? props.history.repoTypeMetadata.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                                title={t(`repositoryTypes.${repositoryType ? repositoryType.value : props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`)}
                                id={attr.id}
                                required={attr.required}
                                isOpen={repoTypeMetadataErrors[index]}
                                value={repoTypeMetadata[index].value}
                                message={t('messages.error.configurations.repoTypeMetadata.value')}
                                type="text"
                                multiline={attr.type === 'longText'}
                                fullWidth={true}
                                handleChangeInput={(value) => handleTextRepoTypeMetadata(value, index)}
                                previous={props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && props.history.repoTypeMetadata.original[index].value !== repoTypeMetadata[index].value ? props.history.repoTypeMetadata.original[index].value : null}
                                disabled={props.history || props.condition != undefined ? true : false}
                                addButton={attr.multiple}
                              />
                        )
                        : attr.type === 'bool' ?
                          (<Grid key={attr.id + 'container-grid-' + index} container className="textField__container">
                            <Typography component="div" className="textField__typography--form" variant="subtitle1" id={attr.id + index} gutterBottom><div dangerouslySetInnerHTML={{ __html: t(`repositoryTypes.${repositoryType ? repositoryType.value : props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`) }} /></Typography>
                            <Grid key={attr.id + 'required-grid-' + index} item xs={12}>
                              <Switch
                                id={attr.id}
                                checked={attr.value}
                                onChange={() => handleRepoTypeMetadata(!attr.value, index)}
                                disabled={props.history || props.condition != undefined ? true : false}
                                name={attr.id + '-boolean'}
                              />
                            </Grid>
                          </Grid>)
                          :
                          attr.type === 'date' ?
                            <Grid key={attr.id + 'container-grid-' + index} container className="textField__container--y">
                              <Grid item xs={12}>
                                <Typography className="textField__typography--form" variant="subtitle1" component="div" gutterBottom>
                                  {t(`repositoryTypes.${repositoryType ? repositoryType.value : props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`) + (attr.required ? " *" : " (" + t('labels.common.optional') + ")")}
                                </Typography>
                              </Grid>
                              {
                                !attr.multiple ?
                                  <>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <KeyboardDatePicker
                                        className={props.history && props.history.repoTypeMetadata ? props.history.repoTypeMetadata.action === "UPDATE" ? "datePicker__container request__color--update" : "datePicker__container request__color--create" : "datePicker__container"}
                                        disableToolbar
                                        inputVariant="outlined"
                                        variant="inline"
                                        format="yyyy/MM/dd"
                                        openTo="date"
                                        id="date-picker-inline"
                                        label={t('labels.repositories.datePicker')}
                                        error={repoTypeMetadataErrors[index]}
                                        helperText={repoTypeMetadataErrors[index] ? t('messages.error.configurations.repoTypeMetadata.value') : ''}
                                        value={repoTypeMetadata[index].value}
                                        onChange={(value) => handleRepoTypeMetadata(value, index)}
                                        disabled={props.history || props.condition != undefined ? true : false}
                                      />
                                    </MuiPickersUtilsProvider>

                                    {props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && props.history.repoTypeMetadata.original[index].value !== repoTypeMetadata[index].value &&
                                      <Grid item xs={12}>
                                        <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                          {t('labels.workflows.previous') + props.history.repoTypeMetadata.original[index].value.split('T')[0]}
                                        </Typography>
                                      </Grid>
                                    }
                                  </>

                                  :
                                  attr.value.map((date, dateIndex) =>
                                    <Grid container key={attr.id + '-date-' + dateIndex}>
                                      <Grid item xs={12} md={props.object ? 12 : 11}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <KeyboardDatePicker
                                            className={(props.history && props.history.repoTypeMetadata ? props.history.repoTypeMetadata.action === "ADD" ? "request__color--create" : repoTypeMetadataValuesHistory(date, dateIndex, props.history.repoTypeMetadata.original[index]) : "") + " datePicker__container"}
                                            disableToolbar
                                            inputVariant="outlined"
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            openTo="date"
                                            id="date-picker-inline"
                                            label={t('labels.repositories.datePicker')}
                                            error={repoTypeMetadataErrors[index]}
                                            helperText={repoTypeMetadataErrors[index] ? t('messages.error.configurations.repoTypeMetadata.value') : ''}
                                            value={date}
                                            onChange={(value) => handleRepoTypeMetadata(value, index, dateIndex)}
                                            disabled={props.history || props.condition != undefined ? true : false}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </Grid>
                                      {props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && repoTypeMetadataValuesHistory(date, dateIndex, props.history.repoTypeMetadata.original[index]) === "request__color--update" &&
                                        <Grid item xs={12}>
                                          <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                            {t('labels.workflows.previous') + props.history.repoTypeMetadata.original[index].value[dateIndex].split('T')[0]}
                                          </Typography>
                                        </Grid>
                                      }
                                      {
                                        !props.history &&
                                        <Grid item xs={12} md={1} >
                                          {
                                            dateIndex === attr.value.length - 1 ?
                                              <Button id={attr.id + '-' + index + '-add-button-' + dateIndex} className="form__button--add" size="medium" onClick={(event) => date ? handleAddArrayDate(index) : ''}>
                                                <AddIcon className="form__icon--add" />
                                              </Button>
                                              :
                                              <Button id={attr.id + '-' + index + '-remove-button-' + dateIndex} className="form__button--remove" size="medium" onClick={(event) => date ? handleRemoveArrayDate(index, dateIndex) : ''}>
                                                <RemoveIcon className="form__icon--remove" />
                                              </Button>
                                          }

                                        </Grid>}
                                    </Grid>

                                  )}
                            </Grid>

                            :
                            attr.type === 'listOfValues' &&
                            <Grid key={attr.id + 'container-grid-' + index} container className="textField__container--y">
                              <Grid item xs={12}>
                                <Typography id={attr.id + index} className="textField__typography--form" variant="subtitle1" component="div" gutterBottom>
                                  {t(`repositoryTypes.${repositoryType ? repositoryType.value : props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`) + (attr.required ? " *" : " (" + t('labels.common.optional') + ")")}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  className={!attr.multiple && props.history && props.history.repoTypeMetadata ? repoTypeMetadataListOfValuesHistory(attr.value, index, props.history.repoTypeMetadata) : ""}
                                  getOptionSelected={(option, value) => option.value === value}
                                  filterOptions={options => options}
                                  multiple={attr.multiple}
                                  disableCloseOnSelect={attr.multiple}
                                  id={attr.id + "-autocomplete-input"}
                                  options={[...attr.options]}
                                  popupIcon={<ExpandMoreIcon className="arrowicon__color" />}
                                  value={attr.value || []}
                                  disabled={props.history || props.condition != undefined ? true : false}
                                  getOptionLabel={(selected) => selected.value ? attr.multilang ? getTranslation(selected.i18n, i18n.language, i18n.fallbackLng) : selected.value : ""}
                                  onChange={(event, value) => handleChangeAutocomplete(value, index)}
                                  renderOption={(option, { selected }) => {
                                    if (attr.multiple) {
                                      let sel = !!((attr.value && attr.value.find(selectedVal => selectedVal.value === option.value)) || selected)
                                      return (
                                        <>
                                          <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={sel}
                                          />
                                          {attr.multilang ? getTranslation(option.i18n, i18n.language, i18n.fallbackLng) : option.value}
                                        </>
                                      )
                                    }
                                    else {
                                      if (attr.multilang)
                                        return getTranslation(option.i18n, i18n.language, i18n.fallbackLng)
                                      else
                                        return option.value
                                    }
                                  }
                                  }
                                  renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, optIndex) => {
                                      let style = (props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && props.history.repoTypeMetadata.original[index] && props.history.repoTypeMetadata.original[index].value && props.history.repoTypeMetadata.original[index].value.some(prev => prev.key === option.key) || !props.history || !props.history.repoTypeMetadata) ? {} : { color: "#30A849", fontWeight: "bold" }
                                      return (
                                        <React.Fragment key={"listOfValues-option-" + optIndex}>
                                          <Chip
                                            label={<span style={style}>{getTranslation(option.i18n, i18n.language, i18n.fallbackLng)}</span>}
                                            {...getTagProps({ optIndex })}
                                          />
                                        </React.Fragment>
                                      )
                                    }
                                    )
                                  }
                                  }
                                  renderInput={(params) => <TextField {...params} label={t('common.labels.search') + ' ' + t(`repositoryTypes.${repositoryType ? repositoryType.value : props.object.repositoryType[0].value}.${attr.id.split('###')[2]}`)} variant="outlined" size="small" />}
                                />
                                {!attr.multiple && props.history && props.history.repoTypeMetadata && repoTypeMetadataListOfValuesHistory(attr.value, index, props.history.repoTypeMetadata) === "request__color--update" &&
                                  <Grid item xs={12}>
                                    <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                      {t('labels.workflows.previous') + getTranslation(props.history.repoTypeMetadata.original[index].value.i18n, i18n.language, i18n.fallbackLng)}
                                    </Typography>
                                  </Grid>
                                }
                                {attr.multiple && props.history && props.history.repoTypeMetadata && props.history.repoTypeMetadata.original && repoTypeMetadataListOfValuesHistory(attr.value, index, props.history.repoTypeMetadata)}
                                {
                                  repoTypeMetadataErrors[index] &&
                                  <FormHelperText id={attr.id + '-error'} error>{t('messages.error.configurations.repoTypeMetadata.value')}</FormHelperText>
                                }
                              </Grid>
                            </Grid>
                    )
                    }
                  </Grid>
                </>)
              }
              <TextFieldCommon
                className={props.history && props.history.gps ? props.history.gps.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                title={t('labels.repositories.gps.lat') + " (" + t('labels.common.optional') + ")"}
                id="gps-lat"
                type="text"
                value={props.object && props.object.gps ? props.object.gps.lat : latitude}
                isOpen={isErrorLatitude}
                required={false}
                message={t('messages.error.repositories.gps.lat')}
                fullWidth={true}
                handleChangeInput={setLatitude}
                previous={props.history && props.history.gps && props.history.gps.original ? props.history.gps.original.lat : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <TextFieldCommon
                className={props.history && props.history.gps ? props.history.gps.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                title={t('labels.repositories.gps.lng') + " (" + t('labels.common.optional') + ")"}
                id="gps-lng"
                type="text"
                value={props.object && props.object.gps ? props.object.gps.lng : longitude}
                isOpen={isErrorLongitude}
                required={false}
                message={t('messages.error.repositories.gps.lng')}
                fullWidth={true}
                handleChangeInput={setLongitude}
                previous={props.history && props.history.gps && props.history.gps.original ? props.history.gps.original.lng : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <Grid container>
                {!props.object ?
                  langs && langs.map((lang, index) =>
                    <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                      <TextFieldCommon
                        className={props.history && props.history.keyWords ? props.history.keyWords.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                        title={t('labels.repositories.keyWords', { lang })}
                        id={"keyWords-" + lang}
                        required={true}
                        isOpen={isErrorKeyWords[index]}
                        value={props.object && props.object.keyWords ? props.object.keyWords : keyWords}
                        addButton={index === langs.length - 1}
                        lang={lang}
                        langs={langs}
                        message={t('messages.error.repositories.keyWords')}
                        type="text"
                        fullWidth={true}
                        handleChangeInput={setKeyWords}
                        previous={props.history && props.history.keyWords && !props.history.keyWords.original.some(kw => props.object.keyWords.some(kw1 => kw1[lang] === kw[lang])) ? props.history.keyWords.original : null}
                        disabled={props.history || props.condition != undefined ? true : false}
                        renderIcon={true}
                        tooltip={t('tooltip.repositories.keyWords')}
                      />
                    </Grid>
                  )
                  :

                  langs && props.object.keyWords.map((keyWord, index) => {
                    return (

                      langs.map((lang, indexLang) => <Grid key={lang + index} item xs={12} md={6} className={indexLang % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                        {
                          index === 0 &&
                          <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            <div dangerouslySetInnerHTML={{ __html: t('labels.repositories.keyWords', { lang }) }} />
                          </Typography>
                        }
                        <TextField
                          className={(props.history && props.history.keyWords ? props.history.keyWords.original[index] ? props.history.keyWords.original[index][lang] !== props.object.keyWords[index][lang] ? "request__color--update" : "" : "request__color--create" : "") + " margin__top--9px"}
                          id={"keyWords-" + lang + "-" + index + "-input"}
                          size="small"
                          variant="outlined"
                          required={true}
                          value={keyWord[lang]}
                          disabled={props.history || props.condition != undefined ? true : false}
                          fullWidth={true}
                          name={"keyWords-" + lang}
                        />
                        {props.history && props.history.keyWords && props.history.keyWords.original[index] && props.history.keyWords.original[index][lang] !== keyWord[lang] ? <Grid item xs={12}>
                          <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('labels.workflows.previous') + (props.history.keyWords.original[index][lang])}
                          </Typography>
                        </Grid> : null}
                      </Grid>)
                    )
                  }

                  )
                }
              </Grid>
              <AutoCompleteCommon
                className={props.history && props.history.software ? props.history.software.action === "UPDATE" ? "request__color--update width__50" : "request__color--create width__50" : "width__50"}
                title={t('labels.repositories.software')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.software')}
                id="software"
                tag="autocomplete-vc"
                vc={true}
                path={host + "/controlled-vocabularies?field=software"}
                required={true}
                isOpen={isErrorSoftware}
                message={t('messages.error.repositories.software')}
                selectedValue={props.object && props.object.software ? props.object.software[0] : software}
                handleChange={setSoftware}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.software ? getTranslation(props.history.software.original[0].i18n, i18n.language, i18n.fallbackLng) : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                title={t('labels.repositories.content')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.content')}
                id="content"
                tag="autocomplete-checkbox"
                vc={true}
                path={host + "/controlled-vocabularies?field=content"}
                required={true}
                isOpen={isErrorContent}
                message={t('messages.error.repositories.content')}
                selectedValue={props.object && props.object.content ? props.object.content : content}
                handleChange={setContent}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.content ? props.history.content : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                title={t('labels.repositories.contentFormat')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.contentFormat')}
                id="contentFormat"
                tag="autocomplete-checkbox"
                vc={true}
                path={host + "/controlled-vocabularies?field=contentFormat"}
                required={true}
                isOpen={isErrorContentFormat}
                message={t('messages.error.repositories.contentFormat')}
                selectedValue={props.object && props.object.contentFormat ? props.object.contentFormat : contentFormat}
                handleChange={setContentFormat}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.contentFormat ? props.history.contentFormat : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                title={t('labels.repositories.language')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.language')}
                id="language"
                tag="autocomplete-checkbox"
                vc={true}
                path={host + "/controlled-vocabularies?field=lang"}
                required={true}
                isOpen={isErrorLanguage}
                message={t('messages.error.repositories.language')}
                selectedValue={props.object && props.object.lang ? props.object.lang : language}
                handleChange={setLanguage}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                preValues={["por", "eng"]}
                previous={props.history && props.history.lang ? props.history.lang : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                title={t('labels.repositories.subject')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.subject')}
                id="subject"
                tag="autocomplete-checkbox"
                vc={true}
                path={host + "/controlled-vocabularies?field=subject"}
                required={true}
                isOpen={isErrorSubject}
                message={t('messages.error.repositories.subject')}
                selectedValue={props.object && props.object.subject ? props.object.subject : subject}
                handleChange={setSubject}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.subject ? props.history.subject : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                title={t('labels.repositories.utility')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.utility')}
                id="utility"
                tag="autocomplete-checkbox"
                vc={true}
                path={host + "/controlled-vocabularies?field=utility"}
                required={true}
                isOpen={isErrorUtility}
                message={t('messages.error.repositories.utility')}
                selectedValue={props.object && props.object.utility ? props.object.utility : utility}
                handleChange={setUtility}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.utility ? props.history.utility : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                className={props.history && props.history.organization ? props.history.organization.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                title={t('titles.organizations.organization')}
                label={t('common.labels.search') + ' ' + t('titles.organizations.organization')}
                id="organization"
                tag="autocomplete-organization"
                vc={true}
                path={host + "/organizations?_sort=name:ASC"}
                required={true}
                isOpen={isErrorOrganization}
                editButton={props.history ? null : organization}
                message={t('messages.error.repositories.organization')}
                selectedValue={props.object && props.object.organization ? props.object.organization : organization}
                handleClick={(event) => handleOpenEdit(event, organization._id)}
                handleChange={setOrganization}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.organization ? getTranslation(props.history.organization.original.name, i18n.language, i18n.fallbackLng) : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                className={props.history && props.history.domain ? props.history.domain.action === "UPDATE" ? "request__color--update width__50" : "request__color--create width__50" : "width__50"}
                title={t('labels.repositories.domain')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.domain')}
                id="domain"
                tag="autocomplete-vc"
                vc={true}
                path={host + "/controlled-vocabularies?field=domain"}
                required={true}
                isOpen={isErrorDomain}
                message={t('messages.error.repositories.domain')}
                selectedValue={props.object && props.object.domain ? props.object.domain[0] : domain}
                handleChange={(domain) => changeDomain(domain)}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.domain ? getTranslation(props.history.domain.original[0].i18n, i18n.language, i18n.fallbackLng) : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              {!props.history && props.condition === undefined ?
                <Grid container justify="flex-end" className="textField__container">
                  <Grid item xs={12} md={12}>
                    <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.repositories.imgUpload') + " (" + t('labels.common.optional') + ")"}
                    </Typography>
                  </Grid>

                  <Grid container justify='center' alignItems={undo ? 'center' : 'flex-start'} item xs={12} md={6}>
                    {logo ?
                      <>
                        <Grid item xs={12} md={11} >
                          <img className="repositoryform__image--size" src={host + logo.url} alt={name} />
                        </Grid>
                        <Grid item xs={12} md={1} >
                          <Tooltip placement="top" title={t('tooltip.repositories.logo_remove')}>
                            <IconButton id="logo-remove-button" onClick={handleDeleteLogo} size='small'>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </>
                      :
                      undo ?
                        <Grid item xs={12} md={6} >
                          <SubmitButtonCommon
                            id="repository-logo-undo-button"
                            className="form__button--color-success"
                            title={t('common.buttons.undo')}
                            handleClick={handleUndoLogo} />
                        </Grid>
                        :
                        ""
                    }
                  </Grid>
                  <Grid item xs={12} md={(logo || undo) ? 6 : 12} className={(logo || undo) ? "fields__padding--left" : ""}>
                    {uploadSize && <DropzoneArea
                      className="dropzone__style"
                      initialFiles={uploadImage}
                      dropzoneText={showText ? t('common.labels.dropzone.title') + "\n" + t('common.labels.dropzone.maxFileSize', { size: convertBytesToMbsOrKbs(uploadSize.body) }) + "\n" + t('common.labels.dropzone.acceptedFileTypes', { filetypes: "JPEG, PNG, SVG, WebP, GIF" }) : null}
                      classes={{
                        icon: showText ? "dropZone__color--grey" : "dropZone__color--white"
                      }}
                      dropzoneParagraphClass="dropZone__image--text"
                      maxFileSize={uploadSize.body}
                      filesLimit={1}
                      acceptedFiles={['image/png', 'image/gif', 'image/jpeg', 'image/svg+xml', 'image/webp']}
                      onChange={handleChangeFile}
                      getFileAddedMessage={(filename) => (t('common.labels.dropzone.add', { file: filename }))}
                      getFileRemovedMessage={(filename) => (t('common.labels.dropzone.delete', { file: filename }))}
                      alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                    />}
                  </Grid>
                  {
                    (isErrorLogo) &&
                    <FormHelperText id="logo-error" error>{t('messages.error.repositories.upload')}</FormHelperText>
                  }

                </Grid>
                : props.object && props.object.logo && props.condition !== "REJECTED" || props.history && !props.history.logo ?
                  <TextLable
                    id="logo"
                    title={t('labels.repositories.logo')}
                    value={props.object.logo ? props.object.logo.url : ''}
                    image={true}
                  />
                  : props.history && props.history.logo && props.condition !== "REJECTED" &&
                  <Grid container className="textField__container">
                    <Grid item xs={12} md={6}>
                      <TextLable
                        id="logo"
                        title={t('labels.repositories.oldlogo')}
                        value={props.history.logo.original.url}
                        image={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextLable
                        id="logo"
                        title={t('labels.repositories.newlogo')}
                        value={props.object.logo ? props.object.logo.url : t('common.labels.removed')}
                        image={props.object.logo !== null}
                      />
                    </Grid>
                  </Grid>
              }
            </>
              ,
            <>
              <Grid container className="textField__container">
                <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  {t('labels.repositories.link.supTitle') + " (" + t('labels.common.optional') + ")"}
                </Typography>
                <AutoCompleteCommon
                  title={t('labels.repositories.link.title')}
                  label={t('common.labels.search') + ' ' + t('labels.repositories.link.title')}
                  id="link"
                  tag="autocomplete-link"
                  vc={true}
                  path={host + "/controlled-vocabularies?field=link"}
                  selectedValue={props.history ? props.history.link ? Object.values(linkBag) : props.object.link || [{}] : Object.values(linkBag).length !== 0 ? Object.values(linkBag) : link}
                  handleChange={setLink}
                  singleMultipleValues={true}
                  language={i18n.language}
                  fallbackLng={i18n.languages[i18n.languages.length - 1]}
                  disabled={props.history || props.condition != undefined ? true : false}
                />
              </Grid>
              <TextFieldCommon
                className={props.history && props.history.handlePrefix ? props.history.handlePrefix.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                title={t('labels.repositories.handlePrefix')}
                id="handlePrefix"
                optional={true}
                value={props.object && props.object.handlePrefix ? props.object.handlePrefix : handlePrefix}
                type="text"
                fullWidth={true}
                handleChangeInput={setHandlePrefix}
                previous={props.history && props.history.handlePrefix ? props.history.handlePrefix.original : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                title={t('labels.repositories.targetGroup')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.targetGroup')}
                id="targetGroup"
                tag="autocomplete-checkbox"
                vc={true}
                optional={true}
                path={host + "/controlled-vocabularies?field=targetGroup"}
                selectedValue={props.object && props.object.targetGroup ? props.object.targetGroup : targetGroup}
                handleChange={setTargetGroup}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.targetGroup ? props.history.targetGroup : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                title={t('labels.repositories.metadataFormat')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.metadataFormat')}
                id="metadataFormat"
                tag="autocomplete-checkbox"
                optional={true}
                vc={true}
                path={host + "/controlled-vocabularies?field=metadataFormat"}
                selectedValue={props.object && props.object.metadataFormat ? props.object.metadataFormat : metadataFormat}
                handleChange={setMetadataFormat}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.metadataFormat ? props.history.metadataFormat : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <AutoCompleteCommon
                title={t('labels.repositories.badges')}
                label={t('common.labels.search') + ' ' + t('labels.repositories.badges')}
                id="badges"
                tag="autocomplete-checkbox"
                vc={true}
                path={host + "/controlled-vocabularies?field=badge"}
                required={false}
                optional={true}
                message={t('repositories.error.subject')}
                selectedValue={props.object && props.object.badge ? props.object.badge : badges}
                handleChange={setBadges}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                previous={props.history && props.history.badge ? props.history.badge : null}
                disabled={props.history || props.condition != undefined ? true : false}
              />
              <Grid container className="textField__container">
                <Grid item xs={12} md={6} className="fields__padding--right">
                  <Grid item xs={12} md={12}>
                    <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.repositories.creationDate') + " (" + t('labels.common.optional') + ")"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={props.history && props.history.creationDate ? props.history.creationDate.action === "UPDATE" ? "datePicker__container request__color--update" : "datePicker__container request__color--create" : "datePicker__container"}
                        disableToolbar
                        inputVariant="outlined"
                        variant="inline"
                        format="yyyy"
                        openTo="date"
                        id="date-picker-inline"
                        label={t('labels.repositories.datePicker')}
                        value={props.object && props.object.creationDate ? props.object.creationDate : creationDate}
                        onChange={setCreationDate}
                        disabled={props.history || props.condition != undefined ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {props.history && props.history.creationDate && props.history.creationDate.original && <Grid item xs={12}>
                    <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.workflows.previous') + props.history.creationDate.original}
                    </Typography>
                  </Grid>}
                </Grid>
                <Grid item xs={12} md={6} className="fields__padding--left">
                  <Grid item xs={12} md={12}>
                    <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.repositories.terminationDate') + " (" + t('labels.common.optional') + ")"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={props.history && props.history.terminationDate ? props.history.terminationDate.action === "UPDATE" ? "datePicker__container request__color--update" : "datePicker__container request__color--create" : "datePicker__container"}
                        disableToolbar
                        inputVariant="outlined"
                        variant="inline"
                        format="yyyy/MM"
                        openTo="date"
                        id="date-picker-inline"
                        label={t('labels.repositories.datePicker')}
                        value={props.object && props.object.terminationDate ? props.object.terminationDate : terminationDate}
                        onChange={setTerminationDate}
                        disabled={props.history || props.condition != undefined ? true : false}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {props.history && props.history.terminationDate && props.history.terminationDate.original && <Grid item xs={12}>
                    <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.workflows.previous') + props.history.terminationDate.original}
                    </Typography>
                  </Grid>}
                </Grid>
                {
                  hasPermissions() &&
                  <AutoCompleteCommon
                    className={props.history && props.history.badge ? props.history.badge.action === "UPDATE" ? "request__color--update" : "request__color--create" : null}
                    title={t('labels.repositories.owner')}
                    label={t('common.labels.search') + ' ' + t('labels.repositories.owner')}
                    id="owner"
                    tag="autocomplete-owner"
                    path={host + "/users?email_contains="}
                    required={false}
                    optional={true}
                    message={t('repositories.error.subject')}
                    selectedValue={props.object && props.object.owner ? props.object.owner : owner}
                    handleChange={setOwner}
                    previous={props.history && props.history.owner ? props.history.owner.original : null}
                    disabled={props.history || props.condition != undefined ? true : false}
                  />}
              </Grid>
            </>,
            <>
              <Grid container className="textField__container">
                <AutoCompleteCommon
                  title={t('labels.repositories.policy.title')}
                  label={t('common.labels.search') + ' ' + t('labels.repositories.policy.title')}
                  id="policy"
                  tag="autocomplete-policy"
                  vc={true}
                  optional={true}
                  path={host + "/controlled-vocabularies?field=policy"}
                  showDescription={true}
                  isOpen={policyErrors}
                  message={t('messages.error.repositories.description')}
                  selectedValue={props.history ? Object.values(policyBag).length === 0 ? [null] : Object.values(policyBag) : Object.values(policy).length === 0 ? [null] : Object.values(policyBag).length !== 0 ? Object.values(policyBag) : policy}
                  handleChange={setPolicy}
                  singleMultipleValues={true}
                  langs={langs}
                  language={i18n.language}
                  fallbackLng={i18n.languages[i18n.languages.length - 1]}
                  disabled={props.history || props.condition != undefined ? true : false}
                  renderIcon={true}
                />
              </Grid>
            </>,
            <Grid container className="textField__container">
              <InsertStatistics
                title={t('labels.repositories.statistics.title') + " (" + t('labels.common.optional') + ")"}
                label={t('common.labels.search') + ' ' + t('labels.repositories.statistics.title')}
                id="statistics"
                tag="autocomplete-statistics"
                vc={true}
                path={host + "/statistics"}
                selectedValue={props.history ? Object.values(statsBag).length === 0 ? [{}] : Object.values(statsBag) : Object.values(statsBag).length !== 0 ? Object.values(statsBag) : stats}
                handleChange={setStats}
                singleMultipleValues={true}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
                disabled={props.history || props.condition != undefined ? true : false}
              />
            </Grid>
            ]} />
        {!props.history && props.condition === undefined ?
          <Grid container className="textField__container repositoryform__terms--margins">
            <Terms
              label={t('labels.repositories.terms.supTitle')}
              termForm={showTerms}
              terms={terms}
              checkedTerm={checkedTerm}
              setCheckedTerm={setCheckedTerm}
              isErrorTerm={isErrorTerm}
              errorMessage={t('messages.error.repositories.term')}
            />
          </Grid>
          : null}
      </Card>
      {
        !props.history && props.condition === undefined ?
          <Grid container className="form__button--justify-left repositoryform__buttons--margins">
            <SubmitButtonCommon
              id="repository-save-button"
              className="form__button--color-success"
              title={t('common.buttons.save')}
              handleClick={handleSubmitRepository} />
            {props.goBack ? <SubmitButtonCommon
              id="repository-back-button"
              className="form__button--color-error"
              title={t('common.buttons.cancel')}
              handleClick={props.goBack} />
              :
              <SubmitButtonCommon
                id="repository-cancel-button"
                className="form__button--color-error"
                title={t('common.buttons.cancel')}
                goBack />}
          </Grid>
          : null
      }
      {props.domain &&
        <Dialog id="domain-dialog" open={showDialog}>
          <DialogTitle id="domain-dialog-title">{t('titles.repositories.header.domainChange')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="domain-dialog-description">
              <Typography component="div">
                <div className="configuration__typography--textField--margin" dangerouslySetInnerHTML={{ __html: t('help.repository.domain_change', { prevDomain: getTranslation(props.domain.i18n, i18n.language, i18n.fallbackLng), newDomain: getTranslation(domain.i18n, i18n.language, i18n.fallbackLng) }) }} />
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAcceptDomainChanger} className="form__button--color-success">
              {t('labels.workflows.confirm.confirmButton')}
            </Button>
            <Button onClick={handleDisagreeDomainChanger} className="form__button--color-error">
              {t('labels.workflows.confirm.cancelButton')}
            </Button>
          </DialogActions>
        </Dialog>
      }
      <Dialog id="lose-changes-dialog" open={showLoseChangesDialog}>
        <DialogTitle id="lose-changes-dialog-title">{t('titles.repositories.header.loseChanges')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="lose-changes-dialog-description">
            <Typography component="div">
              <div className="configuration__typography--textField--margin">
                {t('help.repository.lose_changes')}
              </div>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => handleAcceptLoseChanges(event, organization._id)} className="form__button--color-success">
            {t('labels.workflows.confirm.confirmButton')}
          </Button>
          <Button onClick={handleDisagreeLoseChanges} className="form__button--color-error">
            {t('labels.workflows.confirm.cancelButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
