import React, { useContext } from 'react'
import { Typography } from '@material-ui/core';

import Axios from 'axios'
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"

import HelpForm from '@components/Help/HelpForm'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewHelp() {
    const [t] = useTranslation("common")
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

    let history = useHistory()

    const submitHelp = async (help) => {
        try {
            await Axios.post(host + '/helps', help)
            snackForHelp("success", t('messages.success.help.create'))
            history.goBack()
        }
        catch {
            snackForHelp("error", t('messages.error.help.create'))
        }
    }

    const snackForHelp = (tag, message) => {
        setTag(tag)
        setMessage(message)
        setIsSnackBar(true)
    }

    return (
        <div className="admin__container-bottom">
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { href: "/#/admin/helps", name: "helps" },
                { name: "helps_new" }
            ]} />
            <Typography component="h2" className="admin__card--title">{t('titles.help.create')}</Typography>
            <div >
                <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.help.form')}</Typography>
            </div>
            <HelpForm
                snackForHelp={snackForHelp}
                submitHelp={submitHelp}
                submitLable={t('help.form.submit.create')}
            />
        </div>
    )
}
