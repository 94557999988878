import React from 'react'

import LoginPage from '@components/User/Login'

import '@styles/frontOffice/User/Login.css'

export default function Login() {
  return (
    <div className="login__container--center public__container--padding">
      <LoginPage />
    </div>
  )
}