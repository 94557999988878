import React, { useEffect, useState, useContext } from "react"
import { useHistory, useLocation } from 'react-router-dom';

import Axios from "axios"
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"
import ControlledVocabularyForm from "@components/ControlledVocabulary/ControlledVocabularyForm";
import { useTranslation } from "react-i18next";
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"

const qs = require('qs')

export default function NewControlledVocabulary(props) {
    const [t] = useTranslation("common")
    const location = useLocation()

    const [langs, setLangs] = useState(null)
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
    const [value, setValue] = useState('')
    const [labels, setLabels] = useState({})
    const [info, setInfo] = useState({})

    const [isErrorValue, setIsErrorValue] = useState(false)
    const [isErrorUpload, setIsErrorUpload] = useState(false)
    const [isErrorInfo, setIsErrorInfo] = useState(false)
    const [isErrorLabels, setIsErrorLabels] = useState(false)


    const query = qs.parse(location.search.replace(/\?/, ''))
    const [field, setField] = useState(query.field || 'badge')

    const [uploadImage, setUploadImage] = useState([])
    const [showText, setShowText] = useState(true)
    let history = useHistory()

    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
                setIsErrorInfo(res[0].body.map(l => false))
                setIsErrorLabels(res[0].body.map(l => false))
            })
    }, [setLangs])

    const handleChangeFile = (file) => {
        if (file.length !== 0) {
            setShowText(false)
        }
        else {
            setShowText(true)
        }
        setUploadImage(file)
    }

    const handleCreateControlledVocabulary = async () => {
        let validate = true
        if (value === '') {
            setIsErrorValue(true)
            validate = false
        } else setIsErrorValue(false)
        if (langs.some(l => !labels[l] || labels[l] === "")) {
            setIsErrorLabels(langs.map(l => !labels[l] || labels[l] === ""))
            validate = false
        } else setIsErrorLabels(langs.map(l => false))
        if (info && Object.keys(info).length > 0 && (langs.some(lang => !info[lang] || info[lang] === '') && langs.some(lang => info[lang] && info[lang] !== '')) || langs.some(lang => (info[lang] && info[lang].length > 500))) {
            setIsErrorInfo(langs.map(lang => !info[lang] || (info[lang].length > 500 || info[lang] === '')))
            validate = false
        } else setIsErrorInfo(langs.map(l => false))
        if (field === 'badge' && uploadImage.length === 0) {
            setIsErrorUpload(true)
            validate = false
        }
        if (validate) {
            if (field === 'badge') {
                try {
                    const data = new FormData()
                    data.append('files', uploadImage[0])

                    let res = await Axios.post(host + "/upload", data)
                    let newLogo = res.data[0]
                    let logo = { url: newLogo.url, _id: newLogo._id }
                    let newControlledVocabulary = {
                        field: field,
                        key: field + '###' + value,
                        value: value,
                        i18n: {
                            ...labels,
                            badge: logo
                        },
                        info: info
                    }
                    await Axios.post(host + '/controlled-vocabularies', newControlledVocabulary)
                    setIsSnackBar(true)
                    setTag("success")
                    setMessage(t('messages.success.controlledVocabulary.create'))
                    history.goBack()
                }
                catch {
                    setIsSnackBar(true)
                    setTag("error")
                    setMessage(t('messages.error.controlledVocabulary.create'))
                }
            }
            else {
                try {
                    let newControlledVocabulary = {
                        field: field,
                        key: field + '###' + value,
                        value: value,
                        i18n: labels,
                        info: info
                    }
                    await Axios.post(host + '/controlled-vocabularies', newControlledVocabulary)
                    setIsSnackBar(true)
                    setTag("success")
                    setMessage(t('messages.success.controlledVocabulary.create'))
                    history.goBack()
                }
                catch {
                    setIsSnackBar(true)
                    setTag("error")
                    setMessage(t('messages.error.controlledVocabulary.create'))
                }
            }
        }
        else {
            setIsSnackBar(true)
            setTag("error")
            setMessage(t('messages.error.common.validation'))
        }
    }


    useEffect(() => {
        if (query && field !== query.field) {
            setField(query.field)
            if (query.field === undefined) {
                setField("badge")
            }
        }
    }, [query.field])

    return (
        <div className="admin__container-bottom">
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" }, ,
                { href: "/#/admin/" + (history.location.pathname.includes('badges') ? "badges" : "controlled-vocabularies?field=" + field), name: history.location.pathname.includes('badges') ? "badges" : t('titles.controlledVocabulary.' + field), fullTitle: history.location.pathname.includes('badges') ? false : true },
                { name: history.location.pathname.includes('badges') ? "badges_new" : t('titles.controlledVocabulary.create') + ' ' + t('titles.controlledVocabulary.' + field), fullTitle: history.location.pathname.includes('badges') ? false : true }
            ]} />
            <ControlledVocabularyForm
                field={field ? field : 'badge'}
                setValue={setValue}
                showText={showText}
                setLabels={setLabels}
                setInfo={setInfo}
                value={value}
                info={info}
                labels={labels}
                handleChangeFile={handleChangeFile}
                isErrorValue={isErrorValue}
                isErrorLabels={isErrorLabels}
                isErrorUpload={isErrorUpload}
                isErrorInfo={isErrorInfo}
                renderSubmit={true}
                handleClick={handleCreateControlledVocabulary}
            />
        </div>
    )

}
