import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';

import GeneralForm from '@components/Configuration/GeneralForm'
import SmtpForm from '@components/Configuration/SmtpForm';
import TableCommon from '@components/Common/Table'

import TermForm from '@components/Configuration/TermForm'

import { UserContext } from '@context/userContext';

import adminAccess from '@utils/comparators/AdminAccess'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

import "@styles/backOffice/Configuration/Configurations.css"
import '@styles/Common/Common.css'

const qs = require('qs')

function renderFilteredConfigurations(field, isDisabled, setIsDisabled) {
  switch (field) {
    default:
      break
    case 'general':
      return <GeneralForm field={field} disabled={isDisabled} setDisabled={setIsDisabled} />
    case 'smtp':
      return <SmtpForm field={field} disabled={isDisabled} setDisabled={setIsDisabled} />
    case 'term':
      return <TermForm field={field} disabled={isDisabled} setDisabled={setIsDisabled} />
  }
}

export default function Configurations() {
  const [t] = useTranslation('common')
  const location = useLocation()
  const query = qs.parse(location.search.replace(/\?/, ''))
  const [isDisabled, setIsDisabled] = useState(query.disable === 'true' ? true : false)
  const [user] = useContext(UserContext)
  const history = useHistory()
  const [headers, setHeaders] = useState(null)

  const handleClickUpdate = (event) => {
    event.preventDefault()
    let disable = !isDisabled
    setIsDisabled(!isDisabled)
    if (query.index) {
      history.push('/admin/configurations?field=' + query.field + '&disable=' + disable + '&index=' + query.index)
    }
    else {
      history.push('/admin/configurations?field=' + query.field + '&disable=' + disable)
    }
  }

  useEffect(() => {
    if (query.field === "emailTemplate") {
      setHeaders([
        {
          name: t('labels.notifications.body.subject'),
          value: ["body", "subject"]
        },
        {
          name: t('labels.configurations.templateObj.format.title'),
          value: ["body", "format"]
        }
      ])
    }
    setIsDisabled(query.disable === 'true' ? true : false)
  }, [query.field, t, query.disable, setIsDisabled, setHeaders])

  useEffect(() => {
    if (query.field !== "emailTemplate"){

      let disable = isDisabled
      if (query.index) {
        history.push('/admin/configurations?field=' + query.field + '&disable=' + disable + '&index=' + query.index)
      }
      else {
        history.push('/admin/configurations?field=' + query.field + '&disable=' + disable)
      }
    }
  }, [isDisabled])

  return (
    <>
      {query.field === "emailTemplate" ?
        <div className="admin__container-bottom">

          <BreadCrumbs crumbs={[
            { href: "/#/", name: "home" },
            { href: "/#/admin", name: "admin" },
            { name: "emailTemplates" }
          ]} />
          <Typography id="backoffice-emailTemplate-title" component="h2" className="admin__card--title">{t('titles.configurations.emailTemplates')}</Typography>
          {headers && <TableCommon
            headers={headers}
            backendPath="/configurations"
            frontendPath="/emailTemplates"
            filter={"type=TEMPLATE"}
            sort="createdAt"
            controller="configuration"
            order="desc"
            hasRemoveButton={false}
            hasAddButton={false}
          />}
        </div>
        :
        <div className="admin__container-bottom">

          <BreadCrumbs crumbs={[
            { href: "/#/", name: "home" },
            { href: "/#/admin", name: "admin" },
            { name: query.field }
          ]} />
          <Grid container>
            <Grid item xs={6}>
              <Typography component="h2" className="admin__card--title">{query.field && t('titles.configurations.' + query.field)}</Typography>
            </Grid>
            <Grid item xs={6} className="admin__align--left">
              {(user && adminAccess('configuration', 'create', user.group.permission)) && isDisabled && <Tooltip title={query.field && t('tooltip.configurations.' + query.field)}>
                <Button id="configurations-edit-button" className="actionButton admin__button--create" size="medium" onClick={handleClickUpdate}
                  startIcon={
                    <CreateIcon className="configuration--edit--icon--color" />
                  }
                  role="adminCards"
                >
                  {t("common.buttons.edit")}
                </Button>
              </Tooltip>}
            </Grid>
          </Grid>
          <div >
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.configuration.' + query.field)}</Typography>
          </div>
          {renderFilteredConfigurations(query.field, isDisabled, setIsDisabled)}
        </div>
      }
    </>
  );
}
