import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Card } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';



import { UserContext } from '@context/userContext';


import BreadCrumbs from '@components/Common/BreadCrumbs.js'



const qs = require('qs')

export default function ImportPanel() {
    const [t, i18n] = useTranslation("common")
    let history = useHistory()
    const [user] = useContext(UserContext)

    const handleImportType = (type) => {
        if (type === 'file')
            history.push('/admin/repositories/import/file')
        else
            history.push('/admin/repositories/import/' + type)
    }

    return (
        <div className="admin__container-bottom" >
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/admin", name: "admin" },
                { href: "/#/admin/repositories", name: "repositories" },
                { name: "import" }
            ]} />
            <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.repositories.import')}</Typography>
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">
                {t('help.repository.import')}
            </Typography>
            <Card elevation={0} className="tableComponent__repository--filter">
                <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    {t('labels.repositories.import')}
                </Typography>
                <Grid spacing={1} justify="flex-start" container>
                    <Grid item>
                        <Tooltip title={t('tooltip.repositories.import')}>
                            <Button
                                id="repository-import-button-file"
                                className="form__button--color-success"
                                onClick={() => handleImportType('file')}
                            >
                                {t('common.buttons.import_file')}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('tooltip.repositories.import')}>
                            <Button
                                id="repository-import-button-opendoar"
                                className="form__button--color-success"
                                onClick={() => handleImportType('opendoar')}
                            >
                                {t('common.buttons.import_opendoar')}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('tooltip.repositories.import')}>
                            <Button
                                id="repository-import-button-doaj"
                                className="form__button--color-success"
                                onClick={() => handleImportType('doaj')}
                            >
                                {t('common.buttons.import_doaj')}
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Card>

        </div>
    );
}
