import React from "react";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";

export const Accessibility = props => {
  const [cookies] = useCookies(["accessibility"]);
  let accessibility = cookies.accessibility;

  if (accessibility) {
    return (
      <Helmet>
        <style type='text/css'>
          {`
            :root {
              --navbar-height: 62px;
              --navbar-width: 304px;
              --color-blue-nav: #006371;
              --color-blue-tab: #006371;
              --color-blue-table-header: #006371;
              --color-lighten-blue: rgba(2, 182, 204, 0.22);
              --color-blue: #006371;
              --color-link: #006371;
              --color-green: #80CE92;
              --color-green-chip: rgba(54, 176, 83, 0.14);
              --color-lighten-green: rgba(54, 176, 83, 0.2);
              --color-green-tabs: rgba(96, 192, 118, 0.25);
              --color-green-tabs-shade: rgba(54, 176, 83, 0.25);
              --color-lighten-green2: rgba(108, 200, 130, 0.07);
              --color-darken-green: #1a5727;
              --color-grey-outline: #D4DFE0;
              --color-grey-text: #6F6F6F;
              --color-white: #ffffff;
              --color-red: #B84547;
              --color-background: #F6F6F6;
              --color-black: #202020;
              --color-request-edit: #f7ae26;
              --color-request-remove: #177200;
              --color-orange: #C55700;
              --color-request-add: #ee3e3e;
              --color-disabled-tab-text: rgba(0,0,0,0.5);
              --color-lighten-red: rgba(184, 69, 71, 0.1);
            }

            .appNavBar__button--dimentions, .appNavBar__title--dimentions {
              background-color: #006371 !important;
              color: #fff !important;
            }
            .appNavBar__user--container .appNavBar__button--dimentions {
              background-color: var(--color-darken-green) !important;
              color: #fff !important;
            }
          
          `}
        </style>
      </Helmet>
    );
  } else {
    return (null)
  }
}

export default Accessibility;