import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import Axios from 'axios';
import { host } from '@utils/config'

import { SnackBarContext } from "@context/snackBarContext.js"
import { DialogContext } from '@context/dialogContext'
import { UserContext } from '@context/userContext'
import { useHistory } from 'react-router-dom';

import '@styles/Common/Common.css'
import { Button } from '@material-ui/core';

export default function ActionPanel(props) {
  const [t, i18n] = useTranslation('common')
  const [user] = useContext(UserContext)
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [setDialogOpen, setDialogMessage, setDialogCancel, setDialogConfirm, setDialogAction] = useContext(DialogContext)
  const [data, setData] = useState(null)
  const [condition, setCondition] = useState("")

  const [open, setOpen] = useState(false)

  let history = useHistory()

  useEffect(() => {
    setData(props.data)
    setCondition(props.data.condition)
  }, [setData, props.data, setCondition])

  const handleAssignRequest = async (event, data) => {
    event.stopPropagation()
    setDialogMessage(t('labels.workflows.confirm.assign'))
    setDialogCancel(t('labels.workflows.confirm.cancelButton'))
    setDialogConfirm(t('labels.workflows.confirm.confirmButton'))
    let obj = {
      function: assignRequest(data),
    }
    setDialogAction(obj)
    setDialogOpen(true)
  }

  const assignRequest = (data) => async () => {
    try {
      await Axios.put(host + "/requests/assign/" + data._id, {
        userId: user._id
      })
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.actions'))
      history.push('/admin/requests/' + data._id + "?condition=ASSIGNED")
    }
    catch {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.actions'))
    }
  }

  const validateRepoTypeMetadata = (attr) => {
    let value = attr.multiple ? attr.value : [attr.value]
    if (attr.required) {
      if (attr.type === 'text' || attr.type === 'longText' || attr.type === 'url')
        if (attr.multilang)
          return value.length === 0 || (value.length > 0 && value.filter(val => !Object.entries(val).every(m => m[1] !== '' && m[1] !== null && m[1] !== undefined)).length > 0)
        else
          return value.length === 0 || (value.length > 0 && value.filter(val => val === undefined || val === null || val === '').length > 0)

      if (attr.type === 'date')
        return value.length === 0 || (value.length > 0 && value.filter(val => val === undefined || val === null || val === '').length > 0)

      if (attr.type === 'listOfValues')
        return !value.some(v => v !== null && v.length !== 0) || value.length === 0

    } else {
      if (attr.type === 'text' || attr.type === 'longText' || attr.type === 'url')
        if (attr.multilang)
          return (value.filter(val => !(Object.entries(val).every(m => m[1] === '') || Object.entries(val).every(m => m[1] !== '' && m[1] !== null && m[1] !== undefined))).length > 0)
    }
  }

  const approveRequest = (data) => async () => {
    let validation = true
    if (data.objectType === 'REPOSITORY') {
      if (data.object.repoTypeMetadata && data.object.repoTypeMetadata.length > 0) {
        for (let attr of data.object.repoTypeMetadata) {
          validation = !validateRepoTypeMetadata(attr)
          if (!validation)
            break;
        }
      }
    }
    try {
      if (validation) {
        await Axios.put(host + "/requests/approve/" + data._id, data)
        setIsSnackBar(true)
        setTag("success")
        setMessage(t('messages.success.actions'))
        history.push('/admin/requests/' + data._id + "?condition=APPROVED")
      }
      else {
        setIsSnackBar(true)
        setTag("error")
        setMessage(t('messages.error.common.validation'))
      }
    }
    catch (error) {
      if (error.response.data.message.includes("Error validating organization")) {
        setDialogMessage(<div>{t('messages.error.requests.organization') + " " + error.response.data.data.name[i18n.language]}</div>)
        setDialogConfirm(null)
        setDialogOpen(true)
      } else if (error.response.data.message.includes("Error validating owner")) {
        setDialogMessage(<div>{t('messages.error.requests.owner')}</div>)
        setDialogConfirm(null)
        setDialogOpen(true)
      }
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.actions'))
    }
  }

  const handleApproveRequest = async (event, data) => {
    event.stopPropagation()
    setDialogMessage(t('labels.workflows.confirm.approve'))
    setDialogCancel(t('labels.workflows.confirm.cancelButton'))
    setDialogConfirm(t('labels.workflows.confirm.confirmButton'))
    let obj = {
      function: approveRequest(data),
    }
    setDialogAction(obj)
    setDialogOpen(true)
  }

  const handleRejectRequest = async (event, data) => {
    event.stopPropagation()
    setDialogMessage(t('labels.workflows.confirm.reject'))
    setDialogCancel(t('labels.workflows.confirm.cancelButton'))
    setDialogConfirm(t('labels.workflows.confirm.confirmButton'))
    let obj = {
      function: rejectRequest(data),
      message: true,
      text: t('labels.workflows.confirm.note')
    }
    setDialogAction(obj)
    setDialogOpen(true)
  }

  const rejectRequest = (data) => async (message) => {
    try {
      await Axios.put(host + "/requests/reject/" + data._id, { note: message })
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.actions'))
      history.push('/admin/requests/' + data._id + "?condition=REJECTED")
    }
    catch {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.actions'))
    }
  }

  return (
    <>
      {
        condition === "ASSIGNED" &&
        <Tooltip title={t('labels.workflows.action.approve')}>
          {
            !props.buttons ?
              <IconButton id={"request-approve-icon-" + props.index} onClick={(event) => handleApproveRequest(event, data)}>
                <AssignmentTurnedInIcon fontSize="small" />
              </IconButton> :

              <Button
                id="request-approve-button"
                onClick={(event) => handleApproveRequest(event, data)}
                className="actionButton form__button--color-success"
                variant="contained"
              >
                {t('labels.workflows.button.approve')}
              </Button>
          }
        </Tooltip>
      }
      {
        condition === "CREATED" &&
        <Tooltip title={t('labels.workflows.action.assign')}>
          {
            !props.buttons ?
              <IconButton id={"request-assign-icon-" + props.index} onClick={(event) => handleAssignRequest(event, data)}>
                <PersonIcon fontSize="small" />
              </IconButton> :
              <Button
                id="request-assign-button"
                className="actionButton form__button--color-success"
                variant="contained"
                onClick={(event) => handleAssignRequest(event, data)}
              >
                {t('labels.workflows.button.assign')}
              </Button>
          }
        </Tooltip>
      }
      {
        condition === "ASSIGNED" &&
        <Tooltip title={t('labels.workflows.action.reject')}>
          {
            !props.buttons ?
              <IconButton id={"request-reject-icon" + props.index} onClick={(event) => handleRejectRequest(event, data)}>
                <AssignmentReturnIcon fontSize="small" />
              </IconButton> :
              <Button
                id="request-reject-button"
                className="actionButton form__button--color-error"
                onClick={(event) => handleRejectRequest(event, data)}
                variant="contained"
              >
                {t('labels.workflows.button.reject')}
              </Button>
          }
        </Tooltip>
      }

    </>
  )
}
