import React, { useState, useEffect, useContext } from 'react';
import { Card, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

import Axios from 'axios'
import { host } from '@utils/config'

import SubmitButtonCommon from '@components/Common/SubmitButton';
import TextFieldCommon from '@components/Common/TextField';
import SelectorCommon from '@components/Common/Selector';
import CaptchaCommon from '@components/Common/Captcha';
import AutoCompleteCommon from '@components/Common/AutoComplete';

import { CaptchaKeyContext } from '@context/captchaKeyContext';
import { SnackBarContext } from "@context/snackBarContext.js"

import RemoveIcon from '@material-ui/icons/Remove';
import getRequest from '@utils/functions/axiosRequest'
import Terms from '@components/Configuration/Terms.js'

export default function RegisterPage() {
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [captchaKey] = useContext(CaptchaKeyContext)
  const [t, i18n] = useTranslation('common')
  const [showCreateOrganization, setShowCreateOrganization] = useState(false)

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [organization, setOrganization] = useState(null)
  const [job, setJob] = useState([])
  const [subject, setSubject] = useState([])
  const [subjectNotifications, setSubjectNotifications] = useState(false)
  const [phone, setPhone] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [captcha, setCaptcha] = useState('')
  const [cienciaid, setCienciaId] = useState('')

  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [emailOrganization, setEmailOrganization] = useState('')
  const [address, setAddress] = useState('')
  const [fax, setFax] = useState('')
  const [phones, setPhones] = useState([""])
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [legalForm, setLegalForm] = useState(null)
  const [schedule, setSchedule] = useState('')
  const [comments, setComments] = useState('')
  const [notes, setNotes] = useState('')
  const [country, setCountry] = useState(null)
  // const [liability, setLiability] = useState(null)
  const [organizationType, setOrganizationType] = useState(null)

  const [userTerms, setUserTerms] = useState(null)
  const [organizationTerms, setOrganizationTerms] = useState(null)
  const [checkedUserTerms, setCheckedUserTerms] = useState(null)
  const [checkedOrganizationTerms, setCheckedOrganizationTerms] = useState(null)

  const [selectedJobs, setSelectedJobs] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)

  const [isEmail, setIsEmail] = useState(false)
  const [isPassword, setIsPassword] = useState(false)
  const [isConfirmPassword, setIsConfirmPassword] = useState(false)
  const [isFirstName, setIsFirstName] = useState(false)
  const [isLastName, setIsLastName] = useState(false)
  const [isOrganization, setIsOrganization] = useState(false)
  const [isJob, setIsJobs] = useState(false)
  const [isSubject, setIsSubject] = useState(false)

  const [isPhone, setIsPhone] = useState(false)
  const [isCaptcha, setIsCaptha] = useState(false)
  const [isLanguage, setIsLanguage] = useState(false)
  const [isUserTerm, setIsUserTerm] = useState(false)

  const [isName, setIsName] = useState(false)
  const [isEmailOrganization, setIsEmailOrganization] = useState(false)
  const [isAddress, setIsAddress] = useState(false)
  const [isPhones, setIsPhones] = useState(false)
  const [isCountry, setIsCountry] = useState(false)
  // const [isLiability, setIsLiability] = useState(false)
  const [isOrganizationType, setIsOrganizationType] = useState(false)
  const [isOrganizationTerms, setIsOrganizationTerms] = useState(false)
  const [isErrorSchedule, setIsErrorSchedule] = useState(false)
  const [isErrorComments, setIsErrorComments] = useState(false)
  const [isErrorNotes, setIsErrorNotes] = useState(false)
  const [isErrorLatitude, setIsErrorLatitude] = useState(false)
  const [isErrorLongitude, setIsErrorLongitude] = useState(false)
  const [langs, setLangs] = useState(null)

  const [provider, setProvider] = useState('local')
  const recaptchaRef = React.createRef();
  let history = useHistory()

  const handleCaptchaChange = (value) => {
    setCaptcha(value)
  }

  const handleCaptchaSubmit = () => {
    recaptchaRef.current.execute();
  }

  const handleOpenRenderCreateOrganization = () => {
    setShowCreateOrganization(true)
  }

  const handleCloseRenderCreateOrganization = () => {
    setShowCreateOrganization(false)
  }

  const validateOrganization = async () => {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let validation = true

    if (!name || Object.keys(name).length === 0 || langs.filter(lang => !name[lang] || name[lang] === '').length > 0) {
      let newNameErrors = langs.map(l => !name[l] || name[l] === "")
      setIsName(newNameErrors)
      validation = false
    } else setIsName(langs.map(l => false))
    if (!emailOrganization.match(emailRegExp)) {
      setIsEmailOrganization(true)
      validation = false
    } else setIsEmailOrganization(false)
    if (!country) {
      setIsCountry(true)
      validation = false
    } else setIsCountry(false)
    if (!address) {
      setIsAddress(true)
      validation = false
    } else setIsAddress(false)
    if (phones.length === 1 && phones[0] === "") {
      setIsPhones(true)
      validation = false
    } else setIsPhones(false)
    // if (!liability) {
    //   setIsLiability(true)
    //   validation = false
    // } else setIsLiability(false)
    if ((latitude && (latitude > 90 || latitude < -90) || longitude && !latitude) || isNaN(latitude)) {
      setIsErrorLatitude(true)
      validation = false
    } else setIsErrorLatitude(false)
    if ((longitude && (longitude > 180 || longitude < -180) || latitude && !longitude) || isNaN(longitude)) {
      setIsErrorLongitude(true)
      validation = false
    } else setIsErrorLongitude(false)
    if (!organizationType) {
      setIsOrganizationType(true)
      validation = false
    } else setIsOrganizationType(false)
    if (!checkedOrganizationTerms) {
      setIsOrganizationTerms(true)
      validation = false
    } else setIsOrganizationTerms(false)
    if (Object.keys(schedule).length > 0 && (langs.some(lang => !schedule[lang] || schedule[lang] === '') && langs.some(lang => schedule[lang] && schedule[lang] !== ''))) {
      let newScheduleErrors = langs.map((l) => (!schedule[l] || schedule[l] === ""))
      setIsErrorSchedule(newScheduleErrors)
      validation = false
    } else setIsErrorSchedule(langs.map(l => false))
    if (Object.keys(comments).length > 0 && (langs.some(lang => !comments[lang] || comments[lang] === '') && langs.some(lang => comments[lang] && comments[lang] !== ''))) {
      let newCommentsErrors = langs.map((l) => (!comments[l] || comments[l] === ""))
      setIsErrorComments(newCommentsErrors)
      validation = false
    } else setIsErrorComments(langs.map(l => false))
    if (Object.keys(notes).length > 0 && (langs.some(lang => !notes[lang] || notes[lang] === '') && langs.some(lang => notes[lang] && notes[lang] !== ''))) {
      let newNotesErrors = langs.map((l) => (!notes[l] || notes[l] === ""))
      setIsErrorNotes(newNotesErrors)
      validation = false
    } else setIsErrorNotes(langs.map(l => false))
    if (!validation) {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.users.register.validation'))
    }

    return validation;
  }

  const handleUserRegister = async () => {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/
    let validate = true
    if (!email.match(emailRegExp)) {
      setIsEmail(true)
      validate = false
    } else setIsEmail(false)
    if (provider === 'local' && (!password.match(passRegExp) || password.length === 0)) {
      setIsPassword(true)
      validate = false
    } else setIsPassword(false)
    if (provider === 'local' && password !== confirmPassword) {
      setIsConfirmPassword(true)
      validate = false
    } else setIsConfirmPassword(false)
    if (!firstName) {
      setIsFirstName(true)
      validate = false
    } else setIsFirstName(false)
    if (!lastName) {
      setIsLastName(true)
      validate = false
    } else setIsLastName(false)
    //if (job.length === 0) {
    //  setIsJobs(true)
    //  validate = false
    //} else setIsJobs(false)
    setIsJobs(false)
    setIsSubject(false)

    // if (phone.length === 0 || (phone.length === 1 && phone[0] === "")) {
    //   setIsPhone(true)
    //   validate = false
    // } else setIsPhone(false)

    if (provider === 'local' && captchaKey !== '' && !captcha) {
      setIsCaptha(true)
      validate = false
    } else setIsCaptha(false)
    if (!showCreateOrganization && !organization) {
      setIsOrganization(true)
      validate = false
    } else setIsOrganization(false)
    if (!selectedLanguage) {
      setIsLanguage(true)
      validate = false
    } else setIsLanguage(false)
    if (captchaKey !== '' && !captcha) {
      setIsCaptha(true)
      validate = false
    } else setIsCaptha(false)
    if (!checkedUserTerms) {
      setIsUserTerm(true)
      validate = false
    } else setIsUserTerm(false)
    if (!validate) {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.users.register.validation'))
    }
    else {
      try {
        let date = new Date()
        let newUserTerms = [...userTerms]
        newUserTerms[newUserTerms.length - 1]['accepted_at'] = date.toISOString()
        let newRegisterUser = {
          email: email,
          username: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          job: job,
          subject: subject,
          subjectNotifications: subjectNotifications,
          phone: phone,
          language: selectedLanguage.value,
          terms: newUserTerms
        }

        if (provider === 'cienciaid') {
          newRegisterUser.cienciaid = cienciaid
          newRegisterUser.confirmed = true
        }

        if (showCreateOrganization && (await validateOrganization()).valueOf()) {
          await Axios.post(host + '/auth/' + provider + '/register', newRegisterUser)
            .then(async (res) => {
              let newOrgTerms = [...organizationTerms]
              newOrgTerms[newOrgTerms.length - 1]['accepted_at'] = date.toISOString()
              let newOrganization = {
                name: name,
                url: url,
                email: emailOrganization,
                address: address,
                fax: fax,
                phones: phones,
                gps: {
                  lat: latitude,
                  lng: longitude
                },
                schedule: schedule,
                notes: notes,
                comments: comments,
                country: [country],
                // liability: liability,
                organizationType: [organizationType],
                legalForm: legalForm,
                users: [res.data.user._id],
                terms: [newOrgTerms[newOrgTerms.length - 1]]
              }

              try {
                let newRequest = {
                  object: { ...newOrganization },
                  objectType: "ORGANIZATION",
                  type: "CREATE",
                }
                await Axios.post(host + '/requests', newRequest)
                setIsSnackBar(true)
                setTag("success")
                setMessage(t('messages.success.users.register'))
                history.push("/")
              }
              catch {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.users.register.organization'))
                return;
              }
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                let message = error.response.data.message[0].messages[0].message;
                if (message === "Email is already taken.") {
                  setMessage(t('messages.error.users.register.email'))
                }
                else {
                  if (message === "Impossible to find the default group.") {
                    setMessage(t('messages.error.users.register.group'))
                  }
                  else {
                    if (message === "Impossible to find the default role.") {
                      setMessage(t('messages.error.users.register.role'))
                    }
                    else {
                      if (message === 'Email not reachable.') {
                        setMessage(t('messages.error.users.register.emailConfirmation'))
                      }
                      else {
                        setMessage(t('messages.error.users.register.default'))
                      }
                    }
                  }
                }
              }
              else {
                setMessage(t('messages.error.users.register.default'))
              }
              setIsSnackBar(true)
              setTag("error")
              setMessage(t('messages.error.users.register.default'))
            })
        }
        else if (!showCreateOrganization) {
          newRegisterUser.organization = organization._id;

          await Axios.post(host + '/auth/' + provider + '/register', newRegisterUser)
            .then(() => {
              setIsSnackBar(true)
              setTag("success")
              setMessage(t('messages.success.users.register'))
              history.push("/")
              if (provider === 'cienciaid') {
                window.location.reload();
              }
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                let message = error.response.data.message[0].messages[0].message;
                if (message === "Email is already taken.") {
                  setMessage(t('messages.error.users.register.email'))
                }
                else {
                  if (message === "Impossible to find the default group.") {
                    setMessage(t('messages.error.users.register.group'))
                  }
                  else {
                    if (message === "Impossible to find the default role.") {
                      setMessage(t('messages.error.users.register.role'))
                    }
                    else {
                      if (message === 'Email not reachable.') {
                        setMessage(t('messages.error.users.register.emailConfirmation'))
                      }
                      else {
                        setMessage(t('messages.error.users.register.default'))
                      }
                    }
                  }
                }
              }
              else {
                setMessage(t('messages.error.users.register.default'))
              }
              setIsSnackBar(true)
              setTag("error")
            })
        }
      } catch {
        setIsSnackBar(true)
        setTag("error")
        setMessage(t('messages.error.users.register.default'))
      }
    }
  }

  const handleChangeJobs = (value) => {
    if (value) {
      let newJobs = [...value]
      let refactor = []
      for (let job of newJobs) {
        refactor.push({
          key: job.key,
          field: job.field,
          value: job.value,
          i18n: { ...job.i18n }
        })
      }
      setJob(refactor)
      setSelectedJobs(value)
    }
  };

  const handleChangeSubject = (value) => {
    if (value) {
      let newSubject = [...value]
      let refactor = []
      for (let subject of newSubject) {
        refactor.push({
          key: subject.key,
          field: subject.field,
          value: subject.value,
          i18n: {
            pt: subject.i18n.pt,
            en: subject.i18n.en
          }
        })
      }
      setSubject(refactor)
      setSelectedSubject(value)
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeLangInput = (value, lang, input, set) => {
    let newValue = { ...input }
    newValue[lang] = value
    set(newValue)
  }

  const handleSpreadLangInput = (event, value, input, set) => {
    if (value !== undefined && value !== '') {
      let newValue = { ...input }
      langs.map(l => newValue[l] === '' || newValue[l] === undefined || event.type === 'dblclick' ? newValue[l] = value : '')
      set(newValue)

    }
  }

  useEffect(() => {
    if (window.location.href.includes('provider=cienciaid')) {
      Axios.get(host + '/users/me')
        .then(res => {
          if (res.data.provider === 'cienciaid') {
            setProvider('cienciaid')
            setEmail(res.data.email)
            setFirstName(res.data.firstName)
            setLastName(res.data.lastName)
            setCienciaId(res.data.cienciaid)
            setSelectedLanguage(res.data.lang === 'pt' ? { name: t('common.labels.language.pt'), value: 'pt' } : { name: t('common.labels.language.en'), value: 'en' })
          }
        })
    }
  }, [t, setEmail])

  useEffect(() => {
    Axios.get(host + '/configurations/public?type=TERM&&objectType=USER')
      .then(res => {
        setUserTerms(res.data)
      })

    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setUserTerms, setLangs])

  useEffect(() => {
    Axios.get(host + '/configurations/public?type=TERM&&objectType=ORGANIZATION')
      .then(res => {
        setOrganizationTerms(res.data)
      })
  }, [setOrganizationTerms])

  useEffect(() => {
    const passRegExp = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d]|[^A-Za-z0-9]){13,}$/
    if (provider === 'local' && (password.match(passRegExp) && password.length >= 13))
      setIsPassword(false)
    if (provider === 'local' && password === confirmPassword)
      setIsConfirmPassword(false)
  }, [password, confirmPassword])
  return (
    <Card elevation={0} className="MuiBox-root-43">
      <Grid container>

        <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
          {t('titles.users.register.authentication')}
        </Typography>

        <TextFieldCommon
          className="width__33"
          title={t('labels.users.email')}
          id="email"
          required={true}
          isOpen={isEmail}
          message={t('messages.error.users.email')}
          label={t('common.labels.required')}
          type="text"
          fullWidth={true}
          handleChangeInput={setEmail}
          value={email}
        />

        <TextFieldCommon
          className="width__33"
          title={t('labels.users.password')}
          id="password"
          required={true}
          isOpen={isPassword}
          message={t('messages.error.users.password.error')}
          label={t('common.labels.required')}
          type="password"
          renderIcon={true}
          showPassword={showPassword}
          value={password}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          fullWidth={true}
          handleChangeInput={setPassword}
          render={provider === 'local'}
        />

        <TextFieldCommon
          className="width__33"
          title={t('labels.users.confirmPassword')}
          id="confirmPassword"
          required={true}
          isOpen={isConfirmPassword}
          message={t('messages.error.users.confirmPassword')}
          label={t('common.labels.required')}
          type="password"
          renderIcon={true}
          showPassword={showConfirmPassword}
          handleClickShowPassword={handleClickShowConfirmPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          fullWidth={true}
          handleChangeInput={setConfirmPassword}
          value={confirmPassword}
          password={password}
          render={provider === 'local'}
        />

        {
          provider === 'cienciaid' &&
          <TextFieldCommon
            className="width__33"
            title={t('labels.users.scienceID')}
            id="cienciaid"
            type="text"
            fullWidth={true}
            disabled={true}
            handleChangeInput={setCienciaId}
            value={cienciaid}
          />
        }

        <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
          {t('titles.users.register.personal')}
        </Typography>

        <TextFieldCommon
          className="width__33"
          title={t('labels.users.firstName')}
          id="firstName"
          required={true}
          isOpen={isFirstName}
          message={t('messages.error.users.firstName')}
          label={t('common.labels.required')}
          type="text"
          fullWidth={true}
          handleChangeInput={setFirstName}
          value={firstName}
        />
        <TextFieldCommon
          className="width__33"
          title={t('labels.users.lastName')}
          id="lastName"
          required={true}
          isOpen={isLastName}
          message={t('messages.error.users.lastName')}
          label={t('common.labels.required')}
          type="text"
          fullWidth={true}
          handleChangeInput={setLastName}
          value={lastName}
        />

        <AutoCompleteCommon
          title={t('labels.users.job')}
          label={t('common.labels.search') + ' ' + t('labels.users.job')}
          id="job"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=job"}
          required={true}
          optional={true}
          isOpen={isJob}
          message={t('messages.error.users.job')}
          selectedValue={selectedJobs}
          handleChange={handleChangeJobs}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
        />
        <AutoCompleteCommon
          title={t('labels.users.subject')}
          label={t('common.labels.search') + ' ' + t('labels.users.subject')}
          id="subject"
          tag="autocomplete-checkbox"
          vc={true}
          path={host + "/controlled-vocabularies?field=subject"}
          required={false}
          optional={true}
          isOpen={isSubject}
          selectedValue={selectedSubject}
          handleChange={handleChangeSubject}
          language={i18n.language}
          fallbackLng={i18n.languages[i18n.languages.length - 1]}
        />
        <Grid container className="textField__container">
          <Grid item xs={12} md={12}>
            <Typography id="subjectNotifications-lable" className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
              <div dangerouslySetInnerHTML={{ __html: t('labels.users.emailNotifications') }} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              className="checkbox__text"
              label={t('labels.users.subjectNotifications')}
              control={
                <Checkbox
                  id="subjectNotifications-input"
                  checked={subjectNotifications}
                  onChange={(event) => setSubjectNotifications(event.target.checked)}
                  name="subjectNotifications"
                />
              }
            />
          </Grid>
        </Grid>
        <TextFieldCommon
          className="width__33"
          title={t('labels.users.phone')}
          id="phone"
          optional={true}
          isOpen={isPhone}
          message={t('messages.error.users.phone')}
          label={t('common.labels.required')}
          type="text"
          fullWidth={true}
          handleChangeInput={setPhone}
        />
        <SelectorCommon
          className="width__33"
          title={t('labels.users.language')}
          id="language"
          tag="autocomplete-simple"
          required={true}
          isOpen={isLanguage}
          message={t('messages.error.users.language')}
          label={t('common.labels.required')}
          selectedValue={selectedLanguage}
          handleChangeAutocomplete={setSelectedLanguage}
          dataValues={langs ? langs.map(lang => {
            return {
              name: t('common.labels.language.' + lang), value: lang
            }
          }) : []}
        />
        {
          !showCreateOrganization ?
            <AutoCompleteCommon
              title={t('titles.organizations.organization')}
              label={t('common.labels.search') + ' ' + t('titles.organizations.organization')}
              id="organization"
              tag="autocomplete-organization"
              vc={true}
              path={host + "/organizations?_sort=name:ASC"}
              required={true}
              isOpen={isOrganization}
              message={t('messages.error.users.organization')}
              selectedValue={organization}
              handleChange={setOrganization}
              language={i18n.language}
              fallbackLng={i18n.languages[i18n.languages.length - 1]}
              addButton={showCreateOrganization ? false : true}
              handleClick={showCreateOrganization ? handleCloseRenderCreateOrganization : handleOpenRenderCreateOrganization}
            /> :
            <Grid container className="register__box--style">
              <Grid key="new-organization" item xs={12} md={12}>
                <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  {t('titles.organizations.organization')}
                  <Button className="form__button--remove" size="small" onClick={(event) => handleCloseRenderCreateOrganization(event)}>
                    <RemoveIcon className="form__icon--remove" />
                  </Button>
                </Typography>
              </Grid>
              {langs && langs.map((lang, index) =>
                <Grid key={"organization-name-" + lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextFieldCommon
                    title={t('labels.organizations.name') + " <sup>" + lang + "</sup>"}
                    id={"organization-name-" + lang}
                    required={true}
                    isOpen={isName[index]}
                    value={name[lang]}
                    message={t('messages.error.organizations.name')}
                    label={t('common.labels.required')}
                    type="text"
                    fullWidth={true}
                    handleChangeInput={(value) => handleChangeLangInput(value, lang, name, setName)}
                    handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, name, setName)}
                    renderIcon={true}
                  />
                </Grid>
              )}
              <TextFieldCommon
                title={t('labels.organizations.url')}
                id="organization-url"
                required={false}
                optional={true}
                value={url}
                type="text"
                fullWidth={true}
                handleChangeInput={setUrl}
              />

              <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                {t('titles.organizations.contact')}
              </Typography>

              <TextFieldCommon
                className="width__33"
                title={t('labels.organizations.email')}
                id="organization-email"
                required={true}
                isOpen={isEmailOrganization}
                value={emailOrganization}
                message={t('messages.error.organizations.email')}
                label={t('common.labels.required')}
                type="text"
                fullWidth={true}
                handleChangeInput={setEmailOrganization}
              />
              <TextFieldCommon
                className="width__33"
                title={t('labels.organizations.phones')}
                id="organization-phones"
                required={true}
                isOpen={isPhones}
                message={t('messages.error.organizations.phones')}
                addButton={true}
                value={phones}
                label={t('common.labels.required')}
                type="text"
                fullWidth={true}
                handleChangeInput={setPhones}
              />
              <TextFieldCommon
                className="width__33"
                title={t('labels.organizations.fax')}
                id="organization-fax"
                optional={true}
                value={fax}
                type="text"
                fullWidth={true}
                handleChangeInput={setFax}
              />
              <TextFieldCommon
                title={t('labels.organizations.address')}
                id="organization-address"
                required={true}
                isOpen={isAddress}
                value={address}
                message={t('messages.error.organizations.address')}
                label={t('common.labels.required')}
                type="text"
                fullWidth={true}
                handleChangeInput={setAddress}
              />
              <AutoCompleteCommon
                className="width__33"
                title={t('labels.organizations.country')}
                label={t('common.labels.search') + ' ' + t('labels.organizations.country')}
                id="organization-country"
                tag="autocomplete-vc"
                vc={true}
                path={host + "/controlled-vocabularies?field=country"}
                required={true}
                isOpen={isCountry}
                message={t('messages.error.organizations.country')}
                selectedValue={country}
                handleChange={setCountry}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
              />
              <TextFieldCommon
                className="width__33"
                title={t('labels.organizations.gps.lat')}
                id="organization-latitude"
                type="text"
                isOpen={isErrorLatitude}
                required={false}
                optional={true}
                value={latitude}
                message={t('messages.error.repositories.gps.lat')}
                fullWidth={true}
                handleChangeInput={setLatitude}
              />
              <TextFieldCommon
                className="width__33"
                title={t('labels.organizations.gps.lng')}
                id="organization-longitude"
                isOpen={isErrorLongitude}
                required={false}
                optional={true}
                value={longitude}
                message={t('messages.error.repositories.gps.lng')}
                type="text"
                fullWidth={true}
                handleChangeInput={setLongitude}
              />

              {langs && langs.map((lang, index) =>
                <Grid key={"organization-schedule-" + lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextFieldCommon
                    title={t('labels.organizations.schedule') + " <sup>" + lang + "</sup>"}
                    id={"organization-schedule-" + lang}
                    multiline={true}
                    fullWidth={true}
                    value={schedule[lang]}
                    type="text"
                    required={false}
                    optional={true}
                    isOpen={isErrorSchedule[index]}
                    message={t('messages.error.organizations.schedule')}
                    handleChangeInput={(value) => handleChangeLangInput(value, lang, schedule, setSchedule)}
                    handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, schedule, setSchedule)}
                    renderIcon={true}
                  />
                </Grid>
              )}

              <Typography className="textField__typography--subtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                {t('titles.organizations.aditional')}
              </Typography>

              <AutoCompleteCommon
                className="width__50"
                title={t('labels.organizations.organizationType')}
                label={t('common.labels.search') + ' ' + t('labels.organizations.organizationType')}
                id="organization-organizationType"
                tag="autocomplete-vc"
                required={true}
                isOpen={isOrganizationType}
                vc={true}
                path={host + "/controlled-vocabularies?field=organizationType"}
                message={t('messages.error.organizations.organizationType')}
                selectedValue={organizationType}
                handleChange={setOrganizationType}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
              />
              {/* <AutoCompleteCommon
                className="width__50"
                title={t('labels.organizations.liability')}
                id="organization-liability"
                tag="autocomplete-checkbox"
                vc={true}
                path={host + "/controlled-vocabularies?field=liability"}
                required={true}
                isOpen={isLiability}
                message={t('messages.error.organizations.liability')}
                selectedValue={liability}
                handleChange={setLiability}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
              /> */}
              <AutoCompleteCommon
                className="width__50"
                title={t('labels.organizations.legalForm')}
                id="legalForm"
                tag="autocomplete-vc"
                required={false}
                optional={true}
                vc={true}
                path={host + "/controlled-vocabularies?field=legalForm"}
                selectedValue={legalForm}
                handleChange={setLegalForm}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
              />
              {langs && langs.map((lang, index) =>
                <Grid key={"organization-comments" + lang + index} item xs={12} md={6} className={(index % 2 === 0 ? "fields__padding--right" : "fields__padding--left")}>
                  <TextFieldCommon
                    title={t('labels.organizations.comments') + " <sup>" + lang + "</sup>"}
                    id={"organization-comments-" + lang}
                    multiline={true}
                    value={comments[lang]}
                    fullWidth={true}
                    type="text"
                    required={false}
                    optional={true}
                    isOpen={isErrorComments[index]}
                    message={t('messages.error.organizations.comments')}
                    handleChangeInput={(value) => handleChangeLangInput(value, lang, comments, setComments)}
                    handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, comments, setComments)}
                    renderIcon={true}
                  />
                  </Grid>
                  )}
              {langs && langs.map((lang, index) =>
                <Grid key={"organization-notes" + lang + index} item xs={12} md={6} className={(index % 2 === 0 ? "fields__padding--right" : "fields__padding--left")}>
                  <TextFieldCommon
                    title={t('labels.organizations.notes') + " <sup>" + lang + "</sup>"}
                    id={"organization-notes-" + lang}
                    multiline={true}
                    value={notes[lang]}
                    fullWidth={true}
                    type="text"
                    required={false}
                    optional={true}
                    isOpen={isErrorNotes[index]}
                    message={t('messages.error.organizations.notes')}
                    handleChangeInput={(value) => handleChangeLangInput(value, lang, notes, setNotes)}
                    handleSpreadInput={(event, value) => handleSpreadLangInput(event, value, notes, setNotes)}
                    renderIcon={true}
                  />
                </Grid>
              )}
              <Terms
                label={t('labels.organizations.terms')}
                termForm={true}
                terms={organizationTerms}
                checkedTerm={checkedOrganizationTerms}
                setCheckedTerm={setCheckedOrganizationTerms}
                isErrorTerm={isOrganizationTerms}
                errorMessage={t('messages.error.organizations.term')}
              />
            </Grid>
        }
        <Terms
          label={t('labels.users.terms')}
          termForm={true}
          terms={userTerms}
          checkedTerm={checkedUserTerms}
          setCheckedTerm={setCheckedUserTerms}
          isErrorTerm={isUserTerm}
          errorMessage={t('messages.error.users.terms')}
        />
        <Grid item xs={10} md={11} className="captcha__justify--right">
          <CaptchaCommon
            id='captcha'
            recaptchaRef={recaptchaRef}
            language={i18n.language}
            handleCaptchaChange={handleCaptchaChange}
            handleCaptchaSubmit={handleCaptchaSubmit}
            isOpen={isCaptcha}
            message={t('messages.error.users.captcha.error')}
            render={provider === 'local'}
          />
        </Grid>
        <Grid item xs={12} md={3} />
        <Grid container className="form__button--justify-left">
          <SubmitButtonCommon
            id="save-button"
            className="form__button--color-success"
            title={t('common.buttons.save')}
            fullWidth={false}
            handleClick={handleUserRegister}
          />
        </Grid>
      </Grid>
    </Card >
  )
}
