import React, { useState, useContext, useEffect } from 'react';
import { Card, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tab from '@components/Common/Tab/Tab'

import Axios from 'axios';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import { useLocation } from "react-router-dom";
import { SnackBarContext } from "@context/snackBarContext.js"
import TextFieldCommon from '@components/Common/TextField';
import { useTranslation } from 'react-i18next';
import WYSIWYGeditorCommon from '@components/Common/WYSIWYGeditor';
import SubmitButtonCommon from '@components/Common/SubmitButton';

export default function TermsAccordion(props) {
  const [t, i18n] = useTranslation('common')
  const location = useLocation()
  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [undoTerms, setUndoTerms] = useState([])
  const [terms, setTerms] = useState([])
  const [userContent, setUserContent] = useState('')
  const [repoContent, setRepoContent] = useState('')
  const [orgContent, setOrgContent] = useState('')
  const [langs, setLangs] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [isErrorUser, setIsErrorUser] = useState(false)
  const [isErrorRepository, setIsErrorRepository] = useState(false)
  const [isErrorOrganization, setIsErrorOrganization] = useState(false)
  const [isErrorTab, setIsErrorTab] = useState(new Array(false, false, false))


  const validateTerms = () => {
    let ret = true, error
    let regex = /\[(.*)\]/
    let newErrorTab = [...isErrorTab]
    for (let term of terms) {
      error = true
      if (!Object.entries(term.body.description).every(entry => {
        let match = regex.exec(entry[1])
        if (!match || !match[1] || /^\s*$/.test(match[1])) {
          return false
        }
        return true
      })) {
        ret = false
        error = false
      }
      if (term.objectType === 'USER') {
        newErrorTab[0] = !error
        setIsErrorTab(newErrorTab)
        setIsErrorUser(!error)
      }
      if (term.objectType === 'REPOSITORY') {
        newErrorTab[1] = !error
        setIsErrorTab(newErrorTab)
        setIsErrorRepository(!error)
      }
      if (term.objectType === 'ORGANIZATION') {
        newErrorTab[2] = !error
        setIsErrorTab(newErrorTab)
        setIsErrorOrganization(!error)
      }
    }
    return ret
  }

  const handleChange = (configuration, value, lng, key) => {
    let newTerms = [...terms]
    if (!newTerms.includes(configuration)) {
      newTerms.push(configuration)
    }
    for (let term of newTerms) {
      if (term._id === configuration._id) {
        term.body[key][lng] = value
      }
    }
    setTerms(newTerms)
  }

  const handleSpread = (event, configuration, value, key) => {
    let newTerms = [...terms]
    if (!newTerms.includes(configuration)) {
      newTerms.push(configuration)
    }
    for (let term of newTerms) {
      if (term._id === configuration._id) {
        if (value !== undefined && value !== '')
          langs.map(l => term.body[key][l] === '' || term.body[key][l] === undefined || event.type === 'dblclick' ? term.body[key][l] = value : '')
      }
    }
    setTerms(newTerms)
  }

  const handleChangeisEdit = () => {
    for (let term of undoTerms) {
      if (term.objectType === 'USER')
        setUserContent({ ...term.body.content })
      else if (term.objectType === 'REPOSITORY')
        setRepoContent({ ...term.body.content })
      else if (term.objectType === 'ORGANIZATION')
        setOrgContent({ ...term.body.content })
    }
    setTerms(JSON.parse(JSON.stringify(undoTerms)))
    setIsEdit(false)
    props.setDisabled(true)

  }

  const handleChangeContent = (value, type, lang) => {
    let newContent
    if (type === 'USER') {
      newContent = userContent
      newContent[lang] = value
      setUserContent(newContent)
    }
    else if (type === 'REPOSITORY') {
      newContent = repoContent
      newContent[lang] = value
      setRepoContent(newContent)
    }
    else if (type === 'ORGANIZATION') {
      newContent = orgContent
      newContent[lang] = value
      setOrgContent(newContent)
    }
  }

  const handleUpdateTerm = async () => {
    if (validateTerms()) {
      for (let term of terms) {
        if (term.objectType === 'USER')
          term.body.content = userContent
        else if (term.objectType === 'REPOSITORY')
          term.body.content = repoContent
        else if (term.objectType === 'ORGANIZATION')
          term.body.content = orgContent

        await Axios.put(host + '/configurations/' + term._id, term)
          .then(() => {
          })
          .catch(() => {
            setIsSnackBar(true)
            setTag("error")
            setMessage(t('messages.error.configurations.update'))
          })
      }
      setUndoTerms(JSON.parse(JSON.stringify(terms)))
      props.setDisabled(!props.disabled)
      setIsSnackBar(true)
      setTag("success")
      setMessage(t('messages.success.configurations.update'))
    }
    else {
      setIsSnackBar(true)
      setTag("error")
      setMessage(t('messages.error.configurations.update'))
    }
  }

  useEffect(() => {
    if (location.search.includes('disable=true')) {
      setIsEdit(false)
      props.setDisabled(true)
      setIsErrorUser(false)
      setIsErrorRepository(false)
      setIsErrorOrganization(false)
    }
    else {
      setIsEdit(true)
      props.setDisabled(false)
    }
  }, [location])

  useEffect(() => {
    getRequest(host + '/configurations?type=TERM')
      .then(res => {
        res.entities.map(config => {
          if (config.objectType === 'USER')
            setUserContent(config.body.content)
          else if (config.objectType === 'REPOSITORY')
            setRepoContent(config.body.content)
          else if (config.objectType === 'ORGANIZATION')
            setOrgContent(config.body.content)
        })
        setTerms(JSON.parse(JSON.stringify(res.entities)))
        setUndoTerms(JSON.parse(JSON.stringify(res.entities)))
      })
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setTerms])

  return (
    <>
      <Card elevation={0} className="margin__top--32px">
        {terms && <Tab
          headers={terms.map(term => t('labels.configurations.termObj.' + term.objectType.toLowerCase()))}
          editor={false}
          error={isErrorTab}
          components={terms.map(term => {
            return (
              <div style={{ overflow: 'hidden' }} key={term._id}>
                <Grid container className="textField__container--right">
                  {langs && langs.map((lang, index) =>
                    <Grid key={"configurations-term-description-" + lang} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                      <TextFieldCommon
                        id={"configurations-term-description-" + lang}
                        title={t('labels.configurations.termObj.label_description', { lang: lang })}
                        required={true}
                        value={term.body.description[lang]}
                        multiline={true}
                        rows={5}
                        type="text"
                        disabled={props.disabled}
                        isOpen={isEdit && (term.objectType === 'USER' ? isErrorUser : term.objectType === 'REPOSITORY' ? isErrorRepository : isErrorOrganization)}
                        message={t('messages.error.configurations.termObj.description')}
                        fullWidth={true}
                        handleChangeInput={(value) => handleChange(term, value, lang, "description")}
                        handleSpreadInput={(event, value) => handleSpread(event, term, value, "description")}
                        renderIcon={true}
                      />
                    </Grid>
                  )}
                  {langs && langs.map((lang, index) =>
                    <Grid key={"configurations-term-content-" + lang} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                      <Grid item xs={12}>
                        <Typography id={"configurations-term-content-" + lang + '-label'} className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom><div dangerouslySetInnerHTML={{ __html: t('labels.configurations.termObj.label_content', { lang: lang }) }} /></Typography>
                      </Grid>
                      {props.disabled ?

                        <Typography component="div" className="configuration__typography--textField"><div className="configuration__typography--textField--margin" dangerouslySetInnerHTML={{ __html: term.objectType === 'USER' ? userContent[lang] : term.objectType === 'REPOSITORY' ? repoContent[lang] : orgContent[lang] }} /></Typography>
                        :
                        <WYSIWYGeditorCommon
                          id={"configurations-term-content-" + lang}
                          lang={i18n.language}
                          defaultValue={term.objectType === 'USER' ? userContent[lang] : term.objectType === 'REPOSITORY' ? repoContent[lang] : orgContent[lang]}
                          handleChange={(value) => handleChangeContent(value, term.objectType, lang)}
                        />
                      }
                    </Grid>
                  )}
                </Grid>
              </div>
            )
          })}
        />}
      </Card>
      {!props.disabled &&
        <Grid container className="form__button--justify-left  repositoryform__buttons--margins">
          <SubmitButtonCommon
            id="configurations-term-save-button"
            className="form__button--color-success"
            title={t('common.buttons.save')}
            disabled={props.disabled}
            handleClick={handleUpdateTerm} />
          <SubmitButtonCommon
            id="configurations-term-cancel-button"
            className="form__button--color-error"
            title={t('common.buttons.cancel')}
            handleClick={handleChangeisEdit} />
        </Grid>
      }
    </>
  )
}