import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import Tab from '@components/Common/Tab/Tab'
import getTranslation from '@utils/functions/utilities.js'
import Terms from '@components/Configuration/Terms.js'

export default function Profile() {
  const [t, i18n] = useTranslation('common')
  const [user] = useContext(UserContext)

  let history = useHistory()

  const handleUpdateUser = () => {
    history.push('/profile/update/')
  }

  const handleChangePassword = () => {
    history.push('/profile/changePassword/')
  }

  return (
    <>
      <Typography id="profile-title" component="h2" className="admin__card--title admin__form--title-margin">{t('titles.users.profile.header')}</Typography>
      <Card elevation={0}>
        <Tab
          headers={[t('titles.users.profile.header'), t('titles.organizations.organization')]}
          components={
            [
              <>
                <Grid container>
                  <TextLable
                    id='profile-firstName'
                    title={t('labels.users.firstName')}
                    value={user.firstName}
                  />
                  <TextLable
                    id='profile-lastName'
                    title={t('labels.users.lastName')}
                    value={user.lastName}
                  />
                  <TextLable
                    id='profile-organization'
                    title={t('titles.organizations.organization')}
                    value={getTranslation(user.organization.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />
                  <TextLable
                    id='profile-email'
                    title={t('labels.users.email')}
                    value={user.email}
                  />
                  <TextLable
                    id='profile-job'
                    title={t('labels.users.job')}
                    value={user.job && user.job.length > 0 ? user.job.map(job => getTranslation(job.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])) : t('common.labels.notDefined')}
                    isArray={user.job && user.job.length > 0}
                  />
                  <TextLable
                    id='profile-subject'
                    title={t('labels.users.subject')}
                    value={user.subject && user.subject.length > 0 ? user.subject.map(subject => getTranslation(subject.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])) : t('common.labels.notDefined')}
                    isArray={user.subject && user.subject.length > 0}
                  />
                  <TextLable
                    id='profile-subjectNotifications'
                    title={t('labels.users.subjectNotifications')}
                    value={user.subjectNotifications ? t('common.labels.true') : t('common.labels.false')}
                  />
                  <TextLable
                    id='profile-group'
                    title={t('labels.users.group')}
                    value={getTranslation(user.group.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />
                  <TextLable
                    id='profile-language'
                    title={t('labels.users.language')}
                    value={t('common.labels.language.' + user.lang)}
                  />
                  <TextLable
                    id='profile-phone'
                    title={t('labels.users.phone')}
                    value={user.phone ? user.phone : t('common.labels.notDefined')}
                  />
                  <TextLable
                    id="cienciaid"
                    title={t('labels.users.scienceID')}
                    value={user.cienciaid}
                  />
                  <TextLable
                    id='profile-provider'
                    title={t('labels.users.provider')}
                    value={user.provider}
                  />
                  {user.group.name["en"] === 'domain' &&
                    <TextLable
                      id='profile-domain'
                      title={t('labels.users.domain')}
                      value={getTranslation(user.domain[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                    />}
                  <Terms
                    showTerms={true}
                    terms={user.terms}
                    value={t('labels.users.terms')}
                    acceptedAtLabel={t('labels.users.termsAcceptedAt')}
                  />
                  <Grid container className="form__button--justify-left">
                    <SubmitButtonCommon
                      id='profile-update-personal-data-button'
                      className="form__button--color-success"
                      title={t('titles.users.profile.update')}
                      handleClick={handleUpdateUser} />
                    {user.provider === 'local' &&
                      <SubmitButtonCommon
                        id='profile-change-password-button'
                        className="form__button--color-success"
                        title={t('titles.users.changePassword')}
                        handleClick={handleChangePassword} />}
                  </Grid>
                </Grid>
              </>,
              <>
                <Grid container>
                  <TextLable
                    id='profile-organization-name'
                    title={t('labels.organizations.name')}
                    value={getTranslation(user.organization.name, i18n.language, i18n.languages[i18n.languages.length - 1])}
                  />
                  <TextLable
                    id='profile-organization-organizationType'
                    title={t('labels.organizations.organizationType')}
                    value={user.organization.organizationType && user.organization.organizationType.length > 0 ? getTranslation(user.organization.organizationType[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
                  />
                  <TextLable
                    id='profile-organization-url'
                    title={t('labels.organizations.url')}
                    value={user.organization.url}
                    url={true}
                  />
                  <TextLable
                    id='profile-organization-email'
                    title={t('labels.organizations.email')}
                    value={user.organization.email}
                    url={true}
                    mailto={true}
                  />
                  <TextLable
                    id='profile-organization-country'
                    title={t('labels.organizations.country')}
                    value={user.organization.country && user.organization.country.length > 0 ? getTranslation(user.organization.country[0].i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
                  />
                  <TextLable
                    id='profile-organization-address'
                    title={t('labels.organizations.address')}
                    value={user.organization.address}
                  />
                </Grid>
              </>
            ]} />
      </Card>
    </>
  )
}
