import React, { useState, useEffect } from 'react'

import NewsPage from '@components/NewsItem/NewsItem'
import { useParams } from 'react-router-dom';
import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function NewsItem() {
  const [news, setNews] = useState(null)
  let { id } = useParams()

  useEffect(() => {
    getRequest(host + '/news-items/' + id)
      .then(res => {
        setNews(res)
      })
  }, [id])
  return (
    <>
    {news && 
    <div className="admin__container-bottom" >
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin" , name: "admin"},
      { href: "/#/admin/news" , name: "news"},
      { name: "news-item" }
    ]} />
      <NewsPage news={news} />
    </div>
    }
    </>
  )
}
