import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts"
import getTranslation from '@utils/functions/utilities.js'

import '@styles/Common/Common.css'

export default function PieChart(props) {
  const [t, i18n] = useTranslation("common")
  const [data, setData] = useState([])

  const [options, setOptions] = useState({
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 12,
        left: 5,
        blur: 8,
        opacity: 0.3
      },
      toolbar: {
        show: false
      }
    },/* plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10
      }
    }, */
    stroke: {
      show: false
    },
    title: {
      text: '',
      align: 'left'
    },
    legend: {
      fontSize: '18px',
      horizontalAlign: 'rigth', 
    },
    labels: null,
    responsive: [
      {
        breakpoint: 968,
        options: {
          chart: {
            height: 400
          },
          legend: {
            height: 80,
            fontSize: '14px',
            horizontalAlign: 'center', 
            position: 'bottom',
          },
        }
      }
    ]
  })

  useEffect(() => {
    let newData = props.data.map(d => {
      return { key: typeof d.key === "object" ? getTranslation(d.key, i18n.language, i18n.languages[i18n.languages.length - 1]) : d.key, value: parseInt(d.value) }
    })
    setData(newData)
    let labels = newData.map(d => d.key)
    let newOptions = { ...options, labels: labels, title: { text: getTranslation(props.title, i18n.language, i18n.languages[i18n.languages.length - 1]) } }
    setOptions(newOptions)

  }, [setData, props.data, i18n.language, setOptions])

  return (
    <>
      {(data && options && options.labels) && <ReactApexChart options={options} series={data.map(d => d.value)} type="donut" width={props.width ? props.width : "100%"} height={480} />}
    </>
  )
}
