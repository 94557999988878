import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { host } from '@utils/config'

import TextFieldCommon from '@components/Common/TextField';
import AutoCompleteCommon from '@components/Common/AutoComplete';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import SelectorCommon from '@components/Common/Selector';
import RadioCommon from '@components/Common/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function AdminUserForm(props) {
  const [t, i18n] = useTranslation('common')

  const handleReset = () => {
    props.handleReset()
  }

  return (
    <>
      <Grid container>
        {props.tag !== 'UPDATE_ROLE' ?
          <>
            <TextFieldCommon
              className="width__33"
              title={t('labels.users.firstName')}
              id="firstName"
              required={true}
              isOpen={props.isFirstName}
              message={t('messages.error.users.firstName')}
              label={t('common.labels.required')}
              type="text"
              value={props.firstName}
              fullWidth={true}
              handleChangeInput={props.setFirstName}
            />
            <TextFieldCommon
              className="width__33"
              title={t('labels.users.lastName')}
              id="lastName"
              required={true}
              isOpen={props.isLastName}
              message={t('messages.error.users.lastName')}
              label={t('common.labels.required')}
              type="text"
              value={props.lastName}
              fullWidth={true}
              handleChangeInput={props.setLastName}
            />
            <AutoCompleteCommon
              title={t('titles.organizations.organization')}
              label={t('common.labels.search') + ' ' + t('titles.organizations.organization')}
              id="organization"
              tag="autocomplete-organization"
              vc={true}
              path={host + "/organizations?_sort=name:ASC"}
              required={true}
              isOpen={props.isOrganization}
              message={t('messages.error.users.organization')}
              language={i18n.language}
              fallbackLng={i18n.languages[i18n.languages.length - 1]}
              selectedValue={props.organization}
              handleChange={props.setOrganization}
            />
            <AutoCompleteCommon
              title={t('labels.users.job')}
              label={t('common.labels.search') + ' ' + t('labels.users.job')}
              id="job"
              tag="autocomplete-checkbox"
              vc={true}
              path={host + "/controlled-vocabularies?field=job"}
              required={false}
              optional={true}
              isOpen={props.isJob}
              message={t('messages.error.users.job')}
              selectedValue={props.selectedJob}
              handleChange={props.handleChangeJobs}
              language={i18n.language}
              fallbackLng={i18n.languages[i18n.languages.length - 1]}
            />
            <AutoCompleteCommon
              title={t('labels.users.subject')}
              label={t('common.labels.search') + ' ' + t('labels.users.subject')}
              id="subject"
              tag="autocomplete-checkbox"
              vc={true}
              path={host + "/controlled-vocabularies?field=subject"}
              required={false}
              optional={true}
              isOpen={props.isSubject}
              selectedValue={props.selectedSubject}
              handleChange={props.handleChangeSubject}
              language={i18n.language}
              fallbackLng={i18n.languages[i18n.languages.length - 1]}
            />
            <Grid container className="textField__container">
              <Grid item xs={12} md={12}>
                <Typography id="subjectNotifications-lable" className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: t('labels.users.emailNotifications') }} />
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  className="checkbox__text"
                  label={t('labels.users.subjectNotifications')}
                  control={
                    <Checkbox
                      id="subjectNotifications-input"
                      checked={props.subjectNotifications}
                      onChange={(event) => props.setSubjectNotifications(event.target.checked)}
                      name="subjectNotifications"
                    />
                  }
                />
              </Grid>
            </Grid>
            {props.cienciaid && <TextFieldCommon
              className="width__33"
              title={t('labels.users.scienceID')}
              id="cienciaid"
              type="text"
              disabled
              value={props.cienciaid}
              fullWidth={true}
              handleChangeInput={props.setCienciaId}
            />}
            <TextFieldCommon
              className="width__33"
              title={t('labels.users.phone')}
              id="phone"
              type="text"
              optional={true}
              fullWidth={true}
              value={props.phone}
              handleChangeInput={props.setPhone}
            />
            <SelectorCommon
              className="width__50"
              title={t('labels.users.language')}
              id="language"
              tag="autocomplete-simple"
              required={true}
              isOpen={props.isLanguage}
              message={t('messages.error.users.language')}
              label={t('common.labels.required')}
              selectedValue={props.selectedLanguage}
              handleChangeAutocomplete={props.setSelectedLanguage}
              dataValues={props.languages}
            />
            <RadioCommon
              id="confirmed"
              title={t('labels.users.confirmed')}
              value={props.confirmed}
              firstLabel={t('common.labels.true')}
              firstCondition={true}
              secondLabel={t('common.labels.false')}
              secondCondition={false}
              disabled
              defaultValue={props.confirmed}
              handleChange={(value) => props.setConfirmed(value === 'false' ? false : true)}
            />
            <RadioCommon
              id="confirmedByOrganization"
              title={t('labels.users.confirmedByOrganization')}
              value={props.confirmedByOrganization}
              firstLabel={t('common.labels.true')}
              firstCondition={true}
              secondLabel={t('common.labels.false')}
              secondCondition={false}
              disabled
              defaultValue={props.confirmedByOrganization}
              handleChange={(value) => props.setConfirmedByOrganization(value === 'false' ? false : true)}
            />
            <RadioCommon
              id="blocked"
              title={t('labels.users.blocked')}
              value={props.blocked}
              firstLabel={t('common.labels.true')}
              firstCondition={true}
              secondLabel={t('common.labels.false')}
              secondCondition={false}
              defaultValue={props.blocked}
              handleChange={(value) => props.setBlocked(value === 'false' ? false : true)}
            />
          </> :
          <>
            <AutoCompleteCommon
              title={t('labels.users.group')}
              id="group"
              tag="autocomplete-group"
              vc={true}
              path={host + "/groups"}
              required={true}
              isOpen={props.isGroup}
              message={t('messages.error.users.group')}
              selectedValue={props.selectedGroup || null}
              handleChange={props.setSelectedGroup}
              language={i18n.language}
              fallbackLng={i18n.languages[i18n.languages.length - 1]}
            />
            {props.selectedGroup && (props.selectedGroup.permission.find(perm => perm.method === 'domainCoordinator')) &&
              <AutoCompleteCommon
                title={t('labels.users.domain')}
                id="domain"
                tag="autocomplete-vc"
                vc={true}
                path={host + "/controlled-vocabularies?field=domain"}
                required={true}
                isOpen={props.isDomain}
                message={t('messages.error.users.domain')}
                selectedValue={props.selectedDomain}
                handleChange={props.setSelectedDomain}
                language={i18n.language}
                fallbackLng={i18n.languages[i18n.languages.length - 1]}
              />
            }
          </>
        }
      </Grid>
      <Grid container className="form__button--justify-left repositoryform__buttons--margins users__form--button--container">
        <SubmitButtonCommon
          id="user-save-button"
          className="form__button--color-success"
          title={t('common.buttons.save')}
          handleClick={props.handleClick} />
        <SubmitButtonCommon
          id="user-cancel-button"
          className="form__button--color-error"
          title={t('common.buttons.cancel')}
          goBack />
      </Grid>
    </>
  )

}