import i18n from 'i18next';
import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector';
import Axios from 'axios';
import { host } from '@utils/config'

const dectionOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' }
}

const cacheOptions = {
  enabled: false,
  // prefix for stored languages
  prefix: 'i18next_res_',
  // expiration
  expirationTime: 7 * 24 * 60 * 60 * 1000,
  // language versions
  versions: {}
}

Axios.get(host + '/configurations/public?type=LANGUAGES')
  .then(async (res) => {
    let resources = {}
    let langs = []
    let repositoryTypes = await Axios.get(host + '/configurations/public?type=REPOSITORYTYPE');
    for (let lang of res.data[0].body) {
      langs.push(lang);
      let file = {}
      try {
        file = require('./utils/locales/' + lang + '/translation.json')
      }
      catch (err) {
        file = {}
      }

      //loading domain attributes
      file['repositoryTypes'] = {}
      for(let type of repositoryTypes.data){
        let typeName = type.key.split('###')[1].replace(/\s+/g, '')
        file['repositoryTypes'] = Object.assign(file['repositoryTypes'],{[typeName]: {}}) 
        for(let attr of type.body.attributes){
          file.repositoryTypes[typeName][attr.id.split('###')[2]] = attr.i18n[lang]
          
        }
      }
      resources[lang] = {
        common: file
      }
    }

    let fallBack = await Axios.get(host + '/configurations/public?type=LANGUAGEDEFAULT');
    fallBack = fallBack.data[0].body;

    i18n
      .use(Cache)
      .use(LanguageDetector)
      .init({
        fallbackLng: fallBack,
        supportedLngs: langs,
        cache: cacheOptions,
        detection: dectionOptions,
        interpolation: { escapeValue: false },  // React already does escaping
        resources: resources,
      });
  });

export default i18n;
