import React from 'react'

import Profile from '@components/User/Profile'

import '@styles/frontOffice/User/User.css'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function User() {
  return (
    <div className="public__container--margin public__container--padding-bottom">
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { name: "profile" }
      ]} />
      <Profile />
    </div>
  )
}