import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import TextLable from '@components/Common/TextLable'
import SubmitButtonCommon from '@components/Common/SubmitButton';
import getTranslation from '@utils/functions/utilities.js'
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import adminAccess from '@utils/comparators/AdminAccess'
import Metatags from '../../Metatags';
import '@styles/Common/Common.css'

export default function NewsPage(props) {
  const [t, i18n] = useTranslation('common')
  const [news, setNews] = useState(props.news)
  const [langs, setLangs] = useState(null)
  let history = useHistory()
  const [user] = useContext(UserContext)

  let { id } = useParams()
  useEffect(() => {
    getRequest(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLangs(res[0].body)
      })
  }, [setNews])

  if (news) return (
    <>
      <Metatags title={getTranslation(news.title, i18n.language, i18n.languages[i18n.languages.length - 1])} description={getTranslation(news.body, i18n.language, i18n.languages[i18n.languages.length - 1])} />
      {props.tag !== 'public' ?
        <>
          <Grid container>
            <Grid item xs={6}>
              <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{props.tag !== 'public' ? t('titles.news.news_item') : getTranslation(news.title, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
            </Grid>
            {props.tag !== "public" && <Grid item xs={6} className="admin__align--left">
              {user && adminAccess('news-item', 'update', user.group.permission) &&
                <Tooltip title={t('tooltip.news.edit')}>
                  <Button
                    id="backoffice-repositories-add-button"
                    className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/news/update/' + id)}
                    startIcon={
                      <EditIcon className="admin__icon--create" />
                    }
                    role="adminCards"
                  >
                    {t("common.buttons.edit")}
                  </Button>
                </Tooltip>
              }
            </Grid>}
          </Grid>
          {props.tag !== "public" && <div >
            <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.news-item.show')}</Typography>
          </div>}
          <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
            <Grid container>
              {langs && langs.filter(lang => news.title[lang]).map((lang, index) =>
                <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"title" + lang}
                    title={t('labels.news.title') + " <sup>" + lang + "</sup>"}
                    value={news.title[lang]}
                  />
                </Grid>
              )}
              {langs && langs.filter(lang => news.title[lang]).map((lang, index) =>
                <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"description" + lang}
                    title={t('labels.news.description') + " <sup>" + lang + "</sup>"}
                    value={news.description[lang]}
                  />
                </Grid>
              )}
              <Grid key="newsDate" item xs={12} md={12}>
                <TextLable
                  id="newsDate"
                  title={t('labels.news.news_date')}
                  value={news.newsDate}
                />
              </Grid>
              <Grid key="startDate" item xs={12} md={6}>
                <TextLable
                  id="startDate"
                  title={t('labels.news.start_date')}
                  value={news.startDate}
                />
              </Grid>
              <Grid key="endDate" item xs={12} md={6}>
                <TextLable
                  id="endDate"
                  title={t('labels.news.end_date')}
                  value={news.endDate}
                />
              </Grid>
              {news.image &&
                <>
                  <Grid item xs={12} md={12}>
                    <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                      {t('labels.news.imgage')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <img className="repositoryform__image--size" src={host + news.image.url} alt={news.title[i18n.language]} />
                  </Grid>
                </>
              }
              {langs && langs.filter(lang => news.body[lang]).map((lang, index) =>
                <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                  <TextLable
                    id={"content" + lang}
                    title={t('labels.news.body') + " <sup>" + lang + "</sup>"}
                    value={news.body[lang]}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
          <Grid container className="form__button--justify-left repositoryform__buttons--margins form__one--button--margin-left">
            <SubmitButtonCommon
              id="news-back-button"
              className="form__button--color-error"
              title={t('table.confirm.backButton')}
              goBack />
          </Grid>
        </>
        :
        <>
          <Typography id="news-title" component="h1" className="index__title content--card admin__card--title">{getTranslation(news.title, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
          <Typography variant="h6" color="textSecondary" component="h6" className='content--card news__subtitle--margin' >
            {getTranslation(news.description, i18n.language, i18n.languages[i18n.languages.length - 1])}
          </Typography>
          <Typography variant="subtitle2" component="h2" color="textSecondary" className='content--card news__subtitle--margin ' gutterBottom>
            <time className='news__date' datetime={news.newsDate}>{news.newsDate}</time>
          </Typography>

          <Card elevation={0} className="MuiBox-root-43 border__radius--8px content--card">

            <Grid container>
              {news.image &&
                <Grid item xs={12} md={12} align="center" className="fields__padding--left">
                  <img className="news__card--image" src={host + news.image.url} alt={news.title[i18n.language]} />
                </Grid>
              }
            </Grid>
            <Grid container>
              <Typography variant="body1" color="textSecondary" component="div">
                <div id="news-content" className="indexes__info--text" dangerouslySetInnerHTML={{ __html: getTranslation(news.body, i18n.language, i18n.languages[i18n.languages.length - 1]) }} />
              </Typography>
            </Grid>

          </Card>
        </>
      }
    </>
  )

  return null
}
