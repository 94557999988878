import React, { useEffect, useState } from 'react';
import { HashRouter, Redirect, Switch } from 'react-router-dom'

//Route for roles
import AuthenticatedPrivateRoute from '@containers/AuthenticatedPrivateRoute'
import AdminPrivateRoute from '@containers/AdminPrivateRoute'
import PublicRoute from '@containers/PublicRoute'

//Front Office
import Home from '@containers/frontOffice/Home'
import Statistic from '@containers/frontOffice/Statistic'
import About from '@containers/frontOffice/About'
import Contact from '@containers/frontOffice/Contact'
import ErrorPage from '@containers/frontOffice/Error'
import Login from '@containers/frontOffice/User/Login'
import SAMLLogin from '@containers/frontOffice/User/SAMLLogin'
import Register from '@containers/frontOffice/User/Register'
import Repositories from '@containers/frontOffice/Repository/Repositories';
import Repository from '@containers/frontOffice/Repository/Repository';
import NewRepository from '@containers/frontOffice/Repository/NewRepository';
import EditRepository from '@containers/frontOffice/Repository/EditRepository';
import Profile from '@containers/frontOffice/User/User'
import EditUser from '@containers/frontOffice/User/EditUser'
import ChangePasswordUser from '@containers/frontOffice/User/ChangePasswordUser'
import ForgotPassword from './containers/frontOffice/User/ForgotPassword';
import ResetPassword from './containers/frontOffice/User/ResetPassword';
import PostPage from './containers/frontOffice/Post/Post';
import NewsPage from './containers/frontOffice/NewsItem/NewsItem';
import HighlightPage from './containers/frontOffice/Highlight/Highlight';
import FAQsMenu from '@containers/frontOffice/FAQ/FAQs';
import Indexes from './containers/frontOffice/Repository/Indexes';
import CookiesPolicy from '@containers/frontOffice/CookiesPolicy'

//Back Office
import AdminHome from '@containers/backOffice/Home'
import Users from '@containers/backOffice/User/Users';
import User from '@containers/backOffice/User/User'
import UpdateUser from '@containers/backOffice/User/UpdateUser'
import NewUser from '@containers/backOffice/User/NewUser'
import ControlledVocabularies from '@containers/backOffice/ControlledVocabulary/ControlledVocabularies';
import Badges from '@containers/backOffice/ControlledVocabulary/Badges';
import Configurations from '@containers/backOffice/Configuration/Configurations';
import Template from '@containers/backOffice/Configuration/Template';
import UpdateTemplate from '@containers/backOffice/Configuration/EditTemplate';
import RepoTypesMetadata from '@containers/backOffice/Configuration/RepoTypesMetadata';
import RepoTypeMetadata from '@containers/backOffice/Configuration/RepoTypeMetadata';
import UpdateRepoTypeMetadata from '@containers/backOffice/Configuration/EditRepoTypeMetadata';
import TableRepositories from '@containers/backOffice/Repository/Repositories';
import Organizations from '@containers/backOffice/Organization/Organizations';
import NewOrganization from '@containers/backOffice/Organization/NewOrganization';
import EditOrganization from '@containers/backOffice/Organization/EditOrganization';
import Organization from '@containers/backOffice/Organization/Organization'
import ImportFile from '@containers/backOffice/Repository/ImportFile';
import ImportPanel from '@containers/backOffice/Repository/ImportPanel';
import ImportAPI from '@containers/backOffice/Repository/ImportAPI';
import ImportAPIRepository from '@containers/backOffice/Repository/ImportAPIRepository';
import NewControlledVocabulary from '@containers/backOffice/ControlledVocabulary/NewControlledVocabulary';
import EditControlledVocabulary from '@containers/backOffice/ControlledVocabulary/EditControlledVocabulary';
import ControlledVocabulary from '@containers/backOffice/ControlledVocabulary/ControlledVocabulary';
import Statistics from '@containers/backOffice/Statistic/Statistics';
import StatisticView from '@containers/backOffice/Statistic/Statistic';
import NewStatistics from '@containers/backOffice/Statistic/NewStatistic';
import EditStatistics from '@containers/backOffice/Statistic/EditStatistic';
import TableLogs from '@containers/backOffice/Log/Logs';
import Log from '@containers/backOffice/Log/Log.js'
import Notifications from '@containers/backOffice/Notification/Notifications';
import Notification from '@containers/backOffice/Notification/Notification';
import Workflows from '@containers/backOffice/Workflow/Workflows';
import Groups from '@containers/backOffice/Group/Groups';
import NewGroup from '@containers/backOffice/Group/NewGroup';
import UpdateGroup from '@containers/backOffice/Group/UpdateGroup';
import Group from '@containers/backOffice/Group/Group';
import Posts from '@containers/backOffice/Post/Posts';
import NewPost from '@containers/backOffice/Post/NewPost'
import EditPost from '@containers/backOffice/Post/EditPost'
import Post from '@containers/backOffice/Post/Post'
import NewsItems from '@containers/backOffice/NewsItem/NewsItems';
import NewNewsItem from '@containers/backOffice/NewsItem/NewNewsItem';
import EditNewsItem from '@containers/backOffice/NewsItem/EditNewsItem'
import NewsItem from '@containers/backOffice/NewsItem/NewsItem'
import Highlights from '@containers/backOffice/Highlight/Highlights';
import NewHighlight from '@containers/backOffice/Highlight/NewHighlight';
import EditHighlight from '@containers/backOffice/Highlight/EditHighlight';
import Highlight from '@containers/backOffice/Highlight/Highlight';
import IndexesTable from './containers/backOffice/Repository/Indexes';
import Index from './containers/backOffice/Repository/Index';
import EditIndex from './containers/backOffice/Repository/EditIndex';
import TablePlugins from '@containers/backOffice/Repository/Plugins';
import FAQs from '@containers/backOffice/FAQ/FAQs';
import NewFAQ from '@containers/backOffice/FAQ/NewFAQ';
import EditFAQ from '@containers/backOffice/FAQ/EditFAQ';
import FAQ from '@containers/backOffice/FAQ/FAQ';
import Helps from '@containers/backOffice/Help/Helps';
import NewHelp from '@containers/backOffice/Help/NewHelp';
import EditHelp from '@containers/backOffice/Help/EditHelp';
import Help from '@containers/backOffice/Help/Help';
import HelpMenu from '@containers/backOffice/Help/HelpMenu';






import Logout from '@containers/frontOffice/User/Logout'
import Request from '@containers/backOffice/Workflow/Request';
// Context
import { SnackBarContextProvider } from '@context/snackBarContext'
import { CaptchaKeyContextProvider } from '@context/captchaKeyContext'
import { DialogContextProvider } from '@context/dialogContext'

import ReactGA from 'react-ga4';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

// Styles
import '@styles/index.css';

function Router() {
  const [instance, setInstance] = useState(null)
  useEffect(() => {
    fetch('manifest.json')
      .then((r) => r.json())
      .then((data) => {
        if (data['DRD_ANALYTICS_ID'])
          ReactGA.initialize(data['DRD_ANALYTICS_ID']);
        //if (data['DRD_MATOMO_API']) {
        let instance = createInstance({
          urlBase: "https://stats.wild.labs.keep.pt/",  //data['DRD_MATOMO_API'],
          siteId: 3
        })
        setInstance(instance)
        //}
      })
  }, [])
  return (
    <>
      <CaptchaKeyContextProvider>
        <DialogContextProvider>
          <SnackBarContextProvider>
            <MatomoProvider value={instance}>
              <HashRouter>
                <Switch>
                  <PublicRoute exact path='/'>
                    <Redirect to="/home"></Redirect>
                  </PublicRoute>
                  <PublicRoute exact path='/home' component={Home} />
                  <PublicRoute exact path='/statistics' component={Statistic} />
                  <PublicRoute exact path='/about' component={About} />
                  <PublicRoute exact path='/contact' component={Contact} />
                  <PublicRoute exact path='/saml/login' component={SAMLLogin} />
                  <PublicRoute exact path='/login' component={Login} />
                  <PublicRoute exact path='/forgotPassword' component={ForgotPassword} />
                  <PublicRoute exact path='/resetPassword' component={ResetPassword} />
                  <PublicRoute exact path='/register' component={Register} />
                  <PublicRoute exact path='/repositories' component={Repositories} />
                  <PublicRoute exact path='/indexes' component={Indexes} />
                  <PublicRoute exact path='/posts/:id' component={PostPage} />
                  <PublicRoute exact path='/posts/s/:id' component={PostPage} />
                  <PublicRoute exact path='/news/:id' component={NewsPage} />
                  <PublicRoute exact path='/highlights/:id' component={HighlightPage} />
                  <PublicRoute exact path='/faqs/' component={FAQsMenu} />
                  <PublicRoute exact path='/error' component={ErrorPage} />
                  <PublicRoute exact path='/policy-cookies' component={CookiesPolicy} />
                  <AuthenticatedPrivateRoute exact path='/repositories/new' component={NewRepository} />
                  <AuthenticatedPrivateRoute exact path='/repositories/update/:id' component={EditRepository} />
                  <PublicRoute exact path='/repositories/:id' component={Repository} />
                  <AuthenticatedPrivateRoute exact path='/profile' component={Profile} />
                  <AuthenticatedPrivateRoute exact path='/profile/update' component={EditUser} />
                  <AuthenticatedPrivateRoute exact path='/profile/changePassword' component={ChangePasswordUser} />
                  <AuthenticatedPrivateRoute exact path='/logout' component={Logout} />
                  <AdminPrivateRoute exact path="/admin" component={AdminHome} controller="administration" method="administration" />
                  <AdminPrivateRoute exact path='/admin/users' component={Users} controller="user" method="find" />
                  <AdminPrivateRoute exact path='/admin/users/new' component={NewUser} controller="user" method="create" />
                  <AdminPrivateRoute exact path='/admin/users/update/:id' component={UpdateUser} controller="user" method="update" />
                  <AdminPrivateRoute exact path='/admin/users/:id' component={User} controller="user" method="find" />
                  <AdminPrivateRoute exact path='/admin/organizations' component={Organizations} controller="organization" method="find" />
                  <AdminPrivateRoute exact path='/admin/organizations/new' component={NewOrganization} controller="organization" method="create" />
                  <AdminPrivateRoute exact path='/admin/organizations/update/:id' component={EditOrganization} controller="organization" method="create" />
                  <AdminPrivateRoute exact path='/admin/organizations/:id' component={Organization} controller="organization" method="find" />
                  <AdminPrivateRoute exact path='/admin/controlled-vocabularies' component={ControlledVocabularies} controller="controlled-vocabulary" method="find" />
                  <AdminPrivateRoute exact path='/admin/controlled-vocabularies/new' component={NewControlledVocabulary} controller="controlled-vocabulary" method="create" />
                  <AdminPrivateRoute exact path='/admin/badges' component={Badges} controller="controlled-vocabulary" method="find" />
                  <AdminPrivateRoute exact path='/admin/badges/new' component={NewControlledVocabulary} controller="controlled-vocabulary" method="create" />
                  <AdminPrivateRoute exact path='/admin/badges/update/:id' component={EditControlledVocabulary} controller="controlled-vocabulary" method="create" />
                  <AdminPrivateRoute exact path='/admin/badges/:id' component={ControlledVocabulary} controller="controlled-vocabulary" method="find" />
                  <AdminPrivateRoute exact path='/admin/controlled-vocabularies/update/:id' component={EditControlledVocabulary} controller="controlled-vocabulary" method="create" />
                  <AdminPrivateRoute exact path='/admin/controlled-vocabularies/:id' component={ControlledVocabulary} controller="controlled-vocabulary" method="find" />
                  <AdminPrivateRoute exact path='/admin/statistics' component={Statistics} controller="controlled-vocabulary" method="find" />
                  <AdminPrivateRoute exact path='/admin/statistics/new' component={NewStatistics} controller="controlled-vocabulary" method="create" />
                  <AdminPrivateRoute exact path='/admin/statistics/update/:id' component={EditStatistics} controller="controlled-vocabulary" method="create" />
                  <AdminPrivateRoute exact path='/admin/statistics/:id' component={StatisticView} controller="controlled-vocabulary" method="find" />
                  <AdminPrivateRoute exact path='/admin/configurations' component={Configurations} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/emailTemplates' component={Configurations} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/emailTemplates/:id' component={Template} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/emailTemplates/update/:id' component={UpdateTemplate} controller="configuration" method="create" />
                  <AdminPrivateRoute exact path='/admin/repoTypeMetadata' component={RepoTypesMetadata} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/repoTypeMetadata/:id' component={RepoTypeMetadata} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/repoTypeMetadata/update/:id' component={UpdateRepoTypeMetadata} controller="configuration" method="create" />
                  <AdminPrivateRoute exact path='/admin/repositories' component={TableRepositories} controller="repository" method="find" />
                  <AdminPrivateRoute exact path='/admin/repositories/new' component={NewRepository} controller="repository" method="create" tag="admin" />
                  <AdminPrivateRoute exact path='/admin/repositories/import' component={ImportPanel} controller="repository" method="import" tag="admin" />
                  <AdminPrivateRoute exact path='/admin/repositories/import/file' component={ImportFile} controller="repository" method="import" tag="admin" />
                  <AdminPrivateRoute exact path='/admin/repositories/import/:api' component={ImportAPI} controller="repository" method="import" tag="admin" />
                  <AdminPrivateRoute exact path='/admin/repositories/import/:api/:id' component={ImportAPIRepository} controller="repository" method="import" tag="admin" />
                  <AdminPrivateRoute exact path='/admin/repositories/update/:id' component={EditRepository} controller="repository" method="create" tag="admin" />
                  <AdminPrivateRoute exact path='/admin/repositories/:id' component={Repository} controller="repository" method="find" tag="admin" />
                  <AdminPrivateRoute exact path='/admin/logs' component={TableLogs} controller="log" method="find" />
                  <AdminPrivateRoute exact path='/admin/logs/:id' component={Log} controller="log" method="find" />
                  <AdminPrivateRoute exact path='/admin/notifications' component={Notifications} controller="notification" method="find" />
                  <AdminPrivateRoute exact path='/admin/notifications/:id' component={Notification} controller="notification" method="find" />
                  <AdminPrivateRoute exact path='/admin/requests' component={Workflows} controller="requestManagement" />
                  <AdminPrivateRoute exact path='/admin/groups' component={Groups} controller="group" method="find" />
                  <AdminPrivateRoute exact path='/admin/groups/new' component={NewGroup} controller="group" method="create" />
                  <AdminPrivateRoute exact path='/admin/groups/update/:id' component={UpdateGroup} controller="group" method="create" />
                  <AdminPrivateRoute exact path='/admin/groups/:id' component={Group} controller="group" method="find" />
                  <AdminPrivateRoute exact path='/admin/requests/:id' component={Request} controller="requestManagement" />
                  <AdminPrivateRoute exact path='/admin/posts' component={Posts} controller="post" method="find" />
                  <AdminPrivateRoute exact path='/admin/posts/new' component={NewPost} controller="post" method="create" />
                  <AdminPrivateRoute exact path='/admin/posts/update/:id' component={EditPost} controller="post" method="create" />
                  <AdminPrivateRoute exact path='/admin/posts/:id' component={Post} controller="post" method="find" />
                  <AdminPrivateRoute exact path='/admin/news' component={NewsItems} controller="news-item" method="find" />
                  <AdminPrivateRoute exact path='/admin/news/new' component={NewNewsItem} controller="news-item" method="create" />
                  <AdminPrivateRoute exact path='/admin/news/update/:id' component={EditNewsItem} controller="news-item" method="create" />
                  <AdminPrivateRoute exact path='/admin/news/:id' component={NewsItem} controller="news-item" method="find" />
                  <AdminPrivateRoute exact path='/admin/highlights' component={Highlights} controller="highlight" method="find" />
                  <AdminPrivateRoute exact path='/admin/highlights/new' component={NewHighlight} controller="highlight" method="create" />
                  <AdminPrivateRoute exact path='/admin/highlights/update/:id' component={EditHighlight} controller="highlight" method="create" />
                  <AdminPrivateRoute exact path='/admin/highlights/:id' component={Highlight} controller="highlight" method="find" />
                  <AdminPrivateRoute exact path='/admin/indexes' component={IndexesTable} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/indexes/:id' component={Index} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/indexes/update/:id' component={EditIndex} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/plugins' component={TablePlugins} controller="configuration" method="find" />
                  <AdminPrivateRoute exact path='/admin/faqs' component={FAQs} controller="faq" method="find" />
                  <AdminPrivateRoute exact path='/admin/faqs/new' component={NewFAQ} controller="faq" method="create" />
                  <AdminPrivateRoute exact path='/admin/faqs/update/:id' component={EditFAQ} controller="faq" method="update" />
                  <AdminPrivateRoute exact path='/admin/faqs/:id' component={FAQ} controller="faq" method="find" />
                  <AdminPrivateRoute exact path='/admin/helps' component={Helps} controller="help" method="find" />
                  <AdminPrivateRoute exact path='/admin/helps/menu' component={HelpMenu} controller="help" method="find" />
                  <AdminPrivateRoute exact path='/admin/helps/new' component={NewHelp} controller="help" method="create" />
                  <AdminPrivateRoute exact path='/admin/helps/update/:id' component={EditHelp} controller="help" method="update" />
                  <AdminPrivateRoute exact path='/admin/helps/:id' component={Help} controller="help" method="find" />
                </Switch>
              </HashRouter>
            </MatomoProvider>
          </SnackBarContextProvider>
        </DialogContextProvider>
      </CaptchaKeyContextProvider>
    </>
  );
}

export default Router;