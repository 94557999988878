import React, { useState, useContext, useEffect } from 'react'

import UpdateUserForm from '@components/User/UpdateUserForm'

import { useHistory } from 'react-router-dom'

import Axios from 'axios'
import { host } from '@utils/config'

import { UserContext } from '@context/userContext';
import { SnackBarContext } from "@context/snackBarContext.js"

import '@styles/frontOffice/User/User.css'
import { useTranslation } from 'react-i18next'
import getRequest from '@utils/functions/axiosRequest'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function EditUser() {
    const [t, i18n] = useTranslation('common')
    const [user, setUser] = useContext(UserContext)
    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)

    const [cienciaid, setCienciaId] = useState(user.cienciaid)
    const [firstName, setFirstName] = useState(user.firstName)
    const [lastName, setLastName] = useState(user.lastName)
    const [organization, setOrganization] = useState(user.organization)
    const [phone, setPhone] = useState(user.phone)
    const [job, setJob] = useState(user.job)
    const [subject, setSubject] = useState(user.subject)
    const [subjectNotifications, setSubjectNotifications] = useState(user.subjectNotifications)
    const [selectedLanguage, setSelectedLanguage] = useState({
        name: t('common.labels.language.' + user.lang),
        value: user.lang
    })
    const [selectedJob, setSelectedJob] = useState(user.job)
    const [selectedSubject, setSelectedSubject] = useState(user.subject)

    const [isFirstName, setIsFirstName] = useState(false)
    const [isLastName, setIsLastName] = useState(false)
    const [isOrganization, setIsOrganization] = useState(false)
    const [isJob, setIsJob] = useState(false)


    const [langs, setLangs] = useState(null)
    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
            })
    }, [setLangs])

    let history = useHistory()

    const handleReset = () => {
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setPhone(user.phone)
        setSelectedJob(user.job)
        setSelectedSubject(user.subject)
        setSelectedLanguage({
            name: t('common.labels.language.' + user.lang),
            value: user.lang
        })
        setJob([...user.job])
        setSubject([...user.subject])
    }

    const verifyDomain = async (email, organization) => {
        return true;
        let emailDomain = email.split("@")[1]
        if (!organization.email) return false
        let organizationDomain = organization.email.split("@")[1]

        return emailDomain.includes(organizationDomain)
    }

    const handleUpdateUser = async () => {
        let validate = true
        if (firstName === '') {
            setIsFirstName(true)
            validate = false
        }
        if (lastName === '') {
            setIsLastName(true)
            validate = false
        }
        if (!organization || organization === '') {
            setIsOrganization(true)
            validate = false
        }
        // if (job.length === 0) {
        //     setIsJob(true)
        //     validate = false
        // }
        if (validate) {
            try {
                let newUser = { ...user }
                newUser.firstName = firstName
                newUser.lastName = lastName
                newUser.organization = organization
                newUser.phone = phone
                newUser.job = job
                newUser.subject = subject
                newUser.subjectNotifications = subjectNotifications
                newUser.lang = selectedLanguage.value
                if (user.provider !== 'cienciaid' && cienciaid) {
                    newUser.cienciaid = cienciaid
                }
                await Axios.put(host + '/users/' + newUser._id, newUser)
                setUser({ ...newUser })
                setIsSnackBar(true)
                setTag("success")
                if (i18n.language !== selectedLanguage.value)
                    i18n.changeLanguage(selectedLanguage.value)
                setMessage(t('messages.success.users.profile'))
                history.goBack()

                let validation = await verifyDomain(user.email, newUser.organization)

                if (!validation && newUser.organization._id != user.organization._id) {
                    history.push('/logout')
                }
            }
            catch (error) {
                if (error.response.data.statusCode === 400) {
                    setIsSnackBar(true)
                    setTag("error")
                    setMessage(t('messages.error.users.organizationEmailMissing'))
                } else {
                    setIsSnackBar(true)
                    setTag("error")
                    setMessage(t('messages.error.users.profile'))
                }
            }
        } else {
            setIsSnackBar(true)
            setTag("error")
            setMessage(t('messages.error.users.register.validation'))
        }
    }

    const handleChangeJobs = (value) => {
        if (value) {
            let newJobs = [...value]
            let refactor = []
            for (let job of newJobs) {
                refactor.push({
                    key: job.key,
                    field: job.field,
                    value: job.value,
                    i18n: {
                        pt: job.i18n.pt,
                        en: job.i18n.en
                    }
                })
            }
            setJob(refactor)
            setSelectedJob(value)
        }
    };

    const handleChangeSubject = (value) => {
        if (value) {
            let newSubject = [...value]
            let refactor = []
            for (let subject of newSubject) {
                refactor.push({
                    key: subject.key,
                    field: subject.field,
                    value: subject.value,
                    i18n: {
                        pt: subject.i18n.pt,
                        en: subject.i18n.en
                    }
                })
            }
            setSubject(refactor)
            setSelectedSubject(value)
        }
    };

    return (
        <div className="public__container--margin public__container--padding-bottom">
            <BreadCrumbs crumbs={[
                { href: "/#/", name: "home" },
                { href: "/#/profile", name: "profile" },
                { href: "/#/profile/update", name: "profile_update" }
            ]} />
            {(user) && <UpdateUserForm
                firstName={firstName}
                setFirstName={setFirstName}
                isFirstName={isFirstName}
                lastName={lastName}
                setLastName={setLastName}
                isLastName={isLastName}
                organization={organization}
                setOrganization={setOrganization}
                isOrganization={isOrganization}
                phone={phone}
                cienciaid={cienciaid}
                provider={user.provider}
                setCienciaId={setCienciaId}
                setPhone={setPhone}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                selectedJob={selectedJob}
                selectedSubject={selectedSubject}
                subjectNotifications={subjectNotifications}
                setSubjectNotifications={setSubjectNotifications}
                isJob={isJob}
                languages={langs ? langs.map(lang => {
                    return {
                        name: t('common.labels.language.' + lang), value: lang
                    }
                }) : []}
                handleChangeJobs={handleChangeJobs}
                handleChangeSubject={handleChangeSubject}
                handleReset={handleReset}
                handleClick={handleUpdateUser}
            />}
        </div>
    )
}
