import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import FAQsMenu from '@components/FAQ/FAQsMenu'


export default function FAQs() {
    const [t, i18n] = useTranslation("common")
    const [faqs, setFAQs] = useState(null)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        getRequest(host + '/faqs')
            .then(res => {
                setFAQs(res.entities)
                setLoad(true)
            })
    }, [setFAQs])
    return (
        <>
            {load &&
                <div className="about__container public__container--padding-bottom repositories__body--container"  >
                    <div className="content--card">
                        <BreadCrumbs crumbs={[
                            { href: "/#/", name: "home" },
                            { name: "faqs" }
                        ]} />
                    </div>
                    <FAQsMenu faqs={faqs} />
                </div>
            }
        </>
    )
}