import React, { useContext, useEffect, useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import FormHelperText from '@material-ui/core/FormHelperText';

import { CaptchaKeyContext } from '@context/captchaKeyContext';

export default function CaptchaCommon(props) {
  const [captchaKey] = useContext(CaptchaKeyContext)

  const [render,setRender] = useState(true)

  useEffect(() => {
    if(typeof props.render === "boolean")
      setRender(props.render)
  }, [props.render])

  const handleCaptchaSubmit = (event) => {
    event.preventDefault()
    props.handleCaptchaSubmit()
  }

  const handleCaptchaChange = (value) => {
    props.handleCaptchaChange(value)
  }

  if(!captchaKey || !render){
    return null
  }
  return (
    <>
     <form onSubmit={handleCaptchaSubmit} >
        <ReCAPTCHA
          id={props.id + '-input'}
          ref={props.recaptchaRef}
          hl={props.language}
          sitekey={captchaKey}
          onChange={handleCaptchaChange}
        />
      </form>
      {props.isOpen && <FormHelperText id={props.id + '-error'} error>{props.message}</FormHelperText>}
    </>
  )
}
