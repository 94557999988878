import React, { useEffect, useState, useContext } from 'react';
import { Container } from '@material-ui/core';
import RequestPage from '@components/Workflow/RequestPage';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'

export default function Request(props) {
  return (
    <div className="admin__container-bottom" >
      <BreadCrumbs crumbs={[
        { href: "/#/", name: "home" },
        { href: "/#/admin", name: "admin" },
        { href: "/#/admin/requests", name: "requests" },
        { name: "request" }
      ]} />
      <RequestPage />
    </div>
  );
}
