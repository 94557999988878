import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import getTranslation from '@utils/functions/utilities.js'

import { useTranslation } from 'react-i18next'
import { ButtonBase, Grid, Hidden, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCookies } from "react-cookie";

import Axios from 'axios';
import { host } from '@utils/config'
import { UserContext } from '@context/userContext';
import { SnackBarContext } from "@context/snackBarContext.js"
import PostMenu from '@components/Post/PostMenu'
import Gravatar from 'react-awesome-gravatar'

import adminAccess from '@utils/comparators/AdminAccess'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Brightness6 from '@material-ui/icons/Brightness6';
import Brightness6OutlinedIcon from '@material-ui/icons/Brightness6Outlined';

import INDEXAR from '@utils/assets/indexar-logo-white.svg'
import FCCN from '@utils/assets/by_fccn_negative.svg'

import "@styles/Common/NavBar.css"

function renderLanguageMenu(anchorElLanguageMenu, isOpenLanguageMenu, language, languages, handleChangeLanguage, handleOpenLanguageMenu, handleCloseLanguageMenu, t) {
  return (
    <>
      <Button role="navBar" id="nav-language-menu" className="appNavBar__button appNavBar__icon--button--language" aria-controls="simple-menu" aria-haspopup="true" color="inherit" onClick={handleOpenLanguageMenu}>
        <LanguageIcon className="appNavBar__icon--language"></LanguageIcon>
        <sup className="appNavBar__language--sup-padding">{language.toUpperCase()}</sup>
      </Button>
      <Menu
        anchorEl={anchorElLanguageMenu}
        getContentAnchorEl={null}
        className="appNavBar__menu--language"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={isOpenLanguageMenu}
        onClose={handleCloseLanguageMenu}
      >
        {
          languages && languages.filter(lang => lang !== language).map(lang =>
            <MenuItem key={lang} id="nav-language-pt-button" role="selectMenu" onClick={() => handleChangeLanguage(lang)}>{t('navBar.language.' + lang, lang)}</MenuItem>
          )
        }
      </Menu>
    </>
  )
}

function renderProtectedLinks(anchorElUserMenu, t, user, isOpenUserMenu, handleOpenUserMenu, handleCloseUserMenu, handleLogout, history, gravatar, i18n) {
  if (user) {
    return (<>
      {gravatar ?
        <Gravatar id="nav-gravatar" email={user.email} options={{ default: 'mp' }}>
          {url => (<img className="appNavBar__image--user" src={url} alt={"user gravatar"} />)}
        </Gravatar> : <PersonIcon id="nav-icon" className="appNavBar__icon--user" />
      }
      <div className="appNavBar__userInfo--container" >
        <div >
          <div className="appNavBar__userInfo--name--div">
            <Typography variant="subtitle1" className="appNavBar__userInfo--name">{user.firstName} {user.lastName}</Typography>
          </div>
          <div className="appNavBar__userInfo--role--div">
            <Typography variant="subtitle1" className="appNavBar__userInfo--role">{getTranslation(user.group.description, i18n.language, i18n.languages[i18n.languages.length - 1])}</Typography>
          </div>
        </div>
        <div className="appNavBar__user--menu--icon--button">
          <IconButton id="nav-user-menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenUserMenu} >
            <ArrowDropDownIcon className="appNavBar__user--menu" />
          </IconButton>
        </div>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorElUserMenu}
        getContentAnchorEl={null}
        className="appNavBar__menu--user"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={isOpenUserMenu}
        onClose={handleCloseUserMenu}
      >
        <MenuItem id="user-menu-profile" role="selectMenu" onClick={() => history.push("/profile")}>{t('navBar.user.profile')}</MenuItem>
        {adminAccess('requestRepository', 'create', user.group.permission) && <MenuItem id="user-menu-repository" role="selectMenu" onClick={() => history.push("/repositories/new")}>{t('navBar.user.repository')}</MenuItem>}
        {adminAccess("administration", "administration", user.group.permission) && <MenuItem id="user-menu-admin" role="selectMenu" onClick={() => history.push("/admin")}>{t('navBar.user.admin')}</MenuItem>}
        <MenuItem id="user-menu-logout" role="selectMenu" onClick={() => handleLogout()}>{t('navBar.user.logout')}</MenuItem>
      </Menu>
    </>
    )
  }
  else {
    return <>
      <Hidden smDown>
        <Button id="nav-register-button" className="appNavBar__button--dimentions" onClick={() => {
          handleCloseUserMenu()
          history.push("/register")
        }}>{t('navBar.register')}</Button>
      </Hidden>
      <Hidden mdUp>
        <IconButton className="appNavBar__title--dimentions appNavBar__login--icon--center" onClick={() => {
          handleCloseUserMenu()
          history.push("/register")
        }}>
          <InputIcon />
        </IconButton>
      </Hidden>
      <Hidden smDown>
        <Button id="nav-login-button" className="appNavBar__button--dimentions" onClick={() => {
          handleCloseUserMenu()
          history.push("/login")
        }}>{t('navBar.login')}</Button>
      </Hidden>
      <Hidden mdUp>
        <IconButton className="appNavBar__title--dimentions appNavBar__login--icon--center" onClick={() => {
          handleCloseUserMenu()
          history.push("/login")
        }}>
          <InputIcon />
        </IconButton>
      </Hidden>
    </>
  }
}

function renderIndexesMenu(anchorElIndexesMenu, t, isOpenIndexesMenu, handleOpenIndexesMenu, handleCloseIndexesMenu, handleClickIndexesMenu, small, openExpandedIndexes, handleOpenExpandedIndexes, handleGoLink) {
  if (!small) {
    return (
      <>
        <Menu role="navBar"
          anchorEl={anchorElIndexesMenu}
          getContentAnchorEl={null}
          className="appNavBar__menu--user appNavBar__post--menu--max--width"
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          keepMounted
          open={isOpenIndexesMenu}
          onClose={handleCloseIndexesMenu}
        >
          <MenuItem id="nav-indexes-repositoryType-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('repositoryType')} >{t('navBar.indexes.repositoryType')}</MenuItem>
          <MenuItem id="nav-indexes-software-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('software')}>{t('navBar.indexes.software')}</MenuItem>
          <MenuItem id="nav-indexes-subject-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('subject')}>{t('navBar.indexes.subject')}</MenuItem>
          <MenuItem id="nav-indexes-content-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('content')}>{t('navBar.indexes.content')}</MenuItem>
          <MenuItem id="nav-indexes-contentFormat-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('contentFormat')}>{t('navBar.indexes.contentFormat')}</MenuItem>
          <MenuItem id="nav-indexes-language-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('lang')}>{t('navBar.indexes.language')}</MenuItem>
          <MenuItem id="nav-indexes-domain-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('domain')}>{t('navBar.indexes.domain')}</MenuItem>
          <MenuItem id="nav-indexes-utility-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('utility')}>{t('navBar.indexes.utility')}</MenuItem>
          <MenuItem id="nav-indexes-badge-menu-item" role="selectMenu" onClick={() => handleClickIndexesMenu('badge')}>{t('navBar.indexes.badge')}</MenuItem>
        </Menu>
      </>
    )
  }
  else {
    return (
      <>
        <ListItem id="nav-indexes-menu" button onClick={() => handleOpenExpandedIndexes()}>
          <ListItemText primary={t('navBar.indexes.title')} />
          {openExpandedIndexes ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openExpandedIndexes} timeout="auto" unmountOnExit>
          <List disablePadding>
            <ListItem id="nav-indexes-repositoryType-menu-item" button onClick={() => handleGoLink('/indexes?field=repositoryType')}>
              <ListItemText primary={t('navBar.indexes.repositoryType')} />
            </ListItem>
            <ListItem id="nav-indexes-software-menu-item" button onClick={() => handleGoLink('/indexes?field=software')}>
              <ListItemText primary={t('navBar.indexes.software')} />
            </ListItem>
            <ListItem id="nav-indexes-subject-menu-item" button onClick={() => handleGoLink('/indexes?field=subject')}>
              <ListItemText primary={t('navBar.indexes.subject')} />
            </ListItem>
            <ListItem id="nav-indexes-content-menu-item" button onClick={() => handleGoLink('/indexes?field=content')}>
              <ListItemText primary={t('navBar.indexes.content')} />
            </ListItem>
            <ListItem id="nav-indexes-contentFormat-menu-item" button onClick={() => handleGoLink('/indexes?field=contentFormat')}>
              <ListItemText primary={t('navBar.indexes.contentFormat')} />
            </ListItem>
            <ListItem id="nav-indexes-language-menu-item" button onClick={() => handleGoLink('/indexes?field=lang')}>
              <ListItemText primary={t('navBar.indexes.language')} />
            </ListItem>
            <ListItem id="nav-indexes-domain-menu-item" button onClick={() => handleGoLink('/indexes?field=domain')}>
              <ListItemText primary={t('navBar.indexes.domain')} />
            </ListItem>
            <ListItem id="nav-indexes-utility-menu-item" button onClick={() => handleGoLink('/indexes?field=utility')}>
              <ListItemText primary={t('navBar.indexes.utility')} />
            </ListItem>
            <ListItem id="nav-indexes-badge-menu-item" button onClick={() => handleGoLink('/indexes?field=badge')}>
              <ListItemText primary={t('navBar.indexes.badge')} />
            </ListItem>
          </List>
        </Collapse>
      </>
    )
  }
}

export default function AppNavBar(props) {
  const [t, i18n] = useTranslation('common')
  const [cookies, setCookie, removeCookie] = useCookies(["accessibility"]);

  const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
  const [user, setUser] = useContext(UserContext)
  const [anchorElLanguageMenu, setAnchorElLanguageMenu] = useState(null)
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null)
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false);
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false)
  const [language, setLanguague] = useState(i18n.language)
  const [languages, setLanguages] = useState([])
  const [gravatar, setGravatar] = useState(false)

  const [isOpenIndexesMenu, setIsOpenIndexesMenu] = useState(false);
  const [anchorElIndexesMenu, setAnchorElIndexesMenu] = useState(null)
  const [isOpenSmallMenu, setIsOpenSmallMenu] = useState(false);
  const [openExpandedIndexes, setOpenExpandedIndexes] = useState(false)
  const [openExpandedUser, setOpenExpandedUser] = useState(false)
  const [small, setSmall] = useState(false)


  let history = useHistory()

  let accessibility = cookies.accessibility;

  const handleClickIndexesMenu = (name) => {
    history.push('/indexes?field=' + name)
    setIsOpenIndexesMenu(false)
  };

  const handleOpenIndexesMenu = (event) => {
    setAnchorElIndexesMenu(event.currentTarget);
    setIsOpenIndexesMenu(true)
  };

  const handleCloseIndexesMenu = () => {
    setAnchorElIndexesMenu(null)
    setIsOpenIndexesMenu(false);
  };

  const handleOpenSmallMenu = () => {
    setSmall(true)
    setIsOpenSmallMenu(true)
  };

  const handleCloseSmallMenu = () => {
    setSmall(false)
    setIsOpenSmallMenu(false);
  };


  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguageMenu(event.currentTarget);
    setIsOpenLanguageMenu(true)
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguageMenu(null)
    setIsOpenLanguageMenu(false);
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    setLanguague(lng)
    if (small) {
      handleCloseSmallMenu()
    }
    handleCloseLanguageMenu()
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
    setIsOpenUserMenu(true)
  };

  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null)
    setIsOpenUserMenu(false);
  };

  const handleGoLink = (link) => {
    setIsOpenSmallMenu(false)
    history.push(link)
  }

  const handleOpenExpandedIndexes = () => {
    setOpenExpandedIndexes(!openExpandedIndexes)
  }

  const handleOpenExpandedUser = () => {
    setOpenExpandedUser(!openExpandedUser)
  }

  const handleLogout = () => {
    if (small) {
      setIsOpenSmallMenu(false)
    }
    history.push('/logout')
  }

  const handleChangeCSS = () => {
    if (cookies.accessibility) {
      removeCookie("accessibility");
    } else {
      setCookie("accessibility", "true", {
        path: "/"
      });
    }
  }

  useEffect(() => {
    if (language !== i18n.language) {
      setLanguague(i18n.language)
    }
  }, [language, i18n.language])

  useEffect(() => {
    Axios.get(host + '/configurations/public?type=GRAVATAR')
      .then(res => {
        setGravatar(res.data[0].body)
      })

    Axios.get(host + '/configurations/public?type=LANGUAGES')
      .then(res => {
        setLanguages(res.data[0].body)
      })
  }, [setGravatar, setLanguages])

  return (
    <div className="appNavBar__flex--space-between">
      <AppBar position="fixed" className="appNavBar__dimentions">
        <Toolbar>
          <Grid className="appNavBar__grid--flex-start">
            {props.adminMode &&
              <Hidden lgUp>
                <IconButton className="appNavBar__title--dimentions appNavBar__drawer-icon-margin" onClick={() => props.setOpenDrawer(!props.openDrawer)}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            }
            <a href="#main-content"></a>
            <Button role="navBar" id="home-button" className="appNavBar__title--dimentions" onClick={() => history.push("/home")}>
              <img src={INDEXAR} alt={"INDEXAR"} className="appNavBar__title--img" />
            </Button>
            <ButtonBase role="navBar" className='appNavBar__secondary--button MuiButton-root'>
              <a href="https://www.fccn.pt/" target='_blank'>
                <img src={FCCN} alt={"FCCN"} className="appNavBar__secondary--img" />
              </a>
            </ButtonBase>

          </Grid>
          <Grid className="appNavBar__grid--flex-end">
            <Dialog fullScreen open={isOpenSmallMenu} onClose={handleCloseSmallMenu}>
              <AppBar position="relative" >
                <Toolbar>
                  <div className="appNavBar__dialog--flex">
                    <span>
                      <Button role="navBar" className="appNavBar__title--dimentions" onClick={handleCloseSmallMenu}>
                        <img src={INDEXAR} alt={"INDEXAR"} className="appNavBar__title--img" />
                      </Button>
                      <ButtonBase role="navBar" className='appNavBar__secondaryFlex--button MuiButton-root'>
                        <a href="https://www.fccn.pt/" target='_blank'>
                          <img src={FCCN} alt={"FCCN"} className="appNavBar__secondary--img" />
                        </a>
                      </ButtonBase>
                    </span>
                    <IconButton className="appNavBar__title--dimentions" onClick={handleCloseSmallMenu}>
                      <MenuIcon />
                    </IconButton>
                  </div>
                </Toolbar>
              </AppBar>
              <List >
                <ListItem id="nav-repositories-button" button onClick={() => handleGoLink('/repositories')} >
                  <ListItemText primary={t('navBar.repositories')} />
                </ListItem>
                <Divider />
                {renderIndexesMenu(anchorElIndexesMenu, t, isOpenIndexesMenu, handleOpenIndexesMenu, handleCloseIndexesMenu, handleClickIndexesMenu, small, openExpandedIndexes, handleOpenExpandedIndexes, handleGoLink)}
                <Divider />
                <ListItem id="nav-statistics-button" button onClick={() => handleGoLink('/statistics')}>
                  <ListItemText primary={t('navBar.statistics')} />
                </ListItem>
                <Divider />
                <ListItem id="nav-faqs-button" button onClick={() => handleGoLink('/faqs')}>
                  <ListItemText primary={t('navBar.faq')} />
                </ListItem>
                <Divider />
                <PostMenu id="nav-post-button" tag="small" setIsOpenSmallMenu={setIsOpenSmallMenu}></PostMenu>
                <Divider />
                <ListItem id="highContrast" button onClick={handleChangeCSS}>
                  {cookies.accessibility ?
                    <ListItemText title={t('navBar.highContrastOff')} primary={t('navBar.highContrastOff')} /> :
                    <ListItemText title={t('navBar.highContrastOff')} primary={t('navBar.highContrastOn')} />
                  }
                </ListItem>
                <Divider />
                {user ? <>
                  <ListItem button onClick={() => handleOpenExpandedUser()}>
                    <ListItemText primary={user.firstName + ' ' + user.lastName} secondary={getTranslation(user.group.description, i18n.language, i18n.languages[i18n.languages.length - 1])} />
                    {openExpandedUser ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openExpandedUser} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      <ListItem button onClick={() => handleGoLink('/profile')}>
                        <ListItemText primary={t('navBar.user.profile')} />
                      </ListItem>
                      {adminAccess('requestRepository', 'create', user.group.permission) && <ListItem button onClick={() => handleGoLink('/repositories/new')}>
                        <ListItemText primary={t('navBar.user.repository')} />
                      </ListItem>}
                      {adminAccess('administration', 'administration', user.group.permission) && <ListItem button onClick={() => handleGoLink('/admin')}>
                        <ListItemText primary={t('navBar.user.admin')} />
                      </ListItem>}
                      <ListItem button onClick={() => handleLogout()}>
                        <ListItemText primary={t('navBar.user.logout')} />
                      </ListItem>
                    </List>
                  </Collapse>
                </> :
                  <>
                    <ListItem button onClick={() => handleGoLink('/register')}>
                      <ListItemText primary={t('navBar.register')} />
                    </ListItem>
                    <ListItem id="nav-login-button" button onClick={() => handleGoLink('/login')}>
                      <ListItemText primary={t('navBar.login')} />
                    </ListItem>
                  </>
                }
                <Divider />
                <ListItem>
                  <ButtonGroup variant="text">
                    {languages && languages.map(lang =>
                      <Button id={"nav-language-" + lang + "-button"} key={lang} className={language === lang ? 'appNavBar__language--bold' : ''} onClick={() => handleChangeLanguage(lang)}>{t('common.labels.language.' + lang)}</Button>
                    )}
                  </ButtonGroup>
                </ListItem>

              </List>
            </Dialog>
            <Hidden mdDown>
              <Button role="navBar" id="nav-repositories-button" className="appNavBar__button appNavBar__button--dimentions" onClick={() => history.push("/repositories")}>
                <span className={history.location.pathname === "/repositories" ? "border__button" : ""}>{t('navBar.repositories')}</span>
              </Button>
              <Button role="navBar" id="nav-indexes-menu" className="appNavBar__button appNavBar__button--dimentions" aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenIndexesMenu}>
                <span className={history.location.pathname === "/indexes" ? "border__button" : ""}>{t('navBar.indexes.title')}</span>
              </Button>
              {renderIndexesMenu(anchorElIndexesMenu, t, isOpenIndexesMenu, handleOpenIndexesMenu, handleCloseIndexesMenu, handleClickIndexesMenu)}
              <Button role="navBar" id="nav-statistics-button" className="appNavBar__button appNavBar__button--dimentions" onClick={() => history.push("/statistics")}>
                <span className={history.location.pathname === "/statistics" ? "border__button" : ""}>{t('navBar.statistics')}</span>
              </Button>
              <Button role="navBar" id="nav-faqs-button" className="appNavBar__button appNavBar__button--dimentions" onClick={() => history.push("/faqs")}>
                <span className={history.location.pathname === "/faqs" ? "border__button" : ""}>{t('navBar.faq')}</span>
              </Button>
              <PostMenu></PostMenu>
            </Hidden>
            <Hidden lgUp>
              {!props.adminMode && <IconButton className="appNavBar__title--dimentions appNavBar__drawer-icon-margin" onClick={handleOpenSmallMenu}>
                <MenuIcon />
              </IconButton>}
            </Hidden>
            <Hidden mdDown>
              {renderLanguageMenu(anchorElLanguageMenu, isOpenLanguageMenu, language, languages, handleChangeLanguage, handleOpenLanguageMenu, handleCloseLanguageMenu, t)}
            </Hidden>
            <Hidden mdDown>
              <Button onClick={handleChangeCSS} color="inherit">
                {cookies.accessibility
                  ?
                  <Brightness6 /> : <Brightness6OutlinedIcon />
                }
              </Button>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      <Hidden mdDown>
        <div className="MuiPaper-elevation4 appNavBar__width--user-menu appNavBar__user--container">
          {renderProtectedLinks(anchorElUserMenu, t, user, isOpenUserMenu, handleOpenUserMenu, handleCloseUserMenu, handleLogout, history, gravatar, i18n)}
        </div>
      </Hidden>
    </div>
  );
}
