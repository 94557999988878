import { Card, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import TextFieldCommon from '@components/Common/TextField';
import SubmitButtonCommon from '@components/Common/SubmitButton';
import SelectorCommon from '@components/Common/Selector';
import ConfigsForm from '@components/Statistic/ConfigsForm';

import "@styles/Common/Common.css"
import { host } from '@utils/config';
import getRequest from '@utils/functions/axiosRequest'

export default function StatisticForm(props) {
    const [t] = useTranslation('common')
    const [langs, setLangs] = useState(null)

    useEffect(() => {
        getRequest(host + '/configurations/public?type=LANGUAGES')
            .then(res => {
                setLangs(res[0].body)
            })
    }, [setLangs])

    const handleChangeLabel = (value, lang) => {
        let newValues = { ...props.labels }
        newValues[lang] = value
        props.setLabels(newValues)
    }

    const handleSpreadLabel = (event, value) => {
        if(value !== undefined && value !== ''){
            let newValues = { ...props.labels }
            langs.map(l => newValues[l] === '' || newValues[l] === undefined || event.type === 'dblclick' ? newValues[l] = value : '') 
            props.setLabels(newValues)
        }
    }

    return (
        <>
            <Card elevation={0} className="MuiBox-root-43 margin__top--32px">
                <Grid container >
                    <TextFieldCommon
                        className="width__33"
                        title={t('labels.statistics.value')}
                        id="value"
                        required={true}
                        value={props.value}
                        isOpen={props.isErrorValue}
                        message={t('messages.error.statistics.value')}
                        label={t('common.labels.required')}
                        type="text"
                        fullWidth={true}
                        handleChangeInput={props.setValue}
                    />
                    <SelectorCommon
                        className="width__50"
                        title={t('labels.statistics.type.title')}
                        id="field"
                        tag="autocomplete-text"
                        required={true}
                        isOpen={props.isErrorType}
                        selectedValue={props.type}
                        disable={props.tag === 'UPDATE' && true}
                        message={t('messages.error.statistics.type')}
                        label={t('common.labels.required')}
                        dataValues={props.types}
                        type="text"
                        fullWidth={true}
                        handleChangeAutocomplete={props.setType}
                    />
                    <Grid container>
                        {langs && langs.map((lang, index) =>
                            <Grid key={lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
                                <TextFieldCommon
                                    title={t('labels.controlledVocabulary.label', { lang: lang })}
                                    id={lang}
                                    value={props.labels[lang]}
                                    label={t('common.labels.required')}
                                    type="text"
                                    fullWidth={true}
                                    handleChangeInput={(value) => handleChangeLabel(value, lang)}
                                    handleSpreadInput={(event, value) => handleSpreadLabel(event, value)}
                                    renderIcon={true}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Card>
            {
                props.type !== t('labels.statistics.type.MULTITIMESERIES') && (props.renderSubmit || props.tag === 'UPDATE') &&
                <Grid container className="form__button--justify-left">
                    <SubmitButtonCommon
                        id="statistic-save-button"
                        className="form__button--color-success"
                        title={t('common.buttons.save')}
                        handleClick={props.handleClick} />
                    <SubmitButtonCommon
                        className="form__button--color-error"
                        title={t('common.buttons.cancel')}
                        goBack />
                </Grid>
            }
            {
                props.type === t('labels.statistics.type.MULTITIMESERIES') &&
                <Card elevation={0} className="MuiBox-root-43 margin__top--24px">
                    <Grid container>

                        <ConfigsForm
                            langs={langs}
                            configs={props.configs}
                            setConfigs={props.setConfigs}
                        />
                    </Grid>
                    {
                        (props.renderSubmit || props.tag === 'UPDATE') &&
                        <Grid container className="form__button--justify-left">
                            <SubmitButtonCommon
                                id="statistic-save-button"
                                className="form__button--color-success"
                                title={t('common.buttons.save')}
                                handleClick={props.handleClick} />
                            <SubmitButtonCommon
                                className="form__button--color-error"
                                title={t('common.buttons.cancel')}
                                goBack />
                        </Grid>
                    }
                </Card>
            }
        </>
    )
}