import React, { useEffect, useState } from 'react'

import { useLocation } from "react-router-dom";

import '@styles/frontOffice/Repository/Repositories.css'
import '@styles/frontOffice/Repository/Facets.css'
import '@styles/frontOffice/Repository/Repository.css'

import IndexesPage from '@components/Repository/Indexes'

import getRequest from '@utils/functions/axiosRequest'
import { host } from '@utils/config'
import { useTranslation } from 'react-i18next'

const qs = require('qs')

export default function Indexes() {
  const [t, i18n] = useTranslation('common')
  const [indexes, setIndexes] = useState(null)
  const [config, setConfig] = useState(null)
  const [field, setField] = useState(null)
  const location = useLocation()
  const query = qs.parse(location.search.replace(/\?/, ''))

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [count, setCount] = useState(null)


  const handleChangePage = (value) => {
    setPage(value)
  }

  const handleChangePageSize = (event) => {
    let value = parseInt(event.target.value, 10);
    setPageSize(value)
    setPage(0)
  }

  useEffect(() => {
    getRequest(host + '/repositories/indexes?singleFacet=' + query.field + '&lang=' + i18n.language + '&_start=' + (page) * pageSize + '&_limit=' + pageSize * (page + 1))
      .then(res => {
        if (field !== query.field) setPage(0)
        setIndexes(res.facets[0])
        setField(query.field)
        setCount(res.length)
      })
    getRequest(host + '/configurations/public?type=INDEX&key=index%23%23%23' + query.field)
      .then(res => {
        setConfig(res[0])
      })
  }, [page, query.field, setIndexes, setField, setPage, pageSize, setPageSize])

  return (
    <div className="public__container--margin public__container--padding-bottom">
      {indexes && field && count >= 0 && config &&
        <IndexesPage
          field={field}
          indexes={indexes}
          handleChangePage={handleChangePage}
          page={page}
          count={count}
          pageSize={pageSize}
          handleChangePageSize={handleChangePageSize}
          showField={config.body.show}
        />}
    </div>
  )
}
