import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import getRequest from '@utils/functions/axiosRequest'
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import { FormControl, ListItemText, MenuItem, Select } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { DialogContext } from '@context/dialogContext'

import "@styles/Common/Common.css"
import getTranslation from '@utils/functions/utilities.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function InsertStatistics(props) {
  const [t, i18n] = useTranslation("common")
  const [values, setValues] = useState([])
  const [start, setStart] = useState(0)
  const [search, setSearch] = useState("")
  const [limit, setLimit] = useState(200)
  const [setDialogOpen, setDialogMessage, setDialogCancel, setDialogConfirm, setDialogAction] = useContext(DialogContext)

  useEffect(() => {
    let path;
    if (!props.path.includes('?')) {
      path = props.path + "?toLog=false&_limit=20&_start=0&lang=" + i18n.language
    }
    else path = props.path + "&toLog=false&_limit=20&_start=0&lang=" + i18n.language
    getRequest(path)
      .then(res => {
        setValues(res.entities)
        setStart(start + 20)
      })
  }, [])

  const handleScroll = (event) => {
    if (props.vc && start < limit && Math.round(event.currentTarget.scrollHeight - event.currentTarget.scrollTop) === event.currentTarget.clientHeight) {
      let path;
      if (!props.path.includes('?')) {
        path = props.path + "?toLog=false&_limit=20&_start=" + start + (search.length >= 3 ? ("&_q=" + search) : "") + "&lang=" + i18n.language
      }
      else path = props.path + "&toLog=false&_limit=20&_start=" + start + (search.length >= 3 ? ("&_q=" + search) : "") + "&lang=" + i18n.language
      getRequest(path)
        .then(res => {
          if (res.entities.length === 0) {
            setLimit(values.length)
          }
          else {
            setValues(values.concat(res.entities))
            setStart(start + 20)
          }
        })
    }
  }

  const handleChangeInput = async (value) => {
    if (value === "") {
      let path;
      if (!props.path.includes('?')) {
        path = props.path + "?toLog=false&_limit=20&_start=0&lang=" + i18n.language
      }
      else path = props.path + "&toLog=false&_limit=20&_start=0&lang=" + i18n.language
      getRequest(path)
        .then(res => {
          setValues(res.entities)
          setStart(20)
        })
    }
    else if (value && value.length >= 3) {
      let path;
      if (!props.path.includes('?')) {
        path = props.path + "?toLog=false&_limit=20&_start=0&lang=" + i18n.language + (search.length >= 3 ? ("&_q=" + search) : "")
      }
      else path = props.path + "&toLog=false&_limit=20&_start=0&lang=" + i18n.language + (search.length >= 3 ? ("&_q=" + search) : "")
      getRequest(path)
        .then(res => {
          setValues(res.entities)
          setStart(20)
        })
    }
    setSearch(value)
    setLimit(200)
  }

  const handleAddField = (event) => {
    let values = [...props.selectedValue]
    values.push(null)
    props.handleChange(values)
  }

  const handleRemoveField = (index) => () => {
    let values = [...props.selectedValue]
    values.splice(index, 1)
    if (values.length === 0)
      values.push(null)
    props.handleChange(values)
  }

  const handleRemoveData = async (event, index) => {
    event.stopPropagation()
    setDialogMessage(t('table.confirm.delete'))
    setDialogCancel(t('table.confirm.cancelButton'))
    setDialogConfirm(t('table.confirm.confirmButton'))
    let obj = {
      function: handleRemoveField(index),
    }
    setDialogAction(obj)
    setDialogOpen(true)
  }

  const handleChangeArrayInput = (value, index) => {
    let values = [...props.selectedValue]

    if (!value)
      values.splice(index, 1)
    else values[index] = value

    if (values.length === 0) props.handleChange([null])
    else props.handleChange(values)
  }


  const handleChange = (event, index, tag) => {
    let values = [...props.selectedValue]
    values[index][tag] = tag === "date" ? event : event.target.value
    props.handleChange(values)
  }

  const multiButtons = (index, disable) => {
    if (!disable)
      return (
        <>

          <Button className="form__button--remove" size="medium" onClick={(event) => handleRemoveData(event, index)}>
            <RemoveIcon className="form__icon--remove" />
          </Button>
          {
            index === props.selectedValue.length - 1 &&
            <Button className="form__button--add" size="medium" onClick={handleAddField}>
              <AddIcon className="form__icon--add" />
            </Button>
          }
        </>
      )
  }

  return (
    <Grid container className="textField__container">
      {
        props.selectedValue.map((val, index) => (
          <Grid container className="textField__container--multiple" key={index}>
            <Grid item xs={12} md={12}>
              <Typography className="textField__typography--form" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                <div dangerouslySetInnerHTML={{ __html: props.title }} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className="textField__multi--margin--top margin__top--9px">
              <div className="form__flex--multiple">
                <Autocomplete
                  className={val && val.diff ? val.cssClassName : null}
                  fullWidth
                  id={props.id}
                  options={values}
                  value={val && val.diff && val.current ? val.current : val}
                  getOptionLabel={(selected) => selected.i18n ? getTranslation(selected.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : ""}
                  onChange={(event, value) => handleChangeArrayInput(value, index)}
                  onInputChange={(e, value) => { e && handleChangeInput(value) }}
                  ListboxProps={{ onScroll: handleScroll }}
                  renderInput={(params) => <TextField {...params} label={props.label} variant="outlined"
                    size="small" />}
                  disabled={props.disabled || false}
                />
                {multiButtons(index, props.disabled)}
              </div>
              {val && val.diff && val.prev && val.update &&
                <Grid item xs={12}>
                  <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                    {t('labels.workflows.previous') + getTranslation(val.prev.i18n, props.language, props.fallbackLng)}
                  </Typography>
                </Grid>
              }
              {props.selectedValue[index] &&
                <div>
                  {
                    props.selectedValue[index].type === "MULTITIMESERIES" || val.diff && val.type === "MULTITIMESERIES" ?
                      <Grid container className="textField__container--y">
                        <Grid item xs={12}>
                          <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('labels.repositories.statistics.date')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={"datePicker__container " + val && val.diff ? val.cssClassName : null}
                              disableToolbar
                              inputVariant="outlined"
                              variant="inline"
                              format="yyyy/MM"
                              openTo="date"
                              id="date-picker-inline"
                              views={['year', 'month']}
                              label={t('labels.repositories.datePicker')}
                              value={val && val.diff && val.current ? val.current.date : props.selectedValue[index].date || null}
                              onChange={(event) => handleChange(event, index, "date")}
                              disabled={props.disabled || false}
                            />
                          </MuiPickersUtilsProvider>
                          {val && val.diff && val.prev && val.update &&
                            <Grid item xs={12}>
                              <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                {t('labels.workflows.previous') + val.prev.date}
                              </Typography>
                            </Grid>
                          }
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('labels.repositories.statistics.key')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            className={"selectComponent--input " + (val && val.diff && val.cssClassName != null ? val.cssClassName : "")}>
                            <Select
                              displayEmpty={true}
                              IconComponent={ExpandMoreIcon}
                              classes={{
                                icon: "arrowicon__color"
                              }}
                              required={true}
                              value={val && val.diff && val.current ? val.current.key : props.selectedValue[index].key}
                              onChange={(event) => handleChange(event, index, "key")}
                              input={<OutlinedInput margin="dense" />}
                              renderValue={(selected) => {

                                return selected ? getTranslation(selected.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : "N/D"
                              }}
                              disabled={props.disabled || false}
                            >
                              {props.selectedValue[index].configs.map((data) => {
                                return (
                                  <MenuItem key={data.value} value={data}>
                                    <ListItemText primary={getTranslation(data.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])} />
                                  </MenuItem>
                                )
                              })}
                            </Select>
                            {val && val.diff && val.prev && val.update &&
                              <Grid item xs={12}>
                                <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                  {t('labels.workflows.previous') + getTranslation(val.prev.key.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                                </Typography>
                              </Grid>
                            }
                          </FormControl>
                        </Grid>
                      </Grid>
                      :

                      props.selectedValue[index].type === "TIMESERIES" &&
                      <Grid container className="textField__container--y">
                        <Grid item xs={12}>
                          <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                            {t('labels.repositories.statistics.date')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              views={['year', 'month']}
                              className={"datePicker__container " + val && val.diff ? val.cssClassName : null}
                              disableToolbar
                              inputVariant="outlined"
                              variant="inline"
                              format="yyyy/MM"
                              openTo="date"
                              id="date-picker-inline"
                              label={t('labels.repositories.datePicker')}
                              value={val && val.diff && val.current ? val.current.date : props.selectedValue[index].date || null}
                              onChange={(event) => handleChange(event, index, "date")}
                              disabled={props.disabled || false}
                            />
                          </MuiPickersUtilsProvider>
                          {val && val.diff && val.prev && val.update &&
                            <Grid item xs={12}>
                              <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                                {t('labels.workflows.previous') + getTranslation(val.prev.key.i18n, i18n.language, i18n.languages[i18n.languages.length - 1])}
                              </Typography>
                            </Grid>
                          }
                        </Grid>
                      </Grid>

                  }
                  <Grid container className="textField__container--y">
                    <Grid item xs={12}>
                      <Typography className="textField__typography" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                        {t('labels.repositories.statistics.value')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        className={"selectComponent--input " + (val && val.diff && val.cssClassName != null ? val.cssClassName : "")}
                        variant="outlined"
                        label={props.required ? t('common.labels.required') + " *" : ""}
                        value={val && val.diff && val.current ? val.current.val : props.selectedValue[index].val || ""}
                        onChange={(event) => handleChange(event, index, "val")}
                        type="number"
                        disabled={props.disabled || false}
                      />
                    </Grid>
                    {val && val.diff && val.prev && val.update &&
                      <Grid item xs={12}>
                        <Typography className="textField__typography--preview" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
                          {t('labels.workflows.previous') + val.prev.val}
                        </Typography>
                      </Grid>
                    }
                  </Grid>
                </div>

              }
            </Grid>
            <hr />
          </Grid>
        )
        )
      }
    </Grid >
  )
}
