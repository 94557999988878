import React, {useContext} from 'react'

import TemplatePage from '@components/Configuration/Template/TemplateView.js'
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { UserContext } from '@context/userContext';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '@components/Common/BreadCrumbs.js'
export default function Notification() {
  const [t, i18n] = useTranslation("common")

  let history = useHistory()
  const [user] = useContext(UserContext)
  return (
    <div className="admin__container-bottom" >
    <BreadCrumbs crumbs={[
      { href: "/#/", name: "home" },
      { href: "/#/admin", name: "admin" },
      { href: "/#/admin/emailTemplates?field=emailTemplate", name: "emailTemplates" },
      { name: "emailTemplate" }
    ]} />
      <Grid container>
        <Grid item xs={6}>
          <Typography id="backoffice-repositories-title" component="h2" className="admin__card--title">{t('titles.configurations.emailTemplate')}</Typography>
        </Grid>
        <Grid item xs={6} className="admin__align--left">
          {user &&
            <Tooltip title={t('tooltip.repositories.create')}>
              <Button
                id="backoffice-repositories-add-button"
                className="actionButton admin__button--create" size="medium" onClick={() => history.push('/admin/emailTemplates/update/' + history.location.pathname.split("emailTemplates/")[1])}
                startIcon={
                  <EditIcon className="admin__icon--create" />
                }
                role="adminCards"
              >
                {t("common.buttons.edit")}
              </Button>
            </Tooltip>
          }
        </Grid>
      </Grid>
      <div >
        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.configuration.emailTemplateShow')}</Typography>
      </div>
      <TemplatePage />
    </div>
  )
}