import React, { useContext, useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import MenuList from '@material-ui/core/MenuList';

import { UserContext } from '@context/userContext';

import AdminDrawerAccordion from '@components/Common/Drawer/AdminDrawerAccordion';
import adminAccess from '@utils/comparators/AdminAccess'
import "@styles/Common/Drawer.css"
import { Hidden } from '@material-ui/core';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useLocation } from "react-router-dom";

export default function AdminDrawer(props) {
  const [user] = useContext(UserContext)
  const [selected, setSelected] = useState("dashboard")

  const location = useLocation()

  useEffect(() => {
    let newSelected = location.pathname.replace(/\/admin\//, '').split('/')[0].split('?')[0]
    if (newSelected) {
      if (newSelected === 'controlled-vocabularies')
        newSelected = "controlledVocabulary"
      if (newSelected === "statistics" || newSelected === "emailTemplates" || newSelected === "badges" || newSelected === 'repoTypeMetadata')
        newSelected = "configurations"
      if (newSelected === "users" || newSelected === "groups")
        newSelected = "userManagement"
      if (newSelected === "requests")
        newSelected = "workflow"
      setSelected(newSelected)
    }
    else
      setSelected("dashboard")
  }, [setSelected])

  const renderDrawerAccordion = () => {
    if (user) {
      if (adminAccess("administration", "administration", user.group.permission)) {
        return (
          <MenuList>
            {adminAccess("administration", "administration", user.group.permission) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="dashboard" />}
            {adminAccess("repository", "find", user.group.permission) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="repositories" />}
            {adminAccess("organization", "find", user.group.permission) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="organizations" />}
            {(adminAccess("requestManagement", "repository", user.group.permission) || adminAccess("requestManagement", "organization", user.group.permission)) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="workflow" />}
            {(adminAccess("post", "find", user.group.permission) || adminAccess("news-item", "find", user.group.permission) || adminAccess("highlight", "find", user.group.permission)) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="contents" />}
            {(adminAccess("configuration", "find", user.group.permission)) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="configurations" />}
            {adminAccess("controlled-vocabulary", "find", user.group.permission) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="controlledVocabulary" />}
            {(adminAccess("user", "find", user.group.permission) || adminAccess("group", "find", user.group.permission)) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="userManagement" />}
            {adminAccess("notification", "find", user.group.permission) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="notifications" />}
            {adminAccess("log", "find", user.group.permission) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="logs" />}
            {adminAccess("help", "find", user.group.permission) &&
              <AdminDrawerAccordion
                setOpenDrawer={props.setOpenDrawer}
                selected={selected}
                setSelected={setSelected}
                tag="help" />}
          </MenuList>
        )
      }
    }
    return;
  }

  return (
    <>
      <Hidden lgUp>
        <SwipeableDrawer
          variant="temporary"
          open={props.openDrawer}
          onClose={() => props.setOpenDrawer(false)}
          onOpen={() => props.setOpenDrawer(true)}
        >
          <Toolbar />
          <div className="adminDrawer__container">
            {renderDrawerAccordion(user)}
          </div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
        >
          <Toolbar />
          <div className="adminDrawer__container">
            {renderDrawerAccordion(user)}
          </div>
        </Drawer>
      </Hidden>
    </>
  )
}