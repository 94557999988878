import React, { useEffect, useState, useContext } from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import FolderIcon from '@material-ui/icons/Folder';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import StoreIcon from '@material-ui/icons/Store';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NotificationsIcon from '@material-ui/icons/Notifications';
import GroupIcon from '@material-ui/icons/Group';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import HelpIcon from '@material-ui/icons/Help';

import { useHistory } from 'react-router-dom';
import { UserContext } from '@context/userContext';
import adminAccess from '@utils/comparators/AdminAccess'
import { useLocation } from "react-router-dom";

import "@styles/Common/Drawer.css"

function grantAccess(component, permission) {

  if (component !== 'requestManagement' && component !== 'administration') {
    return adminAccess(component, "find", permission)
  }
  else {
    if (adminAccess(component, "repository", permission)) {
      return true
    }
    else {
      if (adminAccess(component, "organization", permission)) {
        return true
      }
      else {
        if (adminAccess(component, "administration", permission)) {
          return true
        }
        else return false
      }
    }
  }
}

function generateIcon(tag) {
  switch (tag) {
    default:
      break
    case 'dashboard':
      return <DashboardIcon />
    case 'repositories':
      return <FolderIcon />
    case 'organizations':
      return <StoreIcon />
    case 'userManagement':
      return <GroupIcon />
    case 'workflow':
      return <RotateRightIcon />
    case 'configurations':
      return <SettingsIcon />
    case 'controlledVocabulary':
      return <DescriptionIcon />
    case 'notifications':
      return <NotificationsIcon />
    case 'logs':
      return <FileCopyIcon />
    case 'help':
      return <HelpIcon />
    case 'contents':
      return <LibraryBooksIcon />
  }
}

function createMenu(tag, setMenuOptions, t) {
  let newMenu = []
  switch (tag) {
    default:
      break
    case 'dashboard':
      newMenu = {
        name: t('adminDrawer.dashboard.all'),
        link: "/admin/",
        component: 'administration'
      }
      setMenuOptions(newMenu)
      break
    case 'repositories':
      newMenu = {
        id: "drawer-repositories-button",
        name: t('adminDrawer.repositories.all'),
        link: "/admin/repositories",
        component: 'repository'
      }
      setMenuOptions(newMenu)
      break
    case 'organizations':
      newMenu = {
        id: "drawer-organizations-button",
        name: t('adminDrawer.organizations.all'),
        link: "/admin/organizations",
        component: 'organization'
      }
      setMenuOptions(newMenu)
      break
    case 'userManagement':
      newMenu = [
        {
          id: "drawer-users-button",
          name: t('adminDrawer.userManagement.users.title'),
          link: "/admin/users",
          component: 'user',
          field: 'users'
        },
        {
          id: "drawer-groups-button",
          name: t('adminDrawer.userManagement.groups.title'),
          link: "/admin/groups",
          component: 'group',
          field: 'groups'
        }
      ]
      setMenuOptions(newMenu)
      break
    case 'workflow':
      newMenu = [
        {
          id: "drawer-workflow-created-button",
          name: t('adminDrawer.workflow.created'),
          link: "/admin/requests?field=created",
          component: 'requestManagement',
          field: "created"
        },
        {
          id: "drawer-workflow-assigned-button",
          name: t('adminDrawer.workflow.assigned'),
          link: "/admin/requests?field=assigned",
          component: 'requestManagement',
          field: "assigned"
        },
        {
          id: "drawer-workflow-approved-button",
          name: t('adminDrawer.workflow.approved'),
          link: "/admin/requests?field=approved",
          component: 'requestManagement',
          field: "approved"
        },
        {
          id: "drawer-workflow-rejected-button",
          name: t('adminDrawer.workflow.rejected'),
          link: "/admin/requests?field=rejected",
          component: 'requestManagement',
          field: "rejected"
        },
        {
          id: "drawer-workflow-all-button",
          name: t('adminDrawer.workflow.all'),
          link: "/admin/requests",
          component: 'requestManagement',
          field: "all"
        }
      ]
      setMenuOptions(newMenu)
      break
    case 'configurations':
      newMenu = [
        {
          id: "drawer-general-button",
          name: t('adminDrawer.configurations.general'),
          link: "/admin/configurations?field=general&disable=true",
          component: 'configuration',
          field: "general"
        },
        {
          id: "drawer-smtp-button",
          name: t('adminDrawer.configurations.smtp'),
          link: "/admin/configurations?field=smtp&disable=true",
          component: 'configuration',
          field: "smtp"
        },
        {
          id: "drawer-emailTemplate-button",
          name: t('adminDrawer.configurations.emailTemplate'),
          link: "/admin/emailTemplates?field=emailTemplate",
          component: 'configuration',
          field: "emailTemplate"
        },
        {
          id: "drawer-term-button",
          name: t('adminDrawer.configurations.term'),
          link: "/admin/configurations?field=term&disable=true",
          component: 'configuration',
          field: "term"
        },
        {
          id: "drawer-badges-button",
          name: t('adminDrawer.controlledVocabulary.badges'),
          link: "/admin/badges",
          component: 'controlled-vocabulary',
          field: "badges"
        },
        {
          id: "drawer-statistics-button",
          name: t('adminDrawer.statistics.title'),
          link: "/admin/statistics",
          component: 'controlled-vocabulary',
          field: "statistics"
        },
        {
          id: "drawer-repoTypeMetadata-button",
          name: t('adminDrawer.configurations.repoTypeMetadata'),
          link: "/admin/repoTypeMetadata",
          component: 'configuration',
          field: "repoTypeMetadata"
        },
        {
          id: "drawer-indexes-button",
          name: t('adminDrawer.configurations.indexes'),
          link: "/admin/indexes",
          component: 'configuration',
          field: "indexes"
        },
        {
          id: "drawer-plugins-button",
          name: t('adminDrawer.configurations.plugins'),
          link: "/admin/plugins",
          component: 'configuration',
          field: "plugins"
        },

      ]
      setMenuOptions(newMenu)
      break
    case 'controlledVocabulary':
      newMenu = [
        {
          id: "drawer-vc-contentFormat-button",
          name: t('titles.controlledVocabulary.contentFormat'),
          link: "/admin/controlled-vocabularies?field=contentFormat",
          component: 'controlled-vocabulary',
          field: "contentFormat"
        },
        {
          id: "drawer-vc-content-button",
          name: t('titles.controlledVocabulary.content'),
          link: "/admin/controlled-vocabularies?field=content",
          component: 'controlled-vocabulary',
          field: "content"
        },
        {
          id: "drawer-vc-subject-button",
          name: t('titles.controlledVocabulary.subject'),
          link: "/admin/controlled-vocabularies?field=subject",
          component: 'controlled-vocabulary',
          field: "subject"
        },
        {
          id: "drawer-vc-country-button",
          name: t('titles.controlledVocabulary.country'),
          link: "/admin/controlled-vocabularies?field=country",
          component: 'controlled-vocabulary',
          field: "country"
        },
        {
          id: "drawer-vc-lang-button",
          name: t('titles.controlledVocabulary.lang'),
          link: "/admin/controlled-vocabularies?field=lang",
          component: 'controlled-vocabulary',
          field: "lang"
        },
        {
          id: "drawer-vc-job-button",
          name: t('titles.controlledVocabulary.job'),
          link: "/admin/controlled-vocabularies?field=job",
          component: 'controlled-vocabulary',
          field: "job"
        },
        {
          id: "drawer-vc-utility-button",
          name: t('titles.controlledVocabulary.utility'),
          link: "/admin/controlled-vocabularies?field=utility",
          component: 'controlled-vocabulary',
          field: "utility"
        },
        {
          id: "drawer-vc-domain-button",
          name: t('titles.controlledVocabulary.domain'),
          link: "/admin/controlled-vocabularies?field=domain",
          component: 'controlled-vocabulary',
          field: "domain"
        },
        {
          id: "drawer-vc-organizationType-button",
          name: t('titles.controlledVocabulary.organizationType'),
          link: "/admin/controlled-vocabularies?field=organizationType",
          component: 'controlled-vocabulary',
          field: "organizationType"
        },
        {
          id: "drawer-vc-repositoryType-button",
          name: t('titles.controlledVocabulary.repositoryType'),
          link: "/admin/controlled-vocabularies?field=repositoryType",
          component: 'controlled-vocabulary',
          field: "repositoryType"
        },
        // {
        //   id: "drawer-vc-liability-button",
        //   name: t('titles.controlledVocabulary.liabilities'),
        //   link: "/admin/controlled-vocabularies?field=liability",
        //   component: 'controlled-vocabulary',
        //   field: "liabilities"
        // },
        {
          id: "drawer-vc-policy-button",
          name: t('titles.controlledVocabulary.policy'),
          link: "/admin/controlled-vocabularies?field=policy",
          component: 'controlled-vocabulary',
          field: "policy"
        },
        {
          id: "drawer-vc-software-button",
          name: t('titles.controlledVocabulary.software'),
          link: "/admin/controlled-vocabularies?field=software",
          component: 'controlled-vocabulary',
          field: "software"
        },
        {
          id: "drawer-vc-link-button",
          name: t('titles.controlledVocabulary.link'),
          link: "/admin/controlled-vocabularies?field=link",
          component: 'controlled-vocabulary',
          field: "link"
        },
        {
          id: "drawer-vc-legalForm-button",
          name: t('titles.controlledVocabulary.legalForm'),
          link: "/admin/controlled-vocabularies?field=legalForm",
          component: 'controlled-vocabulary',
          field: "legalForm"
        },
        {
          id: "drawer-vc-targetGroup-button",
          name: t('titles.controlledVocabulary.targetGroup'),
          link: "/admin/controlled-vocabularies?field=targetGroup",
          component: 'controlled-vocabulary',
          field: "targetGroup"
        },
        {
          id: "drawer-vc-metadataFormat-button",
          name: t('titles.controlledVocabulary.metadataFormat'),
          link: "/admin/controlled-vocabularies?field=metadataFormat",
          component: 'controlled-vocabulary',
          field: "metadataFormat"
        }
      ]
      setMenuOptions(newMenu)
      break
    case 'logs':
      newMenu = {
        id: "drawer-logs-button",
        name: t('adminDrawer.logs.all'),
        link: "/admin/logs",
        component: 'log'
      }
      setMenuOptions(newMenu)
      break
    case 'help':
      newMenu = {
        id: "drawer-helpMenu-button",
        name: t('adminDrawer.help.all'),
        link: "/admin/helps/menu",
        component: 'help'
      }
      setMenuOptions(newMenu)
      break
    case 'notifications':
      newMenu = {
        id: "drawer-notifications-button",
        name: t('adminDrawer.notifications.all'),
        link: "/admin/notifications",
        component: 'notification'
      }
      setMenuOptions(newMenu)
      break
    case 'contents':
      newMenu = [{
        id: "drawer-post-button",
        name: t('adminDrawer.posts.title'),
        link: "/admin/posts",
        component: 'post'
      },
      {
        id: "drawer-news-button",
        name: t('adminDrawer.news.title'),
        link: "/admin/news",
        component: 'news-item'
      },
      {
        id: "drawer-highlights-button",
        name: t('adminDrawer.highlights.title'),
        link: "/admin/highlights",
        component: 'highlight'
      },
      {
        id: "drawer-faqs-button",
        name: t('adminDrawer.faqs.title'),
        link: "/admin/faqs",
        component: 'faq'
      },
      {
        id: "drawer-helps-button",
        name: t('adminDrawer.help.title'),
        link: "/admin/helps",
        component: 'help'
      },
      ]
      setMenuOptions(newMenu)
      break
  }
}

export default function AdminDrawerAccordion(props) {
  const [t, i18n] = useTranslation('common')
  const [menuOptions, setMenuOptions] = useState([])
  const [user] = useContext(UserContext)
  let history = useHistory()

  const location = useLocation()
  const optionSelect = (route) => {
    props.setOpenDrawer(false)
    props.setSelected(props.tag)
    history.push(route)
  }

  const optionSelectAccordion = () => {
    if (props.selected === props.tag) {
      let newSelected = location.pathname.replace('/admin/', '')
      if (newSelected === props.tag)
        props.setSelected("")
      else if (newSelected)
        props.setSelected(newSelected)
      else
        props.setSelected("dashboard")
    }
    else
      props.setSelected(props.tag)
  }

  const checkSelected = (menuOptions) => {
    if (menuOptions.link === "/admin/" && (location.pathname === "/admin/" || location.pathname === "/admin"))
      return true
    else if (location.pathname.includes(menuOptions.link) && (menuOptions.link !== "/admin/"))
      return true
    return false
  }

  const checkAccordionSelected = (menuOptions) => {
    for (let option of menuOptions) {
      if (location.pathname.includes("/admin/configurations") && option.component === "configuration")
        return true
      if (location.pathname.includes("/admin/emailTemplates") && option.component === "configuration")
        return true
      if (location.pathname.includes("/admin/repoTypeMetadata") && option.component === "configuration")
        return true
      if (location.pathname.includes("/admin/helps/menu") && option.component === "help")
        return false
      if (location.pathname.includes("/admin/controlled-vocabularies") && option.component === "controlled-vocabulary" && option.field !== "badges" && option.field !== "statistics")
        return true
      if (location.pathname.includes("/admin/requests") && option.component === "requestManagement")
        return true
      if (location.pathname.includes(option.link))
        return true
    }
    return false
  }

  const checkMenuSelected = (menu) => {
    let field = location.search.split("field=");
    field = field.length >= 2 ? field[1].split("&")[0] : ""
    let condition = location.search.split("condition=");
    condition = condition.length >= 2 ? condition[1].split("&")[0] : ""
    if (field === menu.field || condition.toLowerCase() === menu.field)
      return true;
    else if (menu.component === "help" && location.pathname.includes(menu.link) && location.pathname !== '/admin/helps/menu')
      return true
    else if (menu.field === "all" && menu.component === "requestManagement" && location.pathname.includes("requests") && !field && !condition)
      return true;
    else if (menu.link === location.pathname && (!location.search.includes("field=")))
      return true;
    else if (location.pathname.includes(menu.field) && !location.pathname.includes("repoTypeMetadata"))
      return true;
    else if (menu.field === "repoTypeMetadata" && location.pathname.includes(menu.field))
      return true
    else if (location.pathname.includes(menu.link) && menu.component !== "requestManagement" && menu.component !== "help")
      return true
    else {
      return false
    }
  }
  useEffect(() => {
    createMenu(props.tag, setMenuOptions, t)
  }, [setMenuOptions, props.tag, t, i18n.language, location.search])

  return (
    <>
      {
        Array.isArray(menuOptions) ?
          <Accordion
            elevation={0}
            expanded={props.selected === props.tag}
            onChange={() =>
              optionSelectAccordion()
            }
            className={checkAccordionSelected(menuOptions) ? "adminDrawer__accordion--selected" : "accordion__style"}
            id={props.tag + "-accordion"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={checkAccordionSelected(menuOptions) ? "adminDrawer__icon--color--selected" : "adminDrawer--icon-color"} />}
            >
              <span className="adminDrawer__typography--subtitle">
                <span className={checkAccordionSelected(menuOptions) ? "adminDrawer__icon--color--selected adminDrawer__icon--align-accordion" : "adminDrawer--icon-color adminDrawer__icon--align-accordion"}>{generateIcon(props.tag)}</span>
                {t('adminDrawer.' + props.tag + '.title')}
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <MenuList role="adminMenu">
                {menuOptions && menuOptions.map(menu => {

                  let selector = checkMenuSelected(menu);
                  if (grantAccess(menu.component, user.group.permission))
                    return <MenuItem className={
                      selector ?
                        "adminDrawer__submenu adminDrawer__submenu--selected" :
                        "adminDrawer__submenu"
                    } role="adminMenu" disableRipple id={menu.id} key={menu.link} onClick={() => optionSelect(menu.link)}>
                      <span
                        className="adminDrawer__typography--subtitle">
                        {menu.name}
                      </span>
                    </MenuItem>
                })}
              </MenuList>
            </AccordionDetails>
          </Accordion> :
          <>
            {grantAccess(menuOptions.component, user.group.permission) &&
              <MenuItem
                id={menuOptions.id}
                disableRipple
                className={checkSelected(menuOptions) ? "adminDrawer__menu adminDrawer__selected--menu" : "adminDrawer__menu"}
                onClick={() => optionSelect(menuOptions.link)}>
                <span
                  className="adminDrawer__typography--subtitle" key={menuOptions.link}>
                  <span className={checkSelected(menuOptions) ? "adminDrawer__icon--color--selected adminDrawer__icon--align-accordion" : "adminDrawer--icon-color adminDrawer__icon--align-accordion"}>{generateIcon(props.tag)}</span>
                  {t('adminDrawer.' + props.tag + '.title')}
                </span>
              </MenuItem>}
          </>
      }
    </>
  )
}
