import React, { useEffect, useState, useContext } from "react"
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from "@material-ui/core";

import Axios from "axios"
import { host } from '@utils/config'
import getRequest from '@utils/functions/axiosRequest'

import { SnackBarContext } from "@context/snackBarContext.js"
import StatisticsForm from "@components/Statistic/StatisticForm";
import { useTranslation } from "react-i18next";
import getTranslation from '@utils/functions/utilities.js'

import BreadCrumbs from '@components/Common/BreadCrumbs.js'
import "@styles/backOffice/ControlledVocabulary/ControlledVocabularies.css"

export default function EditStatistic(props) {
    const [t, i18n] = useTranslation("common")

    const [setIsSnackBar, setTag, setMessage] = useContext(SnackBarContext)
    const [statistic, setStatistic] = useState(null)
    const [value, setValue] = useState('')
    const [labels, setLabels] = useState({})
    const [type, setType] = useState('')
    const [configs, setConfigs] = useState([{ value: "", i18n: {} }])

    const [isErrorValue, setIsErrorValue] = useState(false)
    const [isErrorType, setIsErrorType] = useState(false)
    const [isErrorConfigs, setIsErrorConfigs] = useState(false)

    let { id } = useParams();
    let history = useHistory()

    const handleReset = () => {
        setValue(statistic.value)
        setLabels(statistic.i18n)
        setType(statistic.type)
        setConfigs(statistic.configs)
    }

    const handleUpdateStatistic = async () => {
        let newType;
        let validate = true
        if (type === '') {
            setIsErrorType(true)
            validate = false
        }
        if (value === '') {
            setIsErrorValue(true)
            validate = false
        }

        if (configs.length > 0 && !configs[configs.length - 1].value)
            configs.splice(configs.length - 1, 1)


        if (type === t('labels.statistics.type.MULTITIMESERIES')) {
            newType = "MULTITIMESERIES"
            for (let config of configs)
                if (!config.value) {
                    setIsErrorConfigs(true)
                    validate = false
                }
        }
        else if (type === t('labels.statistics.type.TIMESERIES'))
            newType = "TIMESERIES"
        else newType = "VALUE"



        if (validate) {
            try {
                let newStatistic = { ...statistic }
                newStatistic.value = value
                newStatistic.i18n = labels
                newStatistic.type = newType

                if (newType === "MULTITIMESERIES") newStatistic.configs = configs.map(config => {
                    return {
                        value: config.value,
                        i18n: config.i18n
                    }
                })

                await Axios.put(host + '/statistics/' + newStatistic._id, newStatistic)
                setIsSnackBar(true)
                setTag("success")
                setMessage(t('messages.success.statistics.update'))
                history.goBack()
            }
            catch {
                setIsSnackBar(true)
                setTag("error")
                setMessage(t('messages.error.statistics.update'))
            }
        }
        else if(configs.length === 0) {
            setConfigs([{ value: "", i18n: {} }])
        }
    }


    useEffect(() => {
        getRequest(host + '/statistics/' + id)
        .then(res => {
            setValue(res.value)
            setLabels(res.i18n)
            setType(t("titles.statistics."+res.type))
            setStatistic(res)

            if (res.type === "MULTITIMESERIES") setConfigs(res.configs.map(config => {
                return {
                    value: config.value,
                    i18n: config.i18n
                }
            }))
        })
    }, [setStatistic, setType, setValue, setLabels])

    return (
        <div className="admin__container-bottom">
        <BreadCrumbs crumbs={[
          { href: "/#/", name: "home" },
          { href: "/#/admin", name: "admin" },
          { href: "/#/admin/statistics", name: "statistics" },
          { name: "statistics_update" }
        ]} />
            {statistic &&
                <>
                    <Typography id="statistic-update-title" component="h2" className="admin__card--title">{t('titles.statistics.update') + ' ' + t('titles.statistics.statistic')}</Typography>
                    <div >
                        <Typography className="tableComponent__lorem--text" variant="subtitle1" component="p">{t('help.statistic.form')}</Typography>
                    </div>
                    <StatisticsForm
                        tag="UPDATE"
                        value={value}
                        setValue={setValue}
                        labels={labels}
                        setLabels={setLabels}
                        type={type}
                        setType={setType}
                        configs={configs}
                        setConfigs={setConfigs}
                        types={[t('labels.statistics.type.VALUE'), t('labels.statistics.type.TIMESERIES'), t('labels.statistics.type.MULTITIMESERIES')]}

                        isErrorType={isErrorType}
                        isErrorValue={isErrorValue}
                        handleClick={handleUpdateStatistic}
                        handleReset={handleReset}
                    />
                </>}
        </div>
    )

}
