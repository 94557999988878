import React, {useState, useEffect, useContext} from 'react'
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import { UserContext } from '@context/userContext';
import getRequest from '@utils/functions/axiosRequest'
import TextLable from '@components/Common/TextLable'
import getTranslation from '@utils/functions/utilities.js'
import Typography from '@material-ui/core/Typography';
import TextFieldCommon from '@components/Common/TextField';
import AutoCompleteCommon from '@components/Common/AutoComplete';
import { host } from "@utils/config";

export default function OrganizationPage(props) {
  const [t, i18n] = useTranslation('common')
  const [user] = useContext(UserContext)
  const [langs, setLangs] = useState(null)

  const handleChangeObject = (index, value, lang) => {
    let newObject = props.object
    let newValue = value

    if (index === "lat" || index === "lng") {
      newValue = props.object.gps
      newValue[index] = value
      index = "gps"
    }

    else if (index === "country" || index === "organizationType")
      newValue = [value]

    newObject[index] = newValue
    props.setObject(newObject)
  }

  useEffect(() => {
    if (user) {
      getRequest(host + '/configurations/public?type=LANGUAGES')
        .then(res => {
          setLangs(res[0].body)
        })
    }
  }, [user])

  return (
    <>
    
      {!props.history || !props.history.name ?
        <Grid container>

        {langs && langs.map((lang,index) =>
        <Grid key={"name-"+ lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
        <TextLable
        id={"name-" + lang}
        title={t('labels.organizations.name') + " <sup>"+ lang + "</sup>"}
        value={getTranslation(props.object.name, lang, langs[langs.length - 1])}
        />
        </Grid>
        )}
        </Grid>
        
        :
        <Grid container>
        {langs && langs.map((lang,index) => 
        <Grid key={"name-"+ lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
        <TextFieldCommon
        className={props.history.name.action === "UPDATE" ? "request__color--update" : "request__color--create"}
        title={t('labels.organizations.name') + " <sup>"+ lang + "</sup>"}
        id={"name-" + lang}
        value={getTranslation(props.object.name, lang, langs[langs.length - 1])}
        type="text"
        fullWidth={true}
        handleChangeInput={(value) => handleChangeObject('name', value)}
        previous={getTranslation(props.history.name.original, lang, langs[langs.length - 1])}
        disabled={true}
        />
        </Grid>
        )
        }
        </Grid>
      }
     
      {
        !props.history || !props.history.url ?
          <TextLable
            id="url"
            title={t('labels.organizations.url')}
            value={props.object.url}
          />
          :
          <TextFieldCommon
            className={props.history.url.action === "UPDATE" ? "request__color--update" : "request__color--create"}
            title={t('labels.organizations.url')}
            id="url"
            value={props.object.url}
            type="text"
            fullWidth={true}
            handleChangeInput={(value) => handleChangeObject('url', value)}
            previous={props.history.url.original}
            disabled={true}
          />
      }

      <Typography className="textField__typography--subsubtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
        {t('titles.organizations.contact')}
      </Typography>

      {
        !props.history || !props.history.email ?
          <TextLable
            id="email"
            title={t('labels.organizations.email')}
            value={props.object.email}
          /> :
          <TextFieldCommon
            className={props.history.email.action === "UPDATE" ? "width__50 request__color--update" : "width__50 request__color--create"}
            title={t('labels.organizations.email')}
            id="email"
            value={props.object.email}
            label={t('common.labels.required')}
            type="text"
            fullWidth={true}
            handleChangeInput={(value) => handleChangeObject('email', value)}
            previous={props.history.email.original}
            disabled={true}
          />
      }
      {
        !props.history || !props.history.phones ?
          <TextLable
            id="phones"
            title={t('labels.organizations.phones')}
            value={props.object.phones}
          /> :
          <TextFieldCommon
            className={props.history.phones.action === "UPDATE" ? "width__33 request__color--update" : "width__33 request__color--create"}
            title={t('labels.organizations.phones')}
            id="phones"
            addButton={true}
            value={props.object.phones}
            label={t('common.labels.required')}
            type="text"
            fullWidth={true}
            handleChangeInput={(value) => handleChangeObject('phones', value)}
            previous={props.history.phones.original}
            disabled={true}
          />
      }
      {
        !props.history || !props.history.fax ?
          <TextLable
            id="fax"
            title={t('labels.organizations.fax')}
            value={props.object.fax}
          />
          :
          <TextFieldCommon
            className={props.history.fax.action === "UPDATE" ? "width__33 request__color--update" : "width__33 request__color--create"}
            title={t('labels.organizations.fax')}
            id="fax"
            value={props.object.fax}
            type="text"
            fullWidth={true}
            handleChangeInput={(value) => handleChangeObject('fax', value)}
            previous={props.history.fax.original}
            disabled={true}
          />
      }
      {
        !props.history || !props.history.address ?
          <TextLable
            id="address"
            title={t('labels.organizations.address')}
            value={props.object.address}
          /> :
          <TextFieldCommon
            className={props.history.address.action === "UPDATE" ? "request__color--update" : "request__color--create"}
            title={t('labels.organizations.address')}
            id="address"
            value={props.object.address}
            type="text"
            fullWidth={true}
            handleChangeInput={(value) => handleChangeObject('address', value)}
            previous={props.history.address.original}
            disabled={true}
          />
      }
      {
        !props.history || !props.history.country ?
          <TextLable
            id="country"
            title={t('labels.organizations.country')}
            value={props.object.country.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          /> :
          <AutoCompleteCommon
            className={props.history.country.action === "UPDATE" ? "width__33 request__color--update" : "width__33 request__color--create"}
            title={t('labels.organizations.country')}
            id="country"
            tag="autocomplete-vc"
            vc={true}
            path={host + "/controlled-vocabularies?field=country"}
            selectedValue={props.object.country[0]}
            handleChange={(value) => handleChangeObject('country', value)}
            language={i18n.language}
            fallbackLng={i18n.languages[i18n.languages.length - 1]}
            previous={[props.history.country.original]}
            disabled={true}
          />
      }
      {
        !props.history || !props.history.gps ?
          <TextLable
            id="lat"
            title={t('labels.organizations.gps.lat')}
            value={props.object.gps.lat}
          /> :
          <TextFieldCommon
            className={props.history.gps.action === "UPDATE" ? "width__33 request__color--update" : "width__33 request__color--create"}
            title={t('labels.organizations.gps.lat')}
            id="gps-lat"
            type="text"
            value={props.object.gps.lat}
            fullWidth={true}
            handleChangeInput={(value) => handleChangeObject('lat', value)}
            previous={props.history.gps.original ? props.history.gps.original.lat : undefined}
            disabled={true}
          />
      }
      {
        !props.history || !props.history.gps ?
          <TextLable
            id="lng"
            title={t('labels.organizations.gps.lng')}
            value={props.object.gps.lng}
          /> :
          <TextFieldCommon
            className={props.history.gps.action === "UPDATE" ? "width__33 request__color--update" : "width__33 request__color--create"}
            title={t('labels.organizations.gps.lng')}
            id="gps-lng"
            value={props.object.gps.lng}
            type="text"
            fullWidth={true}
            handleChangeInput={(value) => handleChangeObject('lng', value)}
            previous={props.history.gps.original ? props.history.gps.original.lng : undefined}
            disabled={true}
          />
      }
      {
        !props.history || !props.history.schedule ?
          <Grid container>
          {langs && langs.map((lang,index) => 
            <Grid key={"schedule-"+ lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
            <TextLable
            id={"schedule-" + lang}
            title={t('labels.organizations.schedule') + " <sup>"+ lang + "</sup>"}
            value={props.object.schedule ? getTranslation(props.object.schedule, lang, langs[langs.length - 1]) : undefined}
            /> 
            </Grid>
          )}
          </Grid>
            :
          <Grid container>
            {
              langs && langs.map((lang,index) => 
              <Grid key={"schedule-"+ lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
              <TextFieldCommon
              className={props.history.schedule.action === "UPDATE" ? "request__color--update" : "request__color--create"}
              title={t('labels.organizations.schedule') + " <sup>"+ lang + "</sup>"}
              id={"schedule-" + lang}
              multiline={true}
              fullWidth={true}
              value={props.object.schedule ? getTranslation(props.object.schedule, lang, langs[langs.length - 1]): undefined}
              type="text"
              handleChangeInput={(value) => handleChangeObject('schedule', value)}
              previous={props.history.schedule.original ? getTranslation(props.history.schedule.original, lang, langs[langs.length - 1]) : undefined}
              disabled={true}
              />
              </Grid>
              )
          }
          </Grid>
      }

      <Typography className="textField__typography--subsubtitle" variant="subtitle1" component="h2" color="textSecondary" gutterBottom>
        {t('titles.organizations.aditional')}
      </Typography>

      {
        !props.history || !props.history.organizationType ?
          <TextLable
            id="organizationType"
            title={t('labels.organizations.organizationType')}
            value={props.object.organizationType.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          />
          : <AutoCompleteCommon
            className={props.history.organizationType.action === "UPDATE" ? "width__50 request__color--update" : "width__50 request__color--create"}
            title={t('labels.organizations.organizationType')}
            id="organizationType"
            tag="autocomplete-vc"
            vc={true}
            path={host + "/controlled-vocabularies?field=organizationType"}
            selectedValue={props.object.organizationType[0]}
            handleChange={(value) => handleChangeObject('organizationType', value)}
            language={i18n.language}
            fallbackLng={i18n.languages[i18n.languages.length - 1]}
            previous={props.history.organizationType.original}
            disabled={true}
          />
      }
      {/* {
        !props.history || !props.history.liability ?
          <TextLable
            id="liability"
            title={t('labels.organizations.liability')}
            value={props.object.liability.map(val => getTranslation(val.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]))}
          /> :
          <AutoCompleteCommon
            className={props.history.liability.action === "UPDATE" ? "width__50 request__color--update" : "width__50 request__color--create"}
            title={t('labels.organizations.liability')}
            id="liability"
            tag="autocomplete-checkbox"
            vc={true}
            path={host + "/controlled-vocabularies?field=liability"}
            selectedValue={props.object.liability}
            handleChange={(value) => handleChangeObject('liability', value)}
            language={i18n.language}
            fallbackLng={i18n.languages[i18n.languages.length - 1]}
            previous={props.history.liability.original}
            disabled={true}
          />
      } */}
      {
        !props.history || !props.history.legalForm ?
          <TextLable
            id="legalForm"
            title={t('labels.organizations.legalForm')}
            value={props.object.legalForm ? getTranslation(props.object.legalForm.i18n, i18n.language, i18n.languages[i18n.languages.length - 1]) : t('common.labels.notDefined')}
          /> :
          <AutoCompleteCommon
            className={props.history.legalForm.action === "UPDATE" ? "width__50 request__color--update" : "width__50 request__color--create"}
            title={t('labels.organizations.legalForm')}
            id="legalForm"
            tag="autocomplete-vc"
            vc={true}
            path={host + "/controlled-vocabularies?field=legalForm"}
            selectedValue={props.object.legalForm}
            handleChange={(value) => handleChangeObject('legalForm', value)}
            language={i18n.language}
            fallbackLng={i18n.languages[i18n.languages.length - 1]}
            previous={props.history.legalForm.original}
            disabled={true}
          />
      }
      {
        !props.history || !props.history.comments ?
          <Grid container>
          {langs && langs.map((lang,index) => 
            <Grid key={"comments-"+ lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
            
            <TextLable
            id={"comments-" + lang}
            title={t('labels.organizations.comments') + " <sup>"+ lang + "</sup>"}
            value={props.object.comments ? getTranslation(props.object.comments, lang, langs[langs.length - 1]) : undefined}
            /> 
            </Grid>
          )}
          </Grid>
          :
          <Grid container>
          {
            langs && langs.map((lang,index) => 
            <Grid key={"comments-"+ lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
            <TextFieldCommon
            className={props.history.comments.action === "UPDATE" ? "request__color--update" : "request__color--create"}
            title={t('labels.organizations.comments') + " <sup>"+ lang + "</sup>"}
            id={"comments-" + lang}
            multiline={true}
            value={props.object.comments ? getTranslation(props.object.comments, lang, langs[langs.length - 1]) : undefined}
            fullWidth={true}
            type="text"
            handleChangeInput={(value) => handleChangeObject('comments', value)}
            previous={props.history.comments.original ? getTranslation(props.history.comments.original, lang, langs[langs.length - 1]) : undefined}
            disabled={true}
            />
            </Grid>
            )
          }
            </Grid>
      }
      {
        !props.history || !props.history.notes ?
          <Grid container>
          {langs && langs.map((lang,index) => 
            <Grid key={"notes-"+ lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
            <TextLable
            id={"notes-" + lang}
            title={t('labels.organizations.notes') + " <sup>"+ lang + "</sup>"}
            value={props.object.notes ? getTranslation(props.object.notes, lang, langs[langs.length - 1]) : undefined}
            />
            </Grid>
          )}
          </Grid>
          :
          <Grid container>
          {
            langs && langs.map((lang,index) => 
          <Grid key={"notes-"+ lang + index} item xs={12} md={6} className={index % 2 === 0 ? "fields__padding--right" : "fields__padding--left"}>
          <TextFieldCommon
            className={props.history.notes.action === "UPDATE" ? "request__color--update" : "request__color--create"}
            title={t('labels.organizations.notes') + " <sup>"+ lang + "</sup>"}
            id={"notes-" + lang}
            multiline={true}
            value={props.object.notes ? getTranslation(props.object.notes, lang, langs[langs.length - 1]) : undefined}
            fullWidth={true}
            type="text"
            handleChangeInput={(value) => handleChangeObject('notes', value)}
            previous={props.history.notes.original ? getTranslation(props.history.notes.original, lang, langs[langs.length - 1]) : undefined}
            disabled={true}
          />
            </Grid>
            )
          }
          </Grid>
      }

    </>
  )
}
