import React, { useState, createContext, useEffect } from 'react'

import Axios from 'axios'
import { host } from '@utils/config'


export const UserContext = createContext()

export const UserContextProvider = props => {
  const [userLogin, setUserLogin] = useState(null)

  useEffect(() => {
    Axios.defaults.withCredentials = true;
    Axios.get(host + '/users/me')
      .then(res => {
        setUserLogin(res.data)
      })
      .catch(error => {
        setUserLogin(null)
      })
  }, [setUserLogin])

  return (
    <UserContext.Provider value={[userLogin, setUserLogin]}>
      {props.children}
    </UserContext.Provider>
  )
}