import React, { useState, useContext } from 'react'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { host } from '@utils/config'
import { useTranslation } from 'react-i18next';

export default function ExportMenu(props) {
  const [t] = useTranslation('common')
  const [item] = useState(props.item)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip placement="top" title={t('tooltip.repositories.exportRepository')}>
        <IconButton id={"export-menu-button-" + props.id} aria-label="attach" size="small" onClick={handleClickExport}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseExportMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >

        <MenuItem role="selectMenu" key="json">
          <Link id="export-json-button" className="repositories__a--style" href={host + "/repositories/" + item._id + "?action=export"} onClick={handleCloseExportMenu}>JSON</Link>
        </MenuItem>
        <MenuItem role="selectMenu" key="xml">
          <Link id="export-xml-button" className="repositories__a--style" href={host + "/repositories/" + item._id + "?action=export&format=xml"} onClick={handleCloseExportMenu}>XML</Link>
        </MenuItem>
        <MenuItem role="selectMenu" key="csv">
          <Link id="export-csv-button" className="repositories__a--style" href={host + "/repositories/" + item._id + "?action=export&format=csv"} onClick={handleCloseExportMenu}>CSV</Link>
        </MenuItem>
        <MenuItem role="selectMenu" key="jsonld">
          <Link id="export-jsonld-button" className="repositories__a--style" href={host + "/repositories/" + item._id + "?action=export&format=jsonld"} onClick={handleCloseExportMenu}>JSON-LD</Link>
        </MenuItem>
      </Menu>
    </>
  )
}